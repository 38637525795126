import { isArray } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Alert } from '../../feedback/alert/alert.component';
import { TrackingNumberDetailProps } from './tracking-number-detail';
import { Timeline } from '../../data-display/timeline/timeline.component';
export const TrackingNumberDetailComponent = (props: TrackingNumberDetailProps) => {
  const { waybillResponse, label } = props;
  const { errors } = waybillResponse;
  const { data } = waybillResponse;
  console.log(data);
  const [errorMessages, setErrorMessages] = useState<string[]>()
  useEffect(() => {
    let errorMsg: string[] = [];
    if (errors && isArray(errors)) {
      errors.forEach(err => {
        errorMsg.push(err.error_message)
      })
    }
    setErrorMessages(errorMsg || ['Kesalahan Tidak Diketahui'])
  }, [props])
  return (errorMessages && (errorMessages.length > 0)) ? <Alert
    message="Error"
    description={errorMessages.toString() || 'Kesalahan Tidak Diketahui'}
    closable={false}
    type="error"
  /> : data && <div className="container-fluid tracking-number-detail-wrapper">
    <div className="container py-1 px-lg-5">
      <div className="row">
        <div className="col-lg-6 p-2 h-100">
          <div className="card-courier">
            <div className="title">{label?.courier || 'Courier'}</div>
            <img src={'https://nx-design-system-web-development.clodeo.com/img/logo/courier/code/' + data.summary.courierCode + '.svg'} alt="" srcSet="" className="tracking img-fluid img-cour" />
          </div>
        </div>
        <div className="col-lg-6 p-2 h-100">
          <div className="card-courier">
            <div className="title">{label?.courier || 'Trackingnumber'}</div>
            <div className="tracking">
              {data?.summary?.trackingNumber || '...'}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid summary py-1">
      <div className="row summary">
        <div className="col-lg-4 p-2 h-100">
          <div className="card-detail">
            <div className="title">{label?.shipDate || 'Tanggal Pengiriman'}</div>
            <div className="value">{data?.summary?.shipDate ? moment(data?.detail?.shipDate).format('DD - MM - YYYY') : '...'}</div>
          </div>
        </div>
        <div className="col-lg-4 p-2 h-100">
          <div className="card-detail">
            <div className="title">{label?.deliveryDate || 'Tanggal Diterima'}</div>
            <div className="value">{data?.summary?.deliveryDate ? moment(data?.detail?.shipDate).format('DD - MM - YYYY') : '...'}</div>
          </div>
        </div>
        <div className="col-lg-4 p-2 h-100">
          <div className="card-detail">
            <div className="title">{label?.shippperName || 'Pengirim'}</div>
            <div className="value">{data?.summary?.shipperName || '...'}</div>
          </div>
        </div>
        <div className="col-lg-4 p-2 h-100">
          <div className="card-detail">
            <div className="title">{label?.origin || 'Asal'}</div>
            <div className="value">{data?.summary?.origin || '...'}</div>
          </div>
        </div>
        <div className="col-lg-4 p-2 h-100">
          <div className="card-detail">
            <div className="title">{label?.destination || 'Tujuan'}</div>
            <div className="value">{data?.summary?.destination || '...'}</div>
          </div>
        </div>
        <div className="col-lg-4 p-2 h-100">
          <div className="card-detail">
            <div className="title">{label?.receiverName || 'Tujuan'}</div>
            <div className="value">{data?.summary?.receiverName || '...'}</div>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid outbounds">
      <div className="outbounds-label px-0 py-3 mb-2">
        {label?.outbounds || 'Outbound'}
      </div>
      <div className="time-line">
        <Timeline className="time-line-tracking-number" mode="left">
          {
            data.outbounds && data.outbounds.map(outbound => (
              <Timeline.Item label={
                <div className="time-line-label d-flex flex-column justify-content-between h-100">
                  <label htmlFor="">
                    {outbound.outboundDate ? moment(outbound.outboundDate).format('DD-MM-YYYY') : ''}
                  </label>
                  <label htmlFor="" className="smuy">
                    {outbound.outboundTime ? moment(outbound.outboundTime).format('HH : MM') : ''}
                  </label>
                </div>
              }>
                <div className="d-flex flex-row w-100 flex-wrap justify-content-between h-100">
                  <div className="time-line-label col-lg-10 p-0 text-left d-flex flex-column justify-content-between">
                    <label htmlFor="">
                      {outbound.cityName || ''}
                    </label>
                    <label htmlFor="" className="smuy text-left">
                      {outbound.outboundDescription || ''}
                    </label>
                  </div>
                  <div className="status text-left col-lg-2 p-0">
                    {outbound.outboundCode || ''}
                  </div>
                </div>
              </Timeline.Item>
            ))
          }
        </Timeline>
      </div>
    </div>
    <div className="container-fluid ship-date">
      <div className="outbounds-label px-0 mb-3">
        {label?.shipDate || 'Waktu Pengiriman'}
      </div>
      <div className="d-flex flex-row justify-content-between time-line">
        <div className="time-line d-flex">
          <div className="time-line-label d-flex flex-column justify-content-between h-100 mr-5">
            <label htmlFor="">
              {data.deliveryStatus.podDate ? moment(data.deliveryStatus.podDate).format('DD-MM-YYYY') : '...'}
            </label>
            <label htmlFor="" className="smuy">
              {data.deliveryStatus.podTime ? moment(data.deliveryStatus.podTime).format('HH : MM') : ''}
            </label>
          </div>
          <div className="time-line-label d-flex flex-column justify-content-between h-100">
            <label htmlFor="">
              {data.deliveryStatus.podReceiver || '...'}
            </label>
          </div>
        </div>
        <div className="status text-left col-lg-2 p-0">
          {data?.deliveryStatus?.status || ''}
        </div>
      </div>
    </div>
  </div>
}

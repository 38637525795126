import React, { useState, useRef } from 'react';

import { BaseListComponent } from 'apps/admin-web/src/app/components/base-component/list/base-list.component';
import {
  IColumn,
  IIncludes,
} from 'apps/admin-web/src/app/components/base-component/list/base-list';

import { environment } from 'apps/admin-web/src/environments/environment';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { AdminTenantBankRestService } from '@clodeo/libs/core/rest/admin/tenant-bank/admin-tenant-bank-rest.service';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';

import { IModalValue } from '@clodeo/clodeo-ui/components/feedback/modal/modal';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { TenantsBankCreateComponent } from '../create/tenants-bank-create.component';
import { TenantsBankUpdateComponent } from '../update/tenants-bank-update.component';
import { Confirms } from '@clodeo/clodeo-ui/components/feedback/confirm/confirm.component';
import { AclService } from 'apps/admin-web/src/app/core/auth/acl.service';
import { LogdetailComponent } from 'apps/admin-web/src/app/components/user-log-detail/log-detail.component';
import { SelectButton, SelectGroup } from '@clodeo/clodeo-ui/components/data-entry/select-button/select-button.component';
import { Switch } from '@clodeo/clodeo-ui/components/data-entry/switch/switch.component';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { compact, get, startCase } from 'lodash';
import { Select } from '@clodeo/clodeo-ui/components/data-entry/select/select.component';

const aclService: AclService = new AclService;

const authenticationService: AuthenticationService = new AuthenticationService();
const adminTenantBankRestService = new AdminTenantBankRestService(
  environment.ENDPOINTS,
  authenticationService.axiosInterceptors
);

export const TenantsBankListComponent: React.FC = () => {
  const baseListRef = useRef<any>();
  const [modalValue, setModalValue] = useState<IModalValue>();
  const confirm: Confirms = new Confirms();
  const [statusActive, setStatusActive] = useState<boolean>(true);
  const [isTenant, setIsTenant] = useState<boolean>(true);

  const includes: IIncludes = {
    refresh: true,
    refreshAction: () => {
      baseListRef.current.resetFilters();

      setTimeout(() => {
        baseListRef.current.callLoadData();
      }, 10);
    },
    keyword: true,
    toogleOptions: true,
    extraButtons: compact([
      aclService.can(['wallet.tenant_bank.create']) && ({
        label: 'BANK TENANT',
        iconName: 'deo-plus',
        type: 'filter',
        action: () =>
          setModalValue({
            visible: true,
            title: 'Tambah Tenant Bank',
            contentTemplate: (
              <TenantsBankCreateComponent
                onCancel={() => setModalValue(null)}
                onFinish={() => {
                  baseListRef.current.callLoadData();
                  setModalValue(null);
                }}
              />
            ),
          }),
      }),
    ]),
  };

  function callData() {
    setTimeout(() => {
      baseListRef.current.callLoadData()
    }, 10);
  }

  const statusFilter = () => (
    <SelectGroup type="switch" onChange={(e) => { setStatusActive(e.target.value); callData(); }} defaultValue={statusActive}>
      <SelectButton type="switch" value={true}>{'Aktif'}</SelectButton>
      <SelectButton type="switch" value={false}>{'Tidak Aktif'}</SelectButton>
    </SelectGroup>
  );

  const columns: IColumn[] = compact([
    {
      key: '1',
      dataIndex: 'tenant',
      title: 'TENANTS',
      width: '150px',
      render: (val) => val.companyInfo.companyName,
    },
    {
      key: '2',
      dataIndex: 'accountNumber',
      title: 'NO. REKENING',
      width: '100px',
    },
    {
      key: '3',
      dataIndex: 'accountName',
      title: 'NAMA REKENING',
      width: '100px',
    },
    {
      key: '4',
      dataIndex: 'bank',
      title: 'BANK',
      width: '150px',
      render: (val) => val.bankName,
    },
    {
      key: '5',
      dataIndex: 'district',
      title: 'CABANG BANK',
      width: '100px',
      render: (val) => {
        if (val == null) {
          return <span>-</span>;
        } else {
          return val;
        }
      },
    }, {
      key: 'status',
      dataIndex: 'status',
      title: 'STATUS',
      width: '100px',
      render: (val) => {
        if (val == null) {
          return <span>-</span>;
        } else {
          return startCase(val);
        }
      },
    },
    {
      key: '6',
      title: 'LOG DETAIL',
      dataIndex: 'tenant.id',
      ignoreExprt: true,
      width: '80px',
      align: 'center',
      render: (val: any, record) => (
        <Button
          label="Open"
          type="ghosted"
          className="p-2"
          onClick={() => prepareUserLogDetailModal(record.tenant.id)}
        />
      ),
    },
    aclService.can(['wallet.tenant_bank.edit']) && ({
      key: '7',
      title: 'ACTION',
      align: 'center',
      width: '80px',
      render: (val, data) => {
        const record = {
          ...data,
          bank: get(data, 'bank.bankName'),
          tenant: get(data, 'tenant.companyInfo.companyName'),
          tenantId: get(data, 'tenant.id'),
          tenantBankId: get(data, 'bankId'),
        };
        return (
          <>
            {
              data?.status === 'verified' &&
              <Button
                className="p-2 mr-4"
                iconName="i-PencilLine"
                type="warning"
                onClick={() =>
                  setModalValue({
                    visible: true,
                    title: 'Update Tenant Bank',
                    contentTemplate: (
                      <TenantsBankUpdateComponent
                        record={record}
                        onCancel={() => setModalValue(null)}
                        onFinish={() => {
                          baseListRef.current.callLoadData();
                          setModalValue(null);
                        }}
                      />
                    ),
                  })
                }
              />
            }
          </>
        );
      },
    }),
    {
      key: '8',
      title: 'STATUS',
      dataIndex: 'inactive',
      width: '80px',
      align: 'center',
      render: (val: boolean, record) => (
        <Switch
          disabled={!aclService.can(["wallet.tenant_bank.edit"])}
          checked={!val}
          onChange={(check) => setStatus(check, record.id, record.tenant.id)}
        />
      ),
    },
  ]);

  function setStatus(active: boolean, tenantBankId: string, tenantId: number) {
    // baseListRef.current.showLoading();
    const obs = adminTenantBankRestService.markIsActive(active, tenantBankId, tenantId);
    new HandleService().handleRequest({
      obs: obs,
      successMessage: 'Berhasil diubah',
      errorMessage: '',
      onDone: (res) => res && baseListRef.current.callLoadData(),
    });
  }

  function loadDataObservable(params) {
    const filterValue = {
      active: statusActive,
      tenantId: isTenant ? params.keyword : null,
      keyword: !isTenant ? params.keyword : null,
    };
    const qParams = { ...params, ...filterValue };
    return adminTenantBankRestService.findAll(qParams);
  }

  function showConfirmDeleteTenant(TenantBankId, tenantId) {
    confirm.show({
      title: 'Delete Tenant Bank',
      content: <p>Apakah anda yakin ingin menghapus tenant bank?</p>,
      onOk: () => deleteTenantId(TenantBankId, tenantId)
    });
  }


  function deleteTenantId(TenantBankId, tenantId) {
    const payload = {
      deleteInfo: '',
    };
    return adminTenantBankRestService
      .deleteTenantBank(TenantBankId, payload, tenantId)
      .subscribe();
  }

  function prepareUserLogDetailModal(tenantId) {
    baseListRef.current.showLoading(true);
    const obs = adminTenantBankRestService.getAdminHistory(tenantId);
    new HandleService().handleRequest({
      obs,
      hideError: true,
      onError: (err) => {
        baseListRef.current.showLoading(false);
      },
      onDone: (res) => {
        baseListRef.current.showLoading(false);
        setModalValue({
          title: 'Log Detail',
          visible: true,
          data: null,
          contentTemplate: <LogdetailComponent record={res} />
        })
      }
    })
  }

  function selectIsTenant() {
    const options: any[] = [
      { label: 'Tenant ID', value: true },
      { label: 'Keyword', value: false },
    ];
    return (
      <Select size='large' options={options} value={isTenant} onChange={setIsTenant} />
    )
  }

  return (
    <>
      <BaseListComponent
        listId="tenants-bank-list"
        ref={baseListRef}
        columns={columns}
        loadDataObservable={loadDataObservable}
        includes={{
          ...includes,
          extraComponent: statusFilter(),
          placeholderKeyword: isTenant && 'Cari Tenant ID',
          keywordType: isTenant && 'number',
          suffixComponent: selectIsTenant(),
        }}
      />
      {modalValue && (
        <Modal
          {...modalValue}
          footer={null}
          className="admin"
          onCancel={() => setModalValue(null)}
        >
          {modalValue.contentTemplate}
        </Modal>
      )}
    </>
  );
};

import React from 'react';
import { WidgetChart } from '@clodeo/clodeo-ui/components/chart/chart.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import * as AccountingJS from 'accounting-js';

export const DashboardSummary = (props: {
  summary: {
    creditCOD: number;
    creditRemaining: number;
    creditTotal: number;
    feeCOD: number;
    countCODUnpaid: number;
  };
  salesCOD: number;
  salesCODTotal: number;
  isLoading: boolean;
}) => {
  const { summary, salesCOD, salesCODTotal } = props;

  return (
    <>
      <Spinner spinning={props.isLoading} size="large">
        <div className="row w-100 p-2 m-auto">
          <div className="col-12 col-lg-4 p-2">
            <WidgetChart
              label="Total Pencairan Dana"
              value={
                summary.creditCOD &&
                AccountingJS.formatMoney(summary.creditCOD, {
                  symbol: 'Rp ',
                  format: '%s %v',
                })
              }
              icon={<CreditCOD />}
            />
          </div>
          <div className="col-12 col-lg-4 p-2">
            <WidgetChart
              label="Total Fee COD Keseluruhan"
              value={
                summary.feeCOD &&
                AccountingJS.formatMoney(summary.feeCOD, {
                  symbol: 'Rp ',
                  format: '%s %v',
                })
              }
              icon={<FeeCOD />}
            />
          </div>
          <div className="col-12 col-lg-4 p-2">
            <WidgetChart
              label="Total Pencairan Dana"
              value={
                summary.creditTotal &&
                AccountingJS.formatMoney(summary.creditTotal, {
                  symbol: 'Rp ',
                  format: '%s %v',
                })
              }
              icon={<CreditAmount />}
            />
          </div>
          <div className="col-12 col-lg-4 p-2">
            <WidgetChart
              label="Total Sisa Kredit"
              value={
                summary.creditRemaining &&
                AccountingJS.formatMoney(summary.creditRemaining, {
                  symbol: 'Rp ',
                  format: '%s %v',
                })
              }
              icon={<CreditRemaining />}
            />
          </div>
          <div className="col-12 col-lg-4 p-2">
            <WidgetChart
              label="Total COD Penjualan"
              value={salesCOD}
              icon={<CODAmount />}
            />
          </div>
          <div className="col-12 col-lg-4 p-2">
            <WidgetChart
              label="Jumlah COD Penjualan"
              value={
                salesCODTotal &&
                AccountingJS.formatMoney(salesCODTotal, {
                  symbol: 'Rp ',
                  format: '%s %v',
                })
              }
              icon={<TotalAmount />}
            />
          </div>
          <div className="col-12 col-lg-4 p-2">
            <WidgetChart
              label="COD dibayar dengan status belum lunas"
              value={ summary.countCODUnpaid }
              icon={<CreditRemaining />}
            />
          </div>
        </div>
      </Spinner>
    </>
  );
};

const TotalAmount = () => (
  <img
    src="https://nx-design-system-web-development.clodeo.com/img/admin-clodeo/dashboard/widget/sales-COD-total-amount.svg"
    alt="total-amount"
    srcSet=""
  />
);
const CODAmount = () => (
  <img
    src="https://nx-design-system-web-development.clodeo.com/img/admin-clodeo/dashboard/widget/sales-COD-count.svg"
    alt="cod-amount"
    srcSet=""
  />
);

const CreditRemaining = () => (
  <img
    src="https://nx-design-system-web-development.clodeo.com/img/admin-clodeo/dashboard/widget/credit-remaining.svg"
    alt="credit-remain"
    srcSet=""
  />
);
const CreditCOD = () => (
  <img
    src="https://nx-design-system-web-development.clodeo.com/img/admin-clodeo/dashboard/widget/creditCOD.svg"
    alt="credit-cod"
    srcSet=""
  />
);
const CreditAmount = () => (
  <img
    src="https://nx-design-system-web-development.clodeo.com/img/admin-clodeo/dashboard/widget/credit-total.svg"
    alt="credit-amount"
    srcSet=""
  />
);
const FeeCOD = () => (
  <img
    src="https://nx-design-system-web-development.clodeo.com/img/admin-clodeo/dashboard/widget/fee-COD.svg"
    alt="fee-cod"
    srcSet=""
  />
);

import { Radio as BaseRadio } from 'antd';
import React from 'react';
import { RadioProps } from './radiobutton';
const [RadioGroup] = [BaseRadio.Group];
const Radio = (props: RadioProps) => (
  <BaseRadio
    {...props}
    className={`radio-deo ${props.varian || 'basic'}  ${props.className || ''} `}
  />
)
export {
  Radio,
  RadioGroup
}
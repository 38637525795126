import React from 'react';
import moment from 'moment';
import { Table } from '@clodeo/clodeo-ui/components/data-display/table/table.component';
import { IColumn } from 'apps/admin-web/src/app/components/base-component/list/base-list';

export const VerificationLogDetailComponent = (props: {
  record: any[];
}) => {
  const { record } = props;
  const columns: IColumn[] = [
    {
      key: '1',
      title: 'Tanggal',
      dataIndex: 'logDateUtc',
      width: '130px',
      render: (value) => moment(value).format('DD MMM YYYY HH:mm')
    },
    {
      key: '2',
      title: 'User',
      dataIndex: 'user',
      width: '150px',
      render: (value, record) => record.user.fullName
    },
    {
      key: '3',
      title: 'Deskripsi',
      dataIndex: 'description',
      width: '250px',
      render: (value: any, record: any) => {return record.description + ' ' + (record.detailDescription ? record.detailDescription : '')}
    },
  ]

  return <>
    {record && record.length ? <div className="table-bg-wrapper" style={{ width: '45vw' }}>
      <Table
        columns={columns}
        size="small"
        dataSource={record}
        scroll={{ y: 350 }}
      />
    </div> : <span style={{ width: '20vw' }} className="text-center">Belum ada history perubahan !</span>}
  </>
}
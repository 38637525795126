import { throwError } from 'rxjs';
import { RequestService } from '../../request.service';

export class DownloadFileRestService {
  requestService = new RequestService;
  request: RequestService;
  /**
   * 
   * @param baseUrl only from environment.ENDPOINTS
   * @param useInterceptor 
   * @param withoutInterceptor 
   */
  constructor(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
    this.request = this.requestService.new(`${baseUrl}`, useInterceptor, withoutInterceptor);
  }

  /**
   * Download File tenant shipping integration
   * @param qParams 
   */
  downloadCompressedFile(payload) {
    return this.request.post(`/download-compressed-file/tenant`, payload, {
      responseType: 'arraybuffer',
    });
  }
}

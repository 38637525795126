import { RequestService } from '../../request.service';

export class AccessRoleGroupsRestService {
  requestService = new RequestService;
  request: RequestService;

  /**
   * 
   * @param baseUrl only from environment.ENDPOINTS.API_ADMIN 
   * @param useInterceptor 
   * @param withoutInterceptor 
   */
  constructor(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
    this.request = this.requestService.new(`${baseUrl}/access-role-groups`, useInterceptor, withoutInterceptor);
  }

  findAll(params?) {
    return this.request.get('', {params});
  }

}
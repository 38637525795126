import { RequestService } from '../../request.service';

export class AdminCODListRestService {
  requestService = new RequestService;
  request: RequestService;
  /**
   * 
   * @param baseUrl only from environment.ENDPOINTS
   * @param useInterceptor 
   * @param withoutInterceptor 
   */
  constructor(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
    this.request = this.requestService.new(`${baseUrl['API_ADMIN']}/cod-list`, useInterceptor, withoutInterceptor);
  }

  /**
   * Get list of COD Credit Transaction Line
   * @param qParams 
   */
  findAll(qParams) {
    return this.request.get('', qParams)
  }

  /**
   * Get list of COD Credit Transaction Line with more detail search params
   * @param qParams
   */
  findAllQ(qParams) {
    return this.request.post('q', qParams)
  }

}

import React from 'react';
import './custom-training-schedule-form.component.scss'
import moment from 'moment-timezone';
import { Form } from 'antd';

import { Input } from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';
import { Table } from '@clodeo/clodeo-ui/components/data-display/table/table.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { Action } from '@clodeo/clodeo-ui/components/general/action/action.component';
import { ICustomTrainingSchedulesFormProps } from './custom-training-schedule-form';
import { IColumn } from 'apps/admin-web/src/app/components/base-component/list/base-list';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { DatePicker } from '@clodeo/clodeo-ui/components/data-entry/datepicker/datepicker.component';

// Extra configs
const notif = new Notifications

export const CustomTrainingSchedulesFormComponent = (props: ICustomTrainingSchedulesFormProps) => {
  const columns: IColumn[] = [
    {
      key: '1',
      width: '50px',
      title: 'Jadwal Training',
      dataIndex: 'trainingDate',
      render: (val) => <p>{moment(val).format('DD-MM-YYYY')}</p>
    },
    {
      key: '2',
      width: '50px',
      title: 'Status',
      dataIndex: 'inactive',
      render: (val) => <p>{val ? 'Tidak' : 'Aktif'}</p>
    },
    {
      key: '3',
      width: '50px',
      title: 'Kuota',
      dataIndex: 'quota',
    },
    {
      key: '4',
      width: '50px',
      title: 'Aksi',
      dataIndex: 'action',
      render: (i) => <p><Action type="delete" onClick={() => onRemoveLine(i)} /></p>
    }
  ];

  const { form } = props;
  const required = [{ required: true, message: 'Kolom ini wajib diisi!' }];

  function onSelectChange(value: any) {
    form.setFieldsValue({
      trainingDate: moment(value).format('YYYY-MM-DD')
    })
  }

  function onRemoveLine(i: number) {
    const customShippingIntegrationTrainingDates = [...form.getFieldValue('customShippingIntegrationTrainingDates')];
    customShippingIntegrationTrainingDates.splice(i, 1);
    form.setFieldsValue({ customShippingIntegrationTrainingDates });

  }

  function onSubmit() {
    if (formTraininingDateValid()) {
      const customShippingIntegrationTrainingDates = [...form.getFieldValue('customShippingIntegrationTrainingDates')];
      customShippingIntegrationTrainingDates.push(form.getFieldsValue(['trainingDate', 'inactive', 'quota']))
      form.setFieldsValue({ customShippingIntegrationTrainingDates });
    }
  }

  function formTraininingDateValid() {
    if (form.getFieldValue('trainingDate')) {
      return true;
    } else {
      notif.show({
        type: 'warning',
        title: 'Peringatan',
        description: 'Mohon pilih jadwal training',
        useService: true
      })
      return false;
    }
  }

  return (
    <div className="container-fluid">
      <div className="d-flex flex-lg-row flex-column">
        <div className="p-2 col-3">
          <div className="pb-3">
            <label className="field-label" htmlFor="">Kuota Tenant</label>
            <Form.Item
              name="quota"
              rules={required}
            >
              <Input type="number" />
            </Form.Item>
          </div>
          <div>
              <DatePicker onChange={onSelectChange} className="w-100"/>
          </div>
        </div>
        <div className="p-2 col-9">
          <div className="text-left pb-3 mt-1 pt-4">
            <Button submit icon iconName="deo-plus" iconPosition="left" type="filter" label="Tambah" onClick={onSubmit} />
          </div>
          <Table
            key={'table-create-training-schedule'}
            className="w-100"
            style={{ backgroundColor: '#F9FBFC', minHeight: '300px' }}
            rowKey="id"
            columns={columns}
            dataSource={form.getFieldValue('customShippingIntegrationTrainingDates')
            }
            scroll={{ y: 250 }}
          />
        </div>
      </div>
    </div >
  )
}
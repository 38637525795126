import { RangePicker } from '@clodeo/clodeo-ui/components/data-entry/datepicker/datepicker.component';
import { Option, Select } from '@clodeo/clodeo-ui/components/data-entry/select/select.component';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { AdminPickerRestService } from '@clodeo/libs/core/rest/admin/picker/admin-picker-rest.service';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
const authenticationService: AuthenticationService = new AuthenticationService();
const adminPickerRestService = new AdminPickerRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const { handleRequest } = new HandleService;
export const HistoryTransactionMoreFilterComponent = (porps: { form?: FormInstance<any> }) => {
  const [availablePayments, setAvailablePayments] = useState<any[]>();
  const { form } = porps;

  function onChangePaymentDate() {
    const paymentDate = form.getFieldValue('paymentDate');
    paymentDate && form.setFieldsValue({
      lowPaymentDate: moment(paymentDate[0]).format('YYYY-MM-DD'),
      highPaymentDate: moment(paymentDate[1]).format('YYYY-MM-DD')
    })
  }

  function onChangeCreateDate() {
    const createDate = form.getFieldValue('createDate');
    createDate && form.setFieldsValue({
      lowCreatedDate: moment(createDate[0]).format('YYYY-MM-DD'),
      highCreatedDate: moment(createDate[1]).format('YYYY-MM-DD')
    })
  }

  function loadAvailablePayment() {
    const obs = adminPickerRestService.getAvailablePayment()
    handleRequest({
      obs,
      onDone: (res) => setAvailablePayments(res.data)
    })
  }

  useEffect(() => {
    loadAvailablePayment()
  }, []);

  return <div className="row">
    <div className="col-lg-4" style={{ width: '300px' }}>
      <div className="form-group">
        <label htmlFor="">Tanggal Dibuat</label>
        <Form.Item name="createDate">
          <RangePicker onChange={onChangeCreateDate} className="w-100" />
        </Form.Item>
      </div>
    </div>
    <div className="col-lg-4" style={{ width: '300px' }}>
      <div className="form-group">
        <label htmlFor="">Tanggal Dibayar</label>
        <Form.Item name="paymentDate">
          <RangePicker onChange={onChangePaymentDate} className="w-100" />
        </Form.Item>
      </div>
    </div>
    <div className="col-lg-4" style={{ width: '300px' }}>
      <div className="form-group">
        <label htmlFor="">Tipe Pembayaran</label>
        <Form.Item name="paymentChannel">
          <Select mode="multiple">
          {
            availablePayments && availablePayments.map(payment => (
              <Option value={payment.code}>
                {payment.name}
              </Option>
            ))
          }
        </Select>
        </Form.Item>
    </div>
  </div>
  </div >
}


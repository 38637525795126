import { RequestService } from "../../request.service";

export class UserRestService {
  requestService = new RequestService;
  request: RequestService;

  constructor(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
    this.request = this.requestService.new(`${baseUrl['API_ACCOUNT']}/users`, useInterceptor, withoutInterceptor);
  }

  alreadyLoggedIn() {
    return this.request.get<any>(`/me`);
  }

  /**
   * 
   * @param qParams get all tenant users
   */

  activationAccount(qParams) {
    return this.request.get<any>('/confirm-email', qParams);
  }

  /**
   * 
   * @param userId user id for confirm account
   */

  confirmAccount(userId) {
    return this.request.put<any>(`/${userId}/confirm-email`);
  }

}

import { AdminRestService } from '@clodeo/libs/core/rest/admin/admin/admin-rest.service';
import { IColumn, IIncludes } from 'apps/admin-web/src/app/components/base-component/list/base-list';
import { BaseListComponent } from 'apps/admin-web/src/app/components/base-component/list/base-list.component'
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { capitalize, get, pick } from 'lodash';
import moment from 'moment';
import React, { Fragment, useState, useRef } from 'react';
import { HistoryTransactionMoreFilterComponent } from '../more-filter/history-transaction-more-filter.component';
import * as accountingJS from 'accounting-js';
import { Tag } from '@clodeo/clodeo-ui/components/data-display/tag/tag.component';
import { ExportService } from '@clodeo/libs/core/export/export.service';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';

const authenticationService: AuthenticationService = new AuthenticationService();
const adminRestService = new AdminRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const filters = ['keyword', 'options.take', 'options.skip', 'options.includeTotalCount', 'lowCreatedDate', 'highCreatedDate', 'lowPaymentDate', 'highPaymentDate', 'paymentChannel'];
const { handleRequest } = new HandleService;
const exportService: ExportService = new ExportService;

export const HystoryTransactionListComponent = () => {
  const [loadingExport, setloadingExport] = useState(false);
  const baseListRef = useRef<any>();
  const columns: IColumn[] = [
    {
      key: '1',
      dataIndex: 'id',
      title: 'Id',
      width: '100px',
      ignoreDisply: true
    },
    {
      key: '2',
      dataIndex: 'createDateUtc',
      title: 'TGL.DIBUAT',
      width: '100px',
      align: 'center',
      formatExprt: (value) => {
        if (!value) {
          return '-';
        }
        moment.locale('id');
        const utcDate = moment.utc(value);
        const result = utcDate.tz(moment.tz.guess(true)).format('dddd, DD MMMM YYYY HH:mm:ss');
        return result;
      },
      render: (value) => {

        if (!value) {
          return '-';
        }
        moment.locale('id');
        const utcDate = moment.utc(value);
        const result = utcDate.tz(moment.tz.guess(true)).format('dddd, DD MMMM YYYY HH:mm:ss');
        return result;
      }
    },
    {
      key: '3',
      dataIndex: 'lastPaymentDate',
      title: 'TGL. BAYAR',
      width: '130px',
      align: 'center',
      formatExprt: (value) => {
        if (!value) {
          return '-';
        }
        moment.locale('id');
        const utcDate = moment.utc(value);
        const result = utcDate.tz(moment.tz.guess(true)).format('dddd, DD MMMM YYYY HH:mm:ss');
        return result;
      },
      render: (value) => {

        if (!value) {
          return '-';
        }
        moment.locale('id');
        const utcDate = moment.utc(value);
        const result = utcDate.tz(moment.tz.guess(true)).format('dddd, DD MMMM YYYY HH:mm:ss');
        return result;
      },
    },
    {
      key: '4',
      dataIndex: 'invoiceNumber',
      title: 'NO. INVOICE',
      width: '100px',
    },
    {
      key: '5',
      dataIndex: 'companyName',
      title: 'NAMA PERUSAHAAN',
      width: '120px',
    },
    {
      key: '6',
      dataIndex: 'phone',
      title: 'PHONE',
      width: '100px',
      ignoreDisply: true
    },
    { key: '7', dataIndex: 'description', title: 'PAKET', width: '120px' },
    {
      key: '8',
      dataIndex: 'billingCycle',
      title: 'BILLING CYCLE',
      width: '120px',
      align: 'center',
      render: (value) => <Tag colorType={value} style={{ width: '70px' }}>{capitalize(value)}</Tag>
    },
    {
      key: '9',
      dataIndex: 'subtotal',
      title: 'SUBTOTAL',
      width: '100px',
      ignoreDisply: true,
      formatExprt: (val) => accountingJS.formatMoney(val || 0, { symbol: 'Rp ', format: "%s %v", decimal: ',', thousand: '.' })
    },
    {
      key: '10',
      dataIndex: 'discountPercent',
      title: 'DISCOUNT PERCENT',
      width: '100px',
      ignoreDisply: true
    },
    {
      key: '11',
      dataIndex: 'discountAmount',
      title: 'DISCOUNT AMOUNT',
      width: '100px',
      ignoreDisply: true,
      formatExprt: (val) => accountingJS.formatMoney(val || 0, { symbol: 'Rp ', format: "%s %v", decimal: ',', thousand: '.' })
    },
    {
      key: '12',
      dataIndex: 'taxRate',
      title: 'TAX RATE',
      width: '100px',
      ignoreDisply: true
    },
    {
      key: '13',
      dataIndex: 'taxAmount',
      title: 'TAX AMOUNT',
      width: '100px',
      ignoreDisply: true,
      formatExprt: (val) => accountingJS.formatMoney(val || 0, { symbol: 'Rp ', format: "%s %v", decimal: ',', thousand: '.' })
    },
    {
      key: '14',
      dataIndex: 'adjustmentAmount',
      title: 'ADJUSTMENT AMOUNT',
      width: '100px',
      ignoreDisply: true,
      formatExprt: (val) => accountingJS.formatMoney(val || 0, { symbol: 'Rp ', format: "%s %v", decimal: ',', thousand: '.' })
    },
    {
      key: '15',
      dataIndex: 'amountPaid',
      title: 'AMOUNT PAID',
      width: '100px',
      ignoreDisply: true
    },
    {
      key: '16',
      dataIndex: 'total',
      title: 'HARGA',
      width: '120px',
      render: (val) => accountingJS.formatMoney(val || 0, { symbol: 'Rp ', format: "%s %v", decimal: ',', thousand: '.' })
    },
    {
      key: '17',
      dataIndex: 'coupon',
      ignoreExprt: true,
      title: 'DISKON',
      width: '120px',
      render: (val, rec) => couponTemplate(val, rec.discountAmount, rec.discountPercent),
      formatExprt: (val) => accountingJS.formatMoney(val || 0, { symbol: 'Rp ', format: "%s %v", decimal: ',', thousand: '.' })
    },
    {
      key: '18',
      dataIndex: 'coupon',
      title: 'COUPON',
      width: '100px',
      formatExprt: (value, record) => {
        let dataValue;
        const discountType = get(record, 'coupon.discountType')
        const discountAmount = get(record, 'discountAmount')
        const discountValue = get(record, 'coupon.discountValue')
        const couponCode = get(record, 'coupon.code')
        switch (discountType) {
          case 'amount':
            dataValue = `${couponCode} - ${accountingJS.formatMoney(discountAmount || 0, { symbol: "Rp.", precision: 0, format: "%s %v", decimal: ',', thousand: '.' })}`;
            break;
          case 'percentage':
            dataValue = `${couponCode} - (${discountValue}%) - ${accountingJS.formatMoney(discountAmount || 0, { symbol: "Rp.", precision: 0, format: "%s %v", decimal: ',', thousand: '.' })}`;
            break;
          case 'additional_month':
            dataValue = `${couponCode} - ${discountValue} Bulan`;
            break;
          default:
            break;
        }
        return dataValue;
      },
      ignoreDisply: true
    },
    {
      key: '19',
      dataIndex: 'paymentMethod',
      title: 'METODE PEMBAYARAN',
      width: '130px',
    },
    {
      key: '20',
      dataIndex: 'status',
      title: 'STATUS',
      width: '100px',
      align: 'center',
      render: (value, record, i) => <Tag colorType={value} style={{ width: '70px' }}>{capitalize(value)}</Tag>,
    },
    {
      key: '21',
      dataIndex: 'integratedReferenceId',
      title: 'INTEGRATED REFERENCE ID',
      width: '100px',
      ignoreDisply: true
    },
  ];

  const includes: IIncludes = {
    refresh: true,
    refreshAction: () => {
      baseListRef.current.resetFilters();

      setTimeout(() => {
        baseListRef.current.callLoadData();
      }, 10);
    },
    toogleOptions: true,
    keyword: true,
    exports: [{
      label: 'Export berdasarkan filter',
      basedFilter: true,
      fileName: 'History Transaction | By Filter'
    }, {
      label: 'Export semua data',
      fileName: 'History Transaction'
    }],
    moreFilter: {
      template: <HistoryTransactionMoreFilterComponent />
    }
  }

  function loadDataObservable(params) {
    if (params.paymentChannel) {
      params.paymentChannel = (params.paymentChannel).toString();
    }
    const qParams = pick(params, filters)
    return adminRestService.getTenantBills({ params: qParams });
  }

  const couponTemplate = (coupon, discountAmount, discountPercent) => {
    if (coupon) {
      const discountType = get(coupon, 'discountType');

      switch (discountType) {
        case 'percentage':
          return (
            <Fragment>
              <div className="text-left font-weight-bold">{get(coupon, 'code')}</div>
              <div className="text-left">{get(coupon, 'discountValue')}%</div>
              <div className="text-left">{accountingJS.formatMoney(discountAmount || 0, { symbol: 'Rp ', format: "%s %v", decimal: ',', thousand: '.' })}</div>
            </Fragment>
          )
        case 'amount':
          return (
            <Fragment>
              <div className="text-left font-weight-bold">{get(coupon, 'code')}</div>
              <div className="text-left">{accountingJS.formatMoney(discountAmount || 0, { symbol: 'Rp ', format: "%s %v", decimal: ',', thousand: '.' })}</div>
            </Fragment>
          )
        case 'additional_month':
          return (
            <Fragment>
              <div className="text-left font-weight-bold">{get(coupon, 'code')}</div>
              <div className="text-left">{'+' + get(coupon, 'discountValue')} Bulan</div>
            </Fragment>
          )
        default:
          if (discountPercent) {
            return (
              <Fragment>
                <div className="text-left">{discountPercent}%</div>
                <div className="text-left">{accountingJS.formatMoney(discountAmount || 0, { symbol: 'Rp ', format: "%s %v", decimal: ',', thousand: '.' })}</div>
              </Fragment>
            )
          } else if (discountAmount) {
            return (
              <Fragment>
                <div className="text-left">{accountingJS.formatMoney(discountAmount || 0, { symbol: 'Rp ', format: "%s %v", decimal: ',', thousand: '.' })}</div>
              </Fragment>
            )
          }
          return;
      }
    } else {
      if (discountPercent) {
        return (
          <Fragment>
            <div className="text-left">{discountPercent}%</div>
            <div className="text-left">{accountingJS.formatMoney(discountAmount || 0, { symbol: 'Rp ', format: "%s %v", decimal: ',', thousand: '.' })}</div>
          </Fragment>
        )
      } else if (discountAmount) {
        return (
          <Fragment>
            <div className="text-left">{accountingJS.formatMoney(discountAmount || 0, { symbol: 'Rp ', format: "%s %v", decimal: ',', thousand: '.' })}</div>
          </Fragment>
        )
      } else {
        return (
          <Fragment>
            <div className="text-left">-</div>
          </Fragment>
        )
      }
    }
  }

  return (
    <BaseListComponent
      loadDataObservable={loadDataObservable}
      ref={baseListRef}
      columns={columns}
      includes={includes}
      loadingExport={loadingExport}
    />
  )
}

import { Image } from "@clodeo/clodeo-ui/components/data-display/image/image.component";
import { Tag } from '@clodeo/clodeo-ui/components/data-display/tag/tag.component';
import { Tooltip } from '@clodeo/clodeo-ui/components/data-display/tooltip/tooltip.component';
import { IModalValue } from '@clodeo/clodeo-ui/components/feedback/modal/modal';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { Spinner } from "@clodeo/clodeo-ui/components/feedback/spinner/spinner.component";
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { HandleService } from "@clodeo/libs/core/handle/handle.service";
import { AdminBillingTransactionRestService } from "@clodeo/libs/core/rest/admin/billing-transaction/admin-billing-transaction-rest.service";
import { AuthenticationService } from "apps/admin-web/src/app/core/auth/authentication.service";
import { environment } from "apps/admin-web/src/environments/environment";
import moment from 'moment';
import React, { useState } from 'react';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';

export const BillingShippingReturnTemplateListComponent = (props: {
  data: IBillingTransaction;
}) => {
  const { data } = props;
  const [modalValue, setModalValue] = useState<IModalValue>();
  const [loading, setLoading] = useState(false);
  const { handleRequest } = new HandleService;
  const authenticationService: AuthenticationService = new AuthenticationService;
  const adminBillingTransactionRestService = new AdminBillingTransactionRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
  const notif = new Notifications();


  function showPayment() {
    setLoading(true);
    const qParams = { tenantId: data.tenantId }
    const obs = adminBillingTransactionRestService.getTransactionAttachments(data.billingTransactionId, qParams)
    handleRequest({
      obs,
      errorMessage: "Transaksi ini tidak memiliki bukti bayar",
      onError: () => setLoading(false),
      onDone: (res) => {
        setLoading(false);
        if (res.length) {
          return setModalValue({
            visible: true,
            onCancel: () => setModalValue(null),
            contentTemplate: <>
              <div className="container pt-3">
                <div className="row">
                  <div style={{ width: '160px' }}>Tanggal Pembayaran</div>
                  <div className="value">: {moment(data.billingTransaction.lastPaymentDate).format('DD MMM YYYY')}</div>
                </div>
                <div className="row">
                  <div style={{ width: '160px' }}>Tipe Pembayaran</div>
                  <div className="value">: {data.billingTransaction.lastPaymentMethod}</div>
                </div>
                <div className="row">
                  {res.length == 1 ?
                    <div className="col-lg-12 text-center">
                      <div className="pt-2">
                        <Image.PreviewGroup>
                          <Image
                            src={res[0].attachment.fileUrl}
                            width="200px"
                            height="200px"
                            style={{ borderRadius: "20px", cursor: "pointer" }}
                          ></Image>
                        </Image.PreviewGroup>
                      </div>
                      <span>{res[0].attachment.fileName}</span>
                    </div>
                    :
                    res && res.map((item) => (
                      <>
                        <div className="col-lg-6 text-center">
                          <div className="pt-2">
                            <Image.PreviewGroup>
                              <Image
                                src={item.attachment.fileUrl}
                                width="200px"
                                height="200px"
                                style={{ borderRadius: "20px", cursor: "pointer" }}
                              ></Image>
                            </Image.PreviewGroup>
                          </div>
                          <span>{item.attachment.fileName}</span>
                        </div>
                      </>
                    ))}
                </div>
              </div>
            </>
          })
        } else {
          <div className="container pt-3 text-center">
            <label htmlFor="">Tidaka ada data pembayaran</label>
          </div>
        }
      }
    })
  }

  function showInvoiceReference(billingId, tenantId) {
    setLoading(true);
    const obs = adminBillingTransactionRestService.getBillingRetunDetail(billingId, tenantId);
    handleRequest({
      obs,
      onError: () => {setLoading(false)},
      onDone: (data) => {
        setLoading(false);
        if (data.billingTransaction.billingAdjustments.length) {
          return setModalValue({
            visible: true,
            onCancel: () => {setModalValue(null)},
            title: "Additional Invoice",
            contentTemplate: <>
            {
              data.billingTransaction.billingAdjustments.map(billing => (
                <div className="container pt-2">
                  <div className="row">
                    <p className="mr-4">{billing.billingTransaction.transactionNumber}</p>
                    <div className="billingType d-flex flex-column justify-content-start" style={{ width: '150px', textAlign: 'center' }}>
                      <div className="text-center">
                        {billing.transactionType === 'billing' &&
                          <Tag colorType='deo-blue' size="lg" style={{ width: '90px' }}>Tagihan</Tag>
                        }
                        {billing.transactionType === 'deposit' &&
                          <Tag colorType='deo-blue' size="lg" style={{ width: '115px' }}>Deposit</Tag>
                        }
                        {billing.transactionType === 'fee_reduction' &&
                          <Tag colorType='deo-blue' size="lg" style={{ width: '157px' }}>Pengurangan Tagihan</Tag>
                        }
                      </div>
                    </div>
                    <div className="statusType d-flex flex-column justify-content-start" style={{ width: '150px', textAlign: 'center' }}>
                      <div className="text-center">
                        {billing.billingTransaction.paymentStatus === 'paid' &&
                          <Tag colorType='paid' size="lg" style={{ width: '90px' }}>Lunas</Tag>
                        }
                        {billing.billingTransaction.paymentStatus === 'unpaid' &&
                          <Tag colorType='deo-red' size="lg" style={{ width: '110px' }}>Belum Lunas</Tag>
                        }
                        {billing.billingTransaction.paymentStatus === 'partial_paid' &&
                          <Tag colorType='deo-orange' size="lg" style={{ width: '127px' }}>Bayar Sebagian</Tag>
                        }
                      </div>
                    </div>
                    <p className="text-center">{billing.billingTransaction.total}</p>
                  </div>
                </div>
              ))
            }
            </>
          })
        } else {
          notif.show({
            type: 'info',
            title: 'Informasi',
            description: 'Tidak ada data terkait',
            useService: true
          });
        }
      }
    })
  }

  return <>
    {data.billingTransaction && <div className="list-wrapper">
      <div className="d-flex flex-row flex-wrap justify-content-between">
        <div className="d-flex flex-column justify-content-between grouping">
          <Spinner spinning={loading}>
            <div className="d-flex flex-row justify-content-between">
              <div className="label label-description">No Invoice</div>
              <div className="clickable label-value normal value" style={{color: '#45a9ff'}} onClick={() => {showInvoiceReference(data.id, data.tenantId)}}>
                <Tooltip
                  title={data.billingTransaction.transactionNumber || '-'}
                >
                  {data.billingTransaction.transactionNumber || '-'}
                </Tooltip>
              </div>
            </div>
          </Spinner>
          <div className="d-flex flex-row justify-content-between">
            <div className="label">Seller</div>
            <div className="value normal">
              <Tooltip title={data.sellerName || '-'}>
                {data.sellerName || '-'}
              </Tooltip>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column justify-content-between grouping">
          <div className="d-flex flex-row justify-content-between">
            <div className="label w-90px">Tgl Tagihan</div>
            <div className="value normal w-100px">{data.billingTransaction.transactionDate ? moment(data.billingTransaction.transactionDate).format('DD MMM YYYY') : '-'}</div>
          </div>
          <div className="d-flex flex-row justify-content-between">
            <div className="label w-100px">Jml Resi</div>
            <div className="value normal w-100px">{data.shippingCount || '-'}</div>
          </div>
        </div>

        <div className="d-flex flex-column justify-content-between grouping">
          <div className="d-flex flex-row justify-content-between">
            <div className="label w-100px">Jml Tagihan</div>
            <div className="value normal w-100px">{data.billingTransaction.total || '-'}</div>
          </div>
          <div className="d-flex flex-row justify-content-between">
            <div className="label w-100px">Jml Pembayaran</div>
            <div className="value normal w-100px">{data.billingTransaction.amountPaid || 0}</div>
          </div>
        </div>

        <div className="d-flex flex-column justify-content-between grouping">
          <div className="d-flex flex-row justify-content-between">
            <div className="label w-90px">Tgl Pembayaran</div>
            <div className="value normal">{data.billingTransaction.lastPaymentDate ? moment(data.billingTransaction.lastPaymentDate).format('DD MMM YYYY') : '-'}</div>
          </div>
          <div className="d-flex flex-row justify-content-between">
            <div className="label w-90px">Tgl Jatuh Tempo</div>
            <div className="value normal w-100px">{data.dueDate ? moment(data.dueDate).format('DD MMM YYYY') : '-'}</div>
          </div>
        </div>

        <div>
          <Spinner spinning={loading}>
            <Button iconName="deo-note-2" size="sm" type="outline" disabled={!data.billingTransaction.attachment} onClick={showPayment}></Button>
          </Spinner>
        </div>

        <div className="d-flex flex-column justify-content-between grouping">
          <div className="d-flex flex-column justify-content-start" style={{ width: '160px' }}>
            <div className="label text-center w-100">Status</div>
            <div className="p-2 text-center w-100">
              {data.billingTransaction.paymentStatus === 'unpaid' &&
                <Tag colorType='deo-red' size="lg" style={{ width: '115px' }}>Belum dibayar</Tag>
              }
              {data.billingTransaction.paymentStatus === 'paid' &&
                <Tag colorType='paid' size="lg" style={{ width: '70px' }}>Lunas</Tag>
              }
              {data.billingTransaction.paymentStatus === 'partial_paid' &&
                <Tag colorType='partial_paid' size="lg" style={{ width: '115px' }}>Bayar sebagian</Tag>
              }
            </div>
          </div>
        </div>

      </div>
      {
        modalValue && (
          <Modal
            {...modalValue}
            footer={null}
            width="fit-content"
            className="admin"
            onCancel={() => setModalValue(null)}
          >
            {modalValue.contentTemplate}
          </Modal>
        )
      }
    </div>}
  </>
}

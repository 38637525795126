import React, { useState } from 'react';
import { TenantsBankFormsComponent } from '../form/tenants-bank-form.component';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { AdminTenantBankRestService } from '@clodeo/libs/core/rest/admin/tenant-bank/admin-tenant-bank-rest.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { LoadingComponent } from '@clodeo/clodeo-ui/components/general/loading/loading.component';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';

const authenticationService: AuthenticationService = new AuthenticationService();
const adminTenantBankRestService = new AdminTenantBankRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const { handleRequest } = new HandleService;
export const TenantsBankCreateComponent = (props: TenantBankCreateProps) => {
  const { onCancel, onFinish } = props;
  const [loading, setLoading] = useState(false);
  function onSubmit(value: IPayloadTenantBank) {
    setLoading(true);
    const obs = adminTenantBankRestService.addTenantbank(value, { tenantId: value.tenantId })
    handleRequest({
      obs,
      successMessage: 'Tenant Bank berhasil dibuat',
      onDone: () => {
        setLoading(false);
        onFinish();
      },
      onError: () => {
        setLoading(false);
      }
    })
  }
  return (
    <Spinner indicator={<LoadingComponent />} spinning={loading}>
      <TenantsBankFormsComponent type="create" onCancel={onCancel} onSubmit={onSubmit} />
    </Spinner>
  );
}
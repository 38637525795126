import { Radio } from '@clodeo/clodeo-ui/components/data-entry/radiobutton/radiobutton.component';
import { Input } from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';
import { Switch } from '@clodeo/clodeo-ui/components/data-entry/switch/switch.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { isLength, find } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { IPayloadShippingSetting, IShippingCourierDetail, IShippingSetting } from '../shipping-settings';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { AdminRestService } from '@clodeo/libs/core/rest/admin/admin/admin-rest.service';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';

export const FormShippingSettings = (
  props: {
    onDone: (value: IPayloadShippingSetting) => void;
    data: IShippingSetting[];
    shippingCourierDetailsFromRelated: IShippingCourierDetail;
    tenantId
  }
) => {
  const authenticationService: AuthenticationService = new AuthenticationService;
  const tenantsRestService = new AdminRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
  const { data, shippingCourierDetailsFromRelated, onDone, tenantId } = props;
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [shippingCourierDetails, setShippingCourierDetails] = useState<IShippingCourierDetail>();
  const [shippingSettings, setShippingSettings] = useState<IShippingSetting>();
  const [form, setForm] = useState<IPayloadShippingSetting>();
  const { handleRequest } = new HandleService;
  const baseListRef = useRef<any>();
  const courierImageSettings = {
    jet: '83px',
    jx: '57px',
    sicepat: '100px',
    ninja: '80px',
    jnt: '90px',
    anteraja: '90px',
    jne: '67px',
    sap: '57px',
  };

  useEffect(() => {
    if (data && isLength(data.length)) {
      const setSetting: IShippingSetting = find(data, function (d) { return d.courierChannelId == shippingCourierDetails.code });
      setShippingSettings(setSetting);
    }
  }, [data]);

  useEffect(() => {
    setShippingCourierDetails({ ...shippingCourierDetails, ...shippingSettings })
  }, [shippingSettings])

  useEffect(() => {
    setShippingCourierDetails({ ...shippingCourierDetailsFromRelated, widthImg: courierImageSettings[shippingCourierDetailsFromRelated.code] });
  }, [shippingCourierDetailsFromRelated]);

  useEffect(() => {
    shippingCourierDetails && patchValue();
  }, [shippingCourierDetails])

  function patchValue() {
    setForm({
      courierId: shippingCourierDetails.code,
      isAlreadyMember: shippingCourierDetails.isAlreadyMember,
      memberId: shippingCourierDetails.memberId,
      registrationInfo: shippingCourierDetails.registrationInfo,
      shippingService: shippingCourierDetails.shippingService,
      courierName: shippingCourierDetails.name
    })
  }

  function onSubmit() {
    onDone && onDone(form);
    setIsUpdate(false);
  }

  function generateMemberId() {
    const defaultValue = {
      "companyName": "string",
      "ownerName": "string",
      "address": "string",
      "subdistrict": "string",
      "village": "string",
      "phone": "string",
      "email": "string",
      "lineId": "string",
      "instagramId": "string",
      "bankAccountInfo": "string",
      "joinDate": "string",
      "salesPerson": "string",
      "isAlreadyMemberSiCepat": true
    }
    const obs = tenantsRestService.generateMemberIdSicepat(tenantId, defaultValue);
    handleRequest({
      obs,
      successMessage: 'Berhasil Generate Member ID',
      onDone: (res: any) => {
        setForm({ ...form, memberId: res });
        baseListRef.current.callLoadData();
      },
      onError: () => {
        baseListRef.current.callLoadData();
      }
    })
  }

  return form ? <div className="card-shipping-setting h-100">
    <div
      id={'form-' + shippingCourierDetails.courierChannelId}
      className="d-flex flex-column h-100 justify-content-between p-3">
      <div className="d-flex flex-column">
        <div className="row justify-content-center mb-3 row-courier">
          <label className="col-lg-4 col-md-5 field-label">
            Kurir
          </label>
          <div className="col-lg-7 col-md-6 field-value">
            <img src={'https://nx-design-system-web-development.clodeo.com/img/logo/courier/code/' + shippingCourierDetails.code + '.svg'} width={shippingCourierDetails.widthImg} alt="" srcSet="" className="img-fluid" />
          </div>
        </div>
        <div className="row justify-content-center mb-2 mt-4">
          <label className="col-lg-4 col-md-5 field-label">
            Member ID
          </label>
          <div className="col-lg-7 col-md-6 field-value">
            <Input
              disabled={!isUpdate}
              value={form.memberId}
              onChange={(e) =>
                setForm({ ...form, memberId: e.target.value })
              }
            />
          </div>
        </div>
        {form.courierId === "sicepat" && form.memberId == null ? (
          <div className="row justify-content-center mb-2">
            <div className="col-lg-4 col-md-5 field-label"></div>
            <div className="col-lg-7 col-md-6 field-value">
              <Button
                disabled={!isUpdate}
                type="filter"
                label="Generate ID"
                className="p-2 mb-1 mt-1"
                onClick={() => {
                  generateMemberId()
                }
                }
              />
            </div>
          </div>
        ) : ''
        }
        <div className="row justify-content-center mb-1">
          <label className="col-lg-4 col-md-5 field-label">
            Status Member
          </label>
          <div className="col-lg-7 col-md-6 field-value d-flex flex-row align-items-center">
            <div>
              <Switch
                disabled={!isUpdate}
                checked={form.isAlreadyMember}
                onChange={(e) => setForm({ ...form, isAlreadyMember: e })}
              />
              <label className={`field-label ml-2 ${form.isAlreadyMember ? 'active' : ''}`}>{form.isAlreadyMember ? 'Aktif' : 'Non Aktif'}</label>
            </div>
          </div>
        </div>
        <div className="row justify-content-center mb-4">
          <label className="col-lg-4 col-md-5 field-label">
            Layanan
          </label>
          <div className="col-lg-7 col-md-6 field-value">
            <div className="d-flex flex-column">
              <div className="p-0 mb-2">
                <Radio
                  disabled={!isUpdate}
                  value={0}
                  checked={form.shippingService == 0}
                  onChange={(e) => setForm({
                    ...form,
                    shippingService: e.target.checked ? e.target.value : 0
                  })}
                >No Service</Radio>
              </div>
              {(shippingCourierDetails && shippingCourierDetails.features.pickup) &&
                <div className="p-0 mb-2">
                  <Radio
                    disabled={!isUpdate}
                    value={1}
                    checked={form.shippingService == 1}
                    onChange={(e) => setForm({
                      ...form,
                      shippingService: e.target.checked ? e.target.value : 0
                    })}
                  >Pickup Request</Radio>
                </div>
              }
              {(shippingCourierDetails && shippingCourierDetails.features.isCod) &&
                <div className="p-0">
                  <Radio
                    disabled={!isUpdate}
                    value={2}
                    checked={form.shippingService == 2}
                    onChange={(e) => setForm({
                      ...form,
                      shippingService: e.target.checked ? e.target.value : 0
                    })}
                  >Pickup Request & COD</Radio>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex w-100 justify-content-lg-center justify-content-between">
        {
          isUpdate ?
          <>
            <Button
              id={'button-cancel-' + shippingCourierDetails.courierChannelId}
              type="ghosted"
              label="Batal"
              className="mr-2"
              onClick={() => { setIsUpdate(false); patchValue() }}
            />
            <Button
              id={'button-save-' + shippingCourierDetails.courierChannelId}
              type="primary"
              submit
              label="Simpan"
              onClick={() => onSubmit()}
            />
          </>
          :
          <Button
            id={'button-cancel-' + shippingCourierDetails.courierChannelId}
            type="ghosted"
            label="Edit"
            className="mr-2"
            onClick={() => { setIsUpdate(true) }}
          />
        }
      </div>
    </div>
  </div > : <div className="text-center">
    <div className="p-3">Terjadi Kesalahan</div>
  </div>
}

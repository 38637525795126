import React, { useEffect } from 'react';

import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { DebitCODRestService } from '@clodeo/libs/core/rest/admin/debit-cod/debit-cod-rest.service';

import { map } from 'lodash';

import { UploadTemplateList } from '@clodeo/clodeo-ui/components/data-entry/upload/upload.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { Form } from 'antd';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { AclService } from 'apps/admin-web/src/app/core/auth/acl.service';

export const DebitCodUploadComponent = (props: IDebitCodUpload) => {
  const aclService: AclService = new AclService;
  const authenticationService: AuthenticationService = new AuthenticationService;
  const debitCodRestService = new DebitCODRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);

  const [form] = Form.useForm();
  const { handleRequest } = new HandleService;

  const { record, onFinish, onCancel } = props;

  useEffect(() => {
    form.setFieldsValue({
      ...record
    });
  }, [])

  function defaultFileList() {
    let defaults = [];
    defaults = map(record.attachments, function (att) {
      return att && {
        uid: att.id,
        name: att.fileName,
        status: 'done',
        response: att,
        url: att.fileUrl
      }
    });
    return defaults;
  }

  function onChangeUpload(item) {
    let { fileList } = item;
    fileList = fileList.map((file) => {
      if (file.status == 'done') {
        return file.response;
      }
    });
    form.setFieldsValue({
      attachments: fileList
    })
  }

  function uploadList(val?: IPayloadDebitCod) {
    const id = form.getFieldValue('id');
    const obs = debitCodRestService.updateDebitCod(id, form.getFieldsValue(true));
    handleRequest({
      obs,
      successMessage: 'success',
      onError: () => onCancel(),
      errorMessage: 'error',
      onDone: () => {
        onFinish()
      }
    })
  }

  const configUploadList = !aclService.can(['wallet.withdrawal_history.edit']) && {
    showRemoveIcon: false
  } || {};


  return (
    <div style={{
      width: '35vw'
    }}>
      <UploadTemplateList
        buttonProps={{
          className: 'pl-2 pr-3'
        }}
        showUploadList={configUploadList}
        onChange={onChangeUpload}
        baseUrl={`${environment.ENDPOINTS.API_FILE}/upload/admin`}
        headers={{ 'Authorization': `Bearer ${authenticationService.user.access_token}` }}
        defaultFileList={defaultFileList()}
      />
      <div className="col-12 mt-3 d-flex justify-content-end">
        <Form
          form={form}
          onFinish={uploadList}
        >
          <div>
            <Button
              className="mr-2"
              type="ghosted"
              label="Cancel"
              onClick={() => onCancel()}
            />
            {
              aclService.can(["wallet.withdrawal_history.edit"]) && (
                <Button
                  type="primary"
                  label="Simpan"
                  submit
                />
              )
            }
          </div>
        </Form>
      </div>
    </div>
  );
}

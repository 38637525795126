import { RequestService } from '../../request.service';

export class AdminVerificationRequestRestService {
  requestService = new RequestService;
  request: RequestService;
  
  /**
   *
   * @param baseUrl only from environment.ENDPOINTS
   * @param useInterceptor
   * @param withoutInterceptor
   */
   constructor(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
    this.request = this.requestService.new(`${baseUrl['API_ADMIN']}/tenant-bank-change-request`, useInterceptor, withoutInterceptor);
  }

  /**
 * Get list of verification request
 * @param params
 */
  findAll(params) {
    return this.request.get('', params);
  }

  /**
 * Get single data of verification request
 * @param tenantId
 */
   findSingleData(tenantId: string) {
    return this.request.get(`/${tenantId}`);
  }

  /**
   * Get history of verification request
   * @param tenantId 
   */
  getVerificationHistory(id, tenantId) {
    return this.request.get(`${id}/${tenantId}/histories`);
  }

  /**
   * Approve the verification
   * @param tenantId 
   */
  approveVerification(tenantId, payload) {
    return this.request.post(`${tenantId}/approve`, payload);
  }

  /**
   * Reject the verification
   * @param tenantId 
   */
  rejectVerification(tenantId, payload) {
    return this.request.post(`${tenantId}/reject`, payload);
  }
}

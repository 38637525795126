import { IModalValue } from '@clodeo/clodeo-ui/components/feedback/modal/modal';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { AdminBillingPaymentRestService } from '@clodeo/libs/core/rest/admin/billing-payment/admin-billing-payment-rest.service';
import { AclService } from 'apps/admin-web/src/app/core/auth/acl.service';
import { compact, pick } from 'lodash';
import moment from "moment";
import * as accountingJS from 'accounting-js';
import React, { useEffect, useRef, useState } from 'react';
import { tap } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { IColumn, IIncludes } from '../../../../../components/base-component/list/base-list';
import { BaseListComponent } from '../../../../../components/base-component/list/base-list.component';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { BillingPaymentCreateComponent } from '../create/billing-payment-create.component';
import { BillingPaymentMoreFilterComponent } from '../more-filter/billing-payment-more-filter.component';
import { BillingPaymentSummaryComponent } from '../summary/billing-payment-summary.component';
import { BillingPaymentTemplateListComponent } from './billing-payment-list-template.component';
import './billing-payment-list.component.scss';

export const BillingPaymentListComponent = () => {
  const filters = ['keyword', 'options.take', 'options.skip', 'options.includeTotalCount', 'tenantId', 'filterDateType', 'lowFilterDate', 'highFilterDate', 'paymentChannel', 'paymentStatus', 'status'];
  const baseListRef = useRef<any>();
  const authenticationService: AuthenticationService = new AuthenticationService;
  const adminBillingPaymentRestService = new AdminBillingPaymentRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);

  const { handleRequest } = new HandleService;
  const aclService = new AclService;
  const [modalValue, setModalValue] = useState<IModalValue>()

  const [billingPaymentsSummary, setBillingPaymentsRequestSummary] = useState({
    totalPending: null,
    totalApproved: null,
    totalReject: null,
    total: null
  })
  const [summaryFilter, setSummaryFilter] = useState(null);
  const [firstInit, setFirstInit] = useState<boolean>(true);

  const columns: IColumn[] = [
    {
      key: '1',
      dataIndex: '',
      ignoreExprt: true,
      render: (record: any) => {
        return (<BillingPaymentTemplateListComponent data={record} onSuccessApprove={() => baseListRef.current.callLoadData()} />);
      }
    }, {
      key: '2',
      dataIndex: 'transactionNumber',
      title: 'No Pembayaran',
      ignoreDisply: true
    }, {
      key: '3',
      dataIndex: 'billingTransactions',
      title: 'No Invoice',
      ignoreDisply: true,
      formatExprt: (data) => {
        if (data.length > 1) {
          let invoices = [];
          for (const key in data) {
            invoices.push(data[key].transactionNumber);
          }
          return invoices.join(', ');
        } else {
          return data[0].transactionNumber;
        }
      },
    }, {
      key: '4',
      dataIndex: 'sellerName',
      title: 'Seller',
      ignoreDisply: true
    }, {
      key: '5',
      dataIndex: 'transactionDate',
      title: 'Tanggal Pembayaran',
      ignoreDisply: true,
      formatExprt: (value) => { return moment(value).format('DD/MM/YYYY') }
    }, {
      key: '6',
      dataIndex: 'total',
      title: 'Jumlah Pembayaran',
      ignoreDisply: true,
      formatExprt: (val) => accountingJS.formatMoney(val || 0, { symbol: 'Rp ', format: "%s %v", decimal: ',', thousand: '.' })
    }, {
      key: '7',
      dataIndex: 'total',
      title: 'Adjustment',
      ignoreDisply: true,
      formatExprt: (val) => {return ''}
    },{
      key: '8',
      dataIndex: 'paymentMethod',
      title: 'Metode Pembayaran',
      ignoreDisply: true
    }, {
      key: '9',
      dataIndex: 'billingTransactionCount',
      title: 'Jumlah Invoice',
      ignoreDisply: true
    }, {
      key: '10',
      dataIndex: 'status',
      title: 'Status',
      ignoreDisply: true,
      formatExprt: (val) => {
        if (val === 'pending') {
          return 'Di Tunda'
        } else if (val === 'approved') {
          return 'Lunas'
        } else {
          return 'Di Tolak'
        }
      }
    }
  ];

  const includes: IIncludes = {
    refresh: true,
    keyword: true,
    toogleOptions: true,
    exports: [{
      label: 'Export berdasarkan filter',
      basedFilter: true,
      fileName: 'Billing Payment | By Filter'
    }, {
      label: 'Export semua data',
      fileName: 'Billing Payment'
    }],
    extraButtons: compact([
      aclService.can(['admin.user_admin.create']) &&
      ({
        label: 'Pembayaran',
        type: 'filter',
        iconName: 'deo-plus',
        action: () => setModalValue({
          title: 'Tambah Pembayaran',
          data: null,
          visible: true,
          contentTemplate:
            <BillingPaymentCreateComponent
              onClose={() => setModalValue(null)}
              onFinish={() => onFinishCreate()}
            />
        }),
      })
    ])
  }


  function loadBillingPaymentSummary(params) {
    const summaryParams = {
      lowDate: params.lowCreatedDate,
      highDate: params.highCreatedDate
    }
    const obs = adminBillingPaymentRestService.getBillingPaymentSummary({ params: summaryParams })
    handleRequest({
      obs,
      onDone: (res) => setBillingPaymentsRequestSummary(res)
    })
  }

  function loadDataObservable(params) {
    if (params.paymentChannel) {
      params.paymentChannel = (params.paymentChannel).toString();
    }
    if (params.status) {
      params.status = (params.status).toString();
    }
    if (params.paymentStatus) {
      params.paymentStatus = (params.paymentStatus).toString();
    }
    const filterBySummary = summaryFilter;
    const moreFilterAndParams = pick(params, filters)
    const qParams = { ...moreFilterAndParams, ...filterBySummary }

    return adminBillingPaymentRestService.billingPaymentMini({params: qParams}).pipe(
      tap(() => loadBillingPaymentSummary(params))
    )
  }

  function onFinishCreate() {
    setModalValue(null);
    baseListRef.current.callLoadData();
  }

  useEffect(() => {
    if (!firstInit) {
      baseListRef.current.callLoadData();
    }
    setFirstInit(false)
  }, [summaryFilter])


  return (
    <>
      <BillingPaymentSummaryComponent billingPaymentsSummary={billingPaymentsSummary}
        onChangeFilter={setSummaryFilter} />
      <BaseListComponent
        listId="billing-payment-list"
        columns={columns}
        ref={baseListRef}
        loadDataObservable={loadDataObservable}
        includes={{
          ...includes,
          moreFilter: {
            templateRf: (form) => <BillingPaymentMoreFilterComponent form={form} />
          }
        }}
      />
      {modalValue && (
        <Modal
          {...modalValue}
          footer={null}
          className="admin create-modal"
          onCancel={() => setModalValue(null)}
        >
          {modalValue.contentTemplate}
        </Modal>
      )}
    </>
  )
}

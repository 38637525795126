import { Card } from '@clodeo/clodeo-ui/components/data-display/card/card.component';
import { Summary, Table } from '@clodeo/clodeo-ui/components/data-display/table/table.component';
import { Input } from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { ExportService } from '@clodeo/libs/core/export/export.service';
import { FormatService } from '@clodeo/libs/core/format/format.service';
import { sumBy, values, uniqBy } from 'lodash';
import React, { useState } from 'react';
import { IColumn } from '../../../../../components/base-component/list/base-list';
import '../transfer-credit-cod.component.scss';
import { ITransferCreditCodLinesComponent } from './transfer-credit-cod-detail.d';

const exportService: ExportService = new ExportService();
const formatService = new FormatService();

export const TransferCreditCodLinesComponent = (props: ITransferCreditCodLinesComponent) => {
  const { data, onCancel } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingExprt, setLoadingExprt] = useState<{ status: boolean; description: string; }>({ status: false, description: '0%' });

  const columnsDetail: IColumn[] = [
    {
      title: 'No',
      width: '150px',
      render: (value, record, index) => index + 1,
    },
    {
      title: 'No Resi',
      dataIndex: 'airwaybillNumber',
      width: '150px',
      render: (value) => formatService.empty(value),
    },
    {
      title: 'Delivery Notes',
      dataIndex: 'deliveryNotes',
      width: '100px',
      render: (value) => formatService.empty(value),
    },
    {
      title: 'Receiver Name',
      dataIndex: 'receiverName',
      width: '100px',
      render: (value) => formatService.empty(value),
    },
    {
      title: 'Destination',
      dataIndex: 'destination',
      width: '150px',
      render: (value) => formatService.empty(value),
    },
    {
      title: 'notes',
      dataIndex: 'shippingChargeDiscountPercent',
      width: '90px',
      render: (value) => formatService.empty(value),
    },
    {
      title: 'Goods Value',
      dataIndex: 'goodValue',
      width: '90px',
      render: (value) => formatService.currency(value),
    },
    {
      title: 'Shipping Charge',
      dataIndex: 'shippingCharge',
      width: '90px',
      render: (value) => formatService.currency(value),
    },
    {
      title: 'Discount',
      dataIndex: 'discount',
      width: '90px',
      render: (value) => formatService.currency(value),
    },
    {
      title: 'Tax',
      dataIndex: 'tax',
      width: '90px',
      render: (value) => formatService.currency(value),
    },
    {
      title: 'Adjustment',
      dataIndex: 'adjustment',
      width: '110px',
      render: (value) => formatService.currency(value),
    },
    {
      title: 'Total',
      dataIndex: 'total',
      width: '150px',
      render: (value) => formatService.currency(value),
    },
    {
      title: 'Sub Total Amount',
      dataIndex: 'subTotalAmount',
      width: '150px',
      render: (value) => formatService.currency(value),
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      width: '150px',
      render: (value) => formatService.currency(value),
    },
    {
      title: 'Asuransi',
      dataIndex: 'insuranceAmount',
      width: '150px',
      render: (value) => formatService.currency(value),
    },
    {
      title: 'Fee COD (%)',
      dataIndex: 'codFeePercentage',
      width: '150px',
      render: (value) => formatService.empty(value),
    },
    {
      title: 'Fee COD (Rp)',
      dataIndex: 'codValue',
      width: '150px',
      render: (value) => formatService.currency(value),
    },
    {
      title: 'AR Amount Paid',
      dataIndex: 'arAmountPaid',
      width: '150px',
      render: (value) => formatService.currency(value),
    },
    {
      title: 'AR Balance Due',
      dataIndex: 'arBalanceDue',
      width: '150px',
      render: (value) => formatService.currency(value),
    },
    {
      title: 'Last Update Date',
      dataIndex: 'lastUpdateDate',
      width: '150px',
      render: (value) => formatService.date(value, 'DD/MM/YYYY'),
    },
    {
      title: 'Tax Inclusive',
      dataIndex: 'taxInclusive',
      width: '150px',
      render: (value) => formatService.operationBoolean(value),
    },
    {
      title: 'Create Date',
      dataIndex: 'createDate',
      width: '150px',
      render: (value) => formatService.date(value, 'DD/MM/YYYY'),
    },
    {
      title: 'Shipping Charge Ekspedisi',
      dataIndex: 'shippingChargeExpedition',
      width: '150px',
      render: (value) => formatService.currency(value),
    },
    {
      title: 'Diskon Shipping Charge (%)',
      dataIndex: 'discountShippingChargePercentage',
      width: '150px',
      render: (value) => formatService.empty(value),
    },
    {
      title: 'Diskon Shipping Charge (Rp)',
      dataIndex: 'discountShippingChargeAmount',
      width: '150px',
      render: (value) => formatService.currency(value),
    },
    {
      title: 'Total Shipping Charge Ekspedisi',
      dataIndex: 'totalShippingCharge',
      width: '150px',
      render: (value) => formatService.currency(value),
    },
    {
      title: 'Total Diterima',
      dataIndex: 'receiveAmount',
      width: '150px',
      render: (value) => formatService.currency(value),
    },
  ];

  function onSearch(e, rows) {
    let delaySearchTransferCreditCOD;
    const searchValue = e.target.value;
    let rowsData = [];

    clearTimeout(delaySearchTransferCreditCOD);

    if (!searchValue) {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 150);
      return;
    }

    delaySearchTransferCreditCOD = setTimeout(() => {
      setLoading(true);
      if (searchValue.length > 0) {
        let matchData;
        for (let index = 0; index < rows.length; index++) {
          const valuePerRow = values(rows[index]);

          valuePerRow.filter((item) => {
            if (`${item}`.toLowerCase().includes(searchValue.toLowerCase())) {
              matchData = rows[index];
            }
          });

          if (matchData) {
            rowsData.push(matchData);
          }
        }
        rowsData = uniqBy(rowsData, 'tenantId');
      } else {
        rowsData = rows;
      }
      setTimeout(() => {
        setLoading(false);
      }, 150);
    }, 500);
  }

  function exportData(data, fileName, columns) {
    setLoadingExprt({
      status: true,
      description: `0%`,
    });
    exportService.expToXLS({ data: data, wscols: '' }, fileName, columns);
    setTimeout(() => {
      setLoadingExprt({
        status: true,
        description: `100%`,
      });
      setTimeout(() => {
        setLoadingExprt({
          status: false,
          description: `100%`,
        });
      }, 150);
    }, 50);
  }

  return <Spinner spinning={loading} >
    {data &&
      <>
        <div className="detail-transfer-credit-cod">
          <div className="row">
            <div className="col">
              <Card className="mb-1">
                <div className="row">
                  <div className="row col-6">
                    <div className="col-5 font-weight-bolder">Nama Seller</div>
                    <div className="col-auto">
                      {' '}
                      {data?.sellerName || '-'}{' '}
                    </div>
                  </div>
                  <div className="row col-6">
                    <div className="col-5 font-weight-bolder">Tenant Id</div>
                    <div className="col-auto">
                      {data?.lines[0].tenantId || '-'}
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
          <div className="d-flex flex-row flex-wrap justify-content-between filter-section w-100">
            <div className="d-flex flex-row flex-wrap col-12 justify-content-between justify-content-lg-start col-lg-6">
              <Input
                placeholder="Cari"
                onChange={(e) => {
                  onSearch(e, data.lines);
                }}
                suffix={<i className="icon-deo-search icon-16px"></i>}
              />
            </div>
            <div className="d-flex flex-row flex-wrap col-12 justify-content-lg-end col-lg-6">
              <Spinner
                spinning={loadingExprt.status || false}
                tip={loadingExprt.description || ''}
              >
                <div className="px-1 align-self-center py-1 col col-lg-auto">
                  <Button
                    iconName="deo-upload"
                    label="EXPORT"
                    type="filter"
                    className="p-2 px-3 text-center"
                    onClick={() => {
                      exportData(data.lines, `Detail Resi COD - ${data.sellerName}`, columnsDetail
                      )
                    }
                    }
                  />
                </div>
              </Spinner>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Spinner className="d-flex align-item-center" spinning={loading}>
                <Table
                  columns={columnsDetail}
                  dataSource={data.lines || []}
                  // scroll={{ x: true, y: '55vh' }}
                  className="summary detail"
                  summary={(pageData) => (<>
                    <Summary.Row>
                      <Summary.Cell index={0}></Summary.Cell>
                      <Summary.Cell index={1}><strong>Total</strong></Summary.Cell>
                      <Summary.Cell index={2}><strong>{pageData.length}</strong></Summary.Cell>
                      <Summary.Cell index={3}></Summary.Cell>
                      <Summary.Cell index={4}></Summary.Cell>
                      <Summary.Cell index={5}></Summary.Cell>
                      <Summary.Cell index={6}>{formatService.currency(sumBy(pageData, 'goodValue'))}</Summary.Cell>
                      <Summary.Cell index={7}>{formatService.currency(sumBy(pageData, 'shippingCharge'))}</Summary.Cell>
                      <Summary.Cell index={8}>{formatService.currency(sumBy(pageData, 'discount'))}</Summary.Cell>
                      <Summary.Cell index={9}>{formatService.currency(sumBy(pageData, 'tax'))}</Summary.Cell>
                      <Summary.Cell index={10}>{formatService.currency(sumBy(pageData, 'adjustment'))}</Summary.Cell>
                      <Summary.Cell index={11}>{formatService.currency(sumBy(pageData, 'total'))}</Summary.Cell>
                      <Summary.Cell index={12}>{formatService.currency(sumBy(pageData, 'subTotalAmount'))}</Summary.Cell>
                      <Summary.Cell index={13}>{formatService.currency(sumBy(pageData, 'totalAmount'))}</Summary.Cell>
                      <Summary.Cell index={14}>{formatService.currency(sumBy(pageData, 'insuranceAmount'))}</Summary.Cell>
                      <Summary.Cell index={15}></Summary.Cell>
                      <Summary.Cell index={16}>{formatService.currency(sumBy(pageData, 'codValue'))}</Summary.Cell>
                      <Summary.Cell index={17}>{formatService.currency(sumBy(pageData, 'arAmountPaid'))}</Summary.Cell>
                      <Summary.Cell index={18}>{formatService.currency(sumBy(pageData, 'arBalanceDue'))}</Summary.Cell>
                      <Summary.Cell index={19}></Summary.Cell>
                      <Summary.Cell index={20}></Summary.Cell>
                      <Summary.Cell index={21}></Summary.Cell>
                      <Summary.Cell index={22}>{formatService.currency(sumBy(pageData, 'shippingChargeExpedition'))}</Summary.Cell>
                      <Summary.Cell index={23}>{formatService.currency(sumBy(pageData, 'discountShippingChargePercentage'))}</Summary.Cell>
                      <Summary.Cell index={24}>{formatService.currency(sumBy(pageData, 'discountShippingChargeAmount'))}</Summary.Cell>
                      <Summary.Cell index={25}>{formatService.currency(sumBy(pageData, 'totalShippingCharge'))}</Summary.Cell>
                      <Summary.Cell index={26}>{formatService.currency(sumBy(pageData, 'receiveAmount'))}</Summary.Cell>
                    </Summary.Row>
                  </>
                  )}
                />
              </Spinner>
            </div>
          </div>

          <div className="d-flex justify-content-end mt-3">
            <Button
              type="ghosted"
              label="Kembali"
              className="mr-2"
              onClick={() => onCancel()}
            />
          </div>
        </div>
      </>}
  </Spinner>
};


import _ from 'lodash';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';

const notif: Notifications = new Notifications();
export class RolesServices {
  normalizePayload(payloads: IPayloadAccessRoles) {
    let newPayloads;
    payloads.permissions = _.flattenDeep(Object.values(payloads.fakePermissions));
    newPayloads = _.omit(payloads, ["systemType", "systemLocked", "fakePermissions", "permissionRoles"]);

    return newPayloads;
  }

  checkingPermission(data) {
    let validStatus: boolean = true;
    let errorMessage;
    if (!data.fakePermissions || data.fakePermissions && !_.flattenDeep(Object.values(data.fakePermissions)).length) {
      errorMessage = "Pilih minimal 1 permission";
    } else if(!data.validPermission) {
      errorMessage = "Pilihan permission tidak valid";
    }

    if(errorMessage) {
      notif.show({
        type: 'error',
        title: 'Error',
        description: errorMessage,
      });
      validStatus = false;
    }
    return validStatus;
  }
}

import { Layout } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { AclService } from '../../../core/auth/acl.service';
import { HeaderLayoutComponent } from '../header/header-layout.component';
import { MenuComponent } from '../menu/menu-layout.component';
import { SiderLayoutComponent } from '../sider/sider-layout.component';
import './main-layout.component.scss';
const { Content } = Layout;
const aclService: AclService = new AclService;

export const MainLayoutComponent = (props: { children?: any, pathName: string; title?: string }) => {
    const [menus, setMenus] = useState<IMenuRf[]>([]);
    const [collapseSider, setCollapseSider] = useState<boolean>();
    const [parentMenu, setParentMenu] = useState<IMenuRf>()
    const [compact, setCompact] = useState(false);
    function isSubMenu() {
        return parentMenu && _.isLength(parentMenu.subs.length);
    }

    useEffect(() => {
        if (menus && props.pathName) {
            const parentPath = _.split(props.pathName, '/');
            const menu = _.find(menus, function (m) { return m.id === parentPath[1] });
            setParentMenu(menu)
        }
    }, [menus, props.pathName]);

    useEffect(() => {
        const validMenus = aclService.allowedMenus();
        setMenus(validMenus);
    }, []);

    return <Layout className="main-layout">
        <SiderLayoutComponent
            compact={compact}
            onCollapseChanges={setCollapseSider}
            menu={<MenuComponent onCompactChange={setCompact} isCollapse={collapseSider} pathName={props.pathName} menus={menus} />}
        />
        <Layout className="main-layout-content">
            <HeaderLayoutComponent title={props.title || ''} menus={menus} parentMenu={parentMenu} isSubmenu={isSubMenu()} pathName={props.pathName} />
            <Content className="main-content px-3 py-1">
                {props.children}
            </Content>
        </Layout>
    </Layout>
}

import { RequestService } from "../../request.service";

export class AdminBillingTransactionRestService {
  requestService = new RequestService;
  request: RequestService;
  /**
   * 
   * @param baseUrl 
   * @param useInterceptor 
   * @param withoutInterceptor 
   */
  constructor(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
    this.request = this.requestService.new(`${baseUrl['API_ADMIN']}`, useInterceptor, withoutInterceptor);
  }

  /**
   * Get list of Billing Transaction
   * @param qParams 
  */
  findAll(qParams: any) {
    return this.request.get<{ data: any[], total: number, skip: number, take: number }>('/billing-transaction', qParams);
  }

   /**
   * Get list of Billing Transaction
   * @param qParams 
  */
  getInovices(params?) {
    return this.request.get(`/billing-transaction`, {
      params
    });
  }

  /**
   * Get list of Shipping Charge Billing Transaction
   * @param qParams 
  */
  findAllShippingChargeBilling(qParams: any) {
    return this.request.get<{ data: any[], total: number, skip: number, take: number }>('/shipping-charge-billing', qParams);
  }

  /**
   * Get list of Shipping Charge Billing Transaction
   * @param qParams 
  */
   findAllTenantShippingBilling(qParams: any) {
    return this.request.get<{ data: any[], total: number, skip: number, take: number }>('/billing-transaction/tenant-shipping-billing', qParams);
  }


  /**
     * Get Summary of shipping charge Billing Transaction
     * @param qParams 
    */
  getBillingTransactionRequestSummary(qParams: any) {
    return this.request.get<any>('/billing-transaction/summary', qParams);
  }

  /**
     * Get Summary of shipping charge Billing Transaction
     * @param qParams 
    */
  getShippingChageBillingSummary(qParams: any) {
    return this.request.get<any>('/shipping-charge-billing/summary', qParams);
  }

  /**
   * Approve Billing
   * @param id 
   * @param payload 
  */
  updateBilling(id: string, payload: any) {
    return this.request.put(`/billing-transaction/approval/${id}`, payload);
  }
  /**
   * Get attachments from transactions
   * @param id
  */
  getTransactionAttachments(billingTransactionId: string, qParams: any) {
    return this.request.get(`/billing-transaction/attachments/${billingTransactionId}`, { params: qParams });
  }

  // SHIPPING RETURN
  /**
   * Get list of Billing Shipping Return
   * @param qParams 
  */
   getBillingShippingReturnList(qParams: any) {
    return this.request.get<{ data: any[], total: number, skip: number, take: number }>('/shipping-return-billing', qParams);
  }

  /**
     * Get Summary of Shipping Return Billing
     * @param qParams 
    */
  getShippingReturnBillingSummary(qParams: any) {
    return this.request.get<any>('/shipping-return-billing/summary', qParams);
  }

  /**
   * Get attachments from transactions
   * @param id
  */
  getWalletBalance(tenantId: string) {
    return this.request.get(`/wallet-balance/${tenantId}`);
  }

  getBillingReturnFeeList(qParams: any) {
    return this.request.get<{ data: any[], total: number, skip: number, take: number }>('/shipping-return-fee-billing', qParams);
  }

  getBillingReturnFeeListMini(qParams: any) {
    return this.request.get<{ data: any[], total: number, skip: number, take: number }>('/shipping-return-fee-billing/mini', qParams);
  }

  getBillingReturnFeeSummary(qParams: any) {
    return this.request.get<any>('/shipping-return-fee-billing/summary', qParams);
  }

  getBillingAdjustmentMini(qParams: any) {
    return this.request.get<{ data: any[], total: number, skip: number, take: number }>('/shipping-adjustment-billing/mini', qParams);
  }

  getBillingAdjustmentSummary(qParams: any) {
    return this.request.get<any>('/shipping-adjustment-billing/summary', qParams);
  }

  getBillingDetailBilingAdjustment(billingTransactionId, tenantId) {
    return this.request.get<any>(`/shipping-adjustment-billing/${billingTransactionId}?tenantId=${tenantId}`);
  }

  getBillingRetunDetail(billingTransactionId, tenantId) {
    return this.request.get<any>(`/shipping-return-fee-billing/${billingTransactionId}?tenantId=${tenantId}`);
  }

  getBillingShippingChargeDetail(billingTransactionId, tenantId) {
    return this.request.get<any>(`/shipping-charge-billing/${billingTransactionId}?tenantId=${tenantId}`);
  }

  getBillingShippingReturnDetail(billingTransactionId, tenantId) {
    return this.request.get<any>(`/shipping-return-billing/${billingTransactionId}?tenantId=${tenantId}`);
  }
}

import { RequestService } from "../../request.service";
const qs = require('querystring');
export class TokenRestService {
  requestService = new RequestService;
  request: RequestService;
  config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    }
  };

  constructor(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
    this.request = this.requestService.new(baseUrl, useInterceptor, withoutInterceptor);
  }

  getToken(payload) {
    return this.request.post<any>('/token', qs.stringify(payload), this.config);
  }
}

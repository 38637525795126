import { RequestService } from '../../../request.service';

export class AccountAdminRestServices {
  requestService = new RequestService;
  request: RequestService;
  /**
   * 
   * @param baseUrl from --environment.ENDPOINTS.API_ACCOUNT--
   * @param useInterceptor 
   * @param withoutInterceptor 
   */
  constructor(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
    this.request = this.requestService.new(`${baseUrl}/admin`, useInterceptor, withoutInterceptor);
  }

  findAll(qParams) {
    return this.request.get('', qParams);
  }

  findAllQ(params) {
    return this.request.post('q', params);
  }

  findById(id) {
    return this.request.get(id);
  }

  createUserAdmin(payload) {
    return this.request.post('', { ...payload, phoneNumberCountryId: 'id' });
  }

  updateUserAdmin(payload) {
    return this.request.put('', { ...payload, phoneNumberCountryId: 'id' });
  }

  markActiveUser(id: string, active: boolean) {
    return this.request.put(`${id}/mark-active/?active=${active}`);
  }

  resetPassword(id: string, newPassword: string) {
    return this.request.put(`${id}/reset-password`, { newPassword });
  }

  deleteUser(id: string) {
    return this.request.delete(id);
  }

  updateMe(payload) {
    return this.request.put('me', payload);
  }


  changePasswordMe(payload) {
    return this.request.put('me/change-password', payload);
  }

  entryRelatedData() {
    return this.request.get('/entry-related-data');
  }
}

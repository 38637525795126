import { RequestService } from '../../request.service';

export class AccessRolesRestService {
  requestService = new RequestService;
  request: RequestService;

  /**
   * 
   * @param baseUrl only from environment.ENDPOINTS.API_ADMIN 
   * @param useInterceptor 
   * @param withoutInterceptor 
   */
  constructor(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
    this.request = this.requestService.new(`${baseUrl}/access-roles`, useInterceptor, withoutInterceptor);
  }

  findAll(qParams) {
    return this.request.get('', qParams);
  }

  findAllQ(qParams) {
    return this.request.post('q', qParams);
  }

  findById(id) {
    return this.request.get(id);
  }

  loadRelated() {
    return this.request.get('entry-related-data');
  }

  createAccessRoles(payload: any) {
    return this.request.post('', payload);
  }

  updateAccessRoles(id: string, payload: any) {
    return this.request.put(id, payload);
  }

}
import React, { useState, useEffect } from 'react';
import { Form } from 'antd';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { BillingPaymentFormComponent } from '../form/billing-payment-form.component';
import { AdminBillingPaymentRestService } from '@clodeo/libs/core/rest/admin/billing-payment/admin-billing-payment-rest.service';

// Extra configs
const authenticationService: AuthenticationService = new AuthenticationService;
const adminBillingPaymentRestService = new AdminBillingPaymentRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const { handleRequest } = new HandleService;
const notif = new Notifications();

export const BillingPaymentCreateComponent = (props: IBillingPaymentCreateProps) => {

  const { onClose, onFinish } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  function onCancel() {
    form.resetFields();
    onClose();
  }

  function onSubmit() {
    formValid() && createBillingPayment(form.getFieldsValue(true));
  }

  function createBillingPayment(formValue) {
    let newData;
    if (formValue.paymentType === 'transfer_manual') {
      let data = {
        tenantId: formValue.tenantId,
        paymentDate: formValue.paymentDate,
        mainAttachmentId: formValue.attachments[0].id,
        totalAmount: +formValue.totalAmount,
        paymentType: formValue.paymentType,
        note: formValue.note
      }
      newData = data;
    } else {
      let data = {
        tenantId: formValue.tenantId,
        paymentDate: formValue.paymentDate,
        totalAmount: +formValue.totalAmount,
        paymentType: formValue.paymentType,
        note: formValue.note
      }
      newData = data;
    }

    // Setup Data Attachments 
    let attachments = [];
    if (formValue.attachments) {
      formValue.attachments.forEach((attach, index) => {
        const fileAttachment = {
          attachmentId: attach.id,
          isMain: index == 0 ? true : false,
          customFileName: attach.fileName
        }
        attachments.push(fileAttachment);
      });
    }

    // Setup Data billing transactions
    let billingTransactions = [];
    let totalAmount = +formValue.totalAmount;
    formValue.invoices.forEach((invoice, i) => {
      if (totalAmount > 0) {
        billingTransactions.push({
          billingTransactionId: invoice.id,
          amount: totalAmount >= invoice.balanceDue ? invoice.balanceDue : totalAmount
        });
        totalAmount -= invoice.balanceDue;
      }
    });

    const payload = { ...newData, billingTransactions, attachments };
    const obs = adminBillingPaymentRestService.createBillingPayment(payload);
    setLoading(true);
    handleRequest({
      obs,
      successMessage: 'Berhasil tambah pembayaran',
      onError: () => setLoading(false),
      onDone: () => {
        setLoading(false);
        onFinish();
        form.resetFields();
      }
    })
  }

  function formValid() {
    const formValue = form.getFieldsValue(true);
    if (formValue.attachments && formValue.attachments.length || formValue.paymentType === 'wallet_balance') {
      if (formValue.totalAmount > formValue.totalBalanceDue) {
        notif.show({
          type: 'warning',
          title: 'Peringatan',
          description: 'Jumlah Uang melebihi total tagihan',
          useService: true
        });

        return false;
      } else {
        return true;
      }
    } if (formValue.paymentType === 'transfer_manual') {
      notif.show({
        type: 'warning',
        title: 'Peringatan',
        description: 'Mohon upload bukti bayar terlebih dahulu',
        useService: true
      });

      return false;
    } else {
      return true;
    }
  }

  useEffect(() => {
  }, [])

  return (
    <Spinner spinning={loading}>
      <Form form={form} >
        <BillingPaymentFormComponent
          type="create"
          form={form}
          onCancel={onCancel}
          onSubmit={onSubmit}
        />
      </Form>
    </Spinner>
  )
}
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

import { LoginComponent } from './features/login/login.component';
import { ProvideAuth, GuardRoute } from './components/guard-route/guard-route.component';
import { NotFoundComponent } from './features/not-found/not-found.component';
import { ForbiddenAccessComponent } from './features/forbidden-access/forbidden-access.component';
// Dahsboard
import { DashboardComponent } from './features/main/dashboard/list/dashboard-list.component';
// Cash on Delivery
import { CodReceiptNumberListComponent } from './features/main/cod/cod-receipt-number/list/cod-receipt-number-list.component';
import { TransferCreditCodListComponent } from './features/main/cod/transfer-credit-cod/list/transfer-credit-cod-list.component';
// Shipment
import { MonitoringPickUpListComponent } from './features/main/shipments/monitoring-pickup/list/monitoring-pickup-list.component';
import { RequestCancelListComponent } from './features/main/shipments/request-cancel/list/request-cancel-list.component';
import { SendRequestPickupListComponent } from './features/main/shipments/send-request-pickup/list/send-request-pickup-list.component';
// E-wallet
import { TenantsBankListComponent } from './features/main/e-wallet/tenants-bank/list/tenants-bank-list.component';
import { TenantWalletListComponent } from './features/main/e-wallet/tenant-wallet/list/tenant-wallet-list.component';
import { RequestWithdrawFundsListComponent } from './features/main/e-wallet/request-withdraw-funds/list/request-withdraw-funds-list.component';
import { WithdrawOfTenantFunds } from './features/main/e-wallet/withdraw-of-tenant-funds/list/withdraw-of-tenant-funds-list.component';
import { DebitCodListComponent } from './features/main/e-wallet/debit-cod/list/debit-cod-list.component';
import { VerificationRequestBankAccount } from './features/main/e-wallet/verification-rekening/list/verification-rekening-list.component';
// Tenant
import { TenantListComponent } from './features/main/tenants/tenant/list/tenants-list.component';
import { TrainingScheduleListComponent } from './features/main/tenants/training-schedule/list/training-schedule-list.component';
import { CustomTrainingScheduleListComponent } from './features/main/tenants/custom-training-schedule/list/custom-training-schedule-list.component';
import { VerificationRequestListComponent } from './features/main/tenants/verification-request/list/verification-request-list.component';
import { ActivationAccountComponent } from './features/main/tenants/activation-account/list/activation-account-list.component';
// User
import { UsersListComponent } from './features/main/users/users/list/users-list.component';
import { UserPermissionListComponent } from './features/main/users/permissions/list/user-permissions-list.component';
import { UserRolesListComponent } from './features/main/users/roles/list/user-roles-list.component';
// Subcription
import { HystoryTransactionListComponent } from './features/main/subcriptions/history-transaction/list/history-transaction-list.component';
import { CouponListComponent } from './features/main/subcriptions/coupon/list/coupon-list.component';
import { CouponCreateComponent } from './features/main/subcriptions/coupon/create/coupon-create.component';
import { CouponUpdateComponent } from './features/main/subcriptions/coupon/update/coupon-update.component';
import { BillingListComponent } from './features/main/subcriptions/billing/list/billing-list.component';
import { UpgradeRenewComponent } from './features/main/subcriptions/billing/upgrade-renew/upgrade-renew.component';
// Billing
import { BillingPaymentListComponent } from './features/main/bill/billing-payment/list/billing-payment-list.component';
import { BillingShippingReturnListComponent } from './features/main/bill/return/list/billing-shipping-return-list.component';
import { BillingInvoiceListComponent } from './features/main/bill/billing-invoice/list/billing-invoice-list.component';
import { BillingAdjustmentListComponent } from './features/main/bill/adjustment/list/billing-adjustment-list.component';
// Admin
import { AdminNotificationComponent } from './features/main/admin-notifications/list/admin-notifications-list.component';



export function AppRouter() {
  return (
    <>
      <ProvideAuth>
        <Router>
          <Switch>
            {/* Dashboard  */}
            <GuardRoute title="Dashboard" path="/dashboard" exact meta={{ permissions: ['dashboard.general.view'] }} ><DashboardComponent /></GuardRoute>

            {/* Cash on delivery */}
            <GuardRoute title="COD" path="/cod/transfer-credit-cod" exact meta={{ permissions: ['cod.transfer_credit.view'] }} ><TransferCreditCodListComponent /></GuardRoute>
            <GuardRoute title="COD" path="/cod/tracking-number" exact meta={{ permissions: ['cod.transfer_credit.view'] }} ><CodReceiptNumberListComponent /></GuardRoute>

            {/* Shipment */}
            <GuardRoute title="Pengiriman" path="/shipment/monitoring-pickup" exact meta={{ permissions: ['tenant.pickup_monitoring.view'] }} ><MonitoringPickUpListComponent /></GuardRoute>
            <GuardRoute title="Pengiriman" path="/shipment/request-cancel" exact meta={{ permissions: ['tenant.cancel_pickup.view'] }} ><RequestCancelListComponent /></GuardRoute>
            <GuardRoute title="Pengiriman" path="/shipment/request-pickup" exact meta={{ permissions: ['tenant.request_pickup.view'] }} ><SendRequestPickupListComponent /></GuardRoute>

            {/* E-wallet */}
            <GuardRoute title="E-wallet" path="/e-wallet/tenants-bank" exact meta={{ permissions: ['wallet.tenant_bank.view'] }} ><TenantsBankListComponent /></GuardRoute>
            <GuardRoute title="E-wallet" path="/e-wallet/wallet-transactions" exact meta={{ permissions: ['wallet.tenant_wallet.view'] }} ><TenantWalletListComponent /></GuardRoute>
            <GuardRoute title="E-wallet" path="/e-wallet/request-withdraw-funds" exact meta={{ permissions: ['wallet.withdrawal.view'] }} ><RequestWithdrawFundsListComponent /></GuardRoute>
            <GuardRoute title="E-wallet" path="/e-wallet/debit-cod/" exact meta={{ permissions: ['wallet.withdrawal_history.view'] }} ><DebitCodListComponent /></GuardRoute>
            <GuardRoute title="E-wallet" path="/e-wallet/withdraw-of-tenant-funds" exact meta={{ permissions: ['wallet.withdrawal_history.view'] }} ><WithdrawOfTenantFunds /></GuardRoute>
            <GuardRoute title="E-wallet" path="/e-wallet/verifikasi-rekening" exact meta={{ permissions: ['wallet.withdrawal_history.view'] }} ><VerificationRequestBankAccount /></GuardRoute>

            {/* Tenants */}
            <GuardRoute title="Tenants" path="/tenant/list" exact meta={{ permissions: ['tenant.tenant_list.view'] }} ><TenantListComponent /></GuardRoute>
            <GuardRoute title="Tenants" path="/tenant/training-schedule" exact meta={{ permissions: ['tenant.shipping_integration.view'] }} ><TrainingScheduleListComponent /></GuardRoute>
            <GuardRoute title="Tenants" path="/tenant/custom-training-schedule" exact meta={{ permissions: ['tenant.shipping_integration.view'] }} ><CustomTrainingScheduleListComponent /></GuardRoute>
            <GuardRoute title="Tenants" path="/tenant/verification-request" exact meta={{ permissions: ['tenant.shipping_integration.view'] }} ><VerificationRequestListComponent /></GuardRoute>
            <GuardRoute title="Tenants" path="/tenant/activation-account" exact meta={{ permissions: ['admin.user_admin.view'] }} ><ActivationAccountComponent /></GuardRoute>

            {/* Users */}
            <GuardRoute title="Users" path="/users" exact meta={{ permissions: ['admin.user_admin.view'] }} ><UsersListComponent /></GuardRoute>
            <GuardRoute title="Users" path="/users/permissions" exact meta={{ permissions: ['admin.permission_admin.view'] }} ><UserPermissionListComponent /></GuardRoute>
            <GuardRoute title="Users" path="/users/access-roles" exact meta={{ permissions: ['admin.role_admin.view'] }} ><UserRolesListComponent /></GuardRoute>


            {/* Subscription */}
            <GuardRoute title="Subscription" path="/subscription/coupon" exact meta={{ permissions: ['tenant.general_coupon.view'] }} ><CouponListComponent /></GuardRoute>
            <GuardRoute title="Coupon: Create" path="/subscription/coupon/create" exact meta={{ permissions: ['tenant.general_coupon.create'] }} ><CouponCreateComponent /></GuardRoute>
            <GuardRoute title="Coupon: Update" path="/subscription/coupon/:id/update" exact meta={{ permissions: ['tenant.general_coupon.edit'] }} ><CouponUpdateComponent /></GuardRoute>
            <GuardRoute title="Subscription" path="/subscription/billing" exact meta={{ permissions: ['tenant.subscription.view'] }} ><BillingListComponent /></GuardRoute>
            <GuardRoute title="Subscription" path="/subscription/billing/upgrade-renew/:id" exact meta={{ permissions: ['tenant.subscription.edit'] }} ><UpgradeRenewComponent /></GuardRoute>
            <GuardRoute title="Subscription" path="/subscription/history-transaction" exact meta={{ permissions: ['tenant.subscription.view'] }} ><HystoryTransactionListComponent /></GuardRoute>

            {/* Billing */}
            <GuardRoute title="Billing" path="/bill/billing-payment" exact meta={{ permissions: ['finance.billing.view'] }} ><BillingPaymentListComponent /></GuardRoute>
            <GuardRoute title="Billing" path="/bill/billing-shipping-invoice" exact meta={{ permissions: ['finance.billing.view'] }} ><BillingInvoiceListComponent /></GuardRoute>
            <GuardRoute title="Billing" path="/bill/billing-shipping-return" exact meta={{ permissions: ['finance.billing.view'] }} ><BillingShippingReturnListComponent /></GuardRoute>
            <GuardRoute title="Billing" path="/bill/billing-adjustment" exact meta={{ permissions: ['finance.billing.view'] }} ><BillingAdjustmentListComponent /></GuardRoute>


            {/* Others */}
            <GuardRoute title="Admin Notifications" path="/notifications" exact ><AdminNotificationComponent isSummary={false} /></GuardRoute>

            <GuardRoute path="/" exact></GuardRoute>
            <Route path="/login" exact component={LoginComponent} />
            <Route path="/404" component={NotFoundComponent} />
            <Route path="/403" component={ForbiddenAccessComponent} />
            <Route path="*"><Redirect to="/404" /></Route>
          </Switch>
        </Router>
      </ProvideAuth>
    </>
  );
}

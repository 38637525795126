import { Table } from '@clodeo/clodeo-ui/components/data-display/table/table.component';
import { Tag } from '@clodeo/clodeo-ui/components/data-display/tag/tag.component';
import { Checkbox, CheckboxGroup } from '@clodeo/clodeo-ui/components/data-entry/checkbox/checkbox.component';
import { RangePicker } from '@clodeo/clodeo-ui/components/data-entry/datepicker/datepicker.component';
import { InputNumber } from '@clodeo/clodeo-ui/components/data-entry/inputnumber/inputnumber.component';
import { Input } from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';
import { Radio, RadioGroup } from '@clodeo/clodeo-ui/components/data-entry/radiobutton/radiobutton.component';
import { Option, Select } from "@clodeo/clodeo-ui/components/data-entry/select/select.component";
import { Switch } from '@clodeo/clodeo-ui/components/data-entry/switch/switch.component';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { Notifications } from "@clodeo/clodeo-ui/components/feedback/notification/notification.component";
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { LoadingComponent } from '@clodeo/clodeo-ui/components/general/loading/loading.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { AdminPickerRestService } from '@clodeo/libs/core/rest/admin/picker/admin-picker-rest.service';
import { Form } from 'antd';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { CouponFormService } from './coupon-form.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { uniqBy, chunk, map, filter, startCase, findIndex, get } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { concat, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import './coupon-form.component.scss';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';

export const CouponFormComponent = (props: { doc?: any, parentCallback: any, formType: 'create' | 'update' }) => {
  const router = useHistory();
  const { doc, parentCallback, formType } = props;
  const notif: Notifications = new Notifications;
  const couponFormService: CouponFormService = new CouponFormService;
  const { handleRequest } = new HandleService();
  const authenticationService: AuthenticationService = new AuthenticationService;
  const pickerRestService = new AdminPickerRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingTenant, setLoadingTenant] = useState<boolean>(false);
  const [isTenantSettingModal, setIsTenantSettingModal] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [optionTenants, setOptionTenants] = useState<any[]>();
  const [filteredOptionTenants, setFilteredOptionTenants] = useState<any[]>();
  const [keyword, setKeyword] = useState<string>();
  const [tenantSandbox, setTenantSandbox] = useState<string[]>([]);
  const [confirmtenantSandbox, setConfirmTenantSandbox] = useState<string[]>([]);
  const [defValue, setDefValue] = useState<number>(0);

  const required = [
    {
      required: true,
      message: 'Kolom ini wajib diisi!'
    }
  ];
  const reqNumber = [
    {
      required: true,
      message: 'Kolom ini hanya boleh diisi angka!'
    }
  ];

  const optionUsers = [
    { name: 'New', id: 'new' },
    { name: 'Existing', id: 'existing' },
    { name: 'Both', id: 'none' },
  ];

  const optionPlans = [
    { name: 'Starter', id: 'starter' },
    { name: 'Lite Bronze', id: 'lite_bronze' },
    { name: 'Growing', id: 'growing' },
    { name: 'Lite Silver', id: 'lite_silver' },
    { name: 'Professional', id: 'professional' },
    { name: 'Lite Gold', id: 'lite_gold' },
    { name: 'Enterprise', id: 'enterprise' },
  ]

  const optionPriods = [
    { name: '1 Month', id: 'monthly' },
    { name: '3 Month', id: 'quarterly' },
    { name: '6 Month', id: 'semesterly' },
    { name: '12 Month', id: 'yearly' },
  ];

  const optionTenantSettings = [
    { name: 'None', id: 'none' },
    { name: 'Whitelist', id: 'whitelist' },
    { name: 'Blacklist', id: 'blacklist' },
  ];

  const columns = [
    { key: '1', dataIndex: 'id', title: 'Tenant Id' },
    { key: '2', dataIndex: 'name', title: 'Tenant Name' }
  ];

  useEffect(() => {
    setDefValue(0);
    if (formType === "update") {
      form.setFieldsValue(doc);
    }

    // if (isLoading) {
    //   getTenants().subscribe();
    // }
  }, [doc, optionTenants, isLoading]);

  function loadTenants(keyword = '') {
    setLoadingTenant(true);
    const options = {
      take: 50,
      includeTotalCount: true,
    };
    const params = {
      keyword
    }
    const obs = pickerRestService.getTenant(options, params)
    handleRequest({
      obs,
      onError: () => {
        setLoadingTenant(false);
      },
      onDone: (res) => {
        setLoadingTenant(false);
        const selectedTenant = form.getFieldValue('tenants').map(tenant => {
          return {
            label: tenant.name,
            value: tenant.id,
            style: { display: ((tenant.name).toLowerCase()).includes((keyword).toLowerCase()) ? 'inline-block' : 'none' }
          }
        })
        const newFilteredOpt = [
          ...selectedTenant,
          ...res.data.map(tenant => {
            return {
              label: tenant.displayName,
              value: tenant.id,
              style: { display: ((tenant.displayName).toLowerCase()).includes((keyword).toLocaleLowerCase()) ? 'inline-block' : 'none' }
            }
          })
        ]
        setFilteredOptionTenants(uniqBy(newFilteredOpt, 'value'))
      }
    })
  }

  const getTenants = (): Observable<any> => {
    const optionTenants = [];

    const params = {
      "take": 1,
      "includeTotalCount": true,
    };

    return pickerRestService.getTenant(params)
      .pipe(
        catchError(error => {
          notif.show({
            type: "error",
            title: "Error",
            description: error,
            useService: true
          });
          return throwError(error);
        }),
        switchMap((res: any) => {
          const total = map(chunk(new Array(res.total), 300), (request, index) => {
            const params = {
              take: request.length,
              skip: index * 300,
              includeTotalCount: true,
            }

            return pickerRestService.getTenant(params)
              .pipe(
                switchMap((res2: any) => {
                  const result = res2.data.map(tenant => ({
                    id: tenant.id,
                    name: tenant.companyName,
                  }));
                  return of(result);
                }),
                tap((result: any) => optionTenants.push(...result))
              );
          });

          return forkJoin(concat(...total)).pipe(
            catchError(error => {
              notif.show({
                type: "error",
                title: "Error",
                description: error,
                useService: true
              });
              return throwError(error);
            }),
            tap(() => { setOptionTenants(optionTenants); setFilteredOptionTenants(optionTenants.map(tenant => ({ label: `(${tenant.id}) ${tenant.name}`, value: tenant.id }))); })
          );
        }),
        finalize(() => setIsLoading(false))
      );
  }

  function onSubmit(value?) {
    if (value.hasOwnProperty('errorFields') && value.errorFields.length) {
      notif.show({
        type: "error",
        title: "Error",
        description: "Pastikan semua data telah diisi",
      });
      return;
    }
    const formValue = form.getFieldsValue(true);
    parentCallback(formValue);
  }

  const showModal = () => {
    setKeyword('');
    loadTenants();
    setTenantSandbox(form.getFieldValue('tenants').map(tenant => tenant.value ? tenant.value : tenant.id));
    const confirm = [...tenantSandbox, ...confirmtenantSandbox];
    setConfirmTenantSandbox(confirm);
    setIsTenantSettingModal(true);
  };

  const hideModal = () => {
    setIsTenantSettingModal(false);
  };

  const confirmModal = () => {
    const saveFormTenant = form.getFieldsValue();
    const selectedTenant = filteredOptionTenants.filter(val => {
      val.id = val.value;
      val.name = val.label;
      return tenantSandbox.includes(val.value)
    });
    saveFormTenant.tenants = selectedTenant;
    form.setFieldsValue(saveFormTenant);
    hideModal();
  }

  const cancelModal = () => {
    hideModal();
  }

  const onSearch = (query: string = '') => {
    const searchValue = query.toLowerCase();
    const opts = filter(optionTenants, (tenant) => {
      const q = `(${tenant.id}) ${tenant.name}`.toLowerCase();
      return (q).includes(searchValue || '');
    })

    setFilteredOptionTenants(map(optionTenants, tenant => (
      {
        label: `(${tenant.id}) ${tenant.name}`,
        value: tenant.id,
        style: { display: opts.includes(tenant) ? 'inline-block' : 'none' }
      }
    )));
  }

  function disabledDate(current) {
    // Can't before today and today
    return current <= moment().endOf('day').subtract(1, 'd');
  }


  function onFormValueChanges() {
    if (form.getFieldValue('code')) {
      const upperCase = form.getFieldValue('code').toUpperCase();
      form.setFieldsValue({
        code: upperCase.replace(/[^a-z0-9]/gi, '')
      })
    }
  }

  return (
    <div className="col-12">
      <Spinner className="d-flex align-item-center" spinning={isLoading} indicator={<LoadingComponent />}>
        <Form
          form={form}
          name="coupon-form"
          onFinish={onSubmit}
          onFinishFailed={onSubmit}
          onValuesChange={onFormValueChanges}
          initialValues={{
            description: '',
            inactive: false,
            tenantValidType: 'none',
            quotaUsage: 0,
            couponCategoryType: 'subscription_plan',
            minSpend: 0,
            maxDiscount: 0,
            discountValue: 0,
            couponValidType: 'only',
            couponCategory: ['subscription'],
            discountType: 'percentage',
            tenants: [],
            quota: 0
          }}
        >

          <Form.Item shouldUpdate>
            {() => (
              <div className="row">
                <div className="col-12 mb-3">
                  <div className="bg-light p-3">
                    <div className="row">
                      <div className="col-5">
                        <label className="form-label field-required">Coupon Name</label>
                        <Form.Item
                          name="name"
                          rules={required}
                        >
                          <Input placeholder="Coupon Name" type="text" />
                        </Form.Item>
                      </div>
                      <div className="col-5">
                        <label className="form-label field-required">Coupon Code</label>
                        <Form.Item
                          name="code"
                          rules={required}
                        >
                          <Input disabled={form.getFieldValue('quotaUsage') > 0} placeholder="Coupon Code" type="text" />
                        </Form.Item>
                      </div>
                      <div className="col-2">
                        <label className="d-block">Status</label>
                        <Form.Item name="inactive">
                          <Switch checked={!form.getFieldValue('inactive')} onChange={(value) => form.setFieldsValue({ inactive: !value })} />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-12 mb-3">
                  <div className="bg-light p-3">
                    <div className="row">
                      <div className="col-md-4">
                        <label className="form-label">Type</label>
                        <Form.Item name="couponCategory">
                          <Select mode="multiple" allowClear disabled>
                            <Option value="subscription">Subscription</Option>
                          </Select>
                        </Form.Item>
                      </div>
                      <div className="col-md-6">
                        <label className="form-label field field-required">Period Time</label>
                        <Form.Item name="validStartEndDate" rules={couponFormService.form.rules["validStartEndDate"]}>
                          <RangePicker className="w-100" disabledDate={disabledDate} format="DD/MM/YYYY" />
                        </Form.Item>
                      </div>

                      <div className="col-md-2">
                        <label className="form-label field-required">Limit</label>
                        <Form.Item
                          name="quota"
                          rules={reqNumber}
                        >
                          <InputNumber min={0} />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-8 mb-3">
                  <div className="bg-light p-3">
                    <div className="row">
                      <div className="col-4">
                        <label className="form-label field field-required">
                          User
                    </label>
                        <Form.Item name="accountValidType" rules={couponFormService.form.rules["accountValidType"]}>
                          <RadioGroup>
                            {optionUsers.map(option => {
                              return <Radio key={option.id} value={option.id} className="py-1"> {option.name}</Radio>
                            })}
                          </RadioGroup>
                        </Form.Item>
                      </div>
                      <div className="col-5">
                        <label className="form-label field field-required">
                          Plan
                    </label>
                        <Form.Item name="subscriptionPlans" rules={couponFormService.form.rules["subscriptionPlans"]}>
                          <CheckboxGroup>
                            <div className="row">
                              {optionPlans.map(plan => {
                                return <div className="col-md-6 align-self-center py-1" key={plan.id}>
                                  <Checkbox variant="inline-label" value={plan.id}> {plan.name}</Checkbox>
                                </div>
                              })}
                            </div>
                          </CheckboxGroup>
                        </Form.Item>
                      </div>
                      <div className="col-3">
                        <label className="form-label field field-required">
                          Period
                    </label>
                        <Form.Item name="subscriptionCycles" rules={couponFormService.form.rules["subscriptionCycles"]}>
                          <CheckboxGroup>
                            <div className="row">
                              {optionPriods.map(priod => {
                                return <div className="col-md-12 py-1" key={priod.id}>
                                  <Checkbox value={priod.id} variant="inline-label"> {priod.name}</Checkbox>
                                </div>
                              })}
                            </div>
                          </CheckboxGroup>
                        </Form.Item>
                      </div>

                      <div className="col-12 mt-2">
                        <div className="row">
                          <div className="col-auto font-weight-bold" onClick={showModal}>
                            Coupon Setting
                          </div>
                          <div className="col text-right">
                            {
                              form.getFieldValue('tenantValidType') !== 'none' ?
                                <Button onClick={showModal} type="tertiary" label="Tenant Setting" size="md" /> :
                                false
                            }
                          </div>
                        </div>
                      </div>

                      <div className="col-12">
                        <Form.Item name="tenantValidType">
                          <RadioGroup>
                            {optionTenantSettings.map(option => {
                              return <Radio key={option.id} value={option.id}>{option.name}</Radio>
                            })}
                          </RadioGroup>
                        </Form.Item>
                      </div>

                      {
                        form.getFieldValue('tenantValidType') !== 'none' && <div className="col-12">
                          <Table
                            columns={columns}
                            dataSource={form.getFieldValue('tenants')}
                            scroll={{ y: 360 }}
                          />
                        </div>
                      }
                    </div>
                  </div>
                </div>

                <div className="col-md-4 mb-3">
                  <div className="bg-light p-3">
                    <div className="row">
                      <div className="col-12">
                        <span className="font-weight-bold">Clodeo Discount</span>
                      </div>
                      <div className="col-12">
                        <div className="summary-wrapper">
                          <div className="row">
                            <div className="col-md-3">Coupon Code</div>
                            <div className="col-md-9 font-weight-bold text-md-right">
                              {form.getFieldValue('code')}
                            </div>
                          </div>
                        </div>
                        <div className="summary-wrapper">
                          <div className="row">
                            <div className="col-md-3">Type</div>
                            <div className="col-md-9 font-weight-bold text-md-right">
                              {startCase(form.getFieldValue('couponCategory'))}
                            </div>
                          </div>
                        </div>

                        <div className="summary-wrapper">
                          <div className="row">
                            <div className="col-md-3">Limit</div>
                            <div className="col-md-9 font-weight-bold text-md-right">
                              {form.getFieldValue('quota')}
                            </div>
                          </div>
                        </div>

                        <div className="summary-wrapper">
                          <div className="row">
                            <div className="col-md-3">Time Promo</div>
                            <div className="col-md-9 font-weight-bold text-md-right">
                              {form.getFieldValue('validStartEndDate') && ' ' + moment(get(form.getFieldValue('validStartEndDate'), '0')).format('DD/MM/YYYY') + ' - ' + moment(get(form.getFieldValue('validStartEndDate'), '1')).format('DD/MM/YYYY')}
                            </div>
                          </div>
                        </div>

                        <div className="summary-wrapper">
                          <div className="row">
                            <div className="col-md-3">Plan</div>
                            <div className="col-md-9 font-weight-bold text-md-right">
                              {(form.getFieldValue('subscriptionPlans') || []).map(plan =>
                                <Tag colorType={plan} key={plan} className=" mr-2 mb-2">{startCase(plan)}</Tag>)
                              }
                            </div>
                          </div>
                        </div>

                        <div className="summary-wrapper">
                          <div className="row">
                            <div className="col-md-3">Period</div>
                            <div className="col-md-9 font-weight-bold text-md-right">
                              {(form.getFieldValue('subscriptionCycles') || []).map(selectedPriod => {
                                const index = findIndex(optionPriods, (priod) => priod.id === selectedPriod);
                                if (index !== -1) {
                                  return optionPriods[index].name;
                                }
                                return false;
                              }).join(', ')}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12">Type Discount</div>

                      <div className="col-12">
                        <div className="ant-row ant-form-item ant-form-item-has-success d-inline-block">
                          <div className="ant-col ant-form-item-control">
                            <div className="ant-form-item-control-input">
                              <div className="ant-form-item-control-input-content">
                                <div className="ant-radio-group ant-radio-group-outline">
                                  <Radio value="percentage" className="col-auto" checked={form.getFieldValue('discountType') === 'percentage' || form.getFieldValue('discountType') === 'amount'}
                                    onChange={(event) => form.setFieldsValue({ ...form.getFieldsValue(), discountType: event.target.value })}
                                    disabled={form.getFieldValue('quotaUsage')}>Discount</Radio>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <Form.Item name="discountType" className="d-inline-block">
                          <RadioGroup>
                            {/* <Radio value="percentage" className="col-auto" checked={form.getFieldValue('discountType') === 'percentage' || form.getFieldValue('discountType') === 'amount'} disabled={form.getFieldValue('quotaUsage')}>Discount</Radio> */}
                            <Radio value="additional_month" className="col-auto" checked={form.getFieldValue('discountType') === 'additional_month'} disabled={form.getFieldValue('quotaUsage')}>Period</Radio>
                          </RadioGroup>
                        </Form.Item>
                      </div>

                      <div className="col-md-12 mt-2 mb-3">
                        <Form.Item name="discountValue" rules={couponFormService.form.rules["discountValue"]}>
                          <Input disabled={form.getFieldValue('quotaUsage')} addonBefore={form.getFieldValue('discountType') !== "additional_month" ? (

                            <Form.Item name="discountType">
                              <Select disabled={form.getFieldValue('quotaUsage')}>
                                <Option value="percentage">%</Option>
                                <Option value="amount">Rp.</Option>
                              </Select>
                            </Form.Item>
                          ) : false}
                          />
                        </Form.Item>
                      </div>

                      <div className="col-12 text-right">
                        <Button type="outline" label="Cancel" className="mr-2" onClick={() => router.push(`/subscription/coupon`)} size="md" />
                        <Button submit type="tertiary" label={`${formType === 'create' ? 'Tambah' : 'Simpan'} Coupon`} size="md" />
                      </div>
                    </div>
                  </div>
                </div>

                <Form.Item name="couponCategoryType" hidden>
                  <Input />
                </Form.Item>
                <Form.Item name="rowVersion" hidden>
                  <Input />
                </Form.Item>
                <Form.Item name="minSpend" hidden>
                  <Input />
                </Form.Item>
                <Form.Item name="maxDiscount" hidden>
                  <Input />
                </Form.Item>
                <Form.Item name="quotaUsage" hidden>
                  <Input />
                </Form.Item>
                <Form.Item name="id" hidden>
                  <Input />
                </Form.Item>
                <Form.Item name="couponValidType" hidden>
                  <Input />
                </Form.Item>
                <Form.Item name="description" hidden>
                  <Input />
                </Form.Item>
              </div>
            )}
          </Form.Item>


          <Modal
            visible={isTenantSettingModal}
            title={startCase(form.getFieldValue('tenantValidType')) + ' Tenant'}
            onOk={confirmModal}
            onCancel={cancelModal}
            closable={false}
            okButtonProps={{ className: 'd-inline-block' }}
            cancelButtonProps={{ className: 'btn btn-deo-outline d-inline-block' }}
            forceRender
          >
            <div className="m-auto tenant-picker-wrapper">
              <div>
                <Input size="large" className="shadow-none" placeholder="Cari tenant" value={keyword} onChange={(e) => setKeyword(e.target.value)} onPressEnter={() => loadTenants(keyword)} suffix={<i className="icon-deo-search pointer" onClick={() => loadTenants(keyword)}></i>} />
              </div>
              <Spinner spinning={loadingTenant} indicator={<LoadingComponent />}>
                <div className="my-2">
                  {
                    (filteredOptionTenants && (filteredOptionTenants.length > 0)) ?
                      <CheckboxGroup className="listbox-wrapper" options={filteredOptionTenants} defaultValue={tenantSandbox} onChange={(values: string[]) => setTenantSandbox(values)}>
                      </CheckboxGroup>
                      :
                      <div className="text-center listbox-wrapper">Data tenant tidak ditemukan</div>
                  }
                  <div className="w-100">
                    <i>*Hanya menampilkan 50 data</i>
                  </div>
                </div>
              </Spinner>
            </div>
          </Modal>
        </Form >
      </Spinner>
    </div>
  )
}

import _ from 'lodash';
import moment from 'moment';
import { ExcelRenderer } from "react-excel-renderer";
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { IExcelData, IReadFile, IExcelDataHeaders } from './excel.d';
import { FormatService } from '../format/format.service';



export { IExcelData, IReadFile, IExcelDataHeaders } from './excel.d';
const notif = new Notifications;
const formatService = new FormatService();
export class ExcelService {
  reactExcelRenderer = ExcelRenderer;
  data: IExcelData = {
    headers: [],
    excelHeaders: [],
    records: [],
    // ignoreFirstColumn: false,
    isParsedHeaders: false
  };

  readFile(params: IReadFile = { file: new Blob(), columnLimit: 2, onDone: {handle: (value) => {}} }) {
    let { file, onError, onDone, columnLimit } = params;
    if(!columnLimit && columnLimit !== 0) {
      columnLimit = 2;
    }

    ExcelRenderer(file, (err, data) => {
      if (err) {
        console.log(err)

        if (!onError.showNotif) {
          notif.show({
            type: 'error',
            title: 'Error File',
            description: 'Invalid file',
            useService: true
          });
        }
        onError.handle && onError.handle(onError);

      } else {
        let excelData = data.rows;
        this.parseToObject(excelData, columnLimit);
        const excelValue = this.data.records;
        if (!excelValue.length && onError.hasOwnProperty("handle")) {
          onError.handle(excelValue);
          return;
        }

        onDone.handle && onDone.handle(excelValue);
      }
    });
  };

  parseToObject(fileData, columnLimit: number) {
    this.resetAfterRender();

    const trueHeaders = this.data.headers;
    const hiddenHeaders = _.filter(this.data.headers, ['hidden', true]);

    let valuePerRow = {};
    let defaultValuePerRow = {};
    let hasError: boolean;
    for (let i = 0; i < fileData.length; i++) {
      //checking empty row on excel file, value 2 is hardcode
      if (_.compact(fileData[i]).length > columnLimit) {
        if (this.data.isParsedHeaders) {
          for (let colIndex = 0; colIndex <= fileData[i].length; colIndex++) {
            const existData = _.find(this.data.excelHeaders, ["matchIndex", colIndex]);
            if (existData) {

              // validation per column
              if (!fileData[i][existData.matchIndex] && existData.validation?.required) {
                notif.show({
                  type: 'error',
                  title: 'Error',
                  description: `${fileData[i][0] ? "Baris ke " + fileData[i][0] : ""} kolom ${existData.title} wajib diisi`,
                });
                hasError = true;
                // break;

                // return {
                //   error: true,
                //   messages: "Error validation"
                // };
              }


              // set value per column              
              valuePerRow = { ...valuePerRow, [existData.name]: fileData[i][colIndex] };
              
              if (existData.hasOwnProperty("format")) {
                valuePerRow[existData.name] = existData.format(fileData[i][colIndex], fileData[i]);
              } else {
                switch (existData.valueType) {
                  case "string":
                    if (!fileData[i][colIndex]) {
                      valuePerRow[existData.name] = "";
                    } else {
                      valuePerRow[existData.name] = (`${fileData[i][colIndex]}`).trim();
                    }

                    break;
                  case "number":
                    if (!fileData[i][colIndex] || _.isNaN(fileData[i][colIndex])) {
                      valuePerRow[existData.name] = 0;
                    } else {
                      valuePerRow[existData.name] = Math.round(fileData[i][colIndex]);
                    }
                    break;

                  case "boolean":
                    valuePerRow[existData.name] = this.setBooleanValue(fileData[i][colIndex]);
                    break;

                  case "date":
                    if (_.isNumber(fileData[i][colIndex])) {
                      valuePerRow[existData.name] = this.setDateValue(this.excelDateToJSDate(fileData[i][colIndex]));

                    } else {
                      valuePerRow[existData.name] = this.setDateValue(fileData[i][colIndex]);
                    }
                    break;

                  case "percent":
                    valuePerRow[existData.name] = formatService.percentage(fileData[i][colIndex]);
                    break;

                  default:
                    valuePerRow[existData.name] = fileData[i][colIndex];
                    break;
                }
              }

            }

          }

          // set value for attribute hasn't match header
          for (let hiddenIndex = 0; hiddenIndex < hiddenHeaders.length; hiddenIndex++) {
            if (hiddenHeaders[hiddenIndex].hasOwnProperty("format")) {
              valuePerRow[hiddenHeaders[hiddenIndex].name] = hiddenHeaders[hiddenIndex].format(valuePerRow, fileData[i])
            }
          }
          this.data.records.push({ ...valuePerRow, ...defaultValuePerRow });
        }

        // checking if excel columns has same length with define real headers
        if (!this.data.isParsedHeaders) {
          // if (!this.data.isParsedHeaders && fileData[i].length >= trueHeaders.length) {
          defaultValuePerRow = this.setHeaderDefaultValue(fileData[i], trueHeaders);
          this.data.isParsedHeaders = true;
        }
      }
    }

    if (hasError) {
      this.data.records = [];
    }
  }

  setHeaderDefaultValue(rowValues, trueHeaders) {

    let rowDefaultValue = {};
    for (let iHeader = 0; iHeader < trueHeaders.length; iHeader++) {
      let rowData: (IExcelDataHeaders & { isMatch: boolean; matchIndex: number });

      for (let iRow = 0; iRow < rowValues.length; iRow++) {

        if (this.checkHeader(rowValues[iRow], trueHeaders[iHeader].header, trueHeaders[iHeader].hidden)) {
          rowData = {
            ...trueHeaders[iHeader],
            isMatch: true,
            matchIndex: iRow,
          };
          break;
        }

      }

      if (trueHeaders[iHeader].defaultValue !== undefined) {
        rowDefaultValue = { ...rowDefaultValue, [trueHeaders[iHeader].name]: trueHeaders[iHeader].defaultValue };
      }

      this.data.excelHeaders.push(rowData)
    }
    return rowDefaultValue;
  }

  checkHeader(excelHeader, header, hidden) {
    let isMatch = false;
    if (_.isArray(header)) {
      for (let index = 0; index < header.length; index++) {
        if (((`${(excelHeader || "")}`).toLowerCase()).trim() === (header[index] || '').toLowerCase() && !hidden) {
          isMatch = true;
          break;
        }
      }
    } else if (((`${(excelHeader || "")}`).toLowerCase()).trim() === (header || '').toLowerCase() && !hidden) {
      isMatch = true;
    }
    return isMatch;
  }

  resetAfterRender() {
    this.data.excelHeaders = [];
    this.data.records = [];
    this.data.isParsedHeaders = false;
  }


  // BELOW IS ADDON FUNCTION

  excelDateToJSDate(serial) {
    const utc_days = Math.floor(serial - 25569);
    const utc_value = utc_days * 86400;
    const date_info = new Date(utc_value * 1000);

    const fractional_day = serial - Math.floor(serial) + 0.0000001;

    let total_seconds = Math.floor(86400 * fractional_day);

    const seconds = total_seconds % 60;

    total_seconds -= seconds;

    const hours = Math.floor(total_seconds / (60 * 60));
    const minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
  }

  setDateValue(value, format?) {
    let newValue: string | Date;
    if (!value) {
      newValue = null;
    } else {
      if (moment(value).format() === 'Invalid date') {
        newValue = moment(value, 'DD/MM/YYYY').format();
      } else {
        if (format) {
          newValue = moment(value).format(format);
        }
        newValue = moment(value).format();
      }
    }

    return newValue;
  }

  setBooleanValue(value) {
    let newValue: boolean;
    if (value) {
      if (typeof value === 'number') {
        newValue = value ? true : false;
      } else if (typeof value === 'boolean') {
        newValue = value;
      } else {
        newValue = value.toLowerCase() === 'true' ? true : false;
      }
    } else {
      newValue = false;
    }
    return newValue;
  }
}

import { RequestService } from '../../request.service';

export class ShippingIntegrationRestService {
  requestService = new RequestService;
  request: RequestService;
  /**
   * 
   * @param baseUrl only from environment.ENDPOINTS
   * @param useInterceptor 
   * @param withoutInterceptor 
   */
  constructor(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
    this.request = this.requestService.new(`${baseUrl}/tenant-shipping-integrations`, useInterceptor, withoutInterceptor);
  }

  /**
   * Get list of training schedule
   * @param qParams 
   */
  getListTrainingSchedule(qParams) {
    return this.request.get('/training-info', qParams);
  }

  /**
   * Get list of custom training schedule
   * @param qParams 
   */
  getListCustomTrainingSchedule(qParams) {
    return this.request.get('/training-dates', qParams);
  }

  /**
   * Get list of tenant shipping integration
   * @param qParams 
   */
  getListVerificationRequest(qParams) {
    return this.request.get('', qParams);
  }

  /**
   * Get single data of tenant shipping integration
   * @param qParams 
   */
  getSingleTenantShippingIntegration(tenantId: string) {
    return this.request.get(`/${tenantId}`);
  }

  /**
   * Create data training date
   * @param qParams 
   */
  createTrainingDate(payload) {
    return this.request.post('/create-training-date', { ...payload });
  }

  /**
   * Delete data training date
   * @param qParams 
   */
  deleteTrainingDate(payload) {
    return this.request.delete('delete-training-date', { ...payload });
  }

  /**
   * Verify data tenant shipping integration
   * @param qParams 
   */
  verifyRequest(tenantId, payload) {
    return this.request.post(`/verify/${tenantId}`, payload);
  }

  /**
   * Approve data tenant shipping integration
   * @param qParams 
   */
  approvalRequest(tenantId, payload) {
    return this.request.post(`/approval/${tenantId}`, payload);
  }

  /**
   * Get history of admin
   * @param tenantId 
   */
   getAdminHistory(tenantId: string) {
    return this.request.get(`${tenantId}/histories`);
  }
}
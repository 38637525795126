
import * as jsreport from 'jsreport-browser-client-dist';
import * as _ from 'lodash';
import { MReportPayload, MReportRenderOption, MReportResult } from './report.model';
import Axios from 'axios';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { saveAs } from 'file-saver';


export class ReportService {
  report = jsreport;
  defaultConfig: any = {
    format: "A4",
    landscape: false
  };


  constructor(serverUrl) {
    this.report.serverUrl = serverUrl
  }

  render(payload: MReportPayload): Observable<MReportResult> {
    const axios = Axios.create({
      baseURL: this.report.serverUrl
    });

    const axiosObs = new Observable(function subscribe(subscriber) {
      axios
        .post('api/report', payload, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
          responseType: payload.display_type === 'html' ? 'text' : 'arraybuffer',
        })
        .then(response => {
          subscriber.next(response);
          subscriber.complete();
        })
        .catch((err: Error) => {
          subscriber.error(err);
          subscriber.complete();
        });
    });

    return axiosObs.pipe(map((response: any) => {
      const responseRaw = response.data;
      const contentType = response.headers['content-type'];

      const returnSet: MReportResult = {
        to8BitArray: () => {
          return new Uint8Array(responseRaw);
        },
        toString: () => {
          switch (contentType) {
            case 'text/html':
              return responseRaw;
            default:
              return new TextDecoder("utf-8").decode(responseRaw);
          }
        },
        toDataURI: () => {
          return URL.createObjectURL(returnSet.toBlob());
        },
        toBlob: () => {
          const byteArray = returnSet.to8BitArray();
          const blob = new Blob([byteArray.buffer], { type: contentType });

          return blob;
        },
        toObjectURL: () => {
          return URL.createObjectURL(returnSet.toBlob());
        },
        download(fileName: string) {
          const blob = returnSet.toBlob();
          saveAs(blob, fileName);
        },
      };
      return returnSet;
    }));
  }

  buildReport(options: MReportRenderOption) {
    if (!options.templateConfig) {
      options.templateConfig = this.defaultConfig.templateConfig;
    }

    if (!options.display) {
      options.display = 'pdf';
    }

    const payload: MReportPayload = {
      template_id: _.get(options.config, 'templateCode'),
      display_type: options.display,
      data: {
        data: options.data,
        params: options.params,
        config: options.config,
      },
      config: {
        template: options.templateConfig,
      },
    };
  }
}
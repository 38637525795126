import { Skeleton } from 'antd';
import React from 'react';
import { chartWidgetProps } from './chart-widget';

export const WidgetChartBasic = (props: chartWidgetProps) => {
  const type = props.type || 'vertical';
  const { active, onClick, className, loading, label, value, iconClassName, icon } = props;
  return !loading ?
    <div onClick={onClick}
      className={`widget-chart-basic-new ${active && 'active'} ${onClick ? 'pointer ' : ' '} ${type} ${className || ''}`}>
      <div className={"d-flex justify-content-between w-75 " + (type == 'horizontal' ? 'flex-row order-1 ml-3' : 'flex-column order-0')}>
        <div className="label m-1">{label || ''}</div>
        <div className="value m-1">{value || 0}</div>
      </div>
      <div className={"m-1 d-flex " + (type == 'horizontal' ? 'order-0 ' : 'order-1')}>
        <div className={"icon-widget-chart " + (iconClassName || '')}>{icon || ''}</div>
      </div>
    </div>
    :
    <Skeleton.Input active className="widget-chart-basic-skeleton" />
}
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { Form } from 'antd';
import React, { useEffect } from 'react';

export const ActivationAccountFormComponent = (props: {
  data: IPayloadActivaionAccount;
  onCancel: () => void;
  onSubmit: (val: IPayloadRequestCancel) => void;
}) => {
  const { data } = props;
  const [form] = Form.useForm()
  function onOk(val?) {
    const value = form.getFieldsValue(true);
    props.onSubmit && props.onSubmit(value)
  }

  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  return <Form form={form} onFinish={onOk}>
    <div className="container pt-3">
      <div className="row mb-3 chilbro">
        <div className="col-lg-12 title">
          {`Apakah Anda yakin untuk mengaktifkan akun tersebut ?`}
        </div>
      </div>
      <div className="row chilbro mb-2 mt-2">
        <div className="label col-lg-4">Nama :</div>
        <div className="value col-lg-8">
          {data.fullName}
        </div>
      </div>
      <div className="row chilbro mb-2">
        <div className="label col-lg-4">Email :</div>
        <div className="value col-lg-8">
          {data.email}
        </div>
      </div>
      <div className="row p-2 mt-4">
        <div className="col-lg-12 text-center">
          <Button type="ghosted" label="Batal" onClick={props.onCancel && props.onCancel} className="mr-2" />
          <Button type="primary" label="Ok" submit />
        </div>
      </div>
    </div>
  </Form>

}
import React, { useRef, useState, useEffect } from 'react';
import { pick } from 'lodash';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { AdminTenantSalesInvoiceRestService } from '@clodeo/libs/core/rest/admin/tenant-sales-invoice/admin-tenant-sales-invoice-rest.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { BaseListComponent } from '../../../../../components/base-component/list/base-list.component';
import {
  IIncludes,
  IColumn,
} from '../../../../../components/base-component/list/base-list';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { Confirms } from '@clodeo/clodeo-ui/components/feedback/confirm/confirm.component';
import { SendPickupMoreFilterComponent } from './more-filter/send-request-more-filter.component';
import { AdminRestService } from '@clodeo/libs/core/rest/admin/admin/admin-rest.service';
import { SendRequestPickupTemplateListComponent } from './send-request-pickup-template-list';
import { AdminPickerRestService } from '@clodeo/libs/core/rest/admin/picker/admin-picker-rest.service';
import moment from 'moment';

export const SendRequestPickupListComponent: React.FC = () => {
  const filters = ['keyword', 'options.take', 'options.skip', 'options.includeTotalCount', 'courierName', 'tenantId', 'lowDate', 'highDate'];
  const confirm: Confirms = new Confirms();
  const authenticationService: AuthenticationService = new AuthenticationService();
  const baseListRef = useRef<any>();
  const [ready, setReady] = useState<boolean>(false);
  const [listCourier, setListCourier] = useState<any>(null);

  const includes: IIncludes = {
    refresh: true,
    keyword: true,
  };

  const adminTenantSalesInvoiceRestService = new AdminTenantSalesInvoiceRestService(
    environment.ENDPOINTS,
    authenticationService.axiosInterceptors
  );
  const adminRestService = new AdminRestService(
    environment.ENDPOINTS,
    authenticationService.axiosInterceptors
  );
  const adminPickerRestService = new AdminPickerRestService(
    environment.ENDPOINTS,
    authenticationService.axiosInterceptors
  );

  const { handleRequest } = new HandleService;

  const columns: IColumn[] = [
    {
      key: 1,
      title: '',
      render: (val, record) => <SendRequestPickupTemplateListComponent recordData={record} pay={showConfirmFullyPaid} resend={showConfirmResendPickup} />
    }
  ];

  function showConfirmResendPickup(val, visible?: boolean) {
    confirm.show({
      title: 'Kirim Ulang Pickup',
      content: <p>Apakah anda yakin ingin mengirim ulang pickup?</p>,
      onOk: () => onResendPickup(val),
    });
  }

  function showConfirmFullyPaid(val, visible?: boolean) {
    confirm.show({
      title: 'Bayar Lunas',
      content: (
        <p>
          Apakah anda yakin ingin membayar lunas dan mengubah status pengiriman
          menjadi Sudah Diterima untuk transaksi ini?
        </p>
      ),
      onOk: () => onFullyPaid(val),
    });
  }

  function onResendPickup(id: any) {
    baseListRef.current.showLoading();
    const payload = {
      invoiceIds: [id],
    };
    const obs = adminTenantSalesInvoiceRestService.sendPickupRequest(payload);
    handleRequest({
      obs,
      successMessage: 'Berhasil mengirim request pickup.',
      onDone: () => {
        baseListRef.current.callLoadData();
      },
      onError: () => {
        baseListRef.current.callLoadData();
      }
    });
  }

  function onFullyPaid(id) {
    baseListRef.current.showLoading();
    const payload = {
      invoiceIds: [id],
    };
    const obs = adminTenantSalesInvoiceRestService.fullyPaid(payload);
    handleRequest({
      obs,
      successMessage: 'Transaksi berhasil dibayar lunas dan sudah diterima.',
      onDone: () => {
        baseListRef.current.callLoadData();
      },
      onError: () => {
        baseListRef.current.callLoadData();
      }
    });
  }

  function loadPickerCourier() {
    const obs = adminPickerRestService.getCourierPicker();
    handleRequest({
      obs,
      onDone: setListCourier,
    });
  }

  const loadDataObservable = (qParams) => {
    let params = pick(qParams, filters);

    if (!(params.lowDate && params.highDate)) {
      params.lowDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
      params.highDate = moment(new Date).format('YYYY-MM-DD');
    }
    return adminTenantSalesInvoiceRestService.findAll({ params });
  };


  useEffect(() => {
    loadPickerCourier();
  }, [])

  return (
    <>
      <BaseListComponent
        listId="send-request-pickup-list"
        ref={baseListRef}
        isReady={ready}
        columns={columns}
        loadDataObservable={loadDataObservable}
        rowKey="id"
        includes={{
          ...includes,
          moreFilter: {
            template: (
              <SendPickupMoreFilterComponent listCourier={listCourier} />
            ),
          },
        }}
      />
    </>
  );
};

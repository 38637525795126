import { RequestService } from '../../request.service';

export class NotificationsRestService {
  requestService = new RequestService;
  request: RequestService;

  constructor(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
    this.request = this.requestService.new(`${baseUrl}/notifications`, useInterceptor, withoutInterceptor);
  }

  findAllNotifications(qParams) {
    return this.request.post('q', qParams);
  }

  markOneAsRead(notificationId) {
    return this.request.patch(`${notificationId}/mark-as-read`);
  }

  /**
   * Mark all notification as read
   */
  markAllAsRead() {
    return this.request.put('/mark-all-as-read');
  }

  findAll() {
    return this.request.get('');
  }

}
import React, { useEffect, useState, useRef } from 'react'
import { pick } from 'lodash';

import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { Notifications } from "@clodeo/clodeo-ui/components/feedback/notification/notification.component";
import { AdminMonitoringPickupRestService } from '@clodeo/libs/core/rest/admin/monitoring-pickup/admin-monitoring-pickup-rest.service';
import { AdminRestService } from '@clodeo/libs/core/rest/admin/admin/admin-rest.service';
import { IEntryRelatedData } from '@clodeo/libs/core/rest/admin/admin/admin';


import { environment } from 'apps/admin-web/src/environments/environment';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { BaseListComponent } from 'apps/admin-web/src/app/components/base-component/list/base-list.component';
import { IColumn, IIncludes } from 'apps/admin-web/src/app/components/base-component/list/base-list';

import { MonitoringPickupMoreFilterComponent } from './more-filter/monitoring-pickup-more-filter.component';
import { IModalValue } from '@clodeo/clodeo-ui/components/feedback/modal/modal';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { TrackingNumberDetailComponent } from '@clodeo/clodeo-ui/components/base/tracking-number-detail/tracking-number-detail.component';
import { MonitoringPickupTemplateListComponent } from './monitoring-pickup-template-list.component';
export const MonitoringPickUpListComponent = () => {
  const notif: Notifications = new Notifications;
  const authenticationService: AuthenticationService = new AuthenticationService;
  const adminMonitoringPickupRestService = new AdminMonitoringPickupRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
  const adminRestService = new AdminRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
  const filters = ['keyword', 'options.take', 'options.skip', 'options.includeTotalCount', 'shippingCourierChannelId'];

  const baseListRef = useRef<any>();
  const [relatedData, setRelatedData] = useState<IEntryRelatedData>(null);
  const [modalValue, setModalValue] = useState<IModalValue>();
  const { handleRequest } = new HandleService;

  const includes: IIncludes = {
    refresh: true,
    keyword: true,
  }

  const columns: IColumn[] = [
    {
      key: '1', dataIndex: '', render: (value, data) => {
        return <MonitoringPickupTemplateListComponent data={data} />;
      }
    }
  ];

  function loadDataObservable(qParams) {
    let params = pick(qParams, filters);
    return adminMonitoringPickupRestService.findAll({ params })

  }

  function loadRelated() {
    const params = { clientAppId: 'clodeo-admin-web' };
    adminRestService.getRelatedData({ params }).subscribe(setRelatedData);
  }

  function showAwbBill(waybillResponse) {
    setModalValue({
      visible: true,
      title: 'Rincian Pengiriman',
      contentTemplate: <TrackingNumberDetailComponent waybillResponse={waybillResponse} loading={!waybillResponse} />
    });
  }

  function loadAWBDetail(data: { courierCode: string, waybill: string }) {
    const obs = adminMonitoringPickupRestService.getWaybillDetail(data);
    handleRequest({
      obs,
      hideError: true,
      onError: showAwbBill,
      onDone: showAwbBill
    })
  }


  useEffect(() => {
    loadRelated();
  }, []);

  return (
    <>
      <BaseListComponent
        listId="monitoring-pickup"
        ref={baseListRef}
        columns={columns}
        loadDataObservable={loadDataObservable}
        includes={{
          ...includes,
          relatedData: relatedData,
          moreFilter: {
            template:
              <MonitoringPickupMoreFilterComponent relatedData={relatedData} />
          }
        }}
      />

      {modalValue && (
        <Modal
          {...modalValue}
          footer={null}
          className="admin"
          onCancel={() => setModalValue(null)}
        >
          {modalValue.contentTemplate}
        </Modal>
      )}
    </>
  )
}


import { RangePicker } from '@clodeo/clodeo-ui/components/data-entry/datepicker/datepicker.component';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Option, Select } from '@clodeo/clodeo-ui/components/data-entry/select/select.component';

export const CustomTrainingScheduleMoreFilterComponent = (
  props: {
    values?: any,
    form?: any
  }
) => {
  
  const { form } = props;
  const [dateValues, setDateValues] = useState<any>(null);
  const dateFormat = 'YYYY-MM-DD';
  const lowDate = moment().subtract(3, 'months');
  const highDate = moment(new Date);
  
  useEffect(() => {
    /**
     * Declare variable
     */
    let lowDate: string;
    let highDate: string;

    /**
     * Assign value to new variable
     */
    if (dateValues && dateValues.length) {
      /**
     * set low and high date from dateValues
     */
      let [low, high] = dateValues;
      lowDate = low ? moment(low).format('YYYY-MM-DD') : null;
      highDate = high ? moment(high).format('YYYY-MM-DD') : null;
      form.setFieldsValue({ lowDate: lowDate, highDate: highDate });
    }

  }, [dateValues]);

  return <div className="d-flex" id="tenant-more-filter">
    <div className="d-flex flex-column mr-2">
      <label htmlFor="" className="mb-2">Tanggal</label>
      <RangePicker defaultValue={[ moment(lowDate, dateFormat), moment(highDate, dateFormat) ]} onChange={setDateValues} />
    </div>
    <div className="d-flex flex-column">
      <label htmlFor="" className="mb-2">Status</label>
      <Select defaultValue={1} style={{ width: 120 }} onChange={(val) => {form.setFieldsValue({ inactive: val === 0 ? true : false})}}>
        <Option value={1}>Aktif</Option>
        <Option value={0}>Tidak Aktif</Option>
      </Select>
    </div>
  </div>
}

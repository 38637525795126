import React, { useEffect, useState } from 'react';
import { Layout } from 'antd';
import { Avatar } from '@clodeo/clodeo-ui/components/ui-elements/avatar/avatar.component';
import { Dropdown } from '@clodeo/clodeo-ui/components/navigation/dropdown/dropdown.component';
import { Popover } from '@clodeo/clodeo-ui/components/data-display/popover/popover.component';
import { Item, Menu } from '@clodeo/clodeo-ui/components/navigation/menu/menu.component';
import { Icon } from '@clodeo/clodeo-ui/components/foundations/icon/icon.component';
import { Tabs, TabPane } from '@clodeo/clodeo-ui/components/data-display/tabs/tabs.component';
import { AuthenticationService, MUser } from '../../../core/auth/authentication.service';
import { useHistory, useLocation } from 'react-router-dom';
import './header-layout.component.scss';
import _ from 'lodash';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { ResetPasswordComponent } from '../../../features/reset-password/reset-password.component';
import { AdminNotificationsSummary } from '../../../features/main/admin-notifications/summary/admin-notifications-summary.component';
const { Header } = Layout;
const authService: AuthenticationService = new AuthenticationService;

export const HeaderLayoutComponent = (props: {
  pathName?: string;
  menus: IMenuRf[];
  title?: string;
  parentMenu: IMenuRf;
  isSubmenu: boolean;
}) => {
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [isActiveTab, setIsActiveTab] = useState<string>(props.pathName);
  const [user, setUser] = useState<MUser & { name: string }>({ ...authService.user, name: _.startCase(authService.user.first_name + ' ' + ' ' + authService.user.last_name) });
  const router = useHistory();
  const { isSubmenu, parentMenu } = props;

  function onLogout() {
    authService.logout();
  }


  useEffect(() => {
    setTitle(props.title);
    let link = props.pathName;
    parentMenu?.subs.map(m => {
      if (props.pathName.includes(m.to)) {
        setTitle(m.label);
        link = m.to;
      }
    });
    setIsActiveTab(link);
  }, [props.parentMenu, props.pathName])

  function redirectTo(to: string) {
    router.push(to);
  }

  const dropdown = (<Menu>
    <Item onClick={() => setModalVisible(!modalVisible)}>Ganti Password</Item>
    <Item onClick={() => onLogout()}>Logout</Item>
  </Menu>)

  return <> <Header className="header-admin-deo px-3 py-1">
    <div className="w-100 m-auto row justify-content-between border-header">
      <div className="col-lg-auto col-auto order-1 profile-section align-self-start">
        <div className="d-flex flex-row justify-content-end justify-content-lg-end py-2 py-lg-0">
          <div className="notification mx-3">
            <Popover placement="bottom" title={'Admin Notifications'} arrowPointAtCenter content={<AdminNotificationsSummary />} trigger="click">
              <a>
                <Icon name="deo-bell-on" />
              </a>
            </Popover>
          </div>
          <div className="profile">
            <Avatar type="square" />
            <div className="username ml-3 align-self-center">
              <Dropdown overlay={dropdown} placement="bottomCenter" arrow trigger={['click']}>
                <span className="username">
                  <span className="d-none d-lg-inline">
                    {user.name}
                  </span>
                  <Icon name="deo-arrow-down-1" />
                </span>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-auto col-auto order-0 d-flex flex-row">
        {/* <div className={"title-page-header align-self-center " + (isSubmenu ? 'border-title' : '')}> */}
        <div className={"title-page-header align-self-center "}>
          {title || ''}
        </div>
      </div>
      <div className="col-12 order-2 d-flex flex-row">
        {isSubmenu && <Tabs onChange={redirectTo} activeKey={isActiveTab}>
          {
            parentMenu.subs.map(m => {
              return <TabPane tab={m.label} key={m.to} />
            })
          }
        </Tabs>}
      </div>
    </div>
  </Header>

    {/* Pop Up for reset Password Admin */}
    <Modal
      onCancel={() => setModalVisible(false)}
      footer={null}
      visible={modalVisible}
      title="Reset Password Admin"
      className="admin"
      maskClosable={false}
    >
      {modalVisible && <ResetPasswordComponent onCancel={() => setModalVisible(false)} onSuccess={() => setModalVisible(false)} />}
    </Modal>
  </>

}

import { SelectButton, SelectGroup } from '@clodeo/clodeo-ui/components/data-entry/select-button/select-button.component';
import { Select } from '@clodeo/clodeo-ui/components/data-entry/select/select.component';
import { Confirms } from '@clodeo/clodeo-ui/components/feedback/confirm/confirm.component';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { AdminCodBalanceRestService } from '@clodeo/libs/core/rest/admin/cod-balance/admin-cod-balance-rest.service';
import { DebitCODRestService } from '@clodeo/libs/core/rest/admin/debit-cod/debit-cod-rest.service';
import * as accountingJS from 'accounting-js';
import { Form, Menu } from 'antd';
import { IColumn, IIncludes } from 'apps/admin-web/src/app/components/base-component/list/base-list';
import { BaseListComponent } from 'apps/admin-web/src/app/components/base-component/list/base-list.component';
import { AclService } from 'apps/admin-web/src/app/core/auth/acl.service';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { sumBy, values, compact } from 'lodash';
import React, { useRef, useState } from 'react';
import './request-withdraw-funds-list.component.scss';
import { RequestWithdrawFundsTemplateListComponent } from './request-withdraw-funds-template-list.component';
import { IModalValue } from '@clodeo/clodeo-ui/components/feedback/modal/modal';
import { RequestWithdrawFundUploadComponent } from '../upload/request-withdraw-upload.component';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { RequestWithdrawFundsFilter } from '../more-filter/request-withdraw-funds-more-filter.component';
import { Popover } from '@clodeo/clodeo-ui/components/data-display/popover/popover.component';

const authenticationService: AuthenticationService = new AuthenticationService;
const codBalanceRestService = new AdminCodBalanceRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const debitCODRestService = new DebitCODRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const notif = new Notifications();
const confirm = new Confirms();
const handleService = new HandleService();
export const RequestWithdrawFundsListComponent = () => {
  const baseListRef = useRef<any>();
  const [form] = Form.useForm();
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [tenantBankList, setTenantBankList] = useState([]);
  const [integrated, setIntegrated] = useState(false);
  const [isTenant, setIsTenant] = useState<boolean>(true);
  const aclService: AclService = new AclService;
  const [modalValue, setModalValue] = useState<IModalValue>();

  const columns: IColumn[] = [
    {
      key: '1',
      dataIndex: 'tenantId',
      ignoreExprt: true,
      title: selectedKeys?.length ? (<span className="label">Terpilih {selectedKeys?.length} Item</span>) : '',

      render: (val, record) => <RequestWithdrawFundsTemplateListComponent
        required={selectedKeys.includes(val)}
        onChangeLine={onChangeLine}
        tenantBankList={tenantBankList}
        data={record}
      />
    },
    {
      key: '2',
      dataIndex: 'tenantId',
      title: 'Tenant ID',
      ignoreDisply: true
    }, {
      key: '3',
      dataIndex: 'balanceAmount',
      title: 'Balance Amount',
      ignoreDisply: true,
      formatExprt: (val) => accountingJS.formatMoney(val || 0, { symbol: 'Rp ', format: "%s %v", decimal: ',', thousand: '.' })
    }, {
      key: '4',
      dataIndex: 'walletBalance',
      title: 'Wallet Balance',
      ignoreDisply: true,
      formatExprt: (val) => accountingJS.formatMoney(val || 0, { symbol: 'Rp ', format: "%s %v", decimal: ',', thousand: '.' })
    }, {
      key: '5',
      dataIndex: 'balanceTransaction',
      title: 'Balance Transaction',
      ignoreDisply: true,
      formatExprt: (val) => accountingJS.formatMoney(val || 0, { symbol: 'Rp ', format: "%s %v", decimal: ',', thousand: '.' })
    }, {
      key: '6',
      dataIndex: 'userEmail',
      title: 'User Email',
      ignoreDisply: true
    }, {
      key: '7',
      dataIndex: 'username',
      title: 'Username',
      ignoreDisply: true
    }, {
      key: '8',
      dataIndex: 'fullName',
      title: 'Full Name',
      ignoreDisply: true
    }, {
      key: '9',
      dataIndex: 'companyName',
      title: 'Company Name',
      ignoreDisply: true
    }, {
      key: '10',
      dataIndex: 'industry',
      title: 'Industry',
      ignoreDisply: true
    }, {
      key: '11',
      dataIndex: 'phone',
      title: 'Phone',
      ignoreDisply: true
    }, {
      key: '12',
      dataIndex: 'companyEmail',
      title: 'Company Email',
      ignoreDisply: true
    }, {
      key: '13',
      dataIndex: 'website',
      title: 'Website',
      ignoreDisply: true,
      formatExprt: (value) => value || '-'

    }, {
      key: '14',
      dataIndex: 'shippingsMemberIds',
      title: 'Website',
      formatExprt: (value) => value || '-',
      ignoreDisply: true
    },
  ];

  function onChangesetSelectedRowKeys(selectedRowKeys: string[], selectedRows: any[]) {
    setSelectedKeys(selectedRowKeys);
  }

  function onChangeLine(tenantId, line) {
    const py = form.getFieldValue('selectedLine') || {};
    let newSelectedLine = { ...py };
    newSelectedLine = { ...newSelectedLine, [tenantId]: line };
    form.setFieldsValue({ selectedLine: newSelectedLine })
  }

  function handleUploadBatch(payload: IRequestWithdraw[]) {
    baseListRef.current.showLoading();
    const obs = debitCODRestService.uploadBatchDebitCOD(payload);
    handleService.handleRequest({
      obs,
      successMessage: 'Pencairan Dana Berhasil',
      onDone: () => {
        baseListRef.current.showLoading(false);
        baseListRef.current.callLoadData();
        setSelectedKeys([]);
      },
      onError: () => {
        baseListRef.current.showLoading(false);
      }
    })
  }

  function ContentConfirm(props: {
    osAmount: number;
    totalAmount: number;
  }) {
    return <table className="table table-borderless">
      <tbody>
        <tr>
          <td>
            <strong>Jumlah OS</strong>
          </td>
          <td>
            :
          </td>
          <td>
            {props.osAmount}
          </td>
        </tr>
        <tr>
          <td>
            <strong>Total Bayar</strong>
          </td>
          <td>
            :
          </td>
          <td>
            {accountingJS.formatMoney(props.totalAmount || 0, { symbol: 'Rp ', format: '%s %v' })}
          </td>
        </tr>
      </tbody>
    </table>
  }

  async function onUpload() {
    const selectedValidLine: IRequestWithdraw[] = [];
    const selectedLine: IRequestWithdraw[] = values(form.getFieldValue('selectedLine'));
    let isError = false;
    for (let i = 0; i < selectedLine.length; i++) {
      const line = selectedLine[i];
      /**
       * Check line selected & valid
       */
      if (line.selected && !line.valid) {
        notif.show({
          title: 'Error',
          type: 'error',
          description: 'Harap cek kembali baris yang belum diisi!'
        })
        isError = true;
        break;
      } else if (line.selected && line.valid) {
        selectedValidLine.push(line);
      }
    }

    /**
     * Handle Upload
     */
    if (!isError) {
      const forContent = {
        osAmount: selectedValidLine.length || 0,
        totalAmount: sumBy(selectedValidLine, 'amount')
      }
      if (forContent.osAmount > 0) {
        confirm.show({
          title: 'Konfirmasi Transfer',
          content: <ContentConfirm {...forContent} />,
          onOk() {
            handleUploadBatch(selectedValidLine);
          }
        });
      } else {
        notif.show({
          title: 'Error',
          type: 'error',
          description: 'Harap pilih minimal satu baris!'
        });
      }
    }
  }

  function uplaodExcel() {
    setModalValue({
      visible: true,
      className: 'req-upload-excel',
      title: 'Upload Pencairan Dana',
      contentTemplate: <RequestWithdrawFundUploadComponent
        onCancel={() => setModalValue(null)}
        onFinish={() => {
          baseListRef.current.callLoadData();
          setModalValue(null)
        }}
      />,
    })
  }

  const dropdown = (
    <>
      <Menu>
        <Menu.Item onClick={() => onUpload()}>Upload by</Menu.Item>
        <Menu.Item onClick={() => uplaodExcel()}>Upload by excel</Menu.Item>
      </Menu>
    </>
  )

  
  const includes: IIncludes = {
    refresh: true,
    refreshAction: () => {
      baseListRef.current.resetFilters();

      setTimeout(() => {
        baseListRef.current.callLoadData();
      }, 10);
    },
    keyword: true,
    extraButtons: compact([aclService.can(['wallet.withdrawal.create']) &&
    {
      
      label: 'UPLOAD',
      iconName: 'deo-upload',
      type: 'filter',
      action: () => onUpload()
    },
    {
      label: 'UPLOAD EXCEL',
      iconName: 'deo-upload',
      type: 'filter',
      action: () => setModalValue({
        visible: true,
        className: 'req-upload-excel',
        title: 'Upload Pencairan Dana',
        contentTemplate: <RequestWithdrawFundUploadComponent
          onCancel={() => setModalValue(null)}
          onFinish={() => {
            baseListRef.current.callLoadData();
            setModalValue(null)
          }}
        />,
      })
    }]),
    exports: [{
      label: 'Export berdasarkan filter',
      basedFilter: true,
      fileName: 'Pencairan Dana By Filter'
    }, {
      label: 'Export semua data',
      fileName: 'Pencairan Dana'
    }],
  };

  function loadDataObservable(params) {
    if (params.billingAndBalanceStatus) {
      params.billingAndBalanceStatus = (params.billingAndBalanceStatus).toString();
    }
    const qParams = {
      ...params,
      tenantId: isTenant ? params.keyword : null,
      keyword: !isTenant ? params.keyword : null,
      'options.sort.companyName': 'asc'
    };
    const obs = integrated ? codBalanceRestService.findAllIntegrated({ params: qParams }) : codBalanceRestService.findAll({ params: qParams });

    return obs;
  }

  const statusFilter = () => (
    <SelectGroup type="switch" onChange={(e) => { setIntegrated(e.target.value); callData() }} defaultValue={integrated}>
      <SelectButton type="switch" value={true}>{'Clodeo'}</SelectButton>
      {/* <SelectButton type="switch" value={true}>{'Xendit'}</SelectButton> */}
    </SelectGroup>
  );

  function callData() {
    setTimeout(() => {
      baseListRef.current.callLoadData()
    }, 10);
  }

  function selectIsTenant() {
    const options: any[] = [
      { label: 'Tenant ID', value: true },
      { label: 'Keyword', value: false },
    ];
    return (
      <Select size='large' options={options} value={isTenant} onChange={setIsTenant} />
    )
  }

  return (
    <>
      <BaseListComponent
        listId="request-withdraw-funds"
        ref={baseListRef}
        columns={columns}
        includes={{
          ...includes,
          extraComponent: statusFilter(),
          placeholderKeyword: isTenant && 'Cari Tenant ID',
          keywordType: isTenant && 'number',
          suffixComponent: selectIsTenant(),
          moreFilter: {
            template: (
              <RequestWithdrawFundsFilter />
            )
          }
        }}
        loadDataObservable={loadDataObservable}
        rowKey="tenantId"
        rowClassName={'disabled-row'}
        rowSelection={{
          preserveSelectedRowKeys: true,
          type: 'checkbox',
          selectedRowKeys: selectedKeys,
          columnWidth: '50px',
          onChange: onChangesetSelectedRowKeys,
          getCheckboxProps: (selectedRowKeys) => {
            return {
              disabled: selectedRowKeys.isTenantBankOnChangeRequest,
              style: { backgroundColor: selectedRowKeys.isLowBalance === true ? '#FEE7ED' : selectedRowKeys.unpaidBilling === true ? '#FBE6DF' : '' }
            };
          }
        }}
      />
      {modalValue && <Modal
        {...modalValue}
        footer={null}
        className="admin"
        onCancel={() => setModalValue(null)}
        width="fit-content"
      >
        {modalValue.contentTemplate}
      </Modal>}
    </>
  );
}

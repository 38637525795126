import React, { useEffect, useRef, useState } from 'react';
import { environment } from 'apps/admin-web/src/environments/environment';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { BaseListComponent } from 'apps/admin-web/src/app/components/base-component/list/base-list.component';
import {
  IColumn,
  IIncludes,
} from 'apps/admin-web/src/app/components/base-component/list/base-list';
import { ExportService } from '@clodeo/libs/core/export/export.service';
import { TenantsSummary } from '../summary/tenants-summary.component';
import moment from 'moment';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { Switch } from '@clodeo/clodeo-ui/components/data-entry/switch/switch.component';
import { Confirms } from '@clodeo/clodeo-ui/components/feedback/confirm/confirm.component';
import { Input } from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { ShippingSettingsComponent } from './shipping-settings/shipping-settings.component';
import { WareHouseChannelsSettingsComponent } from './warehouse-channels/warehouse-channels.component';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { Checkbox } from '@clodeo/clodeo-ui/components/data-entry/checkbox/checkbox.component';
import { AdminRestService } from '@clodeo/libs/core/rest/admin/admin/admin-rest.service';
import { Form } from 'antd';
import { get } from 'lodash';
import { AclService } from 'apps/admin-web/src/app/core/auth/acl.service';
import { LogdetailComponent } from 'apps/admin-web/src/app/components/user-log-detail/log-detail.component';
import AssignReferralComponent from './assign-referral/assign-referral.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { LoadingComponent } from '@clodeo/clodeo-ui/components/general/loading/loading.component';
const aclService: AclService = new AclService;

const confirm = new Confirms();
const authenticationService: AuthenticationService = new AuthenticationService();
const exportService: ExportService = new ExportService();
const tenantsRestService = new AdminRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const { handleRequest } = new HandleService;

export const TenantListComponent: React.FC = () => {
  const [form] = Form.useForm();
  const [datas, setDatas] = useState([]);
  const [isReal, setIsReal] = useState<boolean>(true);
  const [isCod, setIsCod] = useState<any>(null);
  const [relatedData, setRelatedData] = useState<any>([]);
  const [modal, setModal] = useState<{
    id?: string;
    type?: 'logDetail' | 'shippingSetting' | 'warehouseChannel' | 'userSales';
    visible: boolean;
    title: string;
    record: any;
    hideFooter?: boolean;
  }>();
  const [summary, setSummary] = useState({
    totalRealTenant: null,
    totalTenant: null,
    totalRealTenantRegisteredCOD: null,
    totalTenantRegisteredCOD: null,
  });
  const [loadingRef, setLoadingRef] = useState(false);
  const baseListRef = useRef<any>();


  useEffect(() => {
    form.setFieldsValue({
      ...datas
    })
  })

  const includes: IIncludes = {
    refresh: true,
    keyword: true,
    toogleOptions: true,
    exports: [{
      label: 'Export berdasarkan filter',
      basedFilter: true,
      fileName: 'Tenants | By Filter'
    }, {
      label: 'Export semua data',
      fileName: 'Tenants'
    }]
  };

  const columns: IColumn[] = [
    {
      key: '0',
      dataIndex: 'id',
      title: 'TENANT ID',
      ignoreDisply: true
    },
    {
      key: '1',
      dataIndex: 'companyInfo',
      ignoreExprt: true,
      title: 'PERUSAHAAN',
      width: '150px',
      render: (val) => val.name
    },
    {
      key: '2',
      dataIndex: 'email',
      title: 'EMAIL',
      width: '150px',
    },
    {
      key: '3',
      dataIndex: 'phone',
      width: '150px',
      title: 'NO. TLP',
    },
    {
      key: '4',
      dataIndex: 'totalSku',
      width: '60px',
      title: 'SKU',
      align: 'center',
    },
    {
      key: '5',
      dataIndex: 'totalOrder',
      title: 'ORDER',
      width: '60px',
      align: 'center',
    },
    {
      key: '6',
      dataIndex: 'totalReceipt',
      title: 'RECEIPT',
      width: '70px',
      align: 'center',
    },
    {
      key: '7',
      dataIndex: 'totalUser',
      title: 'USER',
      width: '60px',
      align: 'center',
    },
    {
      key: '8',
      dataIndex: 'owner.lastLoginDateUtc',
      title: 'LAST LOGIN',
      width: '80px',
      formatExprt: (value) => {
        return moment(value).format('DD-MM-YY HH:mm')
      },
      render: (val) => <p>{moment(val).format('DD-MM-YY HH:mm')}</p>,
    },
    {
      key: '9',
      dataIndex: 'owner.joinDateUtc',
      title: 'JOIN DATE',
      width: '80px',
      render: (val) => <p>{moment(val).format('DD-MM-YY HH:mm')}</p>,
    },
    {
      key: '10',
      title: 'SHIPPING',
      dataIndex: 'shippingSettings',
      ignoreExprt: true,
      width: '80px',
      align: 'center',
      render: (val: any, record) => (
        <Button
          type="ghosted"
          disabled={!aclService.can(["tenant.tenant_list.edit"])}
          className="p-2"
          iconName="i-GearSix"
          onClick={() =>
            setModal({
              title: 'Pengaturan Shipping',
              id: record.id,
              visible: true,
              type: 'shippingSetting',
              record: record,
            })
          }
        />
      ),
    },
    {
      key: '11',
      title: 'WAREHOUSE',
      dataIndex: 'warehouseChannels',
      ignoreExprt: true,
      width: '90px',
      align: 'center',
      render: (val: any, record: any) => {
        return <Button
          disabled={!aclService.can(["tenant.tenant_list.edit"]) || !val.length}
          type="warning"
          className="p-2"
          iconName="i-Package"
          onClick={() =>
            setModal({
              title: 'Warehouse Channels',
              id: record.id,
              visible: true,
              type: 'warehouseChannel',
              record: val,
            })
          }
        />
      }
    },
    {
      key: '12',
      title: 'IS REAL',
      dataIndex: 'isReal',
      width: '80px',
      align: 'center',
      render: (val: boolean, record, i: number) => (
        <Switch
          disabled={!aclService.can(["tenant.tenant_list.edit"])}
          checked={val}
          onChange={(check) => onUpdateIsReal(check, record.id, i)}
        />
      ),
    },
    {
      key: '13',
      title: 'STATUS',
      dataIndex: 'status',
      formatExprt: (value) => {
        return value === 'active' ? "Aktif" : "Tidak Aktif"
      },
      width: '80px',
      align: 'center',
      render: (val: string, record, i: number) => {
        const datas = {
          ...record
        }
        return (
          <Switch
            checked={val === 'active'}
            disabled={!aclService.can(["tenant.tenant_list.edit"])}
            onChange={(check) => {
              showConfirm(check, record, i);
              setDatas(datas);

            }}
          />
        );
      }
    },
    {
      key: '14',
      title: 'NOTES',
      width: '100px',
      dataIndex: 'companyInfo',
      render: (val, datas) => {
        return (
          <>
            {val.companyStatus == null || val.companyStatus == "" ? '-' :
              (
                <p>{val.companyStatus}</p>
              )}
          </>
        );
      }
    }, {
      key: '15',
      dataIndex: 'clientAppId',
      title: 'CLIENT APP ID',
      formatExprt: (client) => client === 'clodeo-main-web' ? 'Clodeo' : 'Clodeo Lite',
      ignoreDisply: true
    }, {
      key: '20',
      dataIndex: 'walletWithdrawMethodType',
      title: 'WALLET WITHDRAW METHOD TYPE',
      ignoreDisply: true
    }, {
      key: '21',
      dataIndex: 'owner.fullName',
      title: 'OWNER',
      ignoreDisply: true
    }, {
      key: '22',
      dataIndex: 'companyInfo.name',
      title: 'COMPANY NAME',
      ignoreDisply: true
    }, {
      key: '23',
      dataIndex: 'companyInfo.phone',
      title: 'COMPANY PHONE',
      ignoreDisply: true
    }, {
      key: '24',
      dataIndex: 'companyInfo.email',
      title: 'COMPANY EMAIL',
      ignoreDisply: true
    },
    {
      key: '25',
      dataIndex: 'owner',
      width: '120px',
      title: 'REFERRAL',
      ignoreExprt: true,
      render: (val, data) =>
        get(val, 'referralCode') ?
          <p>
            {get(val, 'referralCode') || '-'} <br />
            {get(val, 'referralName') || '-'}
          </p>
          :
          <Button label='Assign' type='tertiary' onClick={() => {
            setModal({ id: 'user-sales', type: 'userSales', visible: true, record: data.id, title: 'Assign Referral', hideFooter: true })
          }} />
    },
    {
      key: '25',
      dataIndex: 'owner.referralCode',
      title: 'REFERRAL CODE',
      ignoreDisply: true,
    },
    {
      key: '26',
      dataIndex: 'owner.referralName',
      title: 'REFERRAL NAME',
      ignoreDisply: true,
    },
    {
      key: '27',
      title: 'LOG DETAIL',
      dataIndex: 'logDetail',
      ignoreExprt: true,
      width: '80px',
      align: 'center',
      render: (val: any, record) => (
        <Button
          label="Open"
          type="ghosted"
          className="p-2"
          onClick={() => prepareUserLogDetailModal(record.id)}
        />
      ),
    },
  ];

  function onUpdateIsReal(isReal: boolean, tenantId: string, index: number) {
    baseListRef.current.showLoading();
    const obs = tenantsRestService.markIsReal(isReal, tenantId);
    handleRequest({
      obs: obs,
      successMessage: 'Berhasil diubah',
      errorMessage: '',
      onDone: (res) => res && baseListRef.current.callLoadData(),
    });
  }
  // onChange={(check) => onUpdateStatus(check, record.id, i)

  function onUpdateStatus(active: boolean, tenantId: string, index: number) {
    baseListRef.current.showLoading();
    const obs = tenantsRestService.markIsActive(active, tenantId, {
      companyStatus: !active ? form.getFieldValue('companyInfo.companyStatus') : '',
    });
    handleRequest({
      obs: obs,
      successMessage: 'Berhasil diubah',
      errorMessage: '',
      onDone: (res) => res && baseListRef.current.callLoadData(),
    });
  }

  const showConfirm = (check, record, i) => {
    confirm.show({
      title: 'Ubah status Tenant',
      content:
        <>
          {!check && (
            <>
              <div>
                Alasan
              </div>
              <div className="mt-2">
                <Form
                  form={form}
                >
                  <Form.Item
                    name="companyInfo.companyStatus"
                  >
                    <Input />
                  </Form.Item>
                </Form>
              </div>
            </>
          )}
        </>,
      onOk() {
        onUpdateStatus(check, record.id, i);
      },
      onCancel() {
        form.resetFields()
      },
    })
  }

  function loadDataObservable(params) {
    const filterValue = {
      isReal,
      isCod
    };
    const qParams = { ...params, ...filterValue };
    return tenantsRestService.getAll({ params: qParams });
  }

  function loadSummary() {
    return tenantsRestService.getSummary().subscribe(setSummary);
  }

  function loadRelated() {
    return tenantsRestService.loadRelatedData().subscribe(setRelatedData);
  }

  function callData() {
    setTimeout(() => {
      baseListRef.current.callLoadData()
    }, 10);
  }

  const isRealFilter = () => (
    <Checkbox checked={isReal} onChange={(e) => {
      e.target.checked ? setIsReal(e.target.checked) : setIsReal(null);
      callData();
    }}>
      Is Real
    </Checkbox>
  );

  function prepareUserLogDetailModal(tenantId) {
    baseListRef.current.showLoading(true);
    const obs = tenantsRestService.getAdminHistory(tenantId);
    handleRequest({
      obs,
      hideError: true,
      onError: (err) => {
        baseListRef.current.showLoading(false);
      },
      onDone: (res) => {
        baseListRef.current.showLoading(false);
        setModal({
          title: 'Log Detail',
          id: tenantId,
          visible: true,
          type: 'logDetail',
          record: res,
        })
      }
    })
  }

  function onSubmitRefCode(refCodeId, id) {
    setLoadingRef(true);
    const obs = tenantsRestService.setReferralCode(refCodeId, id);
    handleRequest({
      obs,
      onError: () => setLoadingRef(false),
      successMessage: 'NIK Sales berhasil disimpan',
      onDone: () => {
        setLoadingRef(false);
        baseListRef.current.callLoadData();
        setModal({ visible: false, record: null, hideFooter: false, title: null })
      }
    })
  }

  useEffect(() => {
    loadRelated();
    loadSummary();
  }, []);


  const footerModal = () => (
    <div className="w-100 text-right p-2">
      <Button type="ghosted" size='sm' label="Close" onClick={() => setModal(null)} />
    </div>
  );

  return (
    <>
      <TenantsSummary summary={summary} onChangeFilter={(value) => { setIsCod(value); callData(); }} />
      <BaseListComponent
        listId="tenant-list" // ID Wajib diisi
        ref={baseListRef}
        columns={columns}
        loadDataObservable={loadDataObservable}
        includes={{
          ...includes,
          extraComponent: isRealFilter(),
        }}
      />

      {modal && (
        <Modal
          {...modal}
          onCancel={() => setModal(null)}
          footer={!modal.hideFooter ? footerModal() : null}
          className="admin"
          width="fit-content"
        >
          {modal.type == 'logDetail' && (
            <LogdetailComponent
              record={modal.record}
            />
          )}
          {modal.type == 'shippingSetting' && (
            <ShippingSettingsComponent
              tenantId={modal.id}
              shippingCourierDetails={relatedData.shippingCourierDetails}
            />
          )}
          {modal.type == 'warehouseChannel' && (
            <WareHouseChannelsSettingsComponent
              record={modal.record}
              tenantId={modal.id}
            />
          )}

          {
            modal.type === 'userSales' && (
              <Spinner indicator={<LoadingComponent />} spinning={loadingRef}>
                <AssignReferralComponent onSubmit={onSubmitRefCode} id={modal.record} />
              </Spinner>
            )
          }
        </Modal>
      )}
    </>
  );
};


import { Table } from '@clodeo/clodeo-ui/components/data-display/table/table.component';
import { Checkbox } from '@clodeo/clodeo-ui/components/data-entry/checkbox/checkbox.component';
import { Input } from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { Dropdown } from '@clodeo/clodeo-ui/components/navigation/dropdown/dropdown.component';
import {
  Item,
  Menu,
} from '@clodeo/clodeo-ui/components/navigation/menu/menu.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { BaseListProps } from './base-list';
import { BaseListComponent } from './base-list.component';
import './base-list.component.scss';

export const BaseRfListComponent = (props: BaseListProps) => {
  const { columns, includes, loadDataRfObservable, onLoad, scroll, rowSelection, rowKey, hidePaginations } = props;
  const notif: Notifications = new Notifications();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isShowToggleColumn, setIsShowToggleColumn] = useState<boolean>(false);
  const [showExports, setShowExports] = useState<boolean>(false);
  const [search, setSearch] = useState<string>();
  const [tableColumns, setColumns] = useState<any[]>(columns);
  const [table, setTable] = useState({
    dataSource: [],
    pagination: {
      current: 1,
      pageSize: 30,
      total: 0,
    },
  });

  const [selectedColumn, setSelectedColumn] = useState<any[]>(
    _.map(columns || [], 'key')
  );

  const [params, setParams] = useState<any>({
    keyword: null,
    'options.take': table.pagination ? table.pagination.pageSize : 0,
    'options.skip': 0,
    'options.includeTotalCount': true,
  });

  const onClickToggleColumn = ({ key }) => {
    const appearsColumn = [...selectedColumn];
    let tableDataColumns = [...tableColumns];
    const column = _.find(columns, ['key', key]);
    const columnIsExist = _.indexOf(appearsColumn, key);
    const icolumn = _.findIndex(tableDataColumns, ['key', key]);

    if (columnIsExist > -1) {
      appearsColumn.splice(columnIsExist, 1);
      tableDataColumns.splice(icolumn, 1);
    } else {
      appearsColumn.push(key);
      tableDataColumns.push(column);
    }
    tableDataColumns.sort((a, b) => +a.key - +b.key);
    setColumns([...tableDataColumns]);
    setSelectedColumn(appearsColumn);
  };

  const toggleColumns = (
    <Menu onClick={onClickToggleColumn} selectedKeys={selectedColumn}>
      {columns.map((column) => (
        <Item key={`${column.key}`}>
          <Checkbox checked={selectedColumn.includes(column.key)} />
          <span className="ml-2">{column.title}</span>
        </Item>
      ))}
    </Menu>
  );

  const exportsDropdown = (
    <Menu>
      {includes.exports &&
        includes.exports.map((exp, i: number) => {
          return (
            <Item key={i} onClick={() => exp.action(table.dataSource)}>
              {exp.label}
            </Item>
          );
        })}
    </Menu>
  );

  const loadData = (params: any): Observable<any> => {
    setIsLoading(true);
    return loadDataRfObservable(params).pipe(
      tap((response) => {
        const tableValue = table;
        tableValue.dataSource = response.data;
        if (tableValue.pagination) {
          tableValue.pagination.total = response.total;
          tableValue.pagination.pageSize = response.take;
        }
        setTable(tableValue);
      }),
      catchError((error) => {
        notif.show({
          type: 'error',
          title: 'Error',
          description: error,
          useService: true,
        });
        return throwError(error);
      }),
      finalize(() => setIsLoading(false))
    );
  };

  const suffixSearch = () => <i className="icon-deo-search"></i>;
  const filterSectionComponent = () => {
    const [showMoreFilter, setshowMoreFilter] = useState(false);
    return (
      <>
        <div className="d-flex flex-row flex-wrap justify-content-between filter-section w-100">
          <div className="d-flex flex-row flex-wrap col-12 justify-content-between justify-content-lg-start col-lg-6 p-0">
            {includes.moreFilter && (
              <div className="align-self-center py-1 px-1">
                <Button
                  id={props.listId + '-more-filter'}
                  icon
                  type="ghosted"
                  iconName="deo-filter"
                  className="p-2 text-center"
                  onClick={() => setshowMoreFilter(!showMoreFilter)}
                />
              </div>
            )}
            {includes.keyword && (
              <div className="search align-self-center py-1 px-1 col-12 col-lg-auto">
                <Input
                  name="search"
                  placeholder="Search..."
                  size="large"
                  onChange={(event) => setSearch(event.target.value)}
                  onPressEnter={onChangeGridData}
                  suffix={suffixSearch()}
                />
              </div>
            )}
            {includes.extraComponent && (
              <div className="align-self-center py-1 px-1">
                {includes.extraComponent}
              </div>
            )}
          </div>
          <div className="d-flex flex-row flex-wrap col-12 justify-content-lg-end col-lg-6 p-0">
            {includes.extraButtons && _.isLength(includes.extraButtons.length) && (
              <div
                className={
                  'px-1 align-self-center py-1 ' +
                  (includes.extraButtons.length > 1
                    ? 'col-12'
                    : 'col col-lg-auto')
                }
              >
                {includes.extraButtons.map((extraBtn, i: number) => (
                  <Button
                    key={i}
                    id={props.listId + '-extra-btn-' + i}
                    label={extraBtn.label}
                    onClick={extraBtn.action}
                    type={extraBtn.type || 'primary'}
                  />
                ))}
              </div>
            )}

            {includes.exports && _.isLength(includes.exports.length) && (
              <div className="px-1 align-self-center py-1 col col-lg-auto">
                <Dropdown
                  overlay={exportsDropdown}
                  placement="bottomLeft"
                  trigger={['click']}
                  visible={showExports}
                >
                  <Button
                    id={props.listId + '-exports'}
                    iconName="deo-upload"
                    label="EXPORT"
                    type="filter"
                    className="p-2 px-3 text-center"
                    onClick={() => setShowExports(!showExports)}
                  />
                </Dropdown>
              </div>
            )}

            {includes.refresh && (
              <div className="px-1 align-self-center py-1 col col-lg-auto">
                <Button
                  id={props.listId + '-refresh'}
                  icon
                  iconName="deo-refresh"
                  type="filter"
                  className="p-2 text-center"
                  onClick={() => loadData(params).subscribe()}
                />
              </div>
            )}

            {includes.toogleOptions && (
              <div className="px-1 align-self-center py-1 col col-lg-auto">
                <Dropdown
                  overlay={toggleColumns}
                  placement="bottomLeft"
                  trigger={['click']}
                  visible={isShowToggleColumn}
                >
                  <Button
                    id={props.listId + '-toggle-columns'}
                    icon
                    iconName="deo-bars-2"
                    type="filter"
                    className="p-2 text-center"
                    onClick={() => setIsShowToggleColumn(!isShowToggleColumn)}
                  />
                </Dropdown>
              </div>
            )}
          </div>
        </div>
        {showMoreFilter && (
          <div className="d-flex flex-column w-100 animate__animated animate__fadeIn more-filter-wrapper">
            <div className="p-2">{includes.moreFilter.template}</div>
            <div className="p-2 w-100 justify-content-between d-flex justify-content-lg-end">
              <Button
                label="Apply"
                className="mr-3"
                type="tertiary"
                onClick={() =>
                  props.includes.moreFilter &&
                    props.includes.moreFilter.onSubmit
                    ? props.includes.moreFilter.onSubmit()
                    : loadData(params).subscribe()
                }
              />
              <Button
                type="ghosted"
                label="Reset"
                onClick={() =>
                  props.includes.moreFilter && props.includes.moreFilter.onReset
                    ? props.includes.moreFilter.onReset()
                    : ''
                }
              />
            </div>
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (onChangeGridData) {
      const subscription = loadData(params).subscribe();
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [table, onLoad]);

  const onChangeGridData = (value, type) => {
    const tableValue = table;
    const paramsValue = {
      keyword: search || null,
      'options.take': table.pagination.pageSize,
      'options.skip': table.pagination.current * table.pagination.pageSize,
      'options.includeTotalCount': true,
      // 'options.sort': '',
    };

    switch (type) {
      // set take / pageSize params
      case 'pageSize':
        paramsValue['options.take'] = value;
        // reset current page if pageSize has change
        tableValue.pagination.current =
          value !== _.get(table.pagination, 'pageSize')
            ? 1
            : _.get(table.pagination, 'current');
        tableValue.pagination.pageSize = value;
        paramsValue['options.skip'] = 0;
        break;

      // set currentPage / skip params
      case 'currentPage':
        paramsValue['options.skip'] =
          (value - 1) * _.get(table.pagination, 'pageSize');
        tableValue.pagination.current = value;
        break;

      // when search keyword
      default:
        paramsValue['options.skip'] = 0;
        break;
    }

    setTable({
      ...tableValue,
    });
    setParams(paramsValue);
  };

  const [selectedRowRecords, setSelectedRowRecords] = useState<{ dataPerPage: Object, lines: any[], keys: string[] }>({ dataPerPage: {}, lines: [], keys: [] });
  const onSelectedRowKeys = (selectedKeys, selectedRecords) => {
    let recordsPerParge = { ...selectedRowRecords };
    recordsPerParge["dataPerPage"][table.pagination.current] = selectedRecords;
    recordsPerParge["lines"] = _.flattenDeep(Object.values(recordsPerParge["dataPerPage"]));
    recordsPerParge["keys"] = _.map(recordsPerParge["lines"], `${rowKey}`);

    setSelectedRowRecords(recordsPerParge);
  }

  return (
    <div className="wrapper-base-list container-fluid p-0">
      {includes && filterSectionComponent()}
      <Spinner spinning={isLoading}>
        <Table
          key={'table-' + props.listId}
          rowSelection={rowSelection === true ? {
            type: 'checkbox',
            selectedRowKeys: selectedRowRecords["keys"],
            columnWidth: '80px',
            onChange: onSelectedRowKeys
          } : (rowSelection || null)}
          rowKey={rowKey}
          dataSource={table.dataSource}
          columns={table.dataSource && _.reject(tableColumns, 'ignoreDisply')}
          // summary={summary}
          pagination={!hidePaginations && {
            total: _.get(table.pagination, 'total'),
            current: _.get(table.pagination, 'current'),
            pageSize: _.get(table.pagination, 'pageSize'),
            onChange: onChangeGridData,
            onChangePageSize: onChangeGridData
          }}
          scroll={scroll ? scroll : { y: '50vh' }}
        />
      </Spinner>
    </div>
  );
};

export default BaseListComponent;

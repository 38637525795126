import React, { useRef, useState } from 'react';
import * as accountingJS from 'accounting-js';
import { compact } from 'lodash';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import moment from 'moment-timezone';
import { BaseListComponent } from '../../../../../components/base-component/list/base-list.component';
import {
  IIncludes,
  IColumn,
} from '../../../../../components/base-component/list/base-list';
import { WithdrawOfTenantFundsFilter } from '../more-filter/withdraw-of-tenant-fund-more-filter.component';
import { WithdrawOfTenantFundsModal } from '../upload/withdraw-of-tenant-funds.upload.component';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { DebitCODRestService } from '@clodeo/libs/core/rest/admin/debit-cod/debit-cod-rest.service';
import { Tag } from '@clodeo/clodeo-ui/components/data-display/tag/tag.component';
import { AclService } from 'apps/admin-web/src/app/core/auth/acl.service';
import { UpdateNoteComponent } from '@clodeo/clodeo-ui/components/base/update-note/update-note.component';
import { Select } from '@clodeo/clodeo-ui/components/data-entry/select/select.component';

const WithdrawOfTenantFunds: React.FC = () => {
  const aclService: AclService = new AclService;
  const baseListRef = useRef<any>();
  const [isShowWithdrawTenantFunds, setIsShowWithdrawTenantFunds] = useState<
    boolean
  >(false);
  const [isTenant, setIsTenant] = useState<boolean>(true);
  const [ready, setReady] = useState<boolean>(false);
  const authenticationService: AuthenticationService = new AuthenticationService();

  const debitCODRestService = new DebitCODRestService(
    environment.ENDPOINTS,
    authenticationService.axiosInterceptors
  );

  const columns: IColumn[] = compact([
    {
      key: 'id',
      dataIndex: 'id',
      title: 'ID',
      ignoreDisply: true,
    },
    {
      key: 'tenantId',
      dataIndex: 'tenantId',
      title: 'TENANT ID',
      ignoreDisply: true,
    },
    {
      key: 'userAdminName',
      dataIndex: 'userAdminName',
      title: 'USER ADMIN NAME',
      ignoreDisply: true,
    },
    {
      key: 'sellerName',
      dataIndex: 'sellerName',
      title: 'NAMA PERUSAHAAN',
      ignoreDisply: true,
    },
    {
      key: 'amount',
      dataIndex: 'amount',
      title: 'AMOUNT',
      ignoreDisply: true,
      formatExprt: (val) => accountingJS.formatMoney(val || 0, { symbol: 'Rp ', format: "%s %v", decimal: ',', thousand: '.' })
    },
    {
      key: 'bankName',
      dataIndex: 'bankName',
      title: 'NAMA BANK',
      ignoreDisply: true,
    },
    {
      key: 'bankDistrict',
      dataIndex: 'bankDistrict',
      title: 'CABANG BANK',
      ignoreDisply: true,
    },
    {
      key: 'accountName',
      dataIndex: 'accountName',
      title: 'NAMA AKUN',
      ignoreDisply: true,
    },
    {
      key: 'deliveryDate',
      dataIndex: 'deliveryDate',
      title: 'DELIVERY DATE',
      ignoreDisply: true,
      formatExprt: (val) => val ? moment(val).format('DD-MM-YYYY') : '-'
    },
    {
      key: 'transactionDate',
      dataIndex: 'transactionDate',
      title: 'TRANSACTION DATE',
      ignoreDisply: true,
      formatExprt: (val) => val ? moment(val).format('DD-MM-YYYY') : '-'
    },
    {
      key: 'approvalDate',
      dataIndex: 'approvalDate',
      title: 'APPROVAL DATE',
      ignoreDisply: true,
      formatExprt: (val) => val ? moment(val).format('DD-MM-YYYY') : '-'
    },
    {
      key: 'status',
      dataIndex: 'status',
      title: 'STATUS',
      ignoreDisply: true
    },
    {
      key: 'shippingMemberIds',
      dataIndex: 'shippingMemberIds',
      title: 'SHIPPNG MERMBER ID',
      ignoreDisply: true
    },
    {
      key: '1',
      dataIndex: 'transactionDate',
      title: 'TGL. PENARIKAN',
      width: '100px',
      ignoreExprt: true,
      render: (val) => {
        return val ? moment(val).format('DD/MM/YY') : '';
      },
    },
    {
      key: '2',
      dataIndex: 'sellerName',
      title: 'SELLER',
      ignoreExprt: true,
      width: '100px',
    },
    {
      key: '3',
      dataIndex: 'amount',
      title: 'JUMLAH SALDO DITARIK',
      ignoreExprt: true,
      render: (val) => accountingJS.formatMoney(val || 0, { symbol: 'Rp ', format: "%s %v", decimal: ',', thousand: '.' }),
      width: '100px',
    },
    {
      key: '4',
      dataIndex: 'bankName',
      title: 'DITARIK KE REKENING',
      width: '100px',
      ignoreExprt: true,
    },
    {
      key: '5',
      dataIndex: 'bankDistrict',
      title: 'CABANG BANK',
      width: '100px',
      ignoreExprt: true,
      render: (val) => val || '-'
    },
    {
      key: '6',
      dataIndex: 'status',
      title: 'STATUS',
      width: '100px',
      ignoreExprt: true,
      render: (val) => {
        return (
          <Tag colorType={val} className="p-2">
            {val}
          </Tag>
        );
      },
    },

    aclService.can(['wallet.withdrawal_history.edit']) &&
    {
      key: '7',
      dataIndex: 'note',
      title: 'NOTE',
      width: '60px',
      ignoreExprt: true,
      render: (value, data) => <UpdateNoteComponent hideEdit={true} note={value} />
    },

    aclService.can(['wallet.withdrawal_history.edit']) &&
    {
      key: '8',
      title: '',
      width: '60px',
      ignoreExprt: true,
      render: (value, record) => {
        return (
          <Button
            iconName="deo-upload"
            type="ghosted"
            className="p-2"
            onClick={() => {
              setRecord(record);
              setIsShowWithdrawTenantFunds(true);
            }}
          />
        );
      },
    },
  ]);

  const [record, setRecord] = useState<boolean>(null);

  const includes: IIncludes = {
    refresh: true,
    refreshAction: () => {
      baseListRef.current.resetFilters();

      setTimeout(() => {
        baseListRef.current.callLoadData();
      }, 10);
    },
    keyword: true,
    toogleOptions: true,
    exports: [
      {
        label: 'Export berdasarkan filter',
        basedFilter: true,
        fileName: 'Permintaan Penarikan Dana By Filter'
      },
      {
        label: 'Export semua data',
        fileName: 'Permintaan Penarikan Dana'
      }
    ]
  };

  const loadDataObservable = (params) => {
    if (params.bankNames) {
      params.bankNames = (params.bankNames).toString();
    }
    if (!(params.lowDate && params.highDate)) {
      params.lowDate = moment().subtract(3, 'months').format('YYYY-MM-DD')
      params.highDate = moment(new Date).format('YYYY-MM-DD')
    }
    params.dateValues = null;
    params.tenantId = isTenant ? params.keyword : null;
    params.keyword = !isTenant ? params.keyword : null;
    
    return debitCODRestService.findAllAlternatif({ params });
  };


  function selectIsTenant() {
    const options: any[] = [
      { label: 'Tenant ID', value: true },
      { label: 'Keyword', value: false },
    ];
    return (
      <Select size='large' options={options} value={isTenant} onChange={setIsTenant} />
    )
  }


  return (
    <>
      <BaseListComponent
        listId="withdraw-tenant-funds-component"
        ref={baseListRef}
        isReady={ready}
        columns={columns}
        loadDataObservable={loadDataObservable}
        includes={{
          ...includes,
          placeholderKeyword: isTenant && 'Cari Tenant ID',
          keywordType: isTenant && 'number',
          suffixComponent: selectIsTenant(),
          moreFilter: {
            template: (
              <WithdrawOfTenantFundsFilter />
            )
          },
        }}
      />
      <Modal
        visible={isShowWithdrawTenantFunds}
        onCancel={() => setIsShowWithdrawTenantFunds(false)}
        footer={null}
        title='Upload Bukti Transfer'
        className='admin'
      >
        {
          isShowWithdrawTenantFunds &&
          <WithdrawOfTenantFundsModal
            record={record}
            onSuccess={() => { setIsShowWithdrawTenantFunds(false); baseListRef.current.callLoadData(); }}
            onCancel={() => setIsShowWithdrawTenantFunds(false)}
          />
        }
      </Modal>
    </>
  );
};

export { WithdrawOfTenantFunds };

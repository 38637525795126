import { RequestService } from "../../request.service";
export class AdminBillingPaymentRestService {
  requestService = new RequestService;
  request: RequestService;
  /**
   * @param baseUrl 
   * @param useInterceptor 
   * @param withoutInterceptor 
   */
  constructor(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
    this.request = this.requestService.new(`${baseUrl['API_ADMIN']}/billing-payment`, useInterceptor, withoutInterceptor);
  }

  /**
   * Get list of Billing Payment
   * @param qParams 
  */
  findAll(qParams: any) {
    return this.request.get<{ data: any[], total: number, skip: number, take: number }>('', qParams);
  }

  /**
     * Get Summary of Billing Payment
     * @param qParams 
    */
  getBillingPaymentSummary(qParams: any) {
    return this.request.get<any>('/summary', qParams);
  }

  /**
   * Create Billing
   * @param payload 
  */
  createBillingPayment(payload: any) {
    return this.request.post('', payload);
  }

  /**
   * Approve Billing
   * @param id 
   * @param payload 
  */
  updateBilling(id: string, payload: any) {
    return this.request.put(`/payment-approval/${id}`, payload);
  }
  // PUT /billing-payment/payment-approval/{Id}

  /**
    * Get Mini List of Billing Payment
     * @param qParams 
  */
   billingPaymentMini(qParams: any) {
    return this.request.get<{ data: any[], total: number, skip: number, take: number }>('/mini', qParams);
  }

  /**
    * Get Detail of Billing Payment
     * @param billingPaymentId
  */
   detailBillingPayment(billingPaymentId, tenantId) {
    return this.request.get(`${billingPaymentId}?tenantId=${tenantId}`);
  }

  /**
    * GET Attachment of Billing Payment
     * @param billingPaymentId
  */

   billingPaymentAttachment(billingPaymentId) {
    return this.request.get(`/attachment/${billingPaymentId}`);
  }
}

import { RequestService } from '../../request.service';

export class AdminPickerRestService {
  requestService = new RequestService;
  request: RequestService;
  /**
   * 
   * @param baseUrl only from environment.ENDPOINTS
   * @param useInterceptor 
   * @param withoutInterceptor 
   */
  constructor(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
    this.request = this.requestService.new(`${baseUrl['API_ADMIN']}/pickers`, useInterceptor, withoutInterceptor);
  }

  getTenant(payload, params?) {
    return this.request.post(`/tenants`, payload, {
      params
    });
  }

  /**
   * Get Available Payment Method in Xendit
   */
  getAvailablePayment() {
    return this.request.post('available-payment-paydeo');
  }

  /**
   * Get Tenant Bank from tenant id
   * @param params
   */
   getTenantBank(body: any , qParams: any) {
    return this.request.post('/tenant-banks', body, { params: qParams });
   }
   
   /**
   * 
   * @param qOption 
   * @param params 
   * @returns 
   */
  getSalesReferral(qOption, params) {
    return this.request.post('sales-referral', qOption, { params });
  }

  /**
    * Get Picker list Courier
  */
  getCourierPicker() {
    return this.request.post('courier');
  }
}

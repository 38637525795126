import React, { createContext } from 'react';
import {
  Route,
  Redirect,
  useHistory,
  useLocation,
  RouteProps
} from "react-router-dom";

import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { MainLayoutComponent } from '../layout-component/main/main-layout.component';
import { IGuardRoute } from './guard-route';
import { AclService } from '../../core/auth/acl.service';

const authService: AuthenticationService = new AuthenticationService;
const aclService: AclService = new AclService;
const authContext = createContext(authService.user);

function useProvideAuth() {
  return authService.user
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return (
    <authContext.Provider value={auth}>
      {children}
    </authContext.Provider>
  );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export function GuardRoute(props: IGuardRoute) {
  let location = useLocation();
  const router = useHistory();
  const pathName = location.pathname;
  let auth = authService.user;
  if (pathName === '/login' && auth) {
    router.push('/');
  }

  if (pathName === '/' && auth) {
    aclService.redirectAllowedMenu(router);

  } else if(props?.meta?.permissions) {
    const isValidRoles = aclService.can(props.meta.permissions);
    if (!isValidRoles) {
      router.push(`/403`);
    }
  }

  return (
    <Route
      {...props}
      children={null}
      render={({ location }) =>
        auth ? (
          <MainLayoutComponent title={props.title || ''} pathName={location.pathname}>
            {props.children}
          </MainLayoutComponent>
        ) : (
            <Redirect to="/login" />
          )
      }
    />
  );
}

import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { AdminRestService } from '@clodeo/libs/core/rest/admin/admin/admin-rest.service';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import React, { useEffect, useState } from 'react'
import { TenantServices } from '../../../tenants.service';
import { FormShippingSettings } from './form/form-shipping-settings.component';
import { IPayloadShippingSetting, IShippingCourierDetail, IShippingSetting } from './shipping-settings';
import './shipping-settings.component.scss';

const authenticationService: AuthenticationService = new AuthenticationService;
const tenantsRestService = new AdminRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const tenantService = new TenantServices;
export const ShippingSettingsComponent = (props: {
  tenantId: string;
  shippingCourierDetails: IShippingCourierDetail[];
}) => {
  const { shippingCourierDetails, tenantId } = props;
  const [shippingSettings, setShippingSettings] = useState<IShippingSetting[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [relatedData, setRelatedData] = useState<any>([]);
  function loadData() {
    setLoading(true);
    tenantsRestService.loadDataById(tenantId).subscribe((res: any) => { setShippingSettings(res.shippingSettings); setLoading(false) });
  }

  useEffect(() => {
    loadData();
  }, [tenantId]);

  function onUpdate(val: IPayloadShippingSetting) {
    setLoading(true);
    const obs = tenantsRestService.shippingService(tenantId, [val]);
    tenantService.requestService({
      errorMessage: '',
      successMessage: 'Pengaturan Pengiriman berhasil diubah',
      obs,
      onDone: () => loadData()
    });
  }

  return <Spinner spinning={loading}>
    <div className="wrapper-shipping-settings container-lg container-fluid">
      <div className="row">
        {
          shippingCourierDetails && shippingCourierDetails.map((shippingCourierDetail: IShippingCourierDetail, i: number) => (
            <div className="col-lg-4 col-md-6 col-12 p-2" key={i}>
              <FormShippingSettings shippingCourierDetailsFromRelated={shippingCourierDetail} data={shippingSettings} onDone={onUpdate} tenantId={tenantId} />
            </div>
          ))
        }
      </div>
    </div>
  </Spinner>
}

import { RequestService } from "../../../request.service";
import { ICoupon } from "./coupon.d";
export { ICoupon };

export class CouponRestService {
  requestService = new RequestService;
  request: RequestService;
  // requestAddon: RequestService;
  constructor(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
    this.request = this.requestService.new(`${baseUrl['API_ADDON']}/general-coupons`, useInterceptor, withoutInterceptor);
    // this.request = this.requestService.new(baseUrl, useInterceptor, withoutInterceptor);
  }
  getAll(qParams) {
    return this.request.get('', qParams);
  }

  getDetail(couponId) {
    return this.request.get(`/${couponId}`);
  }

  create(payloads) {
    return this.request.post(``, payloads);
  }

  update(couponId, payload) {
    return this.request.put(`/${couponId}`, payload);
  }

  toggleStatus(couponId, isActive) {
    return this.request.put(`/${couponId}/mark-active/${isActive}`);
  }
}

import { Skeleton } from 'antd';
import { isNull } from 'lodash';
import React from 'react';

export {
  Area,
  Bar,
  Box,
  Bullet,
  Column,
  Funnel,
  Histogram,
  Line,
  Liquid,
  Heatmap,
  Pie,
  Progress,
  Radar,
  RingProgress,
  Rose,
  Scatter,
  TinyArea,
  TinyColumn,
  TinyLine,
  Waterfall,
  WordCloud,
  Sunburst,
  DualAxes,
  Stock,
  RadialBar,
  Gauge,
  Sankey,
  BidirectionalBar,
  OrganizationTreeGraph,
  DagreGraph,
  IndentedTree,
  DagreFundFlowGraph,
  G2,
} from '@ant-design/charts';
export { WidgetChartBasic } from './widget/chart-widget.component';
export const WidgetChart = (props: {
  label: any;
  value: any;
  onClick?: () => void;
  className?: string;
  icon?: any
  active?: boolean;
}) => (
  !isNull(props.value) ? < div
    className={"widget-chart-basic" + (props.onClick ? ' pointer ' : ' ' + (props.className || ''))} id="widget-chart"
    onClick={() => props.onClick ? props.onClick() : ''}
  >
    <div className="field">
      <div className="label">
        {props.label}
      </div>
      <div className="value">
        {
          props.value
        }
      </div>
    </div>
    {props.icon && <div className="icon-wrapper">
      {props.icon}
    </div>}
  </div > :
    <Skeleton.Input active className="widget-chart-basic-skeleton" />
)

// demo https://v0-charts.ant.design/demos/global
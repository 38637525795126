import React, { useEffect, useState } from 'react';
import { map } from 'lodash';
import * as accountingJS from 'accounting-js';
import { Form } from 'antd';
import { UploadTemplateList } from '@clodeo/clodeo-ui/components/data-entry/upload/upload.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { Confirms } from '@clodeo/clodeo-ui/components/feedback/confirm/confirm.component';

import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { AdminWalletTransactionRestService } from '@clodeo/libs/core/rest/admin/wallet-transaction/admin-wallet-transaction-rest.service';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { LoadingComponent } from '@clodeo/clodeo-ui/components/general/loading/loading.component';
import './withdraw-of-tenant-funds.upload.component.scss';
import { InputTextArea } from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';
const confirm: Confirms = new Confirms();

const WithdrawOfTenantFundsModal = (props: {
  record;
  onCancel: () => void;
  onSuccess: () => void;
}) => {
  const { handleRequest } = new HandleService();
  const { record, onCancel, onSuccess } = props;
  const authenticationService: AuthenticationService = new AuthenticationService();
  const adminWalletTransactionRestService = new AdminWalletTransactionRestService(
    environment.ENDPOINTS,
    authenticationService.axiosInterceptors
  );

  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  function defaultFileList() {
    let defaults = [];
    if ((record && record.attachments) && (record.attachments.length > 0)) {
      defaults = map(record.attachments, function (att) {
        return att && {
          uid: att.id,
          name: att.fileName,
          status: 'done',
          response: att,
          url: att.fileUrl
        }
      });
    }
    return defaults;
  }

  function ContentConfirm(props: {
    val: any;
  }) {
    const { val } = props;
    return <table className="table table-borderless" style={{ fontSize: '10px' }}>
      <tbody>
        <tr>
          <td>
            <strong>No Rekening</strong>
          </td>
          <td>
            :
          </td>
          <td>
            {val?.accountNumber || '-'}
          </td>
        </tr>
        <tr>
          <td>
            <strong>Nama Rekening</strong>
          </td>
          <td>
            :
          </td>
          <td>
            {val?.accountName || '-'}
          </td>
        </tr>
        <tr>
          <td>
            <strong>Nama Bank</strong>
          </td>
          <td>
            :
          </td>
          <td>
            {val?.bankName || '-'}
          </td>
        </tr>
        <tr>
          <td>
            <strong>Total</strong>
          </td>
          <td>
            :
          </td>
          <td>
            {accountingJS.formatMoney(val?.amount || 0, { symbol: 'Rp ', format: '%s %v' })}
          </td>
        </tr>
        <tr>
          <td>
            <strong>Total Bayar</strong>
          </td>
          <td>
            :
          </td>
          <td>
            {accountingJS.formatMoney(val?.amount || 0, { symbol: 'Rp ', format: '%s %v' })}
          </td>
        </tr>
      </tbody>
    </table>
  }

  function onSubmit(val) {
    confirm.show({
      title: 'Konfirmasi Transfer',
      content: (
        <ContentConfirm val={form.getFieldsValue(true)} />
      ),
      onOk() {
        approveRequest(form.getFieldsValue(true));
      },
    });
  }

  function approveRequest(payload) {
    payload.isApprove = true;

    setLoading(true);
    handleRequest({
      obs: adminWalletTransactionRestService.approve(payload.id, payload),
      successMessage: 'Berhasil transfer dana',
      onDone: () => {
        setLoading(false);
        onSuccess && onSuccess();
      },
      onError: () => setLoading(false),
    })
  }

  function onChangeUpload(item) {
    let { fileList } = item;
    fileList = fileList.map((file) => {
      if (file.status == 'done') {
        return file.response;
      }
    });
    form.setFieldsValue({
      attachments: fileList
    })
  };

  useEffect(() => {
    form.setFieldsValue(record)
  }, [record])

  return (
    <Spinner spinning={loading} indicator={<LoadingComponent />}>
      <Form
        form={form}
        initialValues={{
          amount: 0,
        }}
        name="withdraw-tenant-upload"
        onFinish={onSubmit}
      >
        <Form.Item shouldUpdate>
          <div className="form-attach-wrappero mt-3 px-3">
            <div className="row my-3 form-group-x">
              <label htmlFor="" className="col-lg-4">Nama Perusahaan</label>
              <div className="col-lg-8 value">
                {record?.sellerName || '-'}
              </div>
            </div>
            <div className="row my-3 form-group-x">
              <label htmlFor="" className="col-lg-4">Nama Rekening</label>
              <div className="col-lg-8 value">
                {record?.accountName || '-'}
              </div>
            </div>
            <div className="row my-3 form-group-x">
              <label htmlFor="" className="col-lg-4">No Rekening</label>
              <div className="col-lg-8 value">
                {record?.accountNumber || '-'}
              </div>
            </div>
            <div className="row my-3 form-group-x">
              <label htmlFor="" className="col-lg-4">Bank</label>
              <div className="col-lg-8 value">
                {record?.bankName || '-'}
              </div>
            </div>
            <div className="row my-3 form-group-x">
              <label htmlFor="" className="col-lg-4">Total</label>
              <div className="col-lg-8 value">
                {accountingJS.formatMoney(record?.amount || 0, { symbol: 'Rp ', format: '%s %v' })}
              </div>
            </div>
            <div className="row my-3 form-group-x">
              <label htmlFor="" className="col-lg-4">Total Bayar</label>
              <div className="col-lg-8 value">
                {accountingJS.formatMoney(record?.amount || 0, { symbol: 'Rp ', format: '%s %v' })}
              </div>
            </div>
            <div className="row my-3 form-group-x">
              <label htmlFor="" className="col-lg-4">Catatan</label>
              <div className="col-lg-8 value">
                <Form.Item name="note">
                  <InputTextArea />
                </Form.Item>
              </div>
            </div>
            <div className="row my-3 form-group-x">
              <div className="col-auto">
                <UploadTemplateList
                  buttonProps={{
                    className: 'pr-3 pl-2 '
                  }}
                  defaultFileList={defaultFileList()}
                  onChange={onChangeUpload}
                  baseUrl={`${environment.ENDPOINTS.API_FILE}/upload/admin`}
                  headers={{
                    Authorization: `Bearer ${authenticationService.user.access_token}`,
                  }}
                />
              </div>
            </div>
          </div>
          <div className="text-center">
            <Button
              type="ghosted"
              label="Cancel"
              className="mr-2"
              onClick={() => onCancel()}
            />
            <Button type="primary" label="Simpan" submit />
          </div>
        </Form.Item>
      </Form>

    </Spinner>
  );
};

export { WithdrawOfTenantFundsModal };

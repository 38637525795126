import React, { useState } from 'react';
import { Form } from 'antd';
import { ICourierPicker } from '@clodeo/libs/core/rest/admin/picker/admin-picker';
import { AutoComplete } from '@clodeo/clodeo-ui/components/data-entry/autocomplete/autocomplete.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { AdminPickerRestService } from '@clodeo/libs/core/rest/admin/picker/admin-picker-rest.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import {
  Option,
} from '@clodeo/clodeo-ui/components/data-entry/select/select.component';
import { RangePicker } from '@clodeo/clodeo-ui/components/data-entry/datepicker/datepicker.component';
import moment from 'moment';

export const SendPickupMoreFilterComponent = (props: {
  listCourier: ICourierPicker;
  values?: any;
  form?; // form props is generated by baseList so set to optional props
}) => {
  const { form, listCourier } = props;
  const options = listCourier?.shippingCourierDetails || [];
  const authenticationService: AuthenticationService = new AuthenticationService();
  const pickerRestService = new AdminPickerRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
  const { handleRequest } = new HandleService;
  const [tenantLoading, setTenantLoading] = useState<boolean>(false);
  const [tenants, setTenants] = useState<any[]>([]);
  const dateFormat = 'YYYY-MM-DD';
  const lowDate = moment().subtract(3, 'months')
  const highDate = moment(new Date)

  function onSelectCourier(key, option) {
    form.setFieldsValue({
      courierName: option.key,
    });
  }

  function onSearchCourier() {
    form.setFieldsValue({
      courierName: null,
    });
  }

  function getTenant(payload) {
    setTenantLoading(true);
    const params = { ...payload };
    const obs = pickerRestService.getTenant(null, params);

    handleRequest({
      obs,
      onError: () => setTenantLoading(false),
      onDone: (res) => {
        setTenants(res.data);
        setTenantLoading(false);
      }
    })
  }

  function onSelectTenant(key, option) {
    form.setFieldsValue({
      tenantId: option.key
    })
  }

  let onSearchTenantDelay = null;

  function onSearchTenant(value: string) {
    form.setFieldsValue({
      tenantId: null
    });
    if (!value) return
    clearTimeout(onSearchTenantDelay);

    onSearchTenantDelay = setTimeout(() => {
      const payload = {
        keyword: value,
      }
      getTenant(payload);
    }, 750);
  }

  function onClearTenant(value) {
    if (!value) {
      form.setFieldsValue({
        tenantId: null
      });
    }
  }

  function onChangeCreateDate() {
    const filterDate = form.getFieldValue('filterDate');
    filterDate && form.setFieldsValue({
      lowDate: moment(filterDate[0]).format('YYYY-MM-DD'),
      highDate: moment(filterDate[1]).format('YYYY-MM-DD')
    })
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-6" style={{ width: "270px" }}>
          <div className="form-group">
            <label htmlFor="">Kurir</label>
            <Form.Item name="shippingCourierChannel">
              <AutoComplete
                onSelect={onSelectCourier}
                onSearch={onSearchCourier}
              >
                {options.map((courier) => (
                  <Option key={courier.code} value={courier.name}>
                    {courier.name}
                  </Option>
                ))}
              </AutoComplete>
            </Form.Item>
          </div>
        </div>
        <div className="col-lg-6" style={{ width: "270px" }}>
          <div className="form-group">
            <label htmlFor="">Tenant</label>
            <Spinner spinning={tenantLoading}>
              <Form.Item name="tenant">
                <AutoComplete
                  onDropdownVisibleChange={(open) => open ? getTenant('') : setTenants(null)}
                  onSearch={onSearchTenant}
                  onSelect={onSelectTenant}
                  onChange={onClearTenant}
                >
                  {
                    tenants?.map((tenant) => (
                      <Option key={tenant.id} value={tenant.displayName}>
                        {tenant.displayName}
                      </Option>
                    ))
                  }
                </AutoComplete>
              </Form.Item>
            </Spinner>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12" style={{ width: '300px' }}>
          <div className="form-group">
            <label htmlFor="">Tanggal</label>
            <Form.Item name="filterDate">
              <RangePicker
                defaultValue={[moment(lowDate, dateFormat), moment(highDate, dateFormat)]}
                onChange={onChangeCreateDate} className="w-100" />
            </Form.Item>
          </div>
        </div>
      </div>
    </>);
};

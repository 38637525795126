import { Table } from '@clodeo/clodeo-ui/components/data-display/table/table.component';
import { Input } from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { ExportService } from '@clodeo/libs/core/export/export.service';
import { FormatService } from '@clodeo/libs/core/format/format.service';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { AdminCreditCodRestService } from '@clodeo/libs/core/rest/admin/credit-cod/admin-credit-cod.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { values, startCase, uniqBy } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { throwError } from 'rxjs';
import { IColumn } from '../../../../../components/base-component/list/base-list';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import '../transfer-credit-cod.component.scss';
import { IDataDetailTransferCreditCOD, IDetailTransferCreditWithLines, ITransferCreditCodDetailProps } from './transfer-credit-cod-detail.d';
import { TransferCreditCodLinesComponent } from './transfer-credit-cod-lines.component';

const exportService: ExportService = new ExportService();
const authenticationService = new AuthenticationService();
const notif: Notifications = new Notifications();
const { handleRequest } = new HandleService;
const formatService = new FormatService();
const adminCreditCodRestService = new AdminCreditCodRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const transferCreditCodRestService = new AdminCreditCodRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);

export const TransferCreditCodDetailRFComponent = (
  props: ITransferCreditCodDetailProps
) => {
  const { id, onCancel } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [dataDetailTransferCreditCOD, setDataDetailTransferCreditCOD] = useState<IDataDetailTransferCreditCOD>(null);
  const [loadingExprt, setLoadingExprt] = useState<{ status: boolean; description: string; }>({ status: false, description: '0%' });
  const [dataLinesDetail, setDataLinesDetail] = useState<IDetailTransferCreditWithLines>(null);


  const columns: IColumn[] = [
    {
      title: 'No',
      width: '80px',
      render: (value, record, index) => index + 1,
    },
    {
      title: 'Nama Seller',
      dataIndex: 'sellerName',
      width: '100px',
      render: (value, record) => {
        return (
          <div>
            <span className="clickable" onClick={() => { getDetailLineCODCreditTransaction(record.id) }}>
              {formatService.empty(value)}
            </span>
          </div>
        );
      },
    },
    {
      title: 'Jumlah Paket',
      dataIndex: 'lineCount',
      width: '100px',
      formatExprt(data) {
        return data.length || 0;
      },
      render: (value, record) => {
        return record.lineCount || 0;
      },
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      width: '100px',
      formatExprt(data) {
        return data.totalAmount || 0;
      },
      render: (value, record) => {
        return formatService.currency(value);
      },
    },
    {
      title: 'Fee COD',
      dataIndex: 'codFeeValue',
      width: '100px',
      render: (value) => formatService.currency(value),
    },
    {
      title: 'Total Diterima',
      dataIndex: 'receiveAmount',
      width: '100px',
      render: (value) => formatService.currency(value),
    },
    {
      dataIndex: 'transferStatus',
      width: '150px',
      title: 'STATUS TRANSFER',
      render: (value) => startCase(value)
    },
    {
      dataIndex: 'id',
      title: 'ACTION',
      width: '200px',
      ignoreExprt: true,
      align: 'center',
      render: (value, record) => {
        return <Fragment>
          {record.transferStatus !== 'completed' &&
            <Button type="outline" label="Retry" onClick={() => {
              setLoading(true);
              const obs = transferCreditCodRestService.retrySingleTransferCODCredit(value);
              handleRequest({
                obs,
                onError: (error) => {
                  notif.show({
                    type: 'error',
                    title: 'Error',
                    description: error,
                    useService: true,
                  });

                  setLoading(false);
                  return throwError(error);
                },
                onDone: () => {
                  setLoading(false);
                  notif.show({
                    type: 'success',
                    title: 'Sukses',
                    description:
                      'Kami sedang mengerjakan data anda.',
                  });
                },
              });
            }} />
          }

        </Fragment>
      }
    },
  ];

  function loadTransferCreditCodDetail() {
    setLoading(true)
    const obs = adminCreditCodRestService.getDetail(id);
    handleRequest({
      obs,
      onError: (error) => {
        setLoading(false)
        notif.show({
          type: 'error',
          title: 'Error',
          description: error,
          useService: true,
        });
      },
      onDone: (response) => {
        setLoading(false)
        setDataDetailTransferCreditCOD(response)
      },
    });
  }

  function getDetailLineCODCreditTransaction(CODCreditTransactionId) {
    setLoading(true)
    const obs = adminCreditCodRestService.getLineCODCreditTransaction(CODCreditTransactionId);
    handleRequest({
      obs,
      onError: (error) => {
        setLoading(false)
        notif.show({
          type: 'error',
          title: 'Error',
          description: error,
          useService: true,
        });
      },
      onDone: (response) => {
        setLoading(false)
        setDataLinesDetail(response);
      },
    });
  }

  function onSearch(e, rows) {
    let delaySearchTransferCreditCOD;
    const searchValue = e.target.value;
    let rowsData = [];

    clearTimeout(delaySearchTransferCreditCOD);

    if (!searchValue) {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 150);
      return;
    }

    delaySearchTransferCreditCOD = setTimeout(() => {
      setLoading(true);
      if (searchValue.length > 0) {
        let matchData;
        for (let index = 0; index < rows.length; index++) {
          const valuePerRow = values(rows[index]);
          valuePerRow.filter((item) => {
            if (`${item}`.toLowerCase().includes(searchValue.toLowerCase())) {
              matchData = rows[index];
            }
          });

          if (matchData) {
            rowsData.push(matchData);
          }
        }
        rowsData = uniqBy(rowsData, 'tenantId');
      } else {
        rowsData = rows;
      }
      setTimeout(() => {
        setLoading(false);
      }, 150);
    }, 500);
  }

  function exportData(data, fileName, columns) {
    setLoadingExprt({
      status: true,
      description: `0%`,
    });
    exportService.expToXLS({ data: data, wscols: '' }, fileName, columns);
    setTimeout(() => {
      setLoadingExprt({
        status: true,
        description: `100%`,
      });
      setTimeout(() => {
        setLoadingExprt({
          status: false,
          description: `100%`,
        });
      }, 150);
    }, 50);
  }

  function resetLinesData() {
    setDataLinesDetail(null)
  }

  useEffect(() => {
    loadTransferCreditCodDetail()
  }, []);


  return <Spinner spinning={loading} >
    {dataDetailTransferCreditCOD && !dataLinesDetail && <>
      <div className="d-flex flex-row flex-wrap justify-content-between filter-section w-100">
        <div className="d-flex flex-row flex-wrap col-12 justify-content-between justify-content-lg-start col-lg-6">
          <Input
            placeholder="Cari"
            onChange={(e) => onSearch(e, dataDetailTransferCreditCOD.codCreditTransactions)}
            suffix={<i className="icon-deo-search icon-16px"></i>}
          />
        </div>
        <div className="d-flex flex-row flex-wrap col-12 justify-content-lg-end col-lg-6">
          <Spinner
            spinning={loadingExprt.status || false}
            tip={loadingExprt.description || ''}
          >
            <div className="px-1 align-self-center py-1 col col-lg-auto">
              <Button
                iconName="deo-upload"
                label="EXPORT"
                type="filter"
                className="p-2 px-3 text-center"
                onClick={() =>
                  exportData(
                    dataDetailTransferCreditCOD.codCreditTransactions,
                    'Detail Resi COD',
                    columns
                  )
                }
              />
            </div>
          </Spinner>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <Spinner className="d-flex align-item-center" spinning={loading}>
            {dataDetailTransferCreditCOD.codCreditTransactions.length ? <>
              <Table
                columns={columns}
                className="summary"
                dataSource={dataDetailTransferCreditCOD.codCreditTransactions || []}
                summary={(pageData) => (
                  <tfoot>
                    <tr>
                      <td width="80px"><strong>Total</strong></td>
                      <td width="100px"><strong>{pageData.length}</strong></td>
                      <td width="150px"><strong>{dataDetailTransferCreditCOD.lineCount}</strong></td>
                      <td width="100px">{formatService.currency(dataDetailTransferCreditCOD.totalAmount)}</td>
                      <td width="100px">{formatService.currency(dataDetailTransferCreditCOD.codFeeValue)}</td>
                      <td width="100px">{formatService.currency(dataDetailTransferCreditCOD.receiveAmount)}</td>
                    </tr>
                  </tfoot>
                )}
              />
            </> : <>
              <div className="text-center mt-3"> No Data Transaction</div>
            </>}
          </Spinner>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-3">
        <Button
          type="ghosted"
          label="Ok"
          className="mr-2"
          onClick={() => onCancel()}
        />
      </div>
    </>}
    {dataLinesDetail && <TransferCreditCodLinesComponent data={dataLinesDetail} onCancel={resetLinesData} />}
  </Spinner>
};


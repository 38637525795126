import { RequestService } from '../../request.service';
import { IUploadCreditCOD, IRelatedData } from './admin-credit-cod.d';
export { IUploadCreditCOD, IRelatedData } from './admin-credit-cod.d';
export class AdminCreditCodRestService {
  requestService = new RequestService;
  request: RequestService;
  /**
   *
   * @param baseUrl only from environment.ENDPOINTS
   * @param useInterceptor
   * @param withoutInterceptor
   */
  constructor(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
    this.request = this.requestService.new(`${baseUrl['API_ADMIN']}/credit-cod`, useInterceptor, withoutInterceptor);
  }

  /**
   * Get list of COD Credit Transaction
   * @param qParams
   */
  findAll(qParams) {
    return this.request.get('', qParams)
  }

  /**
   * Get list of COD Credit Transaction with more detail search params
   * @param qParams
   */
  findAllQ(qParams) {
    return this.request.post('q', qParams)
  }

  /**
   * Add new COD Credit Transaction
   * @param payload
   */
  addCODCredit(payload) {
    return this.request.post('', payload)
  }

  /**
   * Get detail of COD Credit
   * @param CODCreditTransactionId
   */
  getDetail(CODCreditTransactionId: string) {
    return this.request.get(CODCreditTransactionId)
  }

  /**
   * Get detail lines of COD Credit
   * @param CODCreditTransactionId
   */
  getLineCODCreditTransaction(CODCreditTransactionId: string) {
    return this.request.get(`/line/${CODCreditTransactionId}`)
  }

  /**
   * Get related data for cod credit create or update
   * @param params
   */
  loadRelated(params?) {
    return this.request.get<IRelatedData>('entry-related-data', params)
  }

  /**
   * upload new COD Credit Transaction Background Job
   * @param payload
   */
  uploadCODCredit(payload: IUploadCreditCOD) {
    return this.request.post<{ jobId: string }>('background-job', payload);
  }

  /**
  * retry transfer COD Credit Transaction Background Job
  * @param docId
  */
  retryDocTransferCODCredit(docId: string) {
    return this.request.put(`retry-doc-transfer/${docId}`);
  }

  /**
 * retry transfer COD Credit Transaction Background Job
 * @param idCODCredit
 */
  retrySingleTransferCODCredit(idCODCredit: string) {
    return this.request.put(`retry-single-transfer/${idCODCredit}`);
  }

  /**
  * Sync transfer COD Credit Transaction Background Job
  * @param codCreditTransactionExtentionId
  */
   snycTransferCreditCOD(codCreditTransactionExtentionId: string) {
    return this.request.post(`sent-paid-background-job/${codCreditTransactionExtentionId}`);
  }

  /**
  * Validate For Upload New Credit COD
  * @param codCreditTransactionExtentionId
  */
   validateUploadCODCredit(payload: IUploadCreditCOD) {
    return this.request.post<{ jobId: string }>('validate-upload-data-cod', payload);
  }
}

import React, { useEffect, useRef, useState } from 'react'
import { environment } from '../../../../../../environments/environment';
import { BaseListComponent } from '../../../../../components/base-component/list/base-list.component';
import { omit } from 'lodash';
import { IColumn, IIncludes } from '../../../../../components/base-component/list/base-list';
import { TenantSalesOrderRestService } from '@clodeo/libs/core/rest/admin/tenant-sales-order/tenant-sales-order-rest.service';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { RequestCancelSummaryComponent } from '../summary/requset-cancel-summary.component';
import { RequestCancelTemplateListComponent } from './request-cancel-list-template.component';
import { tap } from 'rxjs/operators';
import { RequestCancelMoreFilterComponent } from '../more-filter/request-cancel-more-filter.component';
import './request-cancel-list.component.scss';
import moment from 'moment';

export const RequestCancelListComponent = () => {
  const filters = ['keyword', 'options.take', 'options.skip', 'options.includeTotalCount', 'courierName', 'tenantId'];
  const baseListRef = useRef<any>();
  const authenticationService: AuthenticationService = new AuthenticationService;
  const tenantSalesOrderRestService = new TenantSalesOrderRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
  const { handleRequest } = new HandleService;
  const [requestSummary, setRequestSummary] = useState({
    total: null,
    totalApproved: null,
    totalRejected: null,
  })
  const [requestSummaryFilter, setRequestSummaryFilter] = useState(null);
  const columns: IColumn[] = [
    {
      key: '1',
      dataIndex: '',
      render: (value, record: IRequestCancel) => {
        return <RequestCancelTemplateListComponent data={record} onSuccessApprove={() => baseListRef.current.callLoadData()} />;
      }
    }
  ];

  const includes: IIncludes = {
    refresh: true,
    keyword: true,
  }

  function loadSummary(params) {
    const obs = tenantSalesOrderRestService.getRequestSummary({ params })
    handleRequest({
      obs,
      onDone: (res) => setRequestSummary(res)
    })
  }

  function loadDataObservable(qParams) {
    const filterValue = requestSummaryFilter;
    const allParam = { ...qParams, ...filterValue}
    let params = omit(allParam, ["date"]);
    if (!(params.lowDate && params.highDate)) {
      params.lowDate = moment().subtract(3, 'months').format('YYYY-MM-DD')
      params.highDate = moment(new Date).format('YYYY-MM-DD')
    }
    return tenantSalesOrderRestService.findRequestAll({ params }).pipe(
      tap(() => loadSummary(params))
    )
  }

  useEffect(() => {
    if (requestSummaryFilter) {
      baseListRef.current.callLoadData();
    }
  }, [requestSummaryFilter])

  return (
    <>
      <RequestCancelSummaryComponent summary={requestSummary} onChangeFilter={setRequestSummaryFilter}/>
      <BaseListComponent
        listId="reques-cancel-list"
        columns={columns}
        ref={baseListRef}
        loadDataObservable={loadDataObservable}
        includes={{
          ...includes,
          moreFilter: {
            template: <RequestCancelMoreFilterComponent />
          }
        }}
      />
    </>
  )
}


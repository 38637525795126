import { RequestService } from "../../request.service";
// import { ICoupon } from "./cod-balance.d";
// export { ICoupon };

export class AdminCodBalanceRestService {
  requestService = new RequestService;
  request: RequestService;
  /**
   * 
   * @param baseUrl 
   * @param useInterceptor 
   * @param withoutInterceptor 
   */
  constructor(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
    this.request = this.requestService.new(`${baseUrl['API_ADMIN']}/cod-balance`, useInterceptor, withoutInterceptor);
  }

  /**
   * Get list of Wallet Balance by Tenant
   * @param qParams 
   */
  findAll(qParams) {
    return this.request.get<{ data: any[], total: number, skip: number, take: number }>('', qParams);
  }

  /**
   * 
   * @param qParams 
   * @returns 
   */
  findAllIntegrated(qParams) {
    return this.request.get<{ data: any[], total: number, skip: number, take: number }>('integrated', qParams);
  }

}

import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component'
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import React, { useEffect } from 'react';
import { useState } from 'react'
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { UploadTemplateList } from '@clodeo/clodeo-ui/components/data-entry/upload/upload.component';
import { AclService } from 'apps/admin-web/src/app/core/auth/acl.service';
import { Form } from 'antd';
import { Input } from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { DatePicker } from '@clodeo/clodeo-ui/components/data-entry/datepicker/datepicker.component';
import moment from 'moment';
import { AdminBillingPaymentRestService } from '@clodeo/libs/core/rest/admin/billing-payment/admin-billing-payment-rest.service';

const authenticationService: AuthenticationService = new AuthenticationService;
const adminBillingPaymentRestService = new AdminBillingPaymentRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const { handleRequest } = new HandleService;
const notif = new Notifications();

export const BillingPaymentConfirmationComponent = (
  props: {
    data: IBillingPayment;
    onCancel: () => void;
    onFinish: () => void;
  }
) => {
  const aclService: AclService = new AclService;
  const [loading, setLoading] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const { data } = props
  const [form] = Form.useForm();
  const [attachment, setAttachment] = useState([]);
  const configUploadList = !aclService.can(['wallet.withdrawal_history.edit']) && {
    showRemoveIcon: false
  } || {};

  function onChangeUpload(item) {
    let { fileList } = item;
    fileList = fileList.map((file) => {
      if (file.status == 'done') {
        return file.response;
      }
    });
    form.setFieldsValue({
      attachments: fileList
    })
    const attachment = form.getFieldValue('attachments');
    if(attachment.length && attachment[0] !== undefined) {
      setIsDisable(false);
    }
  }

  function onSelectChange(value: any) {
    form.setFieldsValue({
      paymentDate: value !== null ? moment(value).format('YYYY-MM-DD') : moment(data.transactionDate).format('YYYY-MM-DD')
    })
  }

  function getAttachment(billingPaymentId) {
    const obs = adminBillingPaymentRestService.billingPaymentAttachment(billingPaymentId);
    handleRequest({
      obs,
      onError: () => {},
      onDone: (data) => {
        setAttachment(data);
      }
    });
  }

  useEffect(() => {
    getAttachment(data.id);
  }, []);

  function onApprove() {
    setLoading(true);
    const formValue = form.getFieldsValue(true);
    
    if (!data.isApproved && !formValue.note) {
      notif.show({
        type: "error",
        title: "Error",
        description: "Alasan ditolak wajib diisi.",
      });
      setLoading(false);
      return;
    }

    let attachments = [];

    // Get attachment from current data
    attachment.forEach((attach) => {
      const fileAttachment = {
        id: attach.attachmentId,
        isMain: attach.isMain,
        customFileName: attach.attachment.fileName
      }
      attachments.push(fileAttachment);
    })

    // Get attachment from formValue
    if (formValue.attachments && formValue.attachments.length) {
      formValue.attachments.forEach((attach, index) => {
        const fileAttachment = {
          id: attach.id,
          isMain: attachments.length == 0 && index == 0 ? true : false,
          customFileName: attach.fileName
        }
        attachments.push(fileAttachment);
      });
    }

    const payload = {
      tenantId: data.tenantId,
      note: formValue.note ? formValue.note : '',
      transactionDate: formValue.paymentDate,
      status: data.isApproved ? 'approved' : 'reject',
      attachments: attachments ? attachments : null,
      paymentMethod: 'BCA'
    }
    const obs = adminBillingPaymentRestService.updateBilling(data.id, payload)
    handleRequest({
      obs,
      successMessage: data.isApproved ? 'Berhasil menyetujui bukti pembayaran' : 'Berhasil menolak bukti pembayaran',
      onDone: () => {
        setLoading(false);
        props.onFinish()
        getAttachment(data.id);
      },
      onError: () => {
        props.onCancel();
      }
    })
  }

  useEffect(() => {
    if (data.isApproved && (!data.attachment && !form.getFieldValue('attachments'))) {
      setIsDisable(true)
    }
  }, []);

  return <Spinner spinning={loading} >
    <Form form={form} onFinish={onApprove}>
      <div className="container pt-3">
        <div className="row mb-3 chilbro">
          <div className="col-lg-12 title">
            {`Apakah Anda yakin untuk ${data.isApproved ? 'menyetujui ' : 'menolak'} tagihan ini ?`}
          </div>
        </div>
        <div className="row chilbro mb-2">
          <div className="label col-lg-4">Nama Os :</div>
          <div className="value col-lg-8">{data.sellerName || '-'}</div>
        </div>
        <div className="row chilbro mb-2">
          <div className="label col-lg-4">Jumlah tagihan :</div>
          <div className="value col-lg-8">{data.total}</div>
        </div>
        <div className="row mb-2">
          <div className="col-lg-12">
            <label className="field-label mb-2">Tanggal Pembayaran (opsional jika terjadi ketidak sesuaian)</label>
            <DatePicker onChange={onSelectChange} className="w-100" />
          </div>
        </div>
        {data.isApproved ? <>
          {/* <div className="row">
            <div className="col-lg-12">
              <label className="field-label mb-2">Total deposit (opsional)</label>
              <Form.Item className="mb-4" name="note">
                <Input id="note" type="number" />
              </Form.Item>
            </div>
          </div> */}
          <div className="row mb-2">
            <div className="col-lg-12">
              <label className="field-label mb-2">Upload Ulang Bukti Bayar</label>
              <div>
                <UploadTemplateList
                  buttonProps={{
                    className: 'pl-2 pr-3'
                  }}
                  showUploadList={configUploadList}
                  onChange={onChangeUpload}
                  baseUrl={`${environment.ENDPOINTS.API_FILE}/upload/admin`}
                  headers={{ 'Authorization': `Bearer ${authenticationService.user.access_token}` }}
                />
              </div>
            </div>
          </div>
        </> : <>
          <Form.Item shouldUpdate>
            <div className="row mb-2">
              <div className="col-lg-12">
                <label className="field-label mb-2">Alasan Ditolak</label>
                <Form.Item className="mb-4" name="note">
                  <Input id="note" maxLength={90} />
                </Form.Item>
              </div>
            </div>
          </Form.Item>
        </>}
        <div className="row p-2">
          <div className="col-lg-12 text-center">
            <Button type="ghosted" label="Batal" onClick={props.onCancel} className="mr-2" />
            <Button type="primary" label="Simpan" disabled={isDisable} onClick={onApprove} />
          </div>
        </div>
      </div>
    </Form>
  </Spinner>
}
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component'
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import React from 'react';
import { useState } from 'react';
import { ActivationAccountFormComponent } from '../form/activation-account-form.component';
import { UserRestService } from '@clodeo/libs/core/rest/account/users/user-rest.service';
const authenticationService: AuthenticationService = new AuthenticationService;
const usersRestService = new UserRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const { handleRequest } = new HandleService;

export const ActivationAccountUpdateComponent = (
  props: {
    data: IPayloadActivaionAccount;
    onCancel: () => void;
    onFinish: () => void;
  }
) => {
  const [loading, setLoading] = useState(false);
  const { data } = props;
  function onApprove() {
    setLoading(true);
    const obs = usersRestService.confirmAccount(data.id)
    handleRequest({
      obs,
      onDone: () => {
        setLoading(false);
        props.onFinish()
      },
      onError: () => {
        props.onCancel();
      }
    })
  }
  return <Spinner spinning={loading} >
    <ActivationAccountFormComponent data={props.data} onCancel={props.onCancel} onSubmit={onApprove} />
  </Spinner>
}
import React, { useState } from 'react';
import _ from 'lodash';
import { Form } from 'antd';
import { from, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { useHistory } from 'react-router-dom';

import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { Card } from '@clodeo/clodeo-ui/components/data-display/card/card.component';
import {
  Input,
  InputPassword,
} from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';

import ClodeoLogo from '../../../../../design-system-web/public/img/logo/clodeo/regular.svg';
import ClodeoIcon from '../../../../../design-system-web/public/img/logo/clodeo/icon.svg';

import { LoginService } from './login.service';
import './login.component.scss';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { AclService } from 'apps/admin-web/src/app/core/auth/acl.service';

import { UserOutlined } from '@ant-design/icons';
import { LoadingComponent } from '@clodeo/clodeo-ui/components/general/loading/loading.component';

const notifications: Notifications = new Notifications();
const loginService: LoginService = new LoginService();
const authService: AuthenticationService = new AuthenticationService();
const aclService: AclService = new AclService();
const LoginComponent = () => {
  const [loading, setLoading] = useState(false);
  const router = useHistory();

  // checking if user has loggedin
  if (authService.user) {
    router.push('/');
  }

  function onSubmit(values) {
    setLoading(true);
    authService
      .login(values)
      .pipe(
        catchError((err) => {
          const errorMessage =
            _.get(err.response, 'data.error_description', '') || err;
          notifications.show({
            type: 'error',
            title: 'Error',
            description: errorMessage,
            useService: true,
          });

          return throwError(err);
        }),
        finalize(() => setLoading(false))
      )
      // .subscribe((res) => router.push('/dashboard'));
      // enable this comment for redirect based roles
      .subscribe((res) => aclService.redirectAllowedMenu(router));
  }

  function cardHeader() {
    return (
      <div className="py-2 card-header-style">
        <img src={ClodeoLogo} className="d-flex mx-auto card-cover" />
      </div>
    );
  }

  return (
    <>
      <div className="login-wrapper">
        <Spinner spinning={loading} indicator={<LoadingComponent />}>
          <div className="card-login">
            <div className="row justify-content-lg-end justify-content-center">
              <img src={ClodeoLogo} alt="clodeo-icon" className="clodeo-logo img-fluid" />
              <img
                className="mascot-logo d-none d-lg-flex"
                src="../../../assets/img/hero-dashboard.svg"
                alt="img-hero-login"
              />
              <div className="col-lg-6 col-12">
                <div className="form-wrapper p-lg-5 p-4">
                  <Form name="login-form" onFinish={onSubmit}>
                    <label htmlFor="" className="title text-center text-lg-left">Login</label>
                    <div className="group">
                      <label htmlFor="">
                        Email
                      </label>
                      <Form.Item
                        className="mb-4"
                        name="username"
                        rules={loginService.form.rules['username']}
                      >
                        <Input size="large" id="username" />
                      </Form.Item>
                    </div>

                    <div className="group">
                      <label htmlFor="">Password</label>
                      <Form.Item
                        className="mb-4"
                        name="password"
                        rules={loginService.form.rules['password']}
                      >
                        <InputPassword size="large" type="password" id="password" />
                      </Form.Item>
                    </div>

                    <Form.Item className="pt-1">
                      <Button submit type="primary" label="Login" />
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </Spinner>
      </div>
    </>
  );
};

export { LoginComponent };

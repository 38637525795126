import React, { useEffect, useState } from 'react';
import { WidgetChart } from '@clodeo/clodeo-ui/components/chart/chart.component';
export const BillingPaymentSummaryComponent = (
  props: {
    billingPaymentsSummary: {
      totalPending: number,
      totalApproved: number,
      totalReject: number,
      total: number
    },
    onChangeFilter(val: any): void;
  }
) => {
  const { billingPaymentsSummary } = props;
  const [status, setStatus] = useState<string>(null);
  const [firstInit, setFirstInit] = useState<boolean>(true);

  useEffect(() => {
    if (!firstInit) {
      props.onChangeFilter && props.onChangeFilter({ summaryFilterType: 'payment', status: status })
    }
    setFirstInit(false);
  }, [status])

  return <>
    {/* payment summary */}
    {<div className="row w-100 p-2 m-auto">
      <div className="col-12 col-lg-3 p-2">
        <WidgetChart
          onClick={() => setStatus(null)}
          label="Total Pembayaran"
          value={billingPaymentsSummary.total}
          icon={<IconTotalBill />}
        />
      </div>
      <div className="col-12 col-lg-3 p-2">
        <WidgetChart
          onClick={() => setStatus('approved')}
          label="Total Pembayaran Lunas"
          value={billingPaymentsSummary.totalApproved}
          icon={<IconTotalBillPaid />}
        />
      </div>
      <div className="col-12 col-lg-3 p-2">
        <WidgetChart
          onClick={() => setStatus('reject')}
          label="Total Pembayaran Ditolak"
          value={billingPaymentsSummary.totalReject}
          icon={<IconTotalBillUnpaid />}
        />
      </div>
      <div className="col-12 col-lg-3 p-2">
        <WidgetChart
          onClick={() => setStatus('pending')}
          label="Total Pembayaran Pending"
          value={billingPaymentsSummary.totalPending}
          icon={<IconTotalBillUnpaid />}
        />
      </div>
    </div>}
  </>
}

const IconTotalBill = () => (
  <img src="https://nx-design-system-web-development.clodeo.com/img/admin-clodeo/bill/summary/total-bill.svg" alt="" srcSet="" />
)
const IconTotalBillPaid = () => (
  <img src="https://nx-design-system-web-development.clodeo.com/img/admin-clodeo/bill/summary/total-bill-paid.svg" alt="" srcSet="" />
)
const IconTotalBillUnpaid = () => (
  <img src="https://nx-design-system-web-development.clodeo.com/img/admin-clodeo/bill/summary/total-bill-unpaid.svg" alt="" srcSet="" />
)
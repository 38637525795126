import * as AccountingJS from 'accounting-js';
const acJS = AccountingJS;
acJS.settings.symbol = 'Rp ';
acJS.settings.decimal = ',';
acJS.settings.thousand = '.';
acJS.settings.precision = 0;

export class AccountingService {
  static ac: any = acJS;

  constructor() { }

  public roundMoney(m: number, decimal: number = 2): number {
    const factor = Math.pow(10, decimal);
    return Math.round(m * factor) / factor;
  }
}

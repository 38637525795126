import { FormInstance } from 'antd/lib/form';
import { Form } from 'antd';
import React from 'react';
import { Radio, RadioGroup } from '@clodeo/clodeo-ui/components/data-entry/radiobutton/radiobutton.component';

export const BillingMoreFilterComponent = (props?: { form?: FormInstance }) => {
  const value = [1, 3, 7]
  return <div className="d-flex flex-column">
    <label htmlFor="">Sisa Waktu Berlangganan</label>
    <Form.Item name="daysBeforeExpDate">
      <RadioGroup>
        {value.map(val => <Radio value={val}>{val + ' Hari'}</Radio>)}
      </RadioGroup>
    </Form.Item>
  </div>
}
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { LoadingComponent } from '@clodeo/clodeo-ui/components/general/loading/loading.component';
import { IEntryRelatedData, ITenantSubscription } from '@clodeo/libs/core/rest/admin/admin/admin';
import { AdminRestService } from '@clodeo/libs/core/rest/admin/admin/admin-rest.service';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { startCase, capitalize } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { BillingService } from '../billing.service';
import { BillingFormComponent } from '../form/billing-form.component';
import * as accountingJS from 'accounting-js';
import './upgrade-renewe.component.scss';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

const authenticationService: AuthenticationService = new AuthenticationService;
const adminRestService = new AdminRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const { normalizePayloadUpgradeRenew } = new BillingService;
const { handleRequest } = new HandleService();

export const UpgradeRenewComponent = () => {
  const [relatedData, setRelatedData] = useState<IEntryRelatedData>();
  const [tenant, setTenant] = useState<ITenantSubscription>();
  const [loading, setLoading] = useState(false);
  const router = useHistory();
  const { id } = useParams<{ id: string }>();

  function loadData() {
    setLoading(true);
    const obs = adminRestService.getTenantsSubscriptionsById(id).pipe(
      switchMap(result => {
        const normalizeMissmatchPackageWithImg = { ...result };

        if (normalizeMissmatchPackageWithImg.subscriptionPlanName === 'starter') {
          normalizeMissmatchPackageWithImg.subscriptionPlanName = 'starting';
        }

        if (normalizeMissmatchPackageWithImg.subscriptionPlanName === 'professional') {
          normalizeMissmatchPackageWithImg.subscriptionPlanName = 'profesional';
        }
        return of(normalizeMissmatchPackageWithImg);
      })
    );
    handleRequest({
      obs,
      handleTap: (result) => loadRelated(result?.clientAppId),
      onDone: (res) => { setTenant(res); setLoading(false) }
    })
  }

  function loadRelated(clientAppId: string) {
    const params = {
      clientAppId
    };
    const obs = adminRestService.getRelatedData({ params });
    handleRequest({
      obs,
      onDone: setRelatedData
    })
  }

  function onCancel() {
    router.push('/subscription/billing');
  }

  function onSubmit(val) {
    setLoading(true);
    const { type } = val;
    const payload = normalizePayloadUpgradeRenew(val);
    const obs = adminRestService.upgradeRenew(type, payload, id)
    handleRequest({
      obs,
      onError: () => {
        setLoading(false);
      },
      successMessage: type == 'renew' ? 'Berhasil Renew Tenant' : 'Berhasil Upgrade Tenant',
      onDone: () => {
        setLoading(false);
        router.push('/subscription/billing')
      }
    })
  }

  function displaySubscriptionDate(billingPeriodEndDate) {
    return <p>
      {moment(billingPeriodEndDate).format('dddd')}, <br />
      {moment(billingPeriodEndDate).format('DD MMMM YYYY')}
    </p>
  }

  useEffect(() => {
    loadData();
  }, [])

  return <Spinner spinning={loading} indicator={<LoadingComponent />}>
    <div className="container-lg">
      <div className="billing-detail row">
        <div className="col-lg-6 p-2">
          <div className="card-point">
            <div className="d-flex flex-row w-100 justify-content-between p-4 h-100">
              <div className="d-flex flex-column align-self-center">
                <div className="label">Company Name</div>
                <div className="big-value">{tenant?.companyInfo?.name}</div>
              </div>
              <div className="d-flex flex-column align-self-center">
                <div className="label">Penagihan</div>
                <div className="big-value">{tenant?.billingPeriodEndDate ? moment(tenant?.billingPeriodEndDate).format('DD MMMM YYYY') : '-'}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 p-2">
          <div className="card-point">
            <div className="d-flex flex-row w-100 justify-content-between p-4 h-100">
              <div className="d-flex flex-column align-self-center">
                <div className="label">Package</div>
                <div className="big-value">{startCase(tenant?.subscriptionPlanId)}</div>
              </div>
              <div className="d-flex flex-column align-self-center">
                <div className={`wrapper-icon ${tenant?.subscriptionPlanId} p-2`}>
                  <img src={`https://nx-design-system-web-development.clodeo.com/img/icon/subcription/${tenant?.subscriptionPlanId}.svg`} alt="" srcSet="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 p-2">
          <div className="card-point">
            <div className="d-flex w-100 p-4 h-50">
              <div className="d-flex w-50">
                <div className="w-25 label align-self-center">
                  Billing Cycle
                </div>
                <div className="w-50 extra-value align-self-center">
                  {tenant?.billingCycle && capitalize(tenant?.billingCycle)}
                </div>
              </div>
              <div className="d-flex w-50">
                <div className="w-25 label align-self-center">
                  Free Trial
                </div>
                <div className="w-50 value align-self-center">
                  {(tenant?.freeTrialEndDate && displaySubscriptionDate(tenant?.freeTrialEndDate)) || '-'}
                </div>
              </div>
            </div>
            <div className="d-flex w-100 p-4 strip h-50">
              <div className="d-flex w-50">
                <div className="w-25 label align-self-center">
                  Start Date
                </div>
                <div className="w-50 value align-self-center">
                  {(tenant?.billingPeriodStartDate && displaySubscriptionDate(tenant?.billingPeriodStartDate)) || '-'}
                </div>
              </div>
              <div className="d-flex w-50">
                <div className="w-25 label align-self-center">
                  End Date
                </div>
                <div className="w-50 value align-self-center">
                  {(tenant?.billingPeriodEndDate && displaySubscriptionDate(tenant?.billingPeriodEndDate)) || '-'}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 p-2">
          <div className="card-point">
            <div className="d-flex w-100 p-4 h-50">
              <div className="d-flex w-50">
                <div className="w-25 label align-self-center">
                  Total Penagihan
                </div>
                <div className="w-50 value align-self-center">
                  {tenant?.billingAmount && accountingJS.formatMoney(tenant?.billingAmount, { symbol: 'Rp ', format: '%s %v' })}
                </div>
              </div>
              <div className="d-flex w-50">
                <div className="w-25 label align-self-center">
                  Nomor Tenant
                </div>
                <div className="w-50 value align-self-center">
                  {tenant?.tenantId}
                </div>
              </div>
            </div>
            <div className="d-flex w-100 p-4 strip justify-content-between h-50">
              <div className="text-center">
                <div className="label align-self-center m-0">
                  Total Product
                </div>
                <div className="value align-self-center">
                  {`${tenant?.totalProducts} / ${tenant?.maxNumOfProducts || '--'}`}
                </div>
              </div>
              <div className="text-center">
                <div className="label align-self-center m-0">
                  Total User
                </div>
                <div className="value align-self-center m-0">
                  {`${tenant?.totalUsers} / ${tenant?.maxNumOfUsers || '--'}`}
                </div>
              </div>
              <div className="text-center">
                <div className="label align-self-center m-0">
                  Email Tenant
                </div>
                <div className="value align-self-center">
                  {tenant?.companyInfo?.email}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BillingFormComponent relatedData={relatedData} tenant={tenant} onSubmit={onSubmit} onCancel={onCancel} />
  </Spinner>

}

import React from 'react';
import footer from 'apps/design-system-web/public/img/logo/clodeo/regular.svg';
import './forbidden-access.component.scss';
import { ErrorComponent } from '@clodeo/clodeo-ui/components/page/error-page.component';

export class ForbiddenAccessComponent extends React.Component<any, any> {

  render() {
    return (
      <ErrorComponent title="403"/> 
    );
  }
}

import { RangePicker } from '@clodeo/clodeo-ui/components/data-entry/datepicker/datepicker.component';
import { Select, Option } from '@clodeo/clodeo-ui/components/data-entry/select/select.component';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import moment from 'moment';
import React from 'react';

const DebitCodFilter = (props: { form?: FormInstance<any> }) => {
  const { form } = props;
  const dateFormat = 'YYYY-MM-DD';
  const lowDate = moment().subtract(3, 'months');
  const highDate = moment(new Date);

  const StatusComponent = () => {
    const statuses = [
      { label: 'Open', value: 'open' },
      { label: 'Paid', value: 'paid' },
      { label: 'Draft', value: 'draft' },
      { label: 'Void', value: 'voided' }]
    return statuses.map(st => (
      <Option key={st.value} value={st.value}>{st.label}</Option>
    ))
  }

  function onChangeDateValues(dateValues) {
    let lowDate: string;
    let highDate: string;
    if (dateValues && dateValues.length) {
      lowDate = dateValues[0] ? moment(dateValues[0]).format('YYYY-MM-DD') : null;
      highDate = dateValues[1] ? moment(dateValues[1]).format('YYYY-MM-DD') : null;
    }
    form.setFieldsValue({ lowDate, highDate })
  }

  return (
    <>
      <div className="d-flex" id="debit-cod-more-filter">
        <div className="d-flex" id="range-picker-debit-cod-more-filter">
          <div className="d-flex flex-column">
            <label htmlFor="" className="mb-2">
              Tanggal
            </label>
            <Form.Item name="dateValues">
              <RangePicker defaultValue={[moment(lowDate, dateFormat), moment(highDate, dateFormat)]} onChange={onChangeDateValues} />
            </Form.Item>
          </div>

          <div className="d-flex ml-2" id="status-filter" style={{width: "250px"}}>
            <div className="d-flex flex-column w-100">
              <label htmlFor="" className="mb-2">
                Status
              </label>
              <Form.Item name="statuses">
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Pilih Status"
                  size="middle"
                  style={{ width: '100%' }}
                >
                  {StatusComponent()}
                </Select>
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { DebitCodFilter };

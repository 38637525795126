import React, { useRef, useState } from 'react';
import { environment } from 'apps/admin-web/src/environments/environment';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { ShippingIntegrationRestService } from '@clodeo/libs/core/rest/admin/shipping-integration/shipping-Integration-rest.service';
import { IColumn, IIncludes } from 'apps/admin-web/src/app/components/base-component/list/base-list';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import moment from 'moment';
import { pick } from 'lodash';
import { TrainingScheduleDetailComponent } from '../detail/trainig-schedule-detail.component';
import { TrainingScheduleMoreFilterComponent } from '../more-filter/training-schedule-more-filter.component';
import { BaseListComponent } from 'apps/admin-web/src/app/components/base-component/list/base-list.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { IModalValue } from '@clodeo/clodeo-ui/components/feedback/modal/modal';


export const TrainingScheduleListComponent = () => {
  const authenticationService: AuthenticationService = new AuthenticationService();
  const shippingIntegrationRestService = new ShippingIntegrationRestService(`${environment.ENDPOINTS.API_ADMIN}`, authenticationService.axiosInterceptors);
  const filters = ['keyword', 'options.take', 'options.skip', 'options.includeTotalCount', 'lowDate', 'highDate'];
  const baseListRef = useRef<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modalValue, setModalValue] = useState<IModalValue>();
  const [moreFilter, setMoreFilter] = useState({
    lowDate: null,
    highDate: null
  });

  const includes: IIncludes = {
    refresh: true
  };

  const columns: IColumn[] = [
    {
      key: '1',
      dataIndex: 'trainingDate',
      title: 'Jadwal Training',
      width: '150px',
      render: (val) => <p>{moment(val).format("DD MMMM YYYY")}</p>
    },
    {
      key: '2',
      dataIndex: 'quota',
      title: 'Kuota',
      width: '150px',
      align: 'center'
    },
    {
      key: '3',
      dataIndex: 'participantCount',
      title: 'Jumlah Tenant',
      width: '150px',
      align: 'center'
    },
    {
      key: '4',
      dataIndex: 'Aksi',
      title: 'Aksi',
      width: '150px',
      render: (val: any, record: any) => <Button type="tertiary" label="Detail" onClick={() => setModalValue({
        title: 'Detail',
        visible: true,
        data: null,
        contentTemplate: <TrainingScheduleDetailComponent record={record} />
      })} />
    },
  ];

  const loadDataObservable = (qParams) => {
    let params = pick(qParams, filters);
    params.lowDate = params.lowDate ? params.lowDate : moment().subtract(3, 'months').format('YYYY-MM-DD');
    params.highDate = params.highDate ? params.highDate : moment(new Date).format('YYYY-MM-DD');
    return shippingIntegrationRestService.getListTrainingSchedule({ params });
  };

  return (
    <>
      <Spinner spinning={isLoading}>
        <BaseListComponent
          listId="training-schedule-list"
          columns={columns}
          ref={baseListRef}
          loadDataObservable={loadDataObservable}
          includes={{
            ...includes,
            moreFilter: {
              template:
                <TrainingScheduleMoreFilterComponent />
            }
          }}
        />
      </Spinner>

      {
        modalValue &&
        <Modal
          {
          ...modalValue
          }
          footer={null}
          width="fit-content"
          className="admin modal-reponsive"
          onCancel={() => setModalValue(null)}
        > {modalValue.contentTemplate}
        </Modal>
      }
    </>
  )
}

export default TrainingScheduleListComponent;

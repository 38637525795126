import { Card } from '@clodeo/clodeo-ui/components/data-display/card/card.component';
import { Table } from 'antd';
import { IColumn } from 'apps/admin-web/src/app/components/base-component/list/base-list';
import moment from 'moment-timezone';
import React from 'react';

export const TrainingScheduleDetailComponent = (props) => {
  const { record } = props;
  const columns: IColumn[] = [
    {
      key: '1',
      title: 'Nama Perusahaan',
      dataIndex: 'companyName'
    },
    {
      key: '2',
      title: 'Nama Owner',
      dataIndex: 'idCardName'
    },
    {
      key: '3',
      title: 'Nomor Telpon',
      dataIndex: 'phoneNumber'
    },
    {
      key: '4',
      title: 'Email',
      dataIndex: 'email'
    },
  ];
  return (
    <div className="col-12">
      <Card className="mb-1">
        <div className="row">
          <div className="col-5">
            <b>Training Date</b>
          </div>
          <div className="col-auto">
            <b> : </b>
          </div>
          <div className="col-auto">
            {moment(record.trainingDate).format('DD MMMM YYYY')}
          </div>
        </div>
      </Card>
      <Card className="mb-1">
        <div className="row">
          <div className="col-5">
            <b>Status</b>
          </div>
          <div className="col-auto">
            <b> : </b>
          </div>
          <div className="col-auto">
            {record.inactive ? 'Tidak Aktif' : 'Aktif'}
          </div>
        </div>
      </Card>
      <div className="row mt-4">
        <div className="col-12">
          <Table
            columns={columns}
            dataSource={record.companyInfos}
            scroll={{ y: 350 }}
          />
        </div>
      </div>
    </div>
  );
};

import React from 'react';
import { Option } from '@clodeo/clodeo-ui/components/data-entry/autocomplete/autocomplete.component';
import { Select } from '@clodeo/clodeo-ui/components/data-entry/select/select.component';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';

const RequestWithdrawFundsFilter = (props: { form?: FormInstance<any> }) => {
  const { form } = props;

  const StatusComponent = () => {
    const statuses = [
      { label: 'Outstanding Invoice', value: 'unpaid_billing' },
      { label: 'Dibawah 10.000', value: 'low_balance' },
      { label: 'Dapat dicairkan', value: 'ready_to_debit' }
    ];

    return statuses.map(status => (
      <Option key={status.value} value={status.value}>{status.label}</Option>
    ));
  }

  return (
    <>
      <div className="d-flex" id="request-withdraw-funds-more-filter">
        <div className="mb-2">
          <div className="d-flex flex-column">
            <label htmlFor="" className="mb-2">Status</label>
            <Form.Item name="billingAndBalanceStatus">
              <Select
                allowClear
                size="middle"
                placeholder="Pilih Status"
                style={{ width: "300px" }}
              >
                {StatusComponent()}
              </Select>
            </Form.Item>
          </div>
        </div>
      </div>
    </>
  )
}

export { RequestWithdrawFundsFilter }
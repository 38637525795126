import React, { useRef, useState, useEffect } from 'react';

import { BaseListComponent } from 'apps/admin-web/src/app/components/base-component/list/base-list.component';
import { UsersCreateComponent } from '../create/users-create.component';

import { compact } from 'lodash';
import { AclService } from 'apps/admin-web/src/app/core/auth/acl.service';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';

import { Switch } from '@clodeo/clodeo-ui/components/data-entry/switch/switch.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';

import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { AccountAdminRestServices } from '@clodeo/libs/core/rest/account/admin/user/account-admin-user.rest.service';
import { AccessRolesRestService } from '@clodeo/libs/core/rest/admin/acces-roles/access-roles-rest.service';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { UsersUpdateComponent } from '../update/users-update.component';
import { UsersResetPasswordComponent } from '../reset-password/user-reset-password.component';
import { IColumn, IIncludes } from '../../../../../components/base-component/list/base-list';
import { IModalValue } from '@clodeo/clodeo-ui/components/feedback/modal/modal';

// Extra configs

export const UsersListComponent = () => {
  const { handleRequest } = new HandleService;
  const aclService = new AclService;
  const authenticationService = new AuthenticationService;
  const accountAdminRestServices = new AccountAdminRestServices(`${environment.ENDPOINTS.API_ACCOUNT}`, authenticationService.axiosInterceptors);
  const accessRolesRestServices = new AccessRolesRestService(environment.ENDPOINTS.API_ADMIN, authenticationService.axiosInterceptors)

  const [modalValue, setModalValue] = useState<IModalValue>()
  const [roles, setRoles] = useState();
  const [relatedData, setRelatedData] = useState();
  const baseListRef = useRef<any>();

  const includes: IIncludes = {
    refresh: true,
    keyword: true,
    toogleOptions: true,
    extraButtons: compact([
      aclService.can(['admin.user_admin.create']) &&
      {
        label: ' USER',
        type: 'filter',
        iconName: 'deo-plus',
        action: () => setModalValue({
          title: 'TAMBAH USER',
          data: null,
          visible: true,
          contentTemplate:
            <UsersCreateComponent
              roles={roles}
              relatedData={relatedData}
              onClose={() => setModalValue(null)}
              onFinish={() => onFinishCreate()}
            />
        }),
      }
    ])
  }

  let columns: IColumn[] = compact([
    { key: '1', dataIndex: 'fullName', title: 'NAMA', width: '150px' },
    { key: '2', dataIndex: 'email', title: 'EMAIL', width: '150px' },
    { key: '3', dataIndex: 'nationalPhoneNumber', title: 'NO.TELP',  width: '150px', render: data => data ? data : '-' },
    { key: '4', dataIndex: 'accessRoleAdmin', title: 'ROLES',  width: '200px', render: data => data.map(item => item.roleName).join(', ') },
    aclService.can(['admin.user_admin.edit']) &&
    {
      key: '5',
      dataIndex: 'status',
      width: '100px',
      title: 'STATUS',
      align: 'center',
      render: (val, record) => <Switch checked={val == 'active'} onChange={(val) => markActiveUser(record.id, val)} />
    },
    aclService.can(['admin.user_admin.edit']) && {
      key: '6',
      dataIndex: 'id',
      title: 'SETTING',
      width: '100px',
      align: 'center',
      render: (val, record) => <Button iconName="i-GearSix" type="ghosted" onClick={() => showUpdateUser(record)} className="mr-2 p-2" />
    },
    aclService.can(['admin.user_admin.edit']) &&
    {
      key: '7',
      dataIndex: 'id',
      align: 'center',
      width: '100px',
      title: 'PASSWORD',
      render: (val) => <Button iconName="i-LockKeyOpen" type="warning" className="p-2" onClick={() => showResetPassword(val)} />
    },
  ]);

  function showUpdateUser(record) {
    setModalValue({
      title: 'UPDATE USER', data: null, visible: true, contentTemplate:
        <UsersUpdateComponent
          data={record}
          roles={roles}
          relatedData={relatedData}
          onClose={() => setModalValue(null)}
          onFinish={() => onFinishCreate()}
        />
    })
  }

  function showResetPassword(id) {
    setModalValue({
      title: 'RESET PASSWORD',
      data: null,
      visible: true,
      contentTemplate:
        <UsersResetPasswordComponent
          id={id}
          onFinish={() => {
            setModalValue(null);
            baseListRef.current.callLoadData();
          }}
          onClose={() => setModalValue(null)}
        />
    })
  }

  function markActiveUser(id, active) {
    const obs = accountAdminRestServices.markActiveUser(id, active)
    handleRequest({
      obs,
      successMessage: `Berhasil ${active ? 'mengaktifkan' : 'menonaktifkan'} user`,
      onDone: () => baseListRef.current.callLoadData()
    })
  }

  function onFinishCreate() {
    setModalValue(null);
    baseListRef.current.callLoadData();
  }

  function loadDataObservable(params) {
    return accountAdminRestServices.findAll({ params: params });
  }

  function loadAccessRoles() {
    const obs = accessRolesRestServices.findAll({})
    handleRequest({
      obs,
      onDone: (res) => res && setRoles(res.data)
    })
  }

  function loadRelatedData() {
    return accountAdminRestServices.entryRelatedData().subscribe((response: any) => {
      setRelatedData(response);
    })
  }

  useEffect(() => {
    aclService.can(['admin.user_admin.create', 'admin.user_admin.edit']) && loadAccessRoles();
    aclService.can(['admin.user_admin.create', 'admin.user_admin.edit']) && loadRelatedData();
  }, [])

  return (
    <>
      <BaseListComponent
        listId="users-list"
        ref={baseListRef}
        columns={columns}
        loadDataObservable={loadDataObservable}
        includes={{...includes}}
      />

      {
        modalValue && <Modal
          {...modalValue}
          footer={null}
          width="fit-content"
          onCancel={() => setModalValue(null)}
          className="admin"
        >
          {modalValue.contentTemplate}
        </Modal>
      }
    </>
  )
}

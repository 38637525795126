import { IColumn } from 'apps/admin-web/src/app/components/base-component/list/base-list';
import { BaseRfListComponent } from 'apps/admin-web/src/app/components/base-component/list/base-rf-list.component';
import { AuthenticationService } from '../../../../core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { NotificationsRestService } from '@clodeo/libs/core/rest/addon/notifications/notifications-rest.service';
import React, { useState, useRef } from 'react';
import { Observable } from 'rxjs';
import './admin-notifications-list.component.scss';
import moment from 'moment';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';

const handleService: HandleService = new HandleService();
const authenticationService: AuthenticationService = new AuthenticationService();
const notificationRestService = new NotificationsRestService(
  `${environment.ENDPOINTS.API_ADDON}`,
  authenticationService.axiosInterceptors
);
export const AdminNotificationComponent = (props: {
  isSummary: boolean
}) => {
  const iconsStatus = {
    syncProductSalesChannelerror: 'linear-gradient(132.97deg, #3FD3A7 3.3%, #40B793 100%)',
    newCodCreditinfo: 'linear-gradient(132.97deg, #FFAA60 3.3%, #FF582B 100%)',
  };
  const [onload, setOnload] = useState(false);
  const baseListRef = useRef<any>();
  const columns: IColumn[] = [
    {
      key: '1',
      title: '',
      render: (val, rec) => {
        return (
          listNotif(rec)
        );
      }
    }
  ]

  const listNotif = (rec) => {
    const date = moment(convertUtctoLocalTimezone(new Date(rec.notificationDateTime)));

    return <div className="col-12">
      <div className={"d-flex flex-row justify-content-between list-notifications list-notifications " + (!rec?.isReadByUser && 'unread')}
        onClick={() => {
          !rec?.isReadByUser && markOneAsRead(rec?.id)
          setOnload(true)
        }}
      >
        <div className="flex-row">
          <p className="title align-self-center my-0">{rec?.title || '-'}</p>
          {!rec?.isReadByUser && !props.isSummary && <p className="mark-as-read align-self-center ml-2 my-0">Tandai sudah dibaca</p>}
        </div>
        <div className="time align-self-center ml-2 my-0">
          {checkingIsWithinWeek(date) ? date.fromNow() : date.format('MMM D, YYYY, H:mm:ss A')}
        </div>
      </div>
    </div>
  }

  function markOneAsRead(id: string) {
    const obs = notificationRestService.markOneAsRead(id);
    handleService.handleRequest({
      obs,
      onDone: () => {
        setOnload(true);
        setOnload(false);
      }
    })
  }

  const loadDataObservable = (params: any): Observable<any> => {
    const newParams = {
      keyword: null,
      includeTotalCount: params['options.includeTotalCount'],
      skip: params['options.skip'],
      take: params['options.take'],
    }

    return notificationRestService.findAllNotifications(newParams)
  };

  function checkingIsWithinWeek(date) {
    const aWeekOld = moment().clone().subtract(8, 'days').startOf('day');

    return moment(date).isAfter(aWeekOld);
  }

  function convertUtctoLocalTimezone(dateUtc) {
    const offset = (new Date().getTimezoneOffset() * -1);
    dateUtc.setMinutes(dateUtc.getMinutes() + offset);
    return dateUtc;
  }

  return (
    <BaseRfListComponent
      listId="notification-list" // ID Wajib diisi
      scroll={{ x: true }}
      columns={columns}
      className="hide-header"
      onLoad={onload}
      hidePaginations={props.isSummary}
      loadDataRfObservable={loadDataObservable}
      includes={{
      }}
    />
  )
}
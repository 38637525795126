import { UpdateNoteComponent } from '@clodeo/clodeo-ui/components/base/update-note/update-note.component';
import { Badge } from '@clodeo/clodeo-ui/components/data-display/badge/badge.component';
import { Tooltip } from '@clodeo/clodeo-ui/components/data-display/tooltip/tooltip.component';
import { AutoComplete, Option } from '@clodeo/clodeo-ui/components/data-entry/autocomplete/autocomplete.component';
import { InputNumber } from '@clodeo/clodeo-ui/components/data-entry/inputnumber/inputnumber.component';
import { UploadTemplateList } from '@clodeo/clodeo-ui/components/data-entry/upload/upload.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { Action } from '@clodeo/clodeo-ui/components/general/action/action.component';
import { Dropdown } from '@clodeo/clodeo-ui/components/navigation/dropdown/dropdown.component';
import { Item, Menu } from '@clodeo/clodeo-ui/components/navigation/menu/menu.component';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { AdminPickerRestService } from '@clodeo/libs/core/rest/admin/picker/admin-picker-rest.service';
import * as accountingJS from 'accounting-js';
import { AclService } from 'apps/admin-web/src/app/core/auth/acl.service';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { remove } from 'lodash';
import React, { useEffect, useState } from 'react';
import './request-withdraw-funds-list.component.scss';
const aclService: AclService = new AclService;
const authenticationService: AuthenticationService = new AuthenticationService;
const adminPickerRestService = new AdminPickerRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);

export const RequestWithdrawFundsTemplateListComponent = (props: {
  data?: IRequestWithdrawFunds;
  tenantBankList: any[];
  required: boolean;
  onChangeLine: (tenantId, line) => void;
}) => {
  const { data, tenantBankList, onChangeLine, required } = props;
  const [showDot, setShowDot] = useState<boolean>(false);
  const [fileAmount, setFileAmount] = useState<number>(0);
  const [bankOptions, setBankOptions] = useState<any[]>();
  const [line, setLine] = useState<IRequestWithdraw>();
  const [optionLoading, setOptionLoading] = useState<boolean>(false);
  const { handleRequest } = new HandleService;

  function onRemoveFile(id) {
    const fileList = [...line.attachments];
    remove(fileList, function (file) { return file.id == id });
    setLine({
      ...line,
      attachments: fileList
    });
  }

  const fileList = (
    <Menu className="file-action">
      {
        (line && line.attachments) && line.attachments.map((attach) => (
          <Item>
            <div className="d-flex justify-content-between">
              <div className="mr-3 align-self-center">
                {attach?.fileName}
              </div>
              <Action type="delete" onClick={() => onRemoveFile(attach.id)} />
            </div>
          </Item>
        ))
      }
    </Menu>
  );


  function patchValue() {
    setLine({
      ...line,
      amount: data.balanceAmount,
      tenantId: data.tenantId,
    })
  }

  function onUpload(val) {
    if (val.file.status === 'done') {
      const fileRes = [...(line?.attachments || []), val.file.response];
      setLine({
        ...line,
        attachments: fileRes
      })
    }
  }

  function handleChangeAccount(val, opt) {
    let selectedBank = {
      tenantBankId: null,
      acountNumber: null
    };
    if (val !== undefined) {
      bankOptions && bankOptions.forEach(bank => {
        if ((bank.id == opt.key)) {
          selectedBank = {
            tenantBankId: bank.id,
            acountNumber: bank.accountNumber
          }
        }
      });
    }
    setLine({
      ...line,
      ...selectedBank
    })
  }

  function getTenantBank(payload) {
    setOptionLoading(true);
    const params = {
      tenantId: data.tenantId,
    };

    const body = {
      tenantId: data.tenantId,
      ...payload
    };
    const obs = adminPickerRestService.getTenantBank(body, params);

    handleRequest({
      obs,
      onError: () => setOptionLoading(false),
      onDone: (res) => {
        setBankOptions(res.data);
        setOptionLoading(false);
      }
    })
  }


  let onSearchTenantDelay = null;
  function onSearchTenant(value: string) {
    if (!value) return
    clearTimeout(onSearchTenantDelay);

    onSearchTenantDelay = setTimeout(() => {
      const payload = {
        keyword: value,
      }
      getTenantBank(payload);
    }, 750);
  }


  useEffect(() => {
    if (line) {
      line.tenantId = data.tenantId;
      line.selected = required;
      line.valid = required && (line.amount && line.tenantBankId) ? true : false;
      onChangeLine(data.tenantId, line)
      if (line.attachments && (line.attachments.length > 0)) {
        setShowDot(true);
        setFileAmount(line.attachments.length);
      } else {
        setShowDot(false);
        setFileAmount(0);
      }
    }
  }, line && [line.tenantBankId, line.amount, line.attachments, line.note, line.isDraft, required])

  useEffect(() => {
    data && patchValue();
  }, [data])

  useEffect(() => {
    if (tenantBankList) {
      const tenantOption = [];
      tenantBankList && tenantBankList.forEach(tenantBank => {
        if (tenantBank.tenant.id == data.tenantId) {
          tenantBank.displayName = `${tenantBank.accountName} - ${tenantBank.bank.bankName} - ${tenantBank.accountNumber}`
          tenantOption.push(tenantBank)
        }
        setBankOptions(tenantOption);
      });
    }
  }, [tenantBankList]);

  return (
    <div>
      <Tooltip title={data.isLowBalance === true ? 'Nominal pencairan kurang dari 10.000' : data.unpaidBilling === true ? 'Tagihan invoice lebih besar dari total pencairan' : '' } placement="topLeft">
        <div className="list-wrapper request-whitdraw" key={data.tenantId}>
          <div className="d-flex flex-row flex-wrap justify-content-between">

            <div className="d-flex flex-column justify-content-between w-res-13">
              <div className="d-flex flex-row justify-content-between">
                <div className="label">Perusahaan</div>
                <div className="value normal" title={data.companyName}>{data.companyName}</div>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <div className="label">Industri</div>
                <div className="value" title={data.industry}>{data.industry}</div>
              </div>
            </div>

            {aclService.can(['wallet.withdrawal_history.edit', 'wallet.withdrawal.edit']) &&
              <div className="d-flex flex-column justify-content-between w-res-17">
                <div className="d-flex flex-row justify-content-between">
                  <div className="label field-required">Akun Bank</div>
                  {(line && aclService.can(['wallet.withdrawal_history.edit', 'wallet.withdrawal.edit'])) &&
                    <div className={`value normal ${required && 'ant-form-item-has-error'}`}>
                      <Spinner spinning={optionLoading}>

                        <AutoComplete
                          disabled={!required}
                          onChange={handleChangeAccount}
                          filterOption
                          onDropdownVisibleChange={(open) => { open ? getTenantBank('') : null; } }
                          onSearch={onSearchTenant}
                          dropdownClassName='cs-drop'
                        >
                          {(bankOptions && bankOptions.length) ? bankOptions.map(tenantBank => (
                            <Option key={tenantBank.id} value={tenantBank.accountNumber}>{tenantBank.accountName + ' - ' + tenantBank.bankName + ' - ' + tenantBank.accountNumber}</Option>
                          ))
                            :
                            <Option value={null}>Tidak Ada Data</Option>}
                        </AutoComplete>
                      </Spinner>
                    </div>}
                </div>
                <div className="d-flex flex-row justify-content-between">
                  <div className="label">No. Rek</div>
                  {line && <div className="value w-110px" title={line.acountNumber}>{line.acountNumber || ''}</div>}
                </div>
              </div>}

            <div className="d-flex flex-column justify-content-between w-res-15">
              <div className="d-flex flex-row justify-content-between">
                <div className="label">Fullname</div>
                <div className="value" title={data.fullName}>
                  {data.fullName}
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <div className="label">Username</div>
                <div className="value" title={data.username}>{data.username}</div>
              </div>
            </div>

            <div className="d-flex flex-column justify-content-between w-res-16">
              <div className="d-flex flex-row justify-content-between">
                <div className="label">Email</div>
                <div className="value" title={data.userEmail}>
                  {data.userEmail}
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <div className="label">Phone</div>
                <div className="value" title={data.phone}>{data.phone}</div>
              </div>
            </div>

            <div className="d-flex flex-column justify-content-between w-res-15">
              <div className="d-flex flex-row justify-content-between">
                <div className="label field-required">Total Bayar</div>
                {(line && aclService.can(['wallet.withdrawal_history.edit', 'wallet.withdrawal.edit'])) &&
                  <div className={`value normal ${(required && !line.amount) && 'ant-form-item-has-error'}`}>
                    <InputNumber disabled={!required} value={line.amount || 0} onChange={(val) => setLine({ ...line, amount: val as number })} />
                  </div>}
              </div>
              <div className="d-flex flex-row justify-content-between">
                <div className="label">Balance</div>
                <div className="value" title={accountingJS.formatMoney(data.balanceAmount || 0, { symbol: 'Rp ', format: '%s %v' })}>
                  {accountingJS.formatMoney(data.balanceAmount || 0, { symbol: 'Rp ', format: '%s %v' })}
                </div>
              </div>
            </div>

            <div className="d-flex flex-column justify-content-between w-res-4">
              <div className="d-flex flex-column justify-content-start">
                <div className="label text-center action m-lg-0">Note</div>
                {(line && aclService.can(['wallet.withdrawal_history.edit', 'wallet.withdrawal.edit'])) &&
                  <div className="p-1 text-center heu">
                    <UpdateNoteComponent hideEdit={!aclService.can(['wallet.withdrawal_history.edit', 'wallet.withdrawal.edit'])} buttonProps={{
                      disabled: !required
                    }} note={line.note || ''} onSubmit={(val) => setLine({ ...line, note: val })} />
                  </div>}
              </div>
            </div>

            <div className="d-flex flex-column justify-content-between w-res-4">
              <div className="d-flex flex-column justify-content-start">
                <div className="label text-center action m-lg-0">File</div>
                <div className="p-1 text-center heu">
                  <Dropdown overlay={fileList} placement="bottomCenter" trigger={['hover']}>
                    <Badge className="file-amount" count={fileAmount}>
                      <UploadTemplateList
                        disabled={!aclService.can(['wallet.withdrawal_history.edit', 'wallet.withdrawal.edit'])}
                        onChange={onUpload}
                        showUploadList={false}
                        showLoadingButton
                        buttonProps={{
                          type: 'ghosted',
                          // disabled: !required,
                          disabled: !aclService.can(['wallet.withdrawal_history.edit', 'wallet.withdrawal.edit']), iconName: 'deo-upload',
                          label: '',
                          className: `${fileAmount && 'active'} p-2`
                        }} baseUrl={`${environment.ENDPOINTS.API_FILE}/upload/admin`} headers={{ 'Authorization': `Bearer ${authenticationService.user.access_token}` }} />
                    </Badge>
                  </Dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Tooltip>
    </div>
  )
}
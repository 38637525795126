import React from 'react';
import { Switch as BaseSwitch } from 'antd';
import { SwitchProps } from 'antd/lib/switch';

const Switch = (props: SwitchProps) => (
  <BaseSwitch
    {...props}
    className={'inputswitch-deo ' + (props.className || '')}
  />
)

export {
  Switch
}
import { RequestService } from '../../request.service';

export class TenantSalesOrderRestService {
  requestService = new RequestService;
  request: RequestService;
  /**
   * 
   * @param baseUrl from environment.ENDPOINTS
   * @param useInterceptor 
   * @param withoutInterceptor 
   */
  constructor(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
    this.request = this.requestService.new(`${baseUrl['API_ADMIN']}/admin/tenant-sales-order`, useInterceptor, withoutInterceptor);
  }

  /**
   * Get list of Request Cancel Pickup Transaction
   * @param qParams 
   * @returns 
   */
  findRequestAll(qParams) {
    return this.request.get('requests', qParams);
  }


  /**
   * Get summary of Request Cancel Pickup Transaction
   * @param qParams 
   * @returns 
   */
  getRequestSummary(qParams) {
    return this.request.get<any>('requests-summary', qParams);
  }

  /**
   * Update Status Request Cancel Pickup Transaction
   * @param id 
   * @param payload 
   * @returns 
   */
  updateStatusRequestCancel(id: string, payload: any) {
    return this.request.post(id, payload);
  }
}

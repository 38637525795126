import { Card } from '@clodeo/clodeo-ui/components/data-display/card/card.component'
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { LoadingComponent } from '@clodeo/clodeo-ui/components/general/loading/loading.component';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { AdminMonitoringPickupRestService } from '@clodeo/libs/core/rest/admin/monitoring-pickup/admin-monitoring-pickup-rest.service';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { startCase } from 'lodash'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'

const authenticationService: AuthenticationService = new AuthenticationService;
const adminMonitoringPickupRestService = new AdminMonitoringPickupRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const { handleRequest } = new HandleService;
export const MonitoringPickupDetailComponent = (props: { id: string }) => {
  const [historyAWB, setHistoryAWB] = useState<any[]>()
  const [loading, setLoading] = useState(false);
  function loadHistory() {
    const obs = adminMonitoringPickupRestService.getWaybillHistory(props.id)
    handleRequest({
      obs,
      onDone: (res) => setHistoryAWB(res)
    })
  }
  useEffect(() => {
    loadHistory()
  }, [props.id])
  return (
    <Spinner spinning={loading} indicator={<LoadingComponent />}>
      {historyAWB && historyAWB.length === 0
        ?
        (
          <p className="text-center">
            <b>Tidak Ada Data</b>
          </p>
        )
        :
        (
          <div className="col-12">
            {historyAWB && historyAWB.map((item) => (
              <>
                <Card className="mb-1">
                  <div className="row">
                    <div className="col-5"><b>No Resi</b></div>
                    <div className="col-auto"><b> : </b></div>
                    <div className="col-auto">{item.airwaybillNumber}</div>
                  </div>
                </Card>
                <Card className="mb-1">
                  <div className="row">
                    <div className="col-5"><b>Status Pengiriman</b></div>
                    <div className="col-auto"><b> : </b></div>
                    <div className="col-auto">{startCase(item.status)}</div>
                  </div>
                </Card>
                <Card>
                  <div className="row">
                    <div className="col-5"><b>Update Terakhir</b></div>
                    <div className="col-auto"><b> : </b></div>
                    <div className="col-auto">
                      {
                        moment(
                          item.lastUpdate,
                          "YYYY-MM-DD hh:mm"
                        ).format("DD-MM-YYYY hh:mm") || "-"}
                    </div>
                  </div>
                </Card>
              </>
            ))}
          </div>
        )
      }
    </Spinner>
  )
}

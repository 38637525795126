import { Icon } from '@clodeo/clodeo-ui/components/foundations/icon/icon.component';
import { Menu } from '@clodeo/clodeo-ui/components/navigation/menu/menu.component';
import { Layout } from 'antd';
import { compact } from 'lodash';
import React, { useEffect, useState } from 'react';
import { SiderLayoutProps } from './sider-layout';
import './sider-layout.component.scss';

const { Sider } = Layout;
export const SiderLayoutComponent = (
  props: SiderLayoutProps
) => {
  const [collapsed, setCollapsed] = useState(false);
  const [isLg, setIsLg] = useState(false);
  const [width, setWidth] = useState<number | string>('auto');
  const [src, setSrc] = useState('');

  useEffect(() => {
    if (collapsed) {
      // setSrc('/img/logo/clodeo/icon.svg');
      setWidth('0');
    } else {
      // setSrc('/img/logo/clodeo/regular.svg');
      setWidth('auto');
    }
    props.compact ? setSrc('/img/logo/clodeo/icon.svg') : setSrc('/img/logo/clodeo/regular.svg');
    // setSrc('/img/logo/clodeo/icon.svg')
    props.onCollapseChanges && props.onCollapseChanges(collapsed);
  }, [collapsed, props.compact]);

  return <Sider
    width={width}
    collapsedWidth={'0'}
    collapsed={collapsed}
    className="sider-admin-deo"
    breakpoint="lg"
    onCollapse={setCollapsed}
    onBreakpoint={broken => {
      if (broken) {
        setCollapsed(true);
        setIsLg(false);
      } else {
        setCollapsed(false);
        setIsLg(true);
      }
    }}
  >
    {
      props.logo ||
      <div className={"logo p-3 " + (props.compact && 'compact')}>
        <img src={"https://nx-design-system-web-development.clodeo.com/" + src} alt="clodeo admin" srcSet="" />
      </div>
    }
    {props.menu || ''}
  </Sider>
}
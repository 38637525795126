import { RequestService } from '../../request.service';
// import { ICoupon } from "./cod-balance.d";
// export { ICoupon };

export class DebitCODRestService {
  requestService = new RequestService;
  request: RequestService;
  // requestAddon: RequestService;
  constructor(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
    this.request = this.requestService.new(`${baseUrl['API_ADMIN']}/debit-cod`, useInterceptor, withoutInterceptor);
    // this.request = this.requestService.new(baseUrl, useInterceptor, withoutInterceptor);
  }

  /**
   * Get list of COD Debit Transaction
   * @param qParams
   */
  findAll(qParams, statuses = 'draft, voided, paid') {
    return this.request.get(`integrated?statuses=${statuses}`, qParams);
  }

  /**
   * Get list of COD Debit Transaction
   * @param qParams
   */
  findAllAlternatif(qParams) {
    return this.request.get(``, qParams);
  }

  /**
   * Get list of COD Debit Transaction New Endpoint History
   * @param qParams
   */
   findHistory(qParams) {
    return this.request.get(`/history`, qParams);
  }

  /**
   * Update COD Debit Transaction
   * @param id
   * @param payload
   */
  postUploadProof(id, payload) {
    return this.request.post(`/${id}`, payload);
  }

  /**
   * Update COD Debit Transaction
   * @param id
   * @param payload
   */
  updateDebitCod(id, payload) {
    return this.request.put(`/${id}`, payload);
  }

  /**
   * Upload Batch Debit COD
   * @param payload
   * @returns true
   */
  uploadBatchDebitCOD(payload: IRequestWithdraw[]) {
    return this.request.post(`batch`, payload);
  }

  requestExcelData(payload) {
    return this.request.post(`/simple-batch-validate`, payload);
  }

  simpleBatch(payload) {
    return this.request.post(`simple-batch`, payload);
  }

}

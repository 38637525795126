import React from 'react';
import { Form } from 'antd';
import { IEntryRelatedData } from '@clodeo/libs/core/rest/admin/admin/admin';
import { AutoComplete, Option } from "@clodeo/clodeo-ui/components/data-entry/autocomplete/autocomplete.component";

export const MonitoringPickupMoreFilterComponent = (
  props: {
    relatedData: IEntryRelatedData,
    values?: any,
    form? // form props is generated by baseList so set to optional props
  }) => {
  const { form, relatedData } = props;
  const options = relatedData?.shippingCourierDetails || [];

  function onSelectCourier(key, option) {
    form.setFieldsValue({
      shippingCourierChannelId: option.key,
    });
  }

  function onSearchCourier() {
    form.setFieldsValue({
      shippingCourierChannelId: null
    });
  }

  return (
    <div className="row">
      <div className="col-lg-12" style={{ width: "270px" }}>
        <div className="form-group">
          <label htmlFor="">Kurir</label>
          <Form.Item name="shippingCourierChannel">
            <AutoComplete
              size="large"
              onSelect={onSelectCourier}
              onSearch={onSearchCourier}
            >
              {options.map(courier => (
                <Option key={courier.code} value={courier.name}>
                  {courier.name}
                </Option>
              ))}
            </AutoComplete>
          </Form.Item>
        </div>
      </div>
    </div>
  )
}

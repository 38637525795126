import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { Popover } from '../../data-display/popover/popover.component';
import { InputTextArea } from '../../data-entry/inputtext/inputtext.component';
import { Modal } from '../../feedback/modal/modal.component';
import { Button } from '../../ui-elements/button/button.component';
import { UpdateNoteProps } from './update-note';

export const UpdateNoteComponent = (props: UpdateNoteProps) => {
  const { note, title, onSubmit, modalClassName, hideEdit, buttonProps } = props;
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      value: note
    })
  }, [note])
  const contentTemplate = () => (
    <Form
      onFinish={onSubmit}
      form={form}
    >
      <Form.Item name='value'>
        <InputTextArea rows={5} className="shadow-none" size="large" placeholder={title || 'Catatan'} />
      </Form.Item>
      <div className="w-100 m-auto text-center pt-3">
        <Button label="Batal" type="ghosted" className="mr-2" onClick={() => setVisible(false)} />
        <Button label="Simpan" type="primary" onClick={() => { onSubmit(form.getFieldValue('value')); setVisible(false) }} />
      </div>
    </Form>
  )

  return <>
    <Popover placement="top" title={false} trigger="hover" content={<p className="note-content m-0">{note || 'Tidak Ada Catatan'}</p>}>
      <a className="btn btn-deo-ghosted btn-deo-md p-2" onClick={() => !hideEdit && setVisible(true)}>
        <i className="icon-i-ChatCenteredText icon-14px"></i>
      </a>
    </Popover>

    <Modal
      visible={visible}
      footer={null}
      title="Catatan"
      onCancel={() => setVisible(false)}
      className={modalClassName || 'admin'}
    >
      {contentTemplate()}
    </Modal>
  </>
}
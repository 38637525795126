import React, { useEffect, useRef, useState } from 'react';
import { compact, includes, filter } from 'lodash';
import { IColumn, IIncludes } from 'apps/admin-web/src/app/components/base-component/list/base-list';
import { AccessRolesRestService } from '@clodeo/libs/core/rest/admin/acces-roles/access-roles-rest.service';
import { AccessRoleGroupsRestService } from '@clodeo/libs/core/rest/admin/access-role-groups/access-role-groups-rest.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';

import { BaseListComponent } from 'apps/admin-web/src/app/components/base-component/list/base-list.component';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { UserService } from '../../users-service';
import { UserRolesCreateComponent } from '../create/user-roles-create.component';
import { UserRolesUpdateComponent } from '../update/user-roles-update.component';
import { AclService } from 'apps/admin-web/src/app/core/auth/acl.service';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';

const aclService = new AclService;
const authenticationService: AuthenticationService = new AuthenticationService;
const accessRolesRestService = new AccessRolesRestService(`${environment.ENDPOINTS.API_ADMIN}`, authenticationService.axiosInterceptors);
const accessRoleGroupsRestService = new AccessRoleGroupsRestService(`${environment.ENDPOINTS.API_ADMIN}`, authenticationService.axiosInterceptors);
const userService = new UserService;
const { handleRequest } = new HandleService;

export const UserRolesListComponent: React.FC = () => {
  const [relatedData, setRelated] = useState<any>();
  const [roleGroups, setRoleGroups] = useState<any[]>();
  const [modalValue, setModalValue] = useState<{
    id?: string;
    type: 'create' | 'update';
    visible: boolean;
    title: string;
    record?: any
  }>()

  const baseListRef = useRef<any>();

  const include: IIncludes = {
    refresh: true,
    keyword: true,
    toogleOptions: true,
    extraButtons: compact([
      aclService.can(['admin.role_admin.create']) &&
      {
        label: 'ROLES',
        iconName: 'deo-plus',
        type: 'filter',
        action: () => setModalValue({
          id: '',
          type: "create",
          visible: true,
          title: 'TAMBAH ROLE',
          record: null
        })
      }
    ])
  }

  const columns: IColumn[] = compact([
    {
      key: '1',
      dataIndex: 'name',
      width: '150px',
      title: 'NAMA'
    },
    {
      key: '2',
      title: 'PERMISSION',
      width: '300px',
      dataIndex: 'permissionRoles',
      render: (permissionRole) => {
        const permissionList = permissionRole.length > 0
          ?
          permissionRole.map(item => item.permission.description)
          :
          ['-']
        return <>{permissionList.join(', ')}</>
      }
    },
    {
      key: '3',
      dataIndex: 'description',
      title: 'DESKRIPSI',
      width: '200px',
      render: (val) => {
        if (val == null || val == '' || val == undefined) {
          return (
            <span>-</span>
          );
        } else {
          return val
        }
      }
    },
    aclService.can(['admin.role_admin.edit']) &&
    {
      key: '4',
      dataIndex: 'id',
      width: '60px',
      title: 'EDIT',
      align: 'center',
      render: (val, record) => <Button iconName="i-PencilLine" type="warning" className="p-2" onClick={() => setModalValue({
        id: val,
        record,
        type: 'update',
        visible: true,
        title: 'UPDATE ROLE ' + (record.name || '')
      })} />
    }
  ]);

  useEffect(() => {
    loadRelated();
    loadRoleGroup();
  }, []);


  function loadDataObservable(params) {
    return accessRolesRestService.findAll({ params })
  }

  function loadRelated() {
    const obs = accessRolesRestService.loadRelated();
    userService.requestService({
      errorMessage: '',
      successMessage: '',
      obs,
      onDone: (response) => {
        const ignorePermissions = [
          // cod list
          'cod.cod_list.view',
          'cod.cod_list.create',
          'cod.cod_list.edit',
          'cod.cod_list.delete',
          // transaction list
          'tenant.transaction.view',
          'tenant.transaction.create',
          'tenant.transaction.edit',
          'tenant.transaction.delete',

          // cod
          'cod.transfer_credit.update',
          'cod.transfer_credit.edit',
          'cod.transfer_credit.delete',

          // tenant_bank
          'wallet.tenant_bank.delete',

          // tenant_wallet
          'wallet.tenant_wallet.delete',

          // withdrawal
          'wallet.withdrawal.create',
          'wallet.withdrawal.delete',

          // withdrawal_history
          'wallet.withdrawal_history.create',
          'wallet.withdrawal_history.delete',

          // tenant_list
          'tenant.tenant_list.create',
          'tenant.tenant_list.delete',

          // pickup_monitoring
          'tenant.pickup_monitoring.create',
          'tenant.pickup_monitoring.edit',
          'tenant.pickup_monitoring.delete',

          // cancel_pickup
          'tenant.cancel_pickup.create',
          'tenant.cancel_pickup.delete',

          // request_pickup
          'tenant.request_pickup.create',
          'tenant.request_pickup.delete',

          // user_admin
          'admin.user_admin.delete',

          // role_admin
          'admin.role_admin.delete',

          // permission_admin
          'admin.permission_admin.create',
          'admin.permission_admin.edit',
          'admin.permission_admin.delete',

          // general_coupon
          'tenant.general_coupon.delete',

          // subscription
          'tenant.subscription.create',
          'tenant.subscription.delete',

          // sales_invoice
          'tenant.sales_invoice.create',
          'tenant.sales_invoice.delete',
          'tenant.sales_invoice.edit',
          'tenant.sales_invoice.view',
        ];
        response.accessPermissions = filter(response.accessPermissions, permission => {

          return !includes(ignorePermissions, permission.id);
        });
        setRelated(response);
      }
    })
  };

  function loadRoleGroup() {
    const obs = accessRoleGroupsRestService.findAll();
    handleRequest({
      obs,
      onDone: (res: any) => { setRoleGroups(res.data) }
    })
  }
  return (
    <>
      <BaseListComponent
        listId="user-roles-list"
        ref={baseListRef}
        columns={columns}
        className="admin"
        loadDataObservable={loadDataObservable}
        includes={{
          ...include
        }}
      />
      {
        modalValue &&
        <Modal
          {
          ...modalValue
          }
          className="admin"
          title={modalValue.title}
          onCancel={() => setModalValue(null)}
          footer={null}
        >
          {
            modalValue.type == 'create' &&
            <UserRolesCreateComponent
              onFinish={() => {
                setModalValue(null);
                baseListRef.current.callLoadData();
              }}
              onCancel={() => setModalValue(null)}
              accessPermissions={relatedData && relatedData.accessPermissions}
              roleGroups={roleGroups}
              
            />
          }
          {
            modalValue.type == 'update' &&
            <UserRolesUpdateComponent
              data={modalValue.record}
              onFinish={() => {
                setModalValue(null);
                baseListRef.current.callLoadData();
              }}
              onCancel={() => setModalValue(null)}
              accessPermissions={relatedData && relatedData.accessPermissions}
              roleGroups={roleGroups}
            />
          }
        </Modal>
      }
    </>
  );
}

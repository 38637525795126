import React, { useEffect, useState } from 'react';
import './verify.component.scss'
import { snakeCase, find, forEach } from 'lodash'

import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { ShippingIntegrationRestService } from '@clodeo/libs/core/rest/admin/shipping-integration/shipping-Integration-rest.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';

import { Form } from 'antd';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { LoadingComponent } from '@clodeo/clodeo-ui/components/general/loading/loading.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { Confirms } from '@clodeo/clodeo-ui/components/feedback/confirm/confirm.component';
import { Checkbox } from '@clodeo/clodeo-ui/components/data-entry/checkbox/checkbox.component';
import { Input } from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';
import { IModalValue } from '@clodeo/clodeo-ui/components/feedback/modal/modal';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { Image } from "@clodeo/clodeo-ui/components/data-display/image/image.component";
import { HandleService } from '@clodeo/libs/core/handle/handle.service';

const notif = new Notifications;
const authenticationService: AuthenticationService = new AuthenticationService();
const shippingIntegrationRestService = new ShippingIntegrationRestService(`${environment.ENDPOINTS.API_ADMIN}`, authenticationService.axiosInterceptors);
const { handleRequest } = new HandleService;

export const VerifyComponent = (props: IVerifyProps) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const [form] = Form.useForm();
  const [modalValue, setModalValue] = useState<IModalValue>();

  const confirm: Confirms = new Confirms;
  const fileType = [
    { id: 'id_card_image_id', label: 'KTP', rejectedReason: 'Foto KTP tidak jelas' },
    { id: 'id_card_image_selfie_id', label: 'Selfie & KTP', rejectedReason: 'Foto selfie + KTP tidak jelas' },
    { id: 'npwp_image_id', label: 'NPWP', rejectedReason: 'Nama di NPWP tidak sesuai dengan KTP' },
    { id: 'signature_image_id', label: 'Tanda Tangan', rejectedReason: 'Tanda tangan berbeda dengan KTP' },
    { id: 'saving_book_id', label: 'Buku Tabungan', rejectedReason: 'Nama di Buku Tabungan tidak sesuai dengan KTP' }
  ]
  const onCancel = () => {
    form.resetFields();
    setModalValue(null);
  }

  function loadTenantShippingIntegration() {
    setLoading(true);
    const obs = shippingIntegrationRestService.getSingleTenantShippingIntegration(props.tenantId)
    handleRequest({
      obs,
      onDone: (res) => { setData(res); setLoading(false) }
    })
  }

  function headerSetup(id) {
    const header = find(fileType, ['id', id]);
    return header.label
  }

  function formValid() {
    const formValue = form.getFieldsValue(true);
    for (const property in formValue) {
      if (formValue[property]) {
        return true
      }
    }
  }

  function showRejectDialog() {
    return setModalValue({
      title: 'ALASAN DATA DITOLAK',
      visible: true,
      data: null,
      contentTemplate:
        <>
          <Form form={form} initialValues={{
            id_card_image_id: false,
            id_card_image_selfie_id: false,
            npwp_image_id: false,
            saving_book_id: false,
            signature_image_id: false,
            rejectedReason: ""
          }}>
            <Form.Item shouldUpdate>
              {() => {
                return <>
                  <div className="row px-4 f12">
                    <div className="col-lg-6 pb-2">
                      <div className="row align-items-center">
                        <Form.Item name="id_card_image_id" valuePropName="checked" className="pr-1">
                          <Checkbox variant="inline-label">Foto KTP tidak jelas</Checkbox>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-6 pb-2">
                      <div className="row align-items-center">
                        <Form.Item name="id_card_image_selfie_id" valuePropName="checked" className="pr-1">
                          <Checkbox variant="inline-label">Foto selfie + KTP tidak jelas</Checkbox>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-6 pb-2">
                      <div className="row align-items-center">
                        <Form.Item name="npwp_image_id" valuePropName="checked" className="pr-1">
                          <Checkbox variant="inline-label">Nama di NPWP tidak sesuai dengan KTP</Checkbox>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-6 pb-2">
                      <div className="row align-items-center">
                        <Form.Item name="signature_image_id" valuePropName="checked" className="pr-1">
                          <Checkbox variant="inline-label">Tanda tangan berbeda dengan KTP</Checkbox>
                        </Form.Item>
                      </div>
                    </div>
                    <div className="col-lg-6 pb-2">
                      <div className="row align-items-center">
                        <Form.Item name="saving_book_id" valuePropName="checked" className="pr-1">
                          <Checkbox variant="inline-label">Nama di Buku Tabungan tidak sesuai dengan KTP</Checkbox>
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                  <div className="form-group px-2">
                    <label>Lainnya</label>
                    <Form.Item name="rejectedReason">
                      <Input id="rejectedReason" />
                    </Form.Item>
                  </div>
                  <div className="text-center">
                    <Button type="ghosted" label="Cancel" className="mr-3" onClick={() => onCancel()} />
                    <Button submit type="primary" label="Simpan" onClick={() => { validateForm() }} />
                  </div>
                </>
              }}
            </Form.Item>
          </Form>
        </>
    })
  }

  function validateForm() {
    if (formValid()) {
      requestVerify(false)
    } else {
      notif.show({
        type: 'warning',
        title: 'Peringatan',
        description: 'Mohon berikan alasan tenant ditolak !',
        useService: true
      })
    }
  }

  function showVerifyDialod() {
    confirm.show({
      title: 'KONFIRMASI DATA',
      content: <p>Apakah anda yakin untuk mem-verifikasi data tenant ?</p>,
      onOk() { requestVerify(true) }
    });
  }

  function requestVerify(isVerifyData: boolean) {
    setLoading(true);
    const tenantId = data.tenantShippingIntegration.tenantId;
    const formValue = form.getFieldsValue(true);
    const payload = normalizeVerifyPayload(data, formValue, isVerifyData);
    const obs = shippingIntegrationRestService.verifyRequest(tenantId, payload)
    handleRequest({
      obs,
      successMessage: 'Data berhasil disimpan.',
      onDone: props.onFinish
    })
    setLoading(false)
  }

  function normalizeVerifyPayload(data: any, formValue: any, isVerifyData: boolean) {
    const payload = {
      isApproved: isVerifyData,
      rejectedReason: isVerifyData ? '' : form.getFieldValue('rejectedReason'),
      infos: []
    };

    forEach(data.tenantShippingIntegration.tenantShippingIntegrationInfos, (value, key) => {
      const infoValue = {
        id: snakeCase(value.id),
        isApproved: true,
        rejectedReason: ''
      }
      if (!isVerifyData) {
        const dataType = find(fileType, ['id', value.id])
        infoValue.isApproved = !formValue[value.id];
        infoValue.rejectedReason = formValue[value.id] ? dataType.rejectedReason : '';
      }
      payload.infos.push(infoValue);
    });
    return payload
  }

  useEffect(() => {
    loadTenantShippingIntegration()
  }, [props.tenantId])

  return (
    <Spinner spinning={loading} indicator={<LoadingComponent />}>
      <div className="container-fluid content-wrapper">
        {data && <div className="row">
          <div className="col-lg-5">
            <div className="bg-wrapper">
              <div className="row">
                <div className="col-lg-6 p-2">
                  <div className="form-group">
                    <label className="label-content">Nama</label>
                    <p className="data">{data.tenantShippingIntegration.idCardName}</p>
                  </div>
                </div>
                <div className="col-lg-6 p-2">
                  <div className="form-group">
                    <label className="label-content">NIK</label>
                    <p className="data">{data.tenantShippingIntegration.idCardNumber}</p>
                  </div>
                </div>
                <div className="col-lg-6 p-2">
                  <div className="form-group">
                    <label className="label-content">Bank</label>
                    <p className="data">{data.tenantShippingIntegration.tenantBank.bank.bankName}</p>
                  </div>
                </div>
                <div className="col-lg-6 p-2">
                  <div className="form-group">
                    <label className="label-content">Nomor Rekening</label>
                    <p className="data">{data.tenantShippingIntegration.tenantBank.accountNumber}</p>
                  </div>
                </div>
                <div className="col-lg-6 p-2">
                  <div className="form-group">
                    <label className="label-content">Nama Nasabah</label>
                    <p className="data">{data.tenantShippingIntegration.tenantBank.accountName}</p>
                  </div>
                </div>
                <div className="col-lg-6 p-2">
                  <div className="form-group">
                    <label className="label-content">Cabang Kota/Kabupaten</label>
                    <p className="data">{data.tenantShippingIntegration.tenantBank.district}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-7">
            {data.tenantShippingIntegration.tenantShippingIntegrationInfos.length ? (
              <>
                <div className="row">
                  {data && data.tenantShippingIntegration.tenantShippingIntegrationInfos.map((item) => (
                    <>
                      <div className="col-lg-4 text-center pb-3">
                        <span>{headerSetup(item.id)}</span>
                        <div className="pt-2">
                          <Image.PreviewGroup>
                            <Image
                              src={item.file.fileUrl}
                              width="120px"
                              height="120px"
                              style={{ borderRadius: "20px", cursor: "pointer" }}
                            ></Image>
                          </Image.PreviewGroup>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </>
            ) : (
              <>
                <p className="text-center">
                  <b>Tidak Ada Data</b>
                </p>
              </>
            )
            }
          </div>
        </div >
        }
      </div>
      <div className="row justify-content-between">
        <div>
          <Button type="ghosted" label="Cancel" className="ml-3" onClick={() => props.onClose()} />
        </div>
        <div>
          <Button type="warning" label="Tolak" className="mr-3" onClick={() => showRejectDialog()} />
          <Button submit type="primary" label="Simpan" className="mr-3" onClick={() => showVerifyDialod()} />
        </div>
      </div>
      {
        modalValue && <Modal
          {...modalValue}
          footer={null}
          width="fit-content"
          onCancel={() => setModalValue(null)}
          className="admin reject-modal"
        >
          {modalValue.contentTemplate}
        </Modal>
      }
    </Spinner >
  )
}
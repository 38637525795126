import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Form } from 'antd';
import { RangePicker } from '@clodeo/clodeo-ui/components/data-entry/datepicker/datepicker.component';

export const TrainingScheduleMoreFilterComponent = (
  props: {
    values?: any,
    form?: any
  }
) => {
  const { form } = props;
  const [dateValues, setDateValues] = useState<any>(null);
  const dateFormat = 'YYYY-MM-DD';
  const lowDate = moment().subtract(3, 'months');
  const highDate = moment(new Date);

  useEffect(() => {
    /**
     * Declare variable
     */
    let lowDate: string;
    let highDate: string;

    /**
     * Assign value to new variable
     */
    if (dateValues && dateValues.length) {
      /**
     * set low and high date from dateValues
     */
      let [low, high] = dateValues;
      lowDate = low ? moment(low).format('YYYY-MM-DD') : null;
      highDate = high ? moment(high).format('YYYY-MM-DD') : null;
      form.setFieldsValue({ lowDate: lowDate, highDate: highDate });
    }

  }, [dateValues]);

  return <div className="d-flex" id="tenant-more-filter">
    <Form.Item name="shippingCourierChannelId">
      <div className="d-flex flex-column">
        <label htmlFor="" className="mb-2">Tanggal Training</label>
        <RangePicker defaultValue={[ moment(lowDate, dateFormat), moment(highDate, dateFormat) ]} onChange={setDateValues} />
      </div>
    </Form.Item>
  </div>
}

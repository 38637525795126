import { IExcelDataHeaders } from '@clodeo/libs/core/excel/excel.service';

export class RequestWithDrawFundUploadService {
  get form() {
    return {
      initialValues: {
        transactionDate: null,
        rows: []
      },
      rules: {
        transactionDate: { required: true, message: 'Kolom ini wajib diisi' },
        file: { required: true, message: 'File wajib diisi' }
      }
    };
  }

  get headers(): IExcelDataHeaders[] {
    return[{
      name: "tenantId",
      header: ["tenant id", "ID tenant"],
      title: "Tenant Id",
      valueType: "number",
      validation: {
        required: true
      }
    }, {
      name: "companyName",
      header: ["nama os", "Nama OS"],
      title: "OS Name",
      valueType: "string",
    }, {
      name: "amount",
      header: "total transfer",
      title: "Total Balance Yang Ditransfer",
      valueType: "number",
    }, {
      name: "bankAccountNumber",
      header: "No Rekening",
      title: "No Rekening",
      valueType: "string",
    }, {
      name: "notes",
      header: "Notes",
      title: "Notes",
      valueType: "string",
    },
  ]}
}
import React, { useState } from 'react';
import { Upload } from 'antd';
import { UploadTemplateListProps } from './upload';
import { Button } from '../../ui-elements/button/button.component';
import { UploadChangeParam } from 'antd/lib/upload';
import { Spinner } from '../../feedback/spinner/spinner.component';
import { LoadingOutlined } from '@ant-design/icons';
import { Messages } from '../../feedback/messages/messages.component';
const LoadingIcon = <LoadingOutlined spin rev='' />;

const messages = new Messages;
const UploadTemplateList = (props: UploadTemplateListProps) => {
  const [loading, setLoading] = useState(false);
  function onChange(info: UploadChangeParam) {
    props.showLoadingButton && setLoading(true);
    if (info.file.status == 'done') {
      messages.show({
        type: "success",
        content: "Upload Complete"
      });
      props.showLoadingButton && setLoading(false);
    } else if (info.file.status == 'error') {
      messages.show({
        type: "error",
        content: "Upload Failed"
      });
      props.showLoadingButton && setLoading(false);
    }
    props.onChange ? props.onChange(info) : '';
  }

  return (
    <>
    {props.withoutAction && (
      <Upload
        showUploadList={{
          removeIcon: <RemoveIcon />,
        }}
        {...props}
        beforeUpload={() => false}
        // customRequest={(options) => {
        //   const { onSuccess } = options;
        //   onSuccess("ok", null);
        // }}
        className="upload-template-list-deo"
        onChange={onChange}
      >
        <Spinner spinning={loading} indicator={LoadingIcon}>
          <Button label="Upload" iconName="deo-attachment" iconPosition="left" type="ghosted" {...props.buttonProps} />
        </Spinner>
      </Upload>
    )}
    {!props.withoutAction && (
      <Upload
        showUploadList={{
          removeIcon: <RemoveIcon />,
        }}
        {...props}
        action={props.baseUrl || props.action || ''}
        className="upload-template-list-deo"
        onChange={onChange}
      >
        <Spinner spinning={loading} indicator={LoadingIcon}>
          <Button label="Upload" iconName="deo-attachment" iconPosition="left" type="ghosted" {...props.buttonProps} />
        </Spinner>
      </Upload>
    )}
    </>
  )
}

const RemoveIcon = () => (
  <div className="remove-icon icon-deo-close">
  </div>
);

export {
  Upload,
  UploadTemplateList
}

export class LoginService {
  get form() {
    const required = [{ required: true, message: 'Kolom ini wajib diisi' }];
    return {
      rules: {
        username: required,
        password: required
      }
    };
  }
}

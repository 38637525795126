import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { AdminNotificationComponent } from '../list/admin-notifications-list.component';
import '../list/admin-notifications-list.component.scss';

import { AuthenticationService } from '../../../../core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { NotificationsRestService } from '@clodeo/libs/core/rest/addon/notifications/notifications-rest.service';

const authenticationService: AuthenticationService = new AuthenticationService();
const notificationRestService = new NotificationsRestService(
  `${environment.ENDPOINTS.API_ADDON}`,
  authenticationService.axiosInterceptors
);
// const [isLoading, setIsLoading] = useState<boolean>(false);

export const AdminNotificationsSummary = () => {
  function readAll() {
    notificationRestService
      .markAllAsRead()
      .subscribe();
  }
  function toggleRefresh() {
    notificationRestService.findAll().subscribe();
  }

  return (
    <div className="d-flex flex-column">
      <div className="d-flex mb-2">
        <Button
          type="ghosted"
          label="Tandai telah dibaca semua"
          onClick={() => readAll()}
        />
        <div className="d-flex col pl-5">
          <div className="px-1 py-1 col pl-5">
            <Button
              id="admin-notif-refresh"
              icon
              iconName="deo-refresh"
              type="filter"
              className="p-2 text-center"
              onClick={() => toggleRefresh()}
            />
          </div>
        </div>
      </div>
      <div className="notif-summary notif-max-width">
        <AdminNotificationComponent isSummary />
      </div>
      <div className="w-100 m-auto text-center see-all py-2">
        <NavLink to="/notifications">Lihat Semua</NavLink>
      </div>
    </div>
  );
};

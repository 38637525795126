import React, { useState } from 'react';

import { Input } from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';
import { Table } from '@clodeo/clodeo-ui/components/data-display/table/table.component';

import { Form } from 'antd';
import './users-form.component.scss';
import { IUsersFormProps } from './users-form';
import { useEffect } from 'react';

export const UsersFormComponent = (props: IUsersFormProps) => {
  const [akunDepartement, setAkunDepartement] = useState();
  const columns = [
    { key: '1', title: 'Nama', dataIndex: 'name' },
    { key: '2', title: 'Description', dataIndex: 'description' }
  ];
  const { selectedRoles, roles, form, onSelectedRoles, type, relatedData, userDepartement } = props;
  const required = [{ required: true, message: 'Kolom ini wajib diisi!' }];

  useEffect(() => {
    const akunDepartements = relatedData.roleGroups.map(roleGroup => {
      return roleGroup.name
    })
    setAkunDepartement(akunDepartements.join(', '));
  }, [])

  return (
    <div className="container-fluid form-wrapper py-2 h-100">
      <div className="d-flex flex-column flex-lg-row mb-2 h-100">
        <div className="first-form">
          <div className="mb-2">
            <label htmlFor="" className="col-12 label">Nama Pengguna</label>
            <div className="col-12">
              <Form.Item
                name="userName"
                rules={required}
              >
                <Input type="text" />
              </Form.Item>
            </div>
          </div>
          <div className="mb-2">
            <label htmlFor="" className="col-12 label">Nama Awal</label>
            <div className="col-12">
              <Form.Item
                name="firstName"
                rules={required}
              >
                <Input type="text" />
              </Form.Item>
            </div>
          </div>
          <div className="mb-2">
            <label htmlFor="" className="col-12 label">Nama Akhir</label>
            <div className="col-12">
              <Form.Item
                name="lastName"
                rules={required}
              >
                <Input type="text" />
              </Form.Item>
            </div>
          </div>
        </div>
        <div className="second-form">
          <div className="mb-2">
            <label htmlFor="" className="col-12 label">No Telepon</label>
            <div className="col-12">
              <Form.Item
                name="nationalPhoneNumber"
                rules={required}
              >
                <Input type="text" />
              </Form.Item>
            </div>
          </div>
          <div className="mb-2">
            <label htmlFor="" className="col-12 label">Email</label>
            <div className="col-12">
              <Form.Item
                name="email"
                rules={required}
              >
                <Input type="text" />
              </Form.Item>
            </div>
          </div>
          {type !== 'update' && <div className="mb-2">
            <label htmlFor="" className="col-12 label">Password</label>
            <div className="col-12">
              <Form.Item
                name="password"
                rules={required}
              >
                <Input type="text" readOnly={true} />
              </Form.Item>
            </div>
          </div>}
          {type == 'update' && <div className="mb-2">
            <label htmlFor="" className="col-12 label">Departement</label>
            <div className="col-12">{userDepartement}</div>
          </div>}
        </div>
      </div>
      {type !== 'update' && <div className="mb-2">
        <label htmlFor="" className="col-12 label">Departement</label>
        <div className="col-12">{akunDepartement}</div>
      </div>}
      <div className="d-flex table-wrapper">
        <Table
          rowKey="id"
          scroll={{ y: '30vh' }}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: selectedRoles || [],
            onChange: onSelectedRoles
          }}
          columns={columns}
          dataSource={roles || []}
        />
      </div>
    </div >
  )
}

import React from 'react';
import { Alert as BaseAlert } from 'antd';
import { AlertProps } from 'antd/lib/alert';

const Alert = (props: AlertProps) => (
  <BaseAlert {
    ...props
  }
    showIcon={props.showIcon || true}
    closable={props.closable || true}
    className={'alert-deo ' + 'alert-deo-' + (props.type || 'success ') + (props.className || '')}
  />

)

export {
  Alert
}
import React, { useState, useEffect } from 'react';

import { Input, InputTextArea } from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';
import { Option as OptionSelect, Select } from '@clodeo/clodeo-ui/components/data-entry/select/select.component';
import { DatePicker } from '@clodeo/clodeo-ui/components/data-entry/datepicker/datepicker.component';

import { Form } from 'antd';
import moment from 'moment';
import { pick, find, remove } from 'lodash';
import { IBillingPaymentFormProps } from './billing-payment-form';
import { AutoComplete, Option } from '@clodeo/clodeo-ui/components/data-entry/autocomplete/autocomplete.component';
import { environment } from 'apps/admin-web/src/environments/environment';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { AdminPickerRestService } from '@clodeo/libs/core/rest/admin/picker/admin-picker-rest.service';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { AdminBillingTransactionRestService } from '@clodeo/libs/core/rest/admin/billing-transaction/admin-billing-transaction-rest.service';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { UploadTemplateList } from '@clodeo/clodeo-ui/components/data-entry/upload/upload.component';
import { Badge } from '@clodeo/clodeo-ui/components/data-display/badge/badge.component';
import { Menu, Item } from '@clodeo/clodeo-ui/components/navigation/menu/menu.component';
import { Action } from '@clodeo/clodeo-ui/components/general/action/action.component';
import { Image } from "@clodeo/clodeo-ui/components/data-display/image/image.component";
import { IModalValue } from '@clodeo/clodeo-ui/components/feedback/modal/modal';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import * as AccountingJS from 'accounting-js';

const authenticationService: AuthenticationService = new AuthenticationService();
const adminPickerRestService = new AdminPickerRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const adminBillingTransactionRestService = new AdminBillingTransactionRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);

export const BillingPaymentFormComponent = (props: IBillingPaymentFormProps) => {
  const { handleRequest } = new HandleService;
  const { onCancel, onSubmit, form, type } = props;
  const required = [{ required: true, message: 'Kolom ini wajib diisi!' }];

  const [tenantList, setTenantList] = useState<any[]>();
  const [tenantInvoiceList, setTenantInvoiceList] = useState<any[]>();
  const [loadingInvoice, setLoadingInvoice] = useState<boolean>(false);
  const [loadingBalance, setLoadingBalance] = useState<boolean>(false);
  const [totalBalanceDue, setTotalBalanceDue] = useState<any>();
  const [fileAmount, setFileAmount] = useState<number>(0);
  const [line, setLine] = useState<any>();
  const [isMaxLimit, setIsMaxLimit] = useState<boolean>(false);
  const [modalValue, setModalValue] = useState<IModalValue>()
  const [walletBalance, setWalletBalance] = useState();

  function loadTenant(query) {
    const obs = adminPickerRestService.getTenant({}, { keyword: query });
    handleRequest({
      obs,
      onDone: (res: any) => setTenantList(res.data)
    })
  }

  function loadListInvoice() {
    setLoadingInvoice(true)
    const qParams = { tenantId: form.getFieldValue('tenantId'), isPaymentPicker: true, take: 500}
    const obs = adminBillingTransactionRestService.findAll({ params: qParams })
    handleRequest({
      obs,
      onDone: (res) => { setTenantInvoiceList(res.data); setLoadingInvoice(false); }
    })
  }

  function onSelectTenantId(opt: any) {
    form.setFieldsValue({ 'tenantId': opt.key, 'invoiceNumber': [] });
    loadListInvoice();
    tenantWalletBalance(opt.key);
  }

  function onChangePaymentDate(value: any) {
    form.setFieldsValue({
      paymentDate: moment(value).format('YYYY-MM-DD')
    })
  }

  function onChangeInvoice(val, opt) {
    let balanceDue = 0
    let invoices = [];
    val.forEach(invoiceNumber => {
      const invoice = pick(find(tenantInvoiceList, ['id', invoiceNumber]), ['balanceDue', 'id', 'transactionNumber']);

      balanceDue = balanceDue + invoice.balanceDue;
      invoices.push(invoice);
    });
    setTotalBalanceDue(balanceDue);
    form.setFieldsValue({ 'totalBalanceDue': balanceDue, 'invoices': invoices })
  }

  function tenantWalletBalance(tenantId) {
    setLoadingBalance(true);
    const obs = adminBillingTransactionRestService.getWalletBalance(tenantId)
    handleRequest({
      obs,
      onDone: (res) => {
        setWalletBalance(res.balance);
        setLoadingBalance(false);
      }
    })
  }

  function onPaymentChange(val, opt) {
    form.setFieldsValue({ 'paymentType': opt.key });
  }

  const fileList = (
    <Menu className="file-action">
      {
        (line && line.attachments) && line.attachments.map((attach) => (
          <Item>
            <div className="d-flex justify-content-between">
              <div className="align-self-center text-truncate" onClick={() => showAttachments(attach)}>
                {attach?.fileName}
              </div>
              <Action type="delete" className="align-self-center" size="sm" onClick={() => onRemoveFile(attach.id)} />
            </div>
          </Item>
        ))
      }
    </Menu>
  );

  function showAttachments(attach) {
    return setModalValue({
      title: 'Image priview',
      data: null,
      visible: true,
      contentTemplate:
        <Image width={500} src={attach.fileUrl} />
    })
  }

  function onRemoveFile(id) {
    const fileList = [...line.attachments];
    remove(fileList, function (file) { return file.id == id });
    setLine({
      ...line,
      attachments: fileList
    });
    form.setFieldsValue({ 'attachments': line.attachments })
  }

  function onUpload(val) {
    if (val.file.status === 'done') {
      const fileRes = [...(line?.attachments || []), val.file.response];
      setLine({
        ...line,
        attachments: fileRes
      })
    }
  }

  useEffect(() => {
    if (line && line.attachments) {
      setFileAmount(line.attachments.length);
      setIsMaxLimit(line.attachments.length === 4 ? true : false);
      form.setFieldsValue({ 'attachments': line.attachments })
    }
  }, [(line)]);

  function onSubmitForm(val?) {
    const values = {
      ...val,
      ...form.getFieldsValue(true)
    };
    onSubmit && onSubmit(values);
  }

  return (
    <>
      <Form
        form={form}
        onFinish={onSubmitForm}
      >
        <div className="container-fluid py-2 h-100">
          <div className="d-flex flex-column flex-lg-row mb-2 h-100">
            <div className="first-colum col-6">
              <div className="mb-2">
                <label htmlFor="" className="col-12 label">Tanggal Pembayaran</label>
                <div className="col-12">
                  <Form.Item name="date"
                    rules={required}
                  >
                    <DatePicker onChange={onChangePaymentDate} className="w-100" />
                  </Form.Item>
                </div>
              </div>
              <div className="mb-2">
                <label htmlFor="" className="col-12 label">Seller</label>
                <div className="col-12">
                  <Form.Item
                    name='tenantId'
                    rules={required}
                  >
                    <AutoComplete
                      disabled={type == 'update'}
                      onDropdownVisibleChange={(open) => open ? loadTenant('') : setTenantList(null)}
                      onSearch={loadTenant}
                      onSelect={(val, opt) => onSelectTenantId(opt)}
                      onClear={() => form.setFieldsValue({ 'tenantId': '' })}
                    >
                      {tenantList && tenantList.map(tenant => (
                        <Option key={tenant.id} value={tenant.displayName}>
                          {tenant.displayName}
                        </Option>
                      ))}
                    </AutoComplete>
                  </Form.Item>
                </div>
              </div>
              <div className="mb-2">
                <label htmlFor="" className="col-12 label">Jumlah Pembayaran</label>
                <div className="col-12">
                  <div className="row">
                    <div className="col-10">
                      <Form.Item
                        name="totalAmount"
                        rules={required}
                      >
                        <Input type="number" />
                      </Form.Item>
                    </div>
                    <div className="text-center">
                      <Badge className="file-amount" count={fileAmount}>
                        <UploadTemplateList
                          onChange={onUpload}
                          showUploadList={false}
                          showLoadingButton
                          disabled={isMaxLimit}
                          buttonProps={{
                            type: 'ghosted',
                            label: '',
                            iconName: 'deo-upload', className: `${fileAmount && 'active'} p-2`
                          }} baseUrl={`${environment.ENDPOINTS.API_FILE}/upload/admin`} headers={{ 'Authorization': `Bearer ${authenticationService.user.access_token}` }} />
                      </Badge>

                    </div>
                  </div>
                </div>
              </div>
              {fileList}
            </div>
            <div className="second-colum col-6">

              <Spinner spinning={loadingInvoice}>
                <div className="mb-2">
                  <label className="col-12 label">No Invoice</label>
                  <div className="col-12">
                    <Form.Item name="invoiceNumber"
                      rules={required}
                    >
                      <Select mode="multiple" placeholder="Pilih invoice" onChange={(val, opt) => onChangeInvoice(val, opt)} filterOption={(input, opt) => opt.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                        {
                          tenantInvoiceList && tenantInvoiceList.map(invoice => (
                            <OptionSelect value={invoice.id} >
                              {invoice.transactionNumber}
                            </OptionSelect>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </div>
                  <label htmlFor="" className="col-12 label">Total tagihan : {totalBalanceDue || 0}</label>
                </div>
              </Spinner>
              <div className="mb-2">
                <label htmlFor="" className="col-12 label">Metode Pembayaran</label>
                <div className="col-12">
                  <Form.Item
                    name='payment-method'
                    rules={required}
                  >
                      <AutoComplete
                      onClear={() => form.setFieldsValue({ 'paymentType': '' })}
                      onSelect={(val, opt) => onPaymentChange(val, opt)}
                    >
                      <Option key={'transfer_manual'} value={'Transfer Manual'}>Transfer Manual</Option>
                      <Option key={'wallet_balance'} value={'E-Wallet'}>E-Wallet</Option>
                    </AutoComplete>
                  </Form.Item>
                </div>
                <div className="col-12">
                  <Spinner spinning={loadingBalance}>
                    <span className="">Total Saldo : {AccountingJS.formatMoney(walletBalance || 0, { symbol: 'Rp ', format: '%s %v'})}
                    </span>
                  </Spinner>
                </div>
              </div>
              <div className="mb-2">
                <label htmlFor="" className="col-12 label">Note</label>
                <div className="col-12">
                  <Form.Item name="note">
                    <InputTextArea />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 text-center">
            <Button type="ghosted" label="Batal" className="mr-3" onClick={() => onCancel()} />
            <Button submit type="primary" label="Simpan" />
          </div>
        </div >
      </Form>
      {modalValue && (
        <Modal
          {...modalValue}
          footer={null}
          className="admin"
          onCancel={() => setModalValue(null)}
        >
          {modalValue.contentTemplate}
        </Modal>
      )}
    </>
  )
}

import React, { useEffect } from 'react';
import { useState } from 'react';
import { MenuService } from './menu.service';
import { Item, Menu, SubMenu } from '@clodeo/clodeo-ui/components/navigation/menu/menu.component';
import { NavLink } from 'react-router-dom';
import { AclService } from '../../../core/auth/acl.service';
import './menu-layout.component.scss';
import { Icon } from '@clodeo/clodeo-ui/components/foundations/icon/icon.component';
import _ from 'lodash';

export const MenuComponent = (props: MenuRfProps) => {
  const [menus, setMenus] = useState<IMenuRf[]>(null);
  const [compact, setCompact] = useState(false);
  useEffect(() => {
    setMenus(props.menus);
    props.onCompactChange && props.onCompactChange(compact)
  }, [props.menus, compact]);

  function isActive(to: string) {
    const path = _.split(props.pathName, '/');
    const current = _.split(to, '/');
    return path[1] == current[1];
  }

  return menus && <Menu
    // defaultSelectedKeys={ }
    className={`menu-layout-admin-deo w-100 mt-4`}
    mode="inline"
  >
    {
      menus.map((menu: IMenuRf, i: number) => (
        <div className="d-flex flex-column">
          <NavLink to={menu.to}
            className={`wrapper-nav-link ${compact && 'compact-view'}`}
            isActive={() => isActive(menu.to)}
            activeClassName="active">
            <div className="icon-wrapper">
              <Icon name={menu.icon} />
            </div>
            {!compact && <div className="label">
              {menu.label}
            </div>}
          </NavLink>
        </div>
      ))
    }
    <div className="separator m-3"></div>
    <div className={`wrapper-nav-link compact ${compact && 'compact-view active'}`} onClick={() => setCompact(!compact)}>
      <div className="icon-wrapper">
        <Icon name="deo-sidebar" />
      </div>
      {
        !compact &&
        <div className="label">Compact View</div>
      }
    </div>
  </Menu >
}
import { AutoComplete, Option } from '@clodeo/clodeo-ui/components/data-entry/autocomplete/autocomplete.component';
import { Messages } from '@clodeo/clodeo-ui/components/feedback/messages/messages.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { AdminPickerRestService } from '@clodeo/libs/core/rest/admin/picker/admin-picker-rest.service';
import { Form } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import React, { useState } from 'react';
import { environment } from '../../../../../../../environments/environment';
import { AuthenticationService } from '../../../../../../core/auth/authentication.service';

export default function AssignReferralComponent({ onSubmit, id }: { onSubmit: (referralCodeId: string, id: string) => void; id: string }) {
  const [refOptions, setRefOptions] = useState([]);
  const [form] = useForm();
  const required = [
    {
      required: true,
      message: 'Kolom ini wajib diisi!'
    }
  ];
  function loadRefPicker(keyword = '') {
    const obs = new AdminPickerRestService(environment.ENDPOINTS, new AuthenticationService().axiosInterceptors).getSalesReferral({ take: 30 }, { keyword })
    new HandleService().handleRequest({
      obs: obs,
      onDone: (res) => {
        setRefOptions(res.data)
      },
    })
  }
  function onSubmitForm() {
    const val = form.getFieldsValue(true);
    if (val.referralCode && !val.referralCodeId) {
      new Messages().show({
        type: 'error',
        content: 'Tidak Valid'
      })
    } else {
      onSubmit(val.referralCodeId, id)
    }
  }
  return (
    <Form
      form={form}
      onFinish={onSubmitForm}
    >
      <div className="mb-3" style={{ width: '20vw' }}>
        <div className="d-flex flex-column">
          <label htmlFor="" className="field field-required">User Sales</label>
          <Form.Item
            name='referralCode'
            rules={required}
          >
            <AutoComplete
              onDropdownVisibleChange={(open) => open ? loadRefPicker('') : setRefOptions(null)}
              onSearch={loadRefPicker}
              onSelect={(val, opt) => form.setFieldsValue({ 'referralCodeId': opt.key })}
              onClear={() => form.setFieldsValue({ 'tenantId': '' })}
            >
              {refOptions && refOptions.map(option => (
                <Option key={option.id} value={option.name}>
                  {option.referralCode} - {option.name}
                </Option>
              ))}
            </AutoComplete>
          </Form.Item>
        </div>
      </div>
      <div className="text-right">
        <Button label="Submit" type='primary' submit />
      </div>
    </Form>
  )
}
import { RequestService } from '../../request.service';

export class AdminMonitoringPickupRestService {
  requestService = new RequestService;
  request: RequestService;
  /**
   * 
   * @param baseUrl only from environment.ENDPOINTS
   * @param useInterceptor 
   * @param withoutInterceptor 
   */
  constructor(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
    this.request = this.requestService.new(`${baseUrl['API_ADMIN']}/monitoring-pickup`, useInterceptor, withoutInterceptor);
  }

  /**
   * Get list of monitoring pickups
   * @param qParams 
   */
  findAll(qParams) {
    return this.request.get('', qParams);
  }

  /**
   * Get list of monitoring pickups with more detail search params
   * @param qParams 
   */
  findAllQ(qParams) {
    return this.request.post('q', qParams);
  }

  /**
   * Get Waybill history
   * @param salesTransactionId 
   */
  getWaybillHistory(salesTransactionId: string) {
    return this.request.get(`waybill-history/${salesTransactionId}`);
  }

  /**
   * Get Waybill Detail
   * @param payload 
   */
  getWaybillDetail(payload) {
    return this.request.post('waybill-detail', payload);
  }



}

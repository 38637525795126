import React, { useRef, useState } from 'react';
import { environment } from 'apps/admin-web/src/environments/environment';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { ShippingIntegrationRestService } from '@clodeo/libs/core/rest/admin/shipping-integration/shipping-Integration-rest.service';
import { IColumn, IIncludes } from 'apps/admin-web/src/app/components/base-component/list/base-list';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { BaseListComponent } from 'apps/admin-web/src/app/components/base-component/list/base-list.component';
import moment from 'moment';
import { throwError } from 'rxjs';
import { CustomTrainingScheduleMoreFilterComponent } from '../more-filter/custom-training-schedule-more-filter.component';
import { AclService } from 'apps/admin-web/src/app/core/auth/acl.service';
import { CustomTrainingScheduleCreateComponent } from '../create/custom-training-schedule-create.component';
import { Action } from '@clodeo/clodeo-ui/components/general/action/action.component';
import { Confirms } from '@clodeo/clodeo-ui/components/feedback/confirm/confirm.component';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { catchError, finalize, tap } from 'rxjs/operators';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { compact, pick } from 'lodash';



export const CustomTrainingScheduleListComponent = () => {
  const authenticationService: AuthenticationService = new AuthenticationService();
  const aclService = new AclService;
  const confirm: Confirms = new Confirms;
  const notif: Notifications = new Notifications;
  const shippingIntegrationRestService = new ShippingIntegrationRestService(`${environment.ENDPOINTS.API_ADMIN}`, authenticationService.axiosInterceptors);
  const filters = ['keyword', 'options.take', 'options.skip', 'options.includeTotalCount', 'lowDate', 'highDate', 'inactive'];
  const baseListRef = useRef<any>();
  const [modal, setModal] = useState<IModal>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const includes: IIncludes = {
    refresh: true,
    toogleOptions: true,
    extraButtons: compact([
      aclService.can(['tenant.shipping_integration.create']) &&
      {
        label: 'Tambah Jadwal',
        action: () => setModal({
          title: 'Tambah Jadwal Latihan',
          data: null,
          visible: true,
          contentTemplate:
            <CustomTrainingScheduleCreateComponent
              onClose={() => setModal(null)}
              onFinish={() => onFinishCreate()}
            />
        }),
      }
    ])
  };

  let columns: IColumn[] = compact([
    {
      key: '1',
      dataIndex: 'trainingDate',
      title: 'Jadwal Training',
      width: '150px',
      render: (val) => <p>{moment(val).format("DD MMMM YYYY HH:mm")}</p>
    },
    {
      key: '2',
      dataIndex: 'quota',
      title: 'Kuota',
      width: '150px',
      align: 'center'
    },
    {
      key: '3',
      dataIndex: 'inactive',
      title: 'Status',
      width: '100px',
      align: 'center',
      render: (val) => <p>{val ? 'Tidak' : 'Aktif'}</p>
    },
    {
      key: '4',
      dataIndex: 'createdUserAdminName',
      title: 'User',
      width: '100px',
      align: 'center',
      render: (value, record) => <p>{value}, {moment(record.createDateUtc).format("DD MMMM YYYY HH:mm")}</p>
    },
    aclService.can(['tenant.shipping_integration.delete']) && {
      key: '5',
      dataIndex: 'Aksi',
      title: 'Aksi',
      width: '100px',
      align: 'center',
      render: (val: any, record: any) => <p><Action type="delete" onClick={() => onRemoveTrainingDate(record)} /></p>
    },
  ]);

  const loadDataObservable = (params) => {
    const qParams = pick(params, filters);
    qParams.lowDate = qParams.lowDate ? qParams.lowDate : moment().subtract(3, 'months').format('YYYY-MM-DD');
    qParams.highDate = qParams.highDate ? qParams.highDate : moment(new Date).format('YYYY-MM-DD');
    return shippingIntegrationRestService.getListCustomTrainingSchedule({ params: qParams });
  };


  function onFinishCreate() {
    setModal(null);
    baseListRef.current.callLoadData();
  }

  function requestDelete(payload) {
    setIsLoading(true);
    return shippingIntegrationRestService.deleteTrainingDate(payload)
      .pipe(
        catchError(error => {
          setIsLoading(false);
          notif.show({
            type: "error",
            title: "Error",
            description: "Gagal melakukan hapus coupon."
          });
          return throwError(error);
        }),
        finalize(() => baseListRef.current.callLoadData())
      )

  }

  function onRemoveTrainingDate(record) {
    const payload = { trainingDates: [record.trainingDate] };
    confirm.show({
      title: 'Konfirmasi Data',
      content: 'Apakah anda yakin menghapus jadwal ?',
      onOk() {
        requestDelete(payload).pipe(
          tap(() => baseListRef.current.callLoadData()))
          .subscribe((res) => {
            setIsLoading(false);
            notif.show({
              type: 'success',
              title: 'Success',
              description: 'Berhasil menghapus jadwal training.',
            });
          });
      },
    });
  }

  return (
    <>
      <Spinner spinning={isLoading}>
        <BaseListComponent
          listId="custom-training-schedule-list"
          ref={baseListRef}
          columns={columns}
          loadDataObservable={loadDataObservable}
          includes={{
            ...includes,
            moreFilter: {
              template:
                <CustomTrainingScheduleMoreFilterComponent />
            }
          }}
        />
      </Spinner>

      {
        modal &&
        <Modal
          {
          ...modal
          }
          width="fit-content"
          className="admin modal-reponsive"
          footer={null}
          onCancel={() => setModal(null)}
        > {modal.contentTemplate}
        </Modal>
      }
    </>
  )
}

export default CustomTrainingScheduleListComponent;

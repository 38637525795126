import React, { useRef, useState } from 'react';
import { environment } from 'apps/admin-web/src/environments/environment';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { BaseListComponent } from 'apps/admin-web/src/app/components/base-component/list/base-list.component';
import {
  IColumn,
  IIncludes,
} from 'apps/admin-web/src/app/components/base-component/list/base-list';
import moment from 'moment';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { AdminRestService } from '@clodeo/libs/core/rest/admin/admin/admin-rest.service';
import { IModalValue } from '@clodeo/clodeo-ui/components/feedback/modal/modal';
import { ActivationAccountMoreFilterComponent } from '../more-filter/activation-account-more-filter.component';
import { ActivationAccountUpdateComponent } from '../update/activation-account-update.component';

const authenticationService: AuthenticationService = new AuthenticationService();
const tenantsRestService = new AdminRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);

export const ActivationAccountComponent: React.FC = () => {
  const [modalValue, setModalValue] = useState<IModalValue>();
  const baseListRef = useRef<any>();

  const includes: IIncludes = {
    refresh: true,
    keyword: true,
    toogleOptions: true,
    exports: [{
      label: 'Export berdasarkan filter',
      basedFilter: true,
      fileName: 'Activation Account | By Filter'
    }, {
      label: 'Export semua data',
      fileName: 'List Activation Account'
    }],
  };

  const columns: IColumn[] = [
    {
      key: '0',
      dataIndex: 'id',
      title: 'TENANT ID',
      ignoreDisply: true,
      render: (value: any, record: any) => {
        return <ActivationAccountUpdateComponent data={record} onCancel={() => setModalValue(null)} onFinish={() => {
          baseListRef.current.callLoadData()
        }} />
      }
    },
    {
      key: '1',
      dataIndex: 'fullName',
      title: 'NAME',
      width: '100px',
    },
    {
      key: '2',
      dataIndex: 'userName',
      title: 'USERNAME',
      width: '100px',
    },
    {
      key: '3',
      dataIndex: 'email',
      title: 'EMAIL',
      width: '100px',
    },
    {
      key: '4',
      dataIndex: 'phoneNumber',
      title: 'NO. TELEPON',
      width: '90px',
      align: 'center',
    },
    {
      key: '5',
      dataIndex: 'joinDate',
      title: 'TGL. DAFTAR',
      width: '90px',
      align: 'center',
      render: (val) => <p>{moment(val).format("DD MMMM YYYY")}</p>
    },
    {
      key: '6',
      dataIndex: 'emailConfirmed',
      title: 'AKSI',
      width: '70px',
      align: 'center',
      render: (val: any, record: any) => {
        return record.emailConfirmed === true ? '' : <Button type="tertiary" label="Aktivasi" onClick={() => setModalValue({
          title: '',
          visible: true,
          data: null,
          contentTemplate: <ActivationAccountUpdateComponent onCancel={() => setModalValue(null)} onFinish={() => {
            baseListRef.current.callLoadData()
            setModalValue(null);
          }} data={record}/>
        })} />
      }
    }
  ];

  function loadDataObservable(params) {
    const qParams = params
    return tenantsRestService.getTenantUser({ params: qParams });
  }

  function callData() {
    setTimeout(() => {
      baseListRef.current.callLoadData()
    }, 10);
  }

  return (
    <>
      <BaseListComponent
        listId="tenant-activation-list" // ID Wajib diisi
        ref={baseListRef}
        columns={columns}
        loadDataObservable={loadDataObservable}
        includes={{
          ...includes,
          moreFilter: {
            template: <ActivationAccountMoreFilterComponent />
          }
        }}
      />

      {
        modalValue &&
        <Modal
          {
            ...modalValue
          }
          footer={null}
          width="fit-content"
          className="admin modal-responsive"
          onCancel={() => setModalValue(null)}
        > {modalValue.contentTemplate}
        </Modal>
      }
    </>
  );
};


import React, { useRef, useState } from 'react';
import { environment } from 'apps/admin-web/src/environments/environment';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { AdminVerificationRequestRestService } from '@clodeo/libs/core/rest/admin/verification-request/admin-verification-request.rest.service';
import { IColumn, IIncludes } from 'apps/admin-web/src/app/components/base-component/list/base-list';
import moment from 'moment';
import { BaseListComponent } from 'apps/admin-web/src/app/components/base-component/list/base-list.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { IModalValue } from '@clodeo/clodeo-ui/components/feedback/modal/modal';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { VerifyComponent } from '../verify/verify-rekening.component';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { VerificationLogDetailComponent } from '../log-detail-verification/log-detail-verification.component';
import { Tag } from '@clodeo/clodeo-ui/components/data-display/tag/tag.component';
import { capitalize } from 'lodash';

export const VerificationRequestBankAccount = () => {
  const { handleRequest } = new HandleService;
  const notif: Notifications = new Notifications;

  const authenticationService: AuthenticationService = new AuthenticationService();
  const adminVerificationRequestRestService = new AdminVerificationRequestRestService(
    environment.ENDPOINTS,
    authenticationService.axiosInterceptors
  );

  const baseListRef = useRef<any>();
  const [modalValue, setModalValue] = useState<IModalValue>();

  const includes: IIncludes = {
    refresh: true,
    toogleOptions: true,
    keyword: true
  }

  const columns: IColumn[] = [
    {
      key: '1',
      dataIndex: 'registrationDate',
      title: 'Tanggal Perubahan',
      width: '90px',
      render: (val) => <p className="mt-3">{moment(val).format("DD MMM YYYY")}</p>
    },
    {
      key: '2',
      dataIndex: 'companyName',
      title: 'Nama Perusahaan',
      width: '120px',
    },
    {
      key: '3',
      dataIndex: 'requestPhone',
      title: 'No Telp',
      width: '90px',
      render: (val) => <p className="mt-3">{'0' + val}</p>
    },
    {
      key: '4',
      dataIndex: 'requestEmail',
      title: 'Email',
      width: '100px',
    },
    {
      key: '5',
      dataIndex: 'isDifferentName',
      title: 'Recheck',
      width: '80px',
      render: (val, record) => {
        return record.isDifferentName ? 'Ya' : '-'
      }
    },
    {
      key: '6',
      dataIndex: 'status',
      title: 'Status',
      width: '150px',
      align: 'center',
      render: (statusApproval) => <Tag colorType={statusApproval}>{capitalize(statusApproval)}</Tag>
    },
    {
      key: '7',
      dataIndex: 'log',
      title: 'Log',
      width: '80px',
      render: (val: any, record) => (
        <Button
          label="Open"
          type="ghosted"
          className="p-2"
          onClick={() => logDetail(record)}
        />
      ),
    },
    {
      key: '8',
      dataIndex: 'registrationDate',
      title: 'Aksi',
      width: '80px',
      render: (val: any, record: any) => {
        return record.status === 'pending' ?
        <Button type={'banana'} label={'Approval'} onClick={() => approvalModal(record)} />
        :
        <Button type={'aqua'} label={'Detail'} onClick={() => detailApproval(record)} />
      }
    }
  ];

  function logDetail(record) {
    baseListRef.current.showLoading(true);
    const obs = adminVerificationRequestRestService.getVerificationHistory(record.id, record.tenantId);
    handleRequest({
      obs,
      hideError: true,
      onError: (err) => {
        baseListRef.current.showLoading(false);
        const errorMessage = err[0].errorMessage || 'Error';
        notif.show({
          type: 'error',
          title: 'Error',
          description: errorMessage
        });
      },
      onDone: (res) => {
        baseListRef.current.showLoading(false);
        setModalValue({
          title: 'Log Detail',
          visible: true,
          data: null,
          contentTemplate: <VerificationLogDetailComponent record={res} />
        })
      }
    })
  }

  function approvalModal(record) {
    setModalValue({
      title: 'Data perubahaan rekening',
      visible: true,
      data: null,
      className: "admin modal-reponsive",
      contentTemplate: 
      <VerifyComponent
        tenantId={record.id}
        onFinish={() => onFinishSubmit()}
        onClose={() => setModalValue(null)}
        detailApproval={false}
      />
    });
  }

  function detailApproval(record) {
    setModalValue({
      title: 'Data perubahaan rekening',
      visible: true,
      data: null,
      className: "admin modal-reponsive",
      contentTemplate: 
      <VerifyComponent
        tenantId={record.id}
        onFinish={() => onFinishSubmit()}
        onClose={() => setModalValue(null)}
        detailApproval={true}
      />
    });
  }

  function onFinishSubmit() {
    setModalValue(null);
    baseListRef.current.callLoadData();
  }

  const loadDataObservable = (params) => {
    const qParams = { params }
    return adminVerificationRequestRestService.findAll({ params })
  }

  return (
    <>
      <BaseListComponent
        listId="verifikasi-rekening"
        ref={baseListRef}
        columns={columns}
        loadDataObservable={loadDataObservable}
        includes={{
          ...includes
        }}
      />

      {modalValue &&
        <Modal
          {...modalValue}
          footer={null}
          width="fit-content"
          onCancel={() => setModalValue(null)}
        >
          {modalValue.contentTemplate}
        </Modal>
      }
    </>
  )

}
import React, { useRef, useState } from 'react'
import * as AccountingJS from 'accounting-js';

import { BaseListComponent } from 'apps/admin-web/src/app/components/base-component/list/base-list.component';
import { IColumn, IIncludes } from 'apps/admin-web/src/app/components/base-component/list/base-list';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';

import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { AdminWalletTransactionRestService } from '@clodeo/libs/core/rest/admin/wallet-transaction/admin-wallet-transaction-rest.service';
import { IModalValue } from '@clodeo/clodeo-ui/components/feedback/modal/modal';
import { TenantsWalletCreateComponent } from '../create/tenant-wallet-create.component';
import { TenantWalletUploadTransactionComponent } from '../upload-transaction/tenant-wallet-upload-transaction.component';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { TenantWalletDetailComponent } from '../detail/tenant-wallet-detail.component';
import { AclService } from 'apps/admin-web/src/app/core/auth/acl.service';
import { Select } from '@clodeo/clodeo-ui/components/data-entry/select/select.component';
const aclService: AclService = new AclService;


export const TenantWalletListComponent = () => {
  const baseListRef = useRef<any>();

  const authenticationService: AuthenticationService = new AuthenticationService();
  const adminWalletTransactionRestService = new AdminWalletTransactionRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
  const [modalValue, setModalValue] = useState<IModalValue>();
  const [isTenant, setIsTenant] = useState<boolean>(true);

  const columns: IColumn[] = [
    {
      key: '1',
      title: 'COMPANY NAME',
      width: '150px',
      render: (data) => data.companyInfo.companyName,
    },
    {
      key: '2',
      title: 'COMPANY EMAIL',
      width: '150px',
      render: (data) => data.companyInfo.email,
    },
    {
      key: '3',
      title: 'COMPANY PHONE',
      width: '150px',
      render: (data) => data.companyInfo.phone,
    },
    {
      key: '4',
      title: 'BALANCE',
      align: 'right',
      width: '150px',
      render: (data) => AccountingJS.formatMoney(data.walletBalance.balance, { symbol: 'Rp ', format: '%s %v' })
    },
    {
      key: '5',
      dataIndex: 'id',
      title: 'DETAIL',
      width: '100px',
      align: 'center',
      render: (value, record) =>
        <Button
          type="tertiary"
          label="Detail"
          onClick={() => setModalValue({
            visible: true,
            title: 'Detail Transaksi',
            contentTemplate: <TenantWalletDetailComponent
              idTenant={value}
              onCancel={() => setModalValue(null)}
              onFinsih={() => setModalValue(null)}
              detailTenant={record}
            />
          })}
        />,
    },
  ];


  const includes: IIncludes = {
    keyword: true,
    toogleOptions: true,
    refresh: true,
    refreshAction: () => {
      baseListRef.current.resetFilters();

      setTimeout(() => {
        baseListRef.current.callLoadData();
      }, 10);
    },
    // extraButtons: aclService.can(['wallet.tenant_wallet.create']) ? ([
    //   {
    //     label: 'UPLOAD WALLET TRANSACTION',
    //     hidden: true,
    //     type: 'filter',
    //     action: () => setModalValue({
    //       visible: true,
    //       title: 'Upload Wallet Transaction',
    //       contentTemplate: <TenantWalletUploadTransactionComponent
    //         onCancel={() => setModalValue(null)}
    //         onFinish={() => {
    //           baseListRef.current.callLoadData();
    //           setModalValue(null)
    //         }}
    //       />,
    //     }),
    //     iconName: 'deo-upload',
    //   },
    //   {
    //     label: 'WALLET TRANSACTION',
    //     hidden: true,
    //     iconName: 'deo-plus',
    //     iconPosition: 'left',
    //     type: 'filter',
    //     action: () => setModalValue({
    //       visible: true,
    //       title: 'Tambah Wallet Transaction',
    //       contentTemplate: <TenantsWalletCreateComponent
    //         onCancel={() => setModalValue(null)}
    //         onFinish={() => {
    //           baseListRef.current.callLoadData();
    //           setModalValue(null)
    //         }}
    //       />,
    //     })
    //   },
    // ]) : [],
  };

  function loadDataObservable(params) {
      params.tenantId = isTenant ? params.keyword : null;
      params.keyword = !isTenant ? params.keyword : null;
    return adminWalletTransactionRestService.findAllTenantTransactionOld({ params });
  }

  function selectIsTenant() {
    const options: any[] = [
      { label: 'Tenant ID', value: true },
      { label: 'Keyword', value: false },
    ];
    return (
      <Select size='large' options={options} value={isTenant} onChange={setIsTenant} />
    )
  }

  return (
    <>
      <BaseListComponent
        listId="wallet-transactions"
        ref={baseListRef}
        columns={columns}
        loadDataObservable={loadDataObservable}
        includes={{
          ...includes,
          placeholderKeyword: isTenant && 'Cari Tenant ID',
          keywordType: isTenant && 'number',
          suffixComponent: selectIsTenant(),
        }}
      />
      {modalValue && <Modal
        {...modalValue}
        footer={null}
        className="admin"
        onCancel={() => setModalValue(null)}
        width="fit-content"
      >
        {modalValue.contentTemplate}
      </Modal>}
    </>
  );
}

import { WidgetChart } from "@clodeo/clodeo-ui/components/chart/chart.component";
import React, { useEffect, useState } from "react";

export const BillingAdjustmentSummaryComponent = (
  props: {
    billingAdjustmentSummary: {
      totalUnpaid: number,
      totalPartiallyPaid: number,
      totalPaid: number,
      total: number
    },
    onChangeFilter(val: any): void;
  }
) => {
  const { billingAdjustmentSummary } = props;
  const [paymentStatus, setPaymentStatus] = useState<string>(null);
  const [firstInit, setFirstInit] = useState<boolean>(true);

  useEffect(() => {
    if (!firstInit) {
      props.onChangeFilter && props.onChangeFilter({ summaryFilterType: 'invoice', paymentStatus: paymentStatus })
    }
    setFirstInit(false);
  }, [paymentStatus]);

  return (
    <>
      <div className="row w-100 p-2 m-auto">
        <div className="col-12 col-lg-3 p-2">
          <WidgetChart
            onClick={() => setPaymentStatus(null)}
            label="Total Tagihan"
            value={billingAdjustmentSummary.total}
            icon={<IconTotalBillUnpaid />}
          />
        </div>
        <div className="col-12 col-lg-3 p-2">
          <WidgetChart
            onClick={() => setPaymentStatus('paid')}
            label="Total Tagihan Lunas"
            value={billingAdjustmentSummary.totalPaid}
            icon={<IconTotalBill />}
          />
        </div>
        <div className="col-12 col-lg-3 p-2">
          <WidgetChart
            onClick={() => setPaymentStatus('partial_paid')}
            label="Total Tagihan Sudah Dibayar"
            value={billingAdjustmentSummary.totalUnpaid}
            icon={<IconTotalBillPaid />}
          />
        </div>
      </div>
    </>
  )
}

const IconTotalBill = () => (
  <img src="https://nx-design-system-web-development.clodeo.com/img/admin-clodeo/bill/summary/total-bill.svg" alt="" srcSet="" />
)
const IconTotalBillPaid = () => (
  <img src="https://nx-design-system-web-development.clodeo.com/img/admin-clodeo/bill/summary/total-bill-paid.svg" alt="" srcSet="" />
)
const IconTotalBillUnpaid = () => (
  <img src="https://nx-design-system-web-development.clodeo.com/img/admin-clodeo/bill/summary/total-bill-unpaid.svg" alt="" srcSet="" />
)
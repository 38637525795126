import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Form } from 'antd';
import { RangePicker } from '@clodeo/clodeo-ui/components/data-entry/datepicker/datepicker.component';

export const ActivationAccountMoreFilterComponent = (
  props: {
    values?: any,
    form?: any
  }
) => {
  const { form } = props;
  const [dateValues, setDateValues] = useState<any>(null);

  useEffect(() => {
    let lowDate: string;
    let highDate: string;

    if (dateValues && dateValues.length) {
      let [low, high] = dateValues;
      lowDate = low ? moment(low).format('YYYY-MM-DD') : null;
      highDate = high ? moment(high).format('YYYY-MM-DD') : null;
      form.setFieldsValue({ lowDate: lowDate, highDate: highDate });
    }

  }, [dateValues]);

  return <div className="d-flex" id="tenant-more-filter">
    <Form.Item name="shippingCourierChannelId">
      <div className="d-flex flex-column">
        <label htmlFor="" className="mb-2">Tanggal Daftar</label>
        <RangePicker value={dateValues} onChange={setDateValues} />
      </div>
    </Form.Item>
  </div>
}

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import _ from 'lodash';
import { Form } from 'antd';
import { catchError, finalize, tap } from 'rxjs/operators';

import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { Checkbox } from '@clodeo/clodeo-ui/components/data-entry/checkbox/checkbox.component';
import { Dropdown } from '@clodeo/clodeo-ui/components/navigation/dropdown/dropdown.component';
import { Input } from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';
import { Menu, Item } from '@clodeo/clodeo-ui/components/navigation/menu/menu.component';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { Table } from '@clodeo/clodeo-ui/components/data-display/table/table.component';
import { LoadingComponent } from '@clodeo/clodeo-ui/components/general/loading/loading.component';
import { Badge } from '@clodeo/clodeo-ui/components/data-display/badge/badge.component';
import { ExportService } from '@clodeo/libs/core/export/export.service';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { Observable, throwError } from 'rxjs';
import { BaseListProps, IColumn, IExport } from './base-list';
import './base-list.component.scss';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';


function BaseList(props: BaseListProps, ref) {
  const { columns, includes, loadDataObservable, scroll, hidePaginations, loadingExport, rowSelection, rowKey, summary } = props;
  const notif: Notifications = new Notifications;
  const exportService: ExportService = new ExportService();
  const handleService: HandleService = new HandleService();
  const [formMoreFilter] = Form.useForm();

  /**
   * State
   */
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingExprt, setLoadingExprt] = useState<{ status: boolean, description: string }>({
    status: false,
    description: '0%'
  });
  const [isShowToggleColumn, setIsShowToggleColumn] = useState<boolean>(false);
  const [showExports, setShowExports] = useState<boolean>(false);
  const [showMoreFilter, setshowMoreFilter] = useState(false);
  const [search, setSearch] = useState<string>();
  const [tableColumns, setColumns] = useState<IColumn[]>(columns);
  const [table, setTable] = useState({
    dataSource: [],
    pagination: {
      current: 1,
      pageSize: 30,
      total: 0,
    },
  });


  const [selectedRowRecords, setSelectedRowRecords] = useState<{ dataPerPage: Object, lines: any[], keys: string[] }>({ dataPerPage: {}, lines: [], keys: [] });
  const onSelectedRowKeys = (selectedKeys, selectedRecords) => {
    let recordsPerParge = { ...selectedRowRecords };
    recordsPerParge["dataPerPage"][table.pagination.current] = selectedRecords;
    recordsPerParge["lines"] = _.flattenDeep(Object.values(recordsPerParge["dataPerPage"]));
    recordsPerParge["keys"] = _.map(recordsPerParge["lines"], `${rowKey}`);

    setSelectedRowRecords(recordsPerParge);
  }

  const thisRef = useRef();
  useImperativeHandle(ref, () => ({
    callLoadData() {
      loadData().subscribe();
    },
    resetFilters() {
      resetParams();
    },
    getDefaultParams() {
      return params;
    },
    showLoading(loading: boolean = true) {
      setIsLoading(loading);
    },
    hideLoading() {
      setIsLoading(false);
    },
    selectedRows: {
      keys: selectedRowRecords["keys"],
      records: selectedRowRecords["lines"]
    }
  }));

  const [selectedColumn, setSelectedColumn] = useState<any[]>(_.map((columns || []), 'key'));
  const [moreFilterComp, setMoreFilterComp] = useState<any[]>(null);
  const defaultParams = {
    keyword: search || null,
    'options.take': table.pagination ? table.pagination.pageSize : 0,
    'options.skip': 0,
    'options.includeTotalCount': true,
  }
  const [params, setParams] = useState<any>(defaultParams);

  function resetParams() {
    setSearch('');
    formMoreFilter.resetFields();
    setParams({
      keyword: null,
      'options.take': table.pagination ? table.pagination.pageSize : 0,
      'options.skip': 0,
      'options.includeTotalCount': true,
    });
  }

  function onChangeToggleColumn(val, idx: number) {
    const newColumn = tableColumns;
    newColumn[idx].className = val ? newColumn[idx].className.replace(/[d\-none]+/g, "") : `${newColumn[idx].className} d-none`;
    newColumn[idx].hide = !val;
    setColumns([...newColumn]);
  }

  function loadData(): Observable<any> {
    const moreFilterValue = formMoreFilter.getFieldsValue(true) || {};

    const qParams = { ...params, ...moreFilterValue };
    setIsLoading(true);
    return loadDataObservable(qParams).pipe(
      tap((response) => {
        const tableValue = table;
        tableValue.dataSource = response.data;
        if (tableValue.pagination) {
          tableValue.pagination.total = response.total;
          tableValue.pagination.pageSize = response.take;
        }
        setTable(tableValue);
      }),
      catchError((error) => {
        notif.show({
          type: 'error',
          title: 'Error',
          description: error,
          useService: true
        });
        return throwError(error);
      }),
      finalize(() => {
        setIsLoading(false);
      })
    )
  }

  function onSubmitMoreFilter(values) {
    if (props.includes.moreFilter && props.includes.moreFilter.onSubmit) {
      props.includes.moreFilter.onSubmit(values);
      return;
    }
    const tableValue = table;
    const paramsValue = params
    paramsValue['options.skip'] = 0;
    tableValue.pagination.current = 0;
    setTable({
      ...tableValue,
    });
    setParams(paramsValue);

    setTimeout(() => {
      setshowMoreFilter(false);
      // loadData().subscribe();
    }, 10);

  }

  function onResetMoreFilter() {
    if (props.includes.moreFilter && props.includes.moreFilter.onReset) {
      props.includes.moreFilter.onReset();
      return;
    }
    formMoreFilter.resetFields();
  }

  function MoreFilterComponent() {
    let component = moreFilterComp;
    if (!component) {
      component = React.Children.map(includes.moreFilter.template, child => {
        // checking isValidElement is the safe way and avoids a typescript error too
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { form: formMoreFilter } as any);
        }
        return child;
      });
    }
    setMoreFilterComp(component);
    return component;
  }

  useEffect(() => {
    if (onChangeGridData) {
      const subscription = loadData().subscribe();
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [table]);

  useEffect(() => {
    checkingColumn();
  }, [columns])

  const onChangeGridData = (value, type) => {
    const tableValue = table;
    const paramsValue = {
      keyword: search || null,
      'options.take': table.pagination.pageSize,
      'options.skip': table.pagination.current * table.pagination.pageSize,
      'options.includeTotalCount': true,
      // 'options.sort': '',
    };

    switch (type) {
      // set take / pageSize params
      case 'pageSize':
        paramsValue['options.take'] = value;
        // reset current page if pageSize has change
        tableValue.pagination.current =
          value !== _.get(table.pagination, 'pageSize')
            ? 1
            : _.get(table.pagination, 'current');
        tableValue.pagination.pageSize = value;
        paramsValue['options.skip'] = 0;
        break;

      // set currentPage / skip params
      case 'currentPage':
        paramsValue['options.skip'] =
          (value - 1) * _.get(table.pagination, 'pageSize');
        tableValue.pagination.current = value;
        break;

      // when search keyword
      default:
        paramsValue['options.skip'] = 0;
        break;
    }

    setTable({
      ...tableValue,
    });
    setParams(paramsValue);
  };

  function checkingColumn() {
    const newColumns: IColumn[] = [];
    _.map(columns, function (col) {
      col.className = !col.hide ? _.replace(col.className, 'd-none', '') : `${col.className} d-none`;
      newColumns.push(col)
    });
    setColumns(newColumns);
  }

  function exportData(propsExp: IExport) {
    const filter = _.omit({ ...params, ...formMoreFilter.getFieldsValue(true) }, ['options.take', 'options.skip', 'options.includeTotalCount']);


    if (propsExp.action) {
      propsExp.action({ data: table.dataSource, selectedColumn, filter });

    } else {
      const moreFilterValue = formMoreFilter.getFieldsValue(true) || {};
      let qParams = {
        "options.take": 1,
        "options.skip": 0,
        "options.includeTotalCount": true,
      };
      if (propsExp.basedFilter) {
        qParams = { ...params, ...qParams, ...moreFilterValue };
      }
      setLoadingExprt({
        status: true,
        description: `0%`
      });

      handleService.handleRequest({
        obs: loadDataObservable(qParams),
        onError: () => {
          setLoadingExprt({
            status: false,
            description: `Error`
          });
        },
        onDone: (response) => {
          qParams['options.take'] = response.total;
          handleService.handleRequest({
            obs: loadDataObservable(qParams),
            onError: () => {
              setLoadingExprt({
                status: false,
                description: `Error`
              });
            },
            onDone: ({ data }) => {
              if (propsExp.basedFilter) {
                exportService.expToXLS({ data, wscols: '' }, propsExp.fileName, tableColumns);

              } else {
                exportService.expToXLS({ data, wscols: '' }, propsExp.fileName, tableColumns, true);
              }

              setTimeout(() => {
                setLoadingExprt({
                  status: true,
                  description: `100%`
                });
                setTimeout(() => {
                  setLoadingExprt({
                    status: false,
                    description: `100%`
                  });
                }, 150);
              }, 50);

            }
          });
        }
      });

      // handleService.handleLoadAllReq({
      //   obs: loadDataObservable,
      //   qParams: qParams,
      //   onUpdate: (data) => {
      //     setLoadingExprt({
      //       status: true,
      //       description: `${Math.round((data.totalSkip / data.totalRecord) * 100)} %`
      //     });
      //   },
      //   onError: () => {
      //     setLoadingExprt({
      //       status: false,
      //       description: `Error`
      //     });
      //   },
      //   onDone: (data) => {
      //     if (propsExp.basedFilter) {
      //       exportService.expToXLS({ data: data.records, wscols: '' }, propsExp.fileName, tableColumns);

      //     } else {
      //       exportService.expToXLS({ data: data.records, wscols: '' }, propsExp.fileName, tableColumns, true);
      //     }

      //     setTimeout(() => {
      //       setLoadingExprt({
      //         status: true,
      //         description: `100%`
      //       });
      //       setTimeout(() => {
      //         setLoadingExprt({
      //           status: false,
      //           description: `100%`
      //         });
      //       }, 150);
      //     }, 50);

      //   }
      // });
    }
  }

  const newToggleColumns = (
    <Menu
      className="m-2"
    >
      {tableColumns.map((column, i) =>
        !column.ignoreDisply && <Item key={`${column.key}`} className="toggle-option-item">
          <Checkbox checked={!column.hide} onChange={(e) => onChangeToggleColumn(e.target.checked, i)}>{column.title}</Checkbox>
        </Item>
      )}
    </Menu>
  )

  const exportsDropdown = (
    <Menu
      className="m-2"
    >
      {includes.exports && includes.exports.map((exp, i: number) => {
        return <Item
          key={i}
          onClick={() => exportData(exp)}
        >
          <div className="p-1">
            {exp.label}
          </div>
        </Item>
      })}
    </Menu>
  )

  const suffixSearch = () => (
    <i className="icon-deo-search"></i>
  )
  const filterSectionComponent = () => {
    function onRefresh() {
      if (includes.refreshAction) {
        includes.refreshAction();

      } else {
        loadData().subscribe();
      }
    }
    return <>
      <div className="d-flex flex-row flex-wrap justify-content-between filter-section w-100">
        <div className="d-flex flex-row flex-wrap col-12 justify-content-between justify-content-lg-start col-lg-6">
          {
            includes.moreFilter && <div className="align-self-center py-1 px-1">
              <Badge dot={!_.isEmpty(formMoreFilter.getFieldsValue(true))} color={!_.isEmpty(formMoreFilter.getFieldsValue(true)) ? '#C3FFDC' : null} >
                <Button
                  id={props.listId + '-more-filter'}
                  icon
                  type="ghosted"
                  iconName="deo-filter"
                  className={`p-2 text-center ${!_.isEmpty(formMoreFilter.getFieldsValue(true)) ? 'active' : ''}`}
                  onClick={() => setshowMoreFilter(!showMoreFilter)}
                />
              </Badge>
            </div>
          }
          {
            includes.suffixComponent && <div className="align-self-center py-1 px-1">
              {includes.suffixComponent}
            </div>
          }
          {includes.keyword &&
            <div className={`search align-self-center py-1 px-1 ${includes.moreFilter ? 'col-10' : 'col-12'} col-lg-auto`}>
              <Input name="search"
                type={includes.keywordType || 'text'}
                placeholder={includes.placeholderKeyword || 'Search...'}
                size="large"
                value={search}
                onChange={(event) => { setSearch(event.target.value); setParams({ ...params, keyword: search }) }}
                onPressEnter={onChangeGridData}
                suffix={suffixSearch()}
              />
            </div>
          }
          {
            includes.extraComponent && <div className="align-self-center py-1 px-1">
              {includes.extraComponent}
            </div>
          }
        </div>
        <div className="d-flex flex-row flex-wrap col-12 justify-content-lg-end col-lg-6">
          {
            (includes.extraButtons && _.isLength(includes.extraButtons.length)) &&
            <div className={"px-1 align-self-center py-1 " + (includes.extraButtons.length > 1 ? 'col-auto' : 'col col-lg-auto')}>
              {
                includes.extraButtons.map((extraBtn, i: number) => (
                  <Button
                    key={i}
                    className={includes.extraButtons.length > 1 ? `ml-2 ${extraBtn.className} mr-2 px-3` : `${extraBtn.className} mr-2 px-3`}
                    id={props.listId + '-extra-btn-' + i}
                    {...extraBtn}
                    onClick={extraBtn.action}
                    type={extraBtn.type || 'primary'}
                  />
                ))
              }
            </div>
          }

          {
            ((includes.exports && _.isLength(includes.exports.length) || includes.onlyExportAllData)) &&
            <Spinner spinning={loadingExport || loadingExprt.status || false} tip={loadingExprt.description || ''}>
              <div className="px-1 align-self-center py-1 col col-lg-auto">
                {includes.onlyExportAllData && (
                  <Button
                    id={props.listId + '-exports'}
                    iconName="deo-upload"
                    label="EXPORT"
                    type="filter"
                    className="p-2 px-3 text-center"
                    onClick={() => exportData({ basedFilter: false, fileName: includes.fileNameExportAllData })}
                  />

                )}
                {!includes.onlyExportAllData && (
                  <Dropdown
                    overlay={exportsDropdown}
                    placement="bottomLeft"
                    trigger={['click']}>
                    <Button
                      id={props.listId + '-exports'}
                      iconName="deo-upload"
                      label="EXPORT"
                      type="filter"
                      className="p-2 px-3 text-center"
                      onClick={() => setShowExports(!showExports)}
                    />
                  </Dropdown>
                )}
              </div>
            </Spinner>
          }

          {
            includes.refresh &&
            <div className="px-1 align-self-center py-1 col col-auto">
              <Button
                id={props.listId + '-refresh'}
                icon
                iconName="i-ArrowsClockwise"
                type="filter"
                className="p-2 text-center text-bold"
                onClick={() => onRefresh()}
              />
            </div>
          }

          {
            includes.toogleOptions &&
            <div className="px-1 align-self-center py-1 col col-auto">
              <Dropdown overlay={newToggleColumns} trigger={['click']} placement="bottomLeft" onVisibleChange={setIsShowToggleColumn} visible={isShowToggleColumn}>
                <Button
                  id={props.listId + '-toggle-columns'}
                  icon
                  iconName="deo-bars-2"
                  type="filter"
                  className="p-2 text-center"
                  onClick={() => setIsShowToggleColumn(!isShowToggleColumn)}
                />
              </Dropdown>
            </div>
          }
        </div>
      </div>
      <Modal width='fit-content' className="admin" visible={showMoreFilter} title="Filters" footer={null} onCancel={() => setshowMoreFilter(false)}>
        {
          showMoreFilter &&
          <div className="d-flex flex-column w-100">
            <Form form={formMoreFilter} onFinish={onSubmitMoreFilter}>
              <Form.Item shouldUpdate>
                {() => {
                  return <>
                    <div className="p-2">
                      {includes.moreFilter.templateRf ? includes.moreFilter.templateRf(formMoreFilter) : MoreFilterComponent()
                      }
                    </div>
                    <div className="p-2 w-100 justify-content-between d-flex justify-content-lg-end">
                      <Button
                        type="ghosted"
                        label="Reset"
                        onClick={onResetMoreFilter}
                        className="mr-1"
                      />
                      <Button
                        submit
                        label="Apply"
                        className="mr-3"
                        type="primary"
                      />
                    </div>
                  </>
                }}
              </Form.Item>
            </Form>
          </div>
        }
      </Modal>
    </>
  }

  return (
    <div className="wrapper-base-list container-fluid p-0" ref={thisRef} id={props.listId}>
      {includes &&
        filterSectionComponent()
      }
      <Spinner className="d-flex align-item-center" spinning={isLoading} indicator={<LoadingComponent />}>
        <Table
          key={'table-' + props.listId}
          rowSelection={rowSelection === true ? {
            type: 'checkbox',
            selectedRowKeys: selectedRowRecords["keys"],
            columnWidth: '50px',
            onChange: onSelectedRowKeys
          } : (rowSelection || null)}
          rowKey={rowKey}
          dataSource={table.dataSource}
          columns={table.dataSource && _.reject(tableColumns, 'ignoreDisply')}
          summary={summary}
          pagination={!hidePaginations && {
            total: _.get(table.pagination, 'total'),
            current: _.get(table.pagination, 'current'),
            pageSize: _.get(table.pagination, 'pageSize'),
            onChange: onChangeGridData,
            onChangePageSize: onChangeGridData
          }}
          scroll={scroll ? scroll : { y: '55vh' }}
        />
      </Spinner>
    </div>
  );
}

const BaseListComponent = forwardRef(BaseList)

export {
  BaseListComponent
}

import React, { useState } from 'react';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { IModalValue } from '@clodeo/clodeo-ui/components/feedback/modal/modal';
import { TrackingNumberDetailComponent } from '@clodeo/clodeo-ui/components/base/tracking-number-detail/tracking-number-detail.component';
import { AdminMonitoringPickupRestService } from '@clodeo/libs/core/rest/admin/monitoring-pickup/admin-monitoring-pickup-rest.service';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import moment from 'moment';
import { Action } from '@clodeo/clodeo-ui/components/general/action/action.component';
import { capitalize, lowerCase } from 'lodash';
import { Tag } from '@clodeo/clodeo-ui/components/data-display/tag/tag.component';
import './monitoring-pickup-list.component.scss'
import { MonitoringPickupDetailComponent } from '../detail/monitoring-pickup-detail.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';

const authenticationService: AuthenticationService = new AuthenticationService;
const adminMonitoringPickupRestService = new AdminMonitoringPickupRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const { handleRequest } = new HandleService;
export const MonitoringPickupTemplateListComponent = (props: {
  data: IMonitoringPickup;
}) => {
  const [modalValue, setModalValue] = useState<IModalValue>();
  const { data } = props;
  const [loading, setLoading] = useState(false);
  function showAwbBill(waybillResponse) {
    setModalValue({
      visible: true,
      title: 'Rincian Pengiriman',
      contentTemplate: <TrackingNumberDetailComponent waybillResponse={waybillResponse} loading={!waybillResponse} />
    });
  }

  function loadAWBDetail(data: { courierCode: string, waybill: string }) {
    setLoading(true);
    const obs = adminMonitoringPickupRestService.getWaybillDetail(data);
    handleRequest({
      obs,
      hideError: true,
      onError: (err) => {
        setLoading(false);
        showAwbBill(err);
      },
      onDone: (res) => {
        setLoading(false);
        showAwbBill(res);
      }
    })
  }

  return <div className="list-wrapper">
    <div className="d-flex flex-row flex-wrap justify-content-between">

      <div className="d-flex flex-column justify-content-between grouping">
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-80px">Tgl Transaksi</div>
          <div className="value normal">{data.transactionDate ? moment(data.transactionDate).format('DD MMM YYYY') : '-'}</div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-80px">No. Transaksi</div>
          <div className="value">{data.transactionNumber}</div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between grouping">
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-50px">Kurir</div>
          <div className="value text-center">
            <img src={'https://nx-design-system-web-development.clodeo.com/img/logo/courier/code/' + data.shippingCourierChannelId + '.svg'} alt="" srcSet="" className="img-fluid" />
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-50px">Layanan</div>
          <div className="value text-center">{data.shippingCourierProduct || '-'}</div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between grouping">
        <div className="d-flex flex-row justify-content-between">
          <div className="label">Status</div>
          <div className="value text-center">
            <Tag size="sm" colorType={data.status || ' '}>{capitalize(data.status)}</Tag>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="label">Resi</div>
          <div className="value resi text-center clickable" onClick={() => { loadAWBDetail({ courierCode: data.shippingCourierChannelId, waybill: data.shippingTrackingNumber }) }}>
            <Spinner spinning={loading}>
              {data.shippingTrackingNumber || '-'}
            </Spinner>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between grouping">
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-100px">Status Pengiriman</div>
          <div className="value" style={data.shipmentStatus === 'received' ? {color: '#239CAA'} : {color: '#E1721D'}}>
            {capitalize(lowerCase(data.shipmentStatus))}
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-100px">Tipe Transaksi</div>
          <div className="value normal">
            {capitalize(lowerCase(data.transactionType))}
          </div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between grouping">
        <div className="d-flex flex-row justify-content-between">
          <div className="label">Status</div>
          <div className="value text-center" style={data.isSuccess ? { color: '#239CAA' } : { color: '#D93535' }}>
            {data.isSuccess ? 'Berhasil' : 'Tidak'}
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="label">Update</div>
          <div className="value normal">
            {data.lastAttempDateTimeUtc ? moment(data.lastAttempDateTimeUtc).format('DD/MM/YY HH:MM:SS') : ''}
          </div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between grouping">
        <div className="d-flex flex-column justify-content-start">
          <div className="label text-center">History</div>
          <div className="p-1 text-center">
            <Action type="history" onClick={() => setModalValue({
              visible: true,
              title: 'History',
              contentTemplate: <MonitoringPickupDetailComponent id={data.id} />
            })} />
          </div>
        </div>
      </div>

    </div>
    {modalValue && (
      <Modal
        {...modalValue}
        footer={null}
        width="fit-content"
        className="admin"
        onCancel={() => setModalValue(null)}
      >
        {modalValue.contentTemplate}
      </Modal>
    )}
  </div>
}

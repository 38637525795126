import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { IReqService } from './tenants';
const notif: Notifications = new Notifications;
export class TenantServices {
  moreFilter: {}
  setFilter(rangeDate: any[]) {
    if (rangeDate && rangeDate.length) {
      const lowDate = rangeDate[0] ? moment(rangeDate[0]).format('YYYY-MM-DD') : null;
      const hightDate = rangeDate[0] ? moment(rangeDate[0]).format('YYYY-MM-DD') : null;
      this.moreFilter = {
        lowDate,
        hightDate
      }
    }

  };

  getMoreFilter() {
    return this.moreFilter;
  }

  requestService(params: IReqService) {
    return params.obs.pipe(
      catchError((error) => {
        notif.show({
          type: 'error',
          title: 'Error',
          description: error,
          useService: true
        });
        params.onDone && params.onDone(null);
        return throwError(error);
      })
    ).subscribe((res) => {
      notif.show({
        type: 'success',
        title: 'Sukses',
        description: params.successMessage,
      });
      params.onDone && params.onDone(res);
    })
  };
}

import React, { useEffect, useRef, useState } from 'react';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { IModalValue } from '@clodeo/clodeo-ui/components/feedback/modal/modal';
import moment from 'moment';
import { Action } from '@clodeo/clodeo-ui/components/general/action/action.component';
import { sumBy, get } from 'lodash';
import { AclService } from 'apps/admin-web/src/app/core/auth/acl.service';
import { BillingPaymentConfirmationComponent } from '../confirmation/billing-payment-confirmation.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { UploadDataPaymentComponent } from '../upload-data-payment/upload-data-payment-confirmation.component';
import { Tag } from '@clodeo/clodeo-ui/components/data-display/tag/tag.component';
import { Image } from "@clodeo/clodeo-ui/components/data-display/image/image.component";
import { Tooltip } from '@clodeo/clodeo-ui/components/data-display/tooltip/tooltip.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { AdminBillingPaymentRestService } from '@clodeo/libs/core/rest/admin/billing-payment/admin-billing-payment-rest.service';
import { environment } from '../../../../../../environments/environment';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { Popover } from '@clodeo/clodeo-ui/components/data-display/popover/popover.component';

const aclService: AclService = new AclService;

export const BillingPaymentTemplateListComponent = (props: {
  data: IBillingPayment;
  onSuccessApprove: () => void
}) => {
  const { data, onSuccessApprove } = props;
  const [modalValue, setModalValue] = useState<IModalValue>();
  const [loading, setLoading] = useState(false);
  const [loadingAttachment, setLoadingAttachment] = useState(false);
  const baseListRef = useRef<any>();
  const authenticationService: AuthenticationService = new AuthenticationService;
  const adminBillingPaymentRestService = new AdminBillingPaymentRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
  const { handleRequest } = new HandleService;

  function showPayment(billingPaymentId) {
    setLoadingAttachment(true);
    const obs = adminBillingPaymentRestService.billingPaymentAttachment(billingPaymentId);
    handleRequest({
      obs,
      onError: () => {setLoading(false)},
      onDone: (data) => {
        setLoadingAttachment(false);
        return setModalValue({
          visible: true,
          onCancel: () => setModalValue(null),
          contentTemplate: <>
            <div className="container pt-3">
              <div className="row">
                {data.length === 1 ?
                  <div className="col-lg-12 text-center">
                    <div className="pt-2">
                      <Image.PreviewGroup>
                        <Image
                          src={data[0].attachment.fileUrl}
                          width="200px"
                          height="200px"
                          style={{ borderRadius: "20px", cursor: "pointer" }}
                        ></Image>
                      </Image.PreviewGroup>
                    </div>
                    <span>{data[0].attachment.fileName}</span>
                  </div>
                  :
                  data && data.map((item) => (
                    <>
                      <div className="col-lg-6 text-center">
                        <div className="pt-2">
                          <Image.PreviewGroup>
                            <Image
                              src={item.attachment.fileUrl}
                              width="200px"
                              height="200px"
                              style={{ borderRadius: "20px", cursor: "pointer" }}
                            ></Image>
                          </Image.PreviewGroup>
                        </div>
                        <span>{item.attachment.fileName}</span>
                      </div>
                    </>
                  ))
                }
              </div>
            </div>
          </>
        });
      }
    });
  }

  function showInfoModal(billingPaymentId, tenantId) {
    setLoading(true);
    const obs = adminBillingPaymentRestService.detailBillingPayment(billingPaymentId, tenantId);
    handleRequest({
      obs,
      onError: () => {baseListRef.current.hideLoading()},
      onDone: (data) => {
        setLoading(false);
        return setModalValue({
          visible: true,
          onCancel: () => setModalValue(null),
          title: `List Invoice ${data.transactionNumber}`,
          contentTemplate: <>
            <div className="col w-240px">
                { data.billingTransactions.map(data => (
                  <ul className="text-left" key={data.billingTransactionId}>
                    <li>{ get(data, 'billingTransaction.transactionNumber') }</li>
                  </ul>
                ))}
            </div>
          </>
        });
      }
    });
  }

  return <div className="list-wrapper">
    <div className="d-flex flex-row flex-wrap justify-content-between">

      <div className="d-flex flex-column justify-content-between grouping">
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-90px">No Pembayaran</div>
          <div className="value w-130px">
            <Tooltip title={data.transactionNumber || '-'}>
              {data.transactionNumber || '-'}
            </Tooltip>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-90px">Seller</div>
          <div className="value normal w-130px">
            <Tooltip title={data.sellerName || '-'}>
              {data.sellerName || '-'}
            </Tooltip>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between grouping">
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-120px">Tgl Pembayaran</div>
          <div className="value normal">{data.transactionDate ? moment(data.transactionDate).format('DD MMM YYYY') : '-'}</div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-120px">Jml Pembayaran</div>
          <div className="value normal w-100px">{data.total || 0}</div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between grouping">
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-120px">Metode Pembayaran</div>
          <div className="value normal" style={{ width: '140px' }}>{data.paymentMethod || '-'}</div>
        </div>

        <div className="d-flex flex-row justify-content-between">
          <div className="label w-100px">Jml Invoice</div>
          <div className="clickable value normal" style={{ width: '140px', color: '#45a9ff'}} onClick={() => {showInfoModal(data.id, data.tenantId)}}>
            <Spinner spinning={loading}>
              {data.billingTransactionCount || 0}
            </Spinner>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between grouping">
        <div>
          <Spinner spinning={loadingAttachment}>
            <Button iconName="deo-note-2" size="sm" type="outline" disabled={!data.attachment} onClick={() => {showPayment(data.id)}}></Button>
          </Spinner>
        </div>
        <div className="text-center mt-2">
          <Popover
            placement="top"
            title={false}
            trigger="hover"
            content={
              <>
                <p className="note-content m-0"><b>Note</b> : {data?.note || 'Tidak Ada Catatan'}</p>
              </>
            }
          >
            <a className="btn btn-deo-ghosted btn-deo-md p-2">
              <i className="icon-i-ChatCenteredText icon-14px"></i>
            </a>
          </Popover>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between grouping">
        <div className="d-flex flex-column justify-content-start" style={{ width: '160px' }}>
          <div className="label text-center w-100">Status</div>
          <div className="p-2 text-center w-100">
            {data.status === 'unapproved' &&
              <Button
                iconName="deo-upload"
                iconPosition="right"
                label="Bukti Bayar"
                type="filter"
                disabled={!aclService.can(["finance.billing.edit"])}
                className="px-3 text-center"
                onClick={() => setModalValue({
                  visible: true,
                  onCancel: () => setModalValue(null),
                  contentTemplate: <UploadDataPaymentComponent
                    onCancel={() => setModalValue(null)}
                    onFinish={() => { setModalValue(null); onSuccessApprove() }}
                    data={data}
                  />
                })}
              />
            }
            {data.status === 'pending' &&
              <>
                <Action type="accept" className="mr-2"
                  disabled={!aclService.can(["finance.billing.edit"])}
                  onClick={() => setModalValue({
                    visible: true,
                    onCancel: () => setModalValue(null),
                    contentTemplate: <BillingPaymentConfirmationComponent
                      onCancel={() => setModalValue(null)}
                      onFinish={() => { setModalValue(null); onSuccessApprove() }}
                      data={{
                        ...data,
                        isApproved: true
                      }} />
                  })} />
                <Action type="reject"
                  disabled={!aclService.can(["finance.billing.edit"])}
                  onClick={() => setModalValue({
                    visible: true,
                    onCancel: () => setModalValue(null),
                    contentTemplate: <BillingPaymentConfirmationComponent
                      onCancel={() => setModalValue(null)}
                      onFinish={() => { setModalValue(null); onSuccessApprove() }}
                      data={{
                        ...data,
                        isApproved: false
                      }} />
                  })} />
              </>
            }
            {data.status === 'approved' && <Tag colorType='paid' size="lg" style={{ width: '70px' }}>Lunas</Tag>}
            {data.status === 'reject' && <Tag colorType='deo-red' size="lg" style={{ width: '115px' }}>Ditolak</Tag>}
          </div>
        </div>
      </div>

    </div>
    {modalValue && (
      <Modal
        {...modalValue}
        footer={null}
        width="fit-content"
        className="admin"
        onCancel={() => setModalValue(null)}
      >
        {modalValue.contentTemplate}
      </Modal>
    )}
  </div>
}

import _ from 'lodash';
import moment from 'moment-timezone';
export class BillingService {

  wscols = [
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 }, // hide
    { wch: 20 }, // owner
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 }
  ]

  normalizedGetterPayload(data) {
    const result = { ...data };
    if (result.validStartDate) {
      result.validStartDate = new Date(result.validStartDate);
    }

    if (result.validEndDate) {
      result.validEndDate = new Date(result.validEndDate);
    }

    result.BillingCategory = _.castArray(result.BillingCategory);
    result.subscriptionCycles = result.subscriptionCycles.map(cycle => (cycle.subscriptionCycle));
    result.subscriptionPlans = result.subscriptionPlans.map(plan => (plan.id));
    result.tenants = result.tenants.map(tenant => ({ name: tenant.companyName, id: String(tenant.tenantId) }));
    return result;
  }

  normalizedSetterPayload(data) {
    const result = { ...data };
    result.BillingCategory = result.BillingCategory[0];
    result.validStartDate = result.validStartDate ? moment(result.validStartDate).format('YYYY-MM-DD') : result.validStartDate;
    result.validEndDate = result.validEndDate ? moment(result.validEndDate).format('YYYY-MM-DD') : result.validEndDate;
    result.subscriptionCycles = result.subscriptionCycles.map(cycle => ({
      subscriptionCycle: cycle,
      description: '',
    }));
    result.subscriptionPlans = result.subscriptionPlans.map(plan => ({
      id: plan,
      description: '',
    }));
    result.tenants = result.tenantValidType === 'none' ? [] : result.tenants.map(tenant => ({
      tenantId: tenant.id,
      tenantValidType: 'none',
    }));

    return _.omit(result, ['tenantSettingModal']);
  }

  normalizeFilterLineExport(data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].companyInfo) {
        data[i].companyName = data[i].companyInfo.name;
        data[i].companyPhone = data[i].companyInfo.phone;
        data[i].companyEmail = data[i].companyInfo.email;
        delete data[i].companyInfo;
      }

      if (data[i].billingPeriodEndDate) {
        moment(data[i].billingPeriodEndDate).format('DD-MM-YYYY')
      }

      if (data[i].billingPeriodStartDate) {
        moment(data[i].billingPeriodStartDate).format('DD-MM-YYYY')
      }

      if (data[i].ownerUser) {
        data[i].ownerName = data[i].ownerUser.fullName;
        data[i].ownerPhone = data[i].ownerUser.phoneNumber;
        data[i].ownerEmail = data[i].ownerUser.email;
        delete data[i].ownerUser
      }
    }
    return data;
  }

  normalizeLineExport(data) {
    for (let i = 0; i < data.length; i++) {
      data[i].billingPeriodEndDate = data[i].billingPeriodEndDate ? moment(data[i].billingPeriodEndDate).format('DD-MM-YYYY') : '-';
      data[i].billingPeriodStartDate = data[i].billingPeriodStartDate ? moment(data[i].billingPeriodStartDate).format('DD-MM-YYYY') : '-';
      data[i].freeTrialEndDate = data[i].freeTrialEndDate ? moment(data[i].freeTrialEndDate).format('DD-MM-YYYY') : '-';
      data[i].companyName = data[i].companyInfo.name;
      data[i].companyPhone = data[i].companyInfo.phone;
      data[i].companyEmail = data[i].companyInfo.email;
      delete data[i].companyInfo
      data[i].ownerName = data[i].ownerUser.fullName;
      data[i].ownerPhone = data[i].ownerUser.phoneNumber;
      data[i].ownerEmail = data[i].ownerUser.email;
      delete data[i].ownerUser
    }
    return data;
  }

  normalizePayloadUpgradeRenew(val) {
    let billingCycle = _.replace(val.billingCycle, 'Price', '');
    if (billingCycle === 'quaterly') {
      billingCycle = 'quarterly';
    }
    const items = [
      {
        itemType: 0,
        discountAmount: 0,
        discountPercent: 0,
        qty: val.qty,
        unitPrice: val.unitPrice,
        billingCycle,
        description: _.startCase(val.subscriptionPlanId) + ' Plan'
      }
    ];
    val.adjustmentAmount = parseFloat(val.adjustmentAmount || 0);
    val.discountAmount = val.discountType === 'Amount' ? parseFloat(val.discountAmount || 0) : 0;
    val.amountPaid = parseFloat(val.amountPaid || 0);
    val.discountPercent = val.discountType === 'Percent' ? parseFloat(val.discountPercent || 0) : null;
    val.taxRate = parseFloat(val.taxRate || 0);
    val.subscriptionStartDate = val.subscriptionStartDate.format('YYYY-MM-DD');
    delete val.qty;
    delete val.unitPrice;
    delete val.billingCycle;
    delete val.type;
    return {
      ...val,
      invoiceDate: moment(new Date).format('YYYY-MM-DD'),
      lastPaymentDate: moment(new Date).format('YYYY-MM-DD'),
      items
    }
  }
}

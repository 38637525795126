import { message } from 'antd';
import { MessagesProps } from './messages';
export class Messages {
  show(params: MessagesProps) {
    message.open({
      ...params,
      type: params.type || 'info',
      content: params.content || '',
      duration: params.duration || 2,
      className: `messages-deo messages-deo-${(params.type || '') + (params.className || '')}`
    })
  };
}

import { RequestService } from '../../request.service';
import { IPayloadTenantWallet } from './admin-wallet-transaction';
export class AdminWalletTransactionRestService {
  requestService = new RequestService;
  request: RequestService;
  /**
   *
   * @param baseUrl only from environment.ENDPOINTS
   * @param useInterceptor
   * @param withoutInterceptor
   */
  constructor(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
    this.request = this.requestService.new(`${baseUrl['API_ADMIN']}/wallet`, useInterceptor, withoutInterceptor);
  }

  /**
   * Get list of Tenant Wallet Transaction
   * @param params
   */
  findAllTenantTransaction(params) {
    return this.request.get('integrated-tenant-transaction', params)
  }

  /**
   * Get list of Tenant Wallet Transaction
   * @param params
   */
   findAllTenantTransactionOld(params) {
    return this.request.get('tenant-transaction', params)
  }

  /**
   * Get list of Tenant Wallet Transaction with more detail search params
   * @param options
   * @param params
   */
  findAllTenantTransactionQ(options, params) {
    return this.request.post('tenant-transaction', options, {
      params
    })
  }

  /**
   * Add new Wallet Transaction
   * @param payload
   */
  addWalletTransaction(payload: IPayloadTenantWallet) {
    return this.request.post('transaction', payload)
  }

  /**
   * Approve withdraw wallet transaction
   * @param id
   * @param payload
   */
  approve(id, payload) {
    return this.request.put(
      `/transaction/approve-withdraw-request/${id}`,
      payload
    );
  }

  /**
   * Get single Wallet Transaction record by id
   * @param idTransaction
   * @param params
   */
  findTenantByIdTransaction(idTransaction: string, params) {
    return this.request.get(`tenant-transaction/${idTransaction}`, { params });
    // return this.request.get(`integrated-tenant-transaction/${idTransaction}`, { params });
  }

  /**
   * Update single wallet transaction record
   * @param idTransaction
   * @param payload
   */
  updateWalletTransaction(idTransaction: string, payload: IPayloadTenantWallet) {
    return this.request.put(`transaction/${idTransaction}`, payload);
  }

  /**
   * Get history of admin
   * @param tenantId 
   */
   getAdminHistory(tenantId: string) {
    return this.request.get(`transaction/${tenantId}/histories`);
  }

  voidTransaction(transactionId, payload) {
    return this.request.put(`transaction/${transactionId}/void`, payload)
  }
}

import React, { useEffect, useState } from 'react';
import './detail-and-approval-request.component.scss'
import { map, find, get } from 'lodash'

import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { ShippingIntegrationRestService } from '@clodeo/libs/core/rest/admin/shipping-integration/shipping-Integration-rest.service';
import { DownloadFileRestService } from '@clodeo/libs/core/rest/admin/download-file/download-file-rest.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { ReportService } from '@clodeo/libs/core/report/report.service';

import { Form } from 'antd';
import { LoadingComponent } from '@clodeo/clodeo-ui/components/general/loading/loading.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { Confirms } from '@clodeo/clodeo-ui/components/feedback/confirm/confirm.component';
import { IColumn } from 'apps/admin-web/src/app/components/base-component/list/base-list';
import { Table } from '@clodeo/clodeo-ui/components/data-display/table/table.component';
import * as FileSaver from "file-saver";
import { HandleService } from '@clodeo/libs/core/handle/handle.service';

const authenticationService: AuthenticationService = new AuthenticationService();
const shippingIntegrationRestService = new ShippingIntegrationRestService(`${environment.ENDPOINTS.API_ADMIN}`, authenticationService.axiosInterceptors);
const downloadFileRestService = new DownloadFileRestService(`${environment.ENDPOINTS.API_FILE}`, authenticationService.axiosInterceptors);
const reportService = new ReportService(`${environment.ENDPOINTS.API_EXTSRV_REPORT}`)
const { handleRequest } = new HandleService;

export const DetailAndApproval = (props: IDetailProps) => {
  const { relatedData, tenantId, onClose, onFinish } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const [dataTenantShippingIntegration, setDataTenantShippingIntegration] = useState<any>([]);
  const [form] = Form.useForm();

  const confirm: Confirms = new Confirms;

  const columns: IColumn[] = [
    {
      key: '1',
      dataIndex: 'courier',
      title: 'Kurir',
      width: '100px'
    },
    {
      key: '2',
      dataIndex: 'memberId',
      title: 'Member Id',
      width: '100px'
    },
    {
      key: '3',
      dataIndex: 'memberStatus',
      title: 'Status Member',
      width: '80px',
      render: (val) => val ? 'Ya' : 'Tidak'
    },
    {
      key: '4',
      dataIndex: 'shippingTermAndConditions',
      title: 'Aksi',
      width: '150px',
      align: 'center',
      render: (val: any, record: any) => <Button type="primary" label="SK" size="sm" iconName="deo-download" iconPosition="right" disabled={!record.memberStatus || !record.shippingTermAndConditions} onClick={() => downloadTermAndConditon(record)} />
    }
  ];

  function loadTenantShippingIntegration() {
    setLoading(true);
    const obs = shippingIntegrationRestService.getSingleTenantShippingIntegration(tenantId)
    handleRequest({
      obs,
      onDone: (res) => {
        setData(res);
        normalizeDataTable(res);
        setLoading(false)
      },
      onError: () => {
        setLoading(false)
      }
    })
  }

  function downloadFileTenant() {
    const payloads = {
      tenantId: tenantId,
      zipFileNmae: `${data.tenantShippingIntegration.idCardName + data.tenantShippingIntegration.tenantId}.zip`,
      fileIds: map(data.tenantShippingIntegration.tenantShippingIntegrationInfos, 'file.id')
    }
    setLoading(true);
    const obs = downloadFileRestService.downloadCompressedFile(payloads)
    handleRequest({
      obs,
      successMessage: 'Data berhasil di download.',
      errorMessage: 'Gagal download file',
      onDone: (res) => {
        var file = new Blob([res], { type: "application/zip" });
        FileSaver.saveAs(file, `${data.tenantShippingIntegration.companyName || 'NoCompanyName'}.zip`);
        setLoading(false);
        onFinish
      },
      onError: () => setLoading(false),
    })
  }

  function downloadDataBankTenant() {
    setLoading(true);
    const templateConfig = {
      template_id: 'TBANK001',
      display_type: 'pdf',
      data: {
        data: {
          companyName: data.tenantShippingIntegration.companyName,
          idCardNumber: data.tenantShippingIntegration.idCardNumber,
          ownerName: data.tenantShippingIntegration.idCardName,
          accountName: data.tenantShippingIntegration.tenantBank.accountName,
          accountNumber: data.tenantShippingIntegration.tenantBank.accountNumber,
          bankName: data.tenantShippingIntegration.tenantBank.bank.bankName,
          district: data.tenantShippingIntegration.tenantBank.district
        },
        params: data.tenantShippingIntegration,
        config: { templateCode: "TBANK001" }
      }
    }
    const obs = reportService.render(templateConfig);
    handleRequest({
      obs,
      successMessage: 'Data berhasil di download.',
      errorMessage: 'Gagal download file',
      onDone: (res) => {
        res.download(`Tenant-Bank-${data.tenantShippingIntegration.companyName}.pdf`);
        setLoading(false);
      },
      onError: () => setLoading(false),
    })
  }

  function downloadTermAndConditon(record: any) {
    setLoading(true)
    // normalize payload must be the same with template
    const templateConfig = {
      template_id: 'SNK001',
      display_type: 'pdf',
      data: {
        data: {
          companyInfo: {
            companyName: data.tenantShippingIntegration.companyName,
            ownerName: data.tenantShippingIntegration.idCardName,
            phone: data.tenantShippingIntegration.phoneNumber,
            email: data.tenantShippingIntegration.email,
            warehouseAddress: data.warehouseAddress,
          },
          courier: record.courier,
          signatureImage: data.signatureImage,
          shippingTermAndConditions: record.shippingTermAndConditions.termAndCondition
        },
        params: data.tenantShippingIntegration,
        config: { templateCode: "SNK001" }
      }
    }

    const obs = reportService.render(templateConfig);
    handleRequest({
      obs,
      successMessage: 'Data berhasil di download.',
      errorMessage: 'Gagal download file',
      onDone: (res) => {
        res.download(`SNK-${record.courier}.pdf`);
        setLoading(false);
      },
      onError: () => setLoading(false),
    });
  }

  function showApproveDialod() {
    confirm.show({
      title: 'KONFIRMASI DATA',
      content: 'Apakah anda yakin untuk menyetujui data tenant ?',
      onOk() { approveRequest(true) }
    });
  }

  function showRejectDialog() {
    confirm.show({
      title: 'KONFIRMASI DATA',
      content: 'Apakah anda yakin akan menolak data tenant ?',
      onOk() { approveRequest(false) }
    });
  }

  function approveRequest(isApprove: boolean) {
    setLoading(true);
    const tenantId = data.tenantShippingIntegration.tenantId;
    const payload = {
      isApproved: isApprove
    }
    const obs = shippingIntegrationRestService.approvalRequest(tenantId, payload)
    handleRequest({
      obs,
      successMessage: 'Data berhasil disimpan.',
      onDone: onFinish
    })
    setLoading(false)
  }

  function normalizeDataTable(data: any) {
    const dataTenantShippingIntegration = []
    const listShippingSettings = relatedData.shippingCourierDetails;
    listShippingSettings.forEach(element => {
      const dataShippingTermAndConditions = find(data.shippingTermAndConditions, ['courierChannelId', element.code]);
      const courier = find(data.tenantShippingSetting, ['courierChannelId', element.code]);

      const dataCourier = {
        courier: element.code,
        name: element.name,
        memberId: get(courier, 'memberId') || '-',
        memberStatus: get(courier, 'shippingService') > 0 ? true : false,
        shippingTermAndConditions: dataShippingTermAndConditions
      }
      dataTenantShippingIntegration.push(dataCourier);
    });
    return setDataTenantShippingIntegration(dataTenantShippingIntegration);
  }

  useEffect(() => {
    loadTenantShippingIntegration()
  }, [tenantId, props.relatedData])

    return (
      <Spinner spinning={loading} indicator={<LoadingComponent />}>
        <div className="container-fluid content-wrapper">
          {data && <>
            {data.tenantShippingIntegration.statusApproval === 'verified' && <span><span className="icon-deo-info" /> Menunggu pengguna mengisi formulir persetujuan </span>}
            <div className="row pb-3">
              <div className="col-lg-4">
                <div className="bg-wrapper h-100">
                  <div className="form-group">
                    <label className="label-content">Nama</label>
                    <p className="data">{data.tenantShippingIntegration.idCardName}</p>
                  </div>

                  <div className="form-group">
                    <label className="label-content">Nomor Induk Kependudukan</label>
                    <p className="data">{data.tenantShippingIntegration.idCardNumber}</p>
                  </div>

                  <div className="form-group">
                    <label className="label-content">Bank</label>
                    <p className="data">{data.tenantShippingIntegration.tenantBank.bank.bankName}</p>
                  </div>

                  <div className="form-group">
                    <label className="label-content">Nomor Rekening</label>
                    <p className="data">{data.tenantShippingIntegration.tenantBank.accountNumber}</p>
                  </div>

                  <div className="form-group">
                    <label className="label-content">Nama Nasabah</label>
                    <p className="data">{data.tenantShippingIntegration.tenantBank.accountName}</p>
                  </div>

                  <div className="form-group">
                    <label className="label-content">Cabang Kota/Kabupaten</label>
                    <p className="data">{data.tenantShippingIntegration.tenantBank.district}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="row d-flex flex-row-reverse">
                  <div className="pb-3">
                    <Button type="primary" label="Foto" className="mr-3" iconName="deo-download" iconPosition="right" onClick={() => downloadFileTenant()} />
                    <Button type="primary" label="Data Bank" iconName="deo-download" iconPosition="right" onClick={() => downloadDataBankTenant()} />
                  </div>
                  <div className="table-bg-wrapper">
                    <Table
                      columns={columns}
                      size="small"
                      dataSource={dataTenantShippingIntegration}
                      scroll={{ y: 350 }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {data.tenantShippingIntegration.statusApproval === 'verified' &&
              <div className="row w-100 justify-content-between">
                <div>
                  <Button type="ghosted" label="Cancel" className="mr-3" onClick={() => onClose()} />
                </div>
                <div>
                  <Button type="warning" label="Tolak" className="mr-3" onClick={() => showRejectDialog()} />
                  <Button submit type="primary" label="Simpan" onClick={() => showApproveDialod()} />
                </div>
              </div>
            }
          </>}
        </div>
      </Spinner >
    )
  }

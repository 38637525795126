import React, { useEffect } from 'react';
import { RangePicker } from '@clodeo/clodeo-ui/components/data-entry/datepicker/datepicker.component';
import moment from 'moment';
import { Form } from 'antd';


const DashboardMoreFilterComponent = (props: {
  onMoreFilterChanges?: (value: {}) => void;
  values?: any;
}) => {
  const { onMoreFilterChanges, values } = props;
  const [form] = Form.useForm();

  function onFieldsChange() {
    const dateValues = form.getFieldValue("dateValues");
    let lowDate: string;
    let highDate: string;

    if (dateValues && dateValues.length) {
      lowDate = moment(dateValues[0]).format('YYYY-MM-DD');
      highDate = (dateValues[1]).format('YYYY-MM-DD');
    }

    onMoreFilterChanges({
      lowDate,
      highDate,
    });
  }

  useEffect(() => {
    if(values.lowDate && values.highDate) {
      form.setFieldsValue({dateValues: [moment(values.lowDate),moment(values.highDate)]})
    } else {
      form.setFieldsValue({dateValues: []})
    }
  }, [values]);

  return (
    <div className="d-flex" id="dashboard-more-filter-component">
      <Form form={form} onFieldsChange={onFieldsChange}>
        <div className="d-flex flex-column">
          <Form.Item name="dateValues">
            <RangePicker />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
};

export { DashboardMoreFilterComponent };

import { Observable, throwError } from 'rxjs';
import { tap, switchMap, catchError } from 'rxjs/operators';
import _ from 'lodash';
import Axios, { AxiosInstance, AxiosPromise, AxiosResponse, AxiosStatic } from 'axios';

import { TokenRestService } from '@clodeo/libs/core/rest/account/token/token-rest.service';
import { UserRestService } from '@clodeo/libs/core/rest/account/users/user-rest.service';
import { TemporaryFixService } from '@clodeo/libs/core/common/temporary-fix.service';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { environment } from 'apps/admin-web/src/environments/environment';
const temporaryService: TemporaryFixService = new TemporaryFixService;
const notifications: Notifications = new Notifications;
const tokenRestService: TokenRestService = new TokenRestService(`${environment.ENDPOINTS.API_ACCOUNT}`);

export class MUser {
  access_permissions: string[];
  access_token: string;
  email: string;
  company_id: string;
  company_name: string;
  firstName: string;
  first_name: string;
  id: string;
  user_id: string;
  last_name: string;
  lastName: string;
  refresh_token: string;
  roles: string[];
  user_name: string;
  timezone_offset: number | string;
  is_bot_agent: boolean | string;
  is_complete_wizard: boolean | string;
  is_new_tenant: boolean;
}

let data: MUser;
let maxRefreshTokenRetry: number = 2;

// just for experimental, so this function need to improve
const refreshToken = () => {
  const payload = {
    grant_type: "refresh_token",
    refresh_token: data.refresh_token,
    client_id: "clodeo-admin-web"
  };
  return tokenRestService.getToken(payload)
    .pipe(
      tap((loginData) => {
        data = loginData;
        localStorage.setItem('currentUser', JSON.stringify(loginData));
        window.location.reload();
      })
    );
}

export class AuthenticationService {
  clientId = 'clodeo-admin-web';
  tokenRestService: TokenRestService = new TokenRestService(`${environment.ENDPOINTS.API_ACCOUNT}`);
  userRestService: UserRestService;
  constructor() {
    this.userRestService  = new UserRestService(`${environment.ENDPOINTS.API_ACCOUNT}`, this.axiosInterceptors);
    const currentUser = localStorage.getItem('currentUser');

    // checking if user manually insert the localstorage
    let user;
    try {
      user = JSON.parse(currentUser);
    } catch (e) {
      this.logout();
    }

    if (user && _.has(user, 'access_token')) {
      this.user = <any>user;
    } else {
      this.user = null;
    }
  }

  set user(newData: MUser) {
    data = newData;
  }

  get user() {
    return data;
  }

  axiosInterceptors(axios: AxiosInstance | AxiosStatic, interceptRequest: boolean = true, interceptResponse: boolean = true) {
    axios.interceptors.request.use(request => {
      request.headers.common['Timezone-Offset'] = _.get(data, 'timezone_offset') || (new Date).getTimezoneOffset();
      request.headers.common['Content-Type'] = 'application/json;charset=UTF-8';
      request.headers.common['Accept'] = 'application/json, text/plain, */*';
      // if use multi language this config need to improve
      request.headers.common['Accept-Language'] = 'id-ID';

      return request;
    });

    if (interceptRequest) {
      axios.interceptors.request.use(request => {
        if (data) {
          request.headers.common['Authorization'] = `Bearer ${data.access_token}`;
        }
        return request;
      });
    }

    if (interceptResponse) {
      axios.interceptors.response.use(response => {
        temporaryService.adressObjectNullHandler(response);

        return response;
      }, async error => {
        const response = error.response;
        if (
          response &&
          response.status === 401 &&
          response.config &&
          response.config.headers &&
          response.config.headers['Authorization']
        ) {

          return refreshToken().pipe(
            switchMap(function (accessToken) {
              data = accessToken;
              response.config.headers['Authorization'] = `Bearer ${accessToken}`;
              return axios.request(response.config);
            }),
            catchError((err) => {
              // notifications.show({
              //   type: "error",
              //   title: "Error",
              //   description: err,
              //   useService: true
              // });
              data = null;
              localStorage.removeItem('currentUser');
              window.location.href = '/login';
              return throwError(err);
            }),
          ).toPromise()
        }
        throw error;
      });
    }
  }

  login(payload: any) {
    const newPayload = {
      ...payload,
      client_id: this.clientId,
      grant_type: 'password'
    };

    return tokenRestService.getToken(newPayload)
      .pipe(
        tap((loginData) => {
          this.user = loginData;
          localStorage.setItem('currentUser', JSON.stringify(loginData));
        })
      );
  }

  logout() {
    data = null;
    localStorage.removeItem('currentUser');
    window.location.href = '/login'
  }
}

import { IModalValue } from "@clodeo/clodeo-ui/components/feedback/modal/modal";
import { HandleService } from "@clodeo/libs/core/handle/handle.service";
import { AdminBillingTransactionRestService } from "@clodeo/libs/core/rest/admin/billing-transaction/admin-billing-transaction-rest.service";
import { AuthenticationService } from "../../../../../core/auth/authentication.service";
import _ from 'lodash';
import { tap } from 'rxjs/operators';
import { environment } from "../../../../../../environments/environment";
import React, { useEffect, useRef, useState } from "react";
import { BillingAdjustmentSummaryComponent } from "../summary/billing-adjustment-summary.component";
import { BaseListComponent } from "apps/admin-web/src/app/components/base-component/list/base-list.component";
import { BillingShippingReturnMoreFilterComponent } from "../../return/more-filter/billing-shipping-return-more-filter.component";
import { Modal } from "@clodeo/clodeo-ui/components/feedback/modal/modal.component";
import { IColumn, IIncludes } from "apps/admin-web/src/app/components/base-component/list/base-list";
import { BillingAdjustmentTemplateListComponent } from "./billing-adjustment-list-template.component";

export const BillingAdjustmentListComponent = () => {
  const filters = ['keyword', 'options.take', 'options.skip', 'options.includeTotalCount', 'tenantId', 'filterDateType', 'lowFilterDate', 'highFilterDate', 'paymentChannel', 'paymentStatus', 'status'];
  const baseListRef = useRef<any>();
  const authenticationService: AuthenticationService = new AuthenticationService;
  const adminBillingTransactionRestService = new AdminBillingTransactionRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);

  const { handleRequest } = new HandleService;
  const [modalValue, setModalValue] = useState<IModalValue>()
  const [billingAdjustmentSummary, setBillingAdjustmentSummary] = useState({
    totalUnpaid: null,
    totalPartiallyPaid: null,
    totalPaid: null,
    total: null
  });
  const [summaryFilter, setSummaryFilter] = useState(null);
  const [firstInit, setFirstInit] = useState<boolean>(true);

  const columns: IColumn[] = [
    {
      key: '1',
      dataIndex: '',
      render: (record: any) => {
        return (<BillingAdjustmentTemplateListComponent data={record} />);
      }
    }
  ];

  function loadDataObservable(params) {
    if (params.paymentChannel) {
      params.paymentChannel = (params.paymentChannel).toString();
    }
    if (params.status) {
      params.status = (params.status).toString();
    }
    if (params.paymentStatus) {
      params.paymentStatus = (params.paymentStatus).toString();
    }
    const filterBySummary = summaryFilter;
    const moreFilterAndParams = _.pick(params, filters)
    const qParams = { ...moreFilterAndParams, ...filterBySummary }
    return adminBillingTransactionRestService.getBillingAdjustmentMini({ params: qParams }).pipe(
      tap(() => loadBillingShippingReturnSummary(params))
    )
  }

  function loadBillingShippingReturnSummary(params) {
    const summaryParams = {
      lowDate: params.lowCreatedDate,
      highDate: params.highCreatedDate
    }
    const obs = adminBillingTransactionRestService.getBillingAdjustmentSummary({ params: summaryParams })
    handleRequest({
      obs,
      onDone: (res) => setBillingAdjustmentSummary(res)
    })
  }

  useEffect(() => {
    if (!firstInit) {
      baseListRef.current.callLoadData();
    }
    setFirstInit(false)
  }, [summaryFilter]);

  const includes: IIncludes = {
    refresh: true,
    keyword: true,
    toogleOptions: true,
  }

  return (
    <>
      <BillingAdjustmentSummaryComponent billingAdjustmentSummary={billingAdjustmentSummary} onChangeFilter={setSummaryFilter}></BillingAdjustmentSummaryComponent>
      <BaseListComponent
        listId="billing-shipping-return-list"
        columns={columns}
        ref={baseListRef}
        loadDataObservable={loadDataObservable}
        includes={{
          ...includes,
          moreFilter: {
            templateRf: (form) => <BillingShippingReturnMoreFilterComponent form={form} />
          }
        }}
      />
      {modalValue && (
        <Modal
          {...modalValue}
          footer={null}
          className="admin"
          onCancel={() => setModalValue(null)}
        >
          {modalValue.contentTemplate}
        </Modal>
      )}
    </>
  )
}
import React from 'react';

import { InputNumber as BaseInputNumber } from 'antd';
import { InputNumberProps } from './inputnumber';
import { random } from 'lodash';

const setPadding = (type: string, label: string) => {
  switch(label?.length) {
    case 1 :
      return type + '-25px';
    case 2 :
      return type + '-35px';
    case 3 :
      return type + '-45px';
    default:
      return type + '-60px';
  }
}

const InputNumber = (props: InputNumberProps) => {
  const inputID = props.unit && 'inputNumber-deo-' + random(100, 500);

  return (
      <div className="inputNumber-deo-wrapper d-flex">
        <BaseInputNumber
          {...props}
          id={inputID}
          size={props.size ? props.size : props.size == 'medium' ? 'middle' : 'middle'}
          formatter={value => inputID ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : `${value}`}
          parser={value => inputID ? value.replace(/(,*)/g, '') : value}
          className={'inputNumber-deo' +
                      (props.unit ? ` inputNumber-deo-${setPadding(props.unit?.type, props.unit?.label)}` : '') +
                      (props.stepper == undefined || props.stepper == false ? ' no-stepper ' : '') +
                      (props.className || '')}
          style={{...props.style}}
        />
        {inputID && props.unit?.label &&
          <label
            htmlFor={inputID}
            className={'inputNumber-deo-label-' + (props.unit?.type + '-' + (props.size ? props.size : 'medium')) + (props.disabled ? ' inputNumber-deo-label-disabled' : '') }
          >
            {props.unit?.label?.length < 4 ?
              props.unit?.label :
              props.unit?.label?.slice(0, 3) + '...'}
          </label>
        }
      </div>
  )
}

export { InputNumber }

import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
const notif: Notifications = new Notifications;
export class UserService {

  requestService(params: IReqService) {
    return params.obs.pipe(
      catchError((error) => {
        notif.show({
          type: 'error',
          title: 'Error',
          description: error,
          useService: true
        });
        params.onDone && params.onDone(null);
        return throwError(error);
      })
    ).subscribe((res) => {
      params.successMessage && notif.show({
        type: 'success',
        title: 'Sukses',
        description: params.successMessage,
      });
      params.onDone && params.onDone(res);
    })
  };
}

declare interface IReqService {
  obs: Observable<any>;
  successMessage: string;
  errorMessage: string;
  onDone: (res: any) => void;
}
import React from 'react';

import { Card as BaseCard,  } from 'antd';
import { CardProps } from 'antd/lib/card/index.d';


const { Meta, Grid } = BaseCard;
const Card = (props: CardProps) => (
  <BaseCard
    {...props}
    className={'card-deo ' + (props.className || '')}
  />
)

export { Card, Meta, Grid }
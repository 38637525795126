import { AutoComplete, Option } from '@clodeo/clodeo-ui/components/data-entry/autocomplete/autocomplete.component';
import { RangePicker } from '@clodeo/clodeo-ui/components/data-entry/datepicker/datepicker.component';
import { Option as OptionSelect, Select } from '@clodeo/clodeo-ui/components/data-entry/select/select.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { AdminPickerRestService } from '@clodeo/libs/core/rest/admin/picker/admin-picker-rest.service';
import { Form } from 'antd';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import moment from 'moment';
import React, { useState } from 'react';

const authenticationService: AuthenticationService = new AuthenticationService();
const adminPickerRestService = new AdminPickerRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const { handleRequest } = new HandleService;

export const BillingShippingReturnMoreFilterComponent = (porps: any) => {
  const { form } = porps;
  const [tenantList, setTenantList] = useState<any[]>();
  const [loadingTenant, setLoadingTenant] = useState<boolean>(false);

  const invoiceListDateTypes = [
    { label: 'Tanggal Transaksi', value: 'transactionDate' },
    { label: 'Due date', value: 'dueDate' },
    { label: 'Tanggal pembayaran', value: 'paymentDate' },
  ];

  const availablePaymentStatus = [
    { label: 'Dibayar sebagian', value: 'partial_paid' },
    { label: 'Belum dibayar', value: 'unpaid' },
    { label: 'Lunas', value: 'paid' }
  ];

  const statuses = [
    { label: 'Lunas', value: 'approved' },
    { label: 'Pending', value: 'pending' },
    { label: 'Ditolak', value: 'reject' }
  ];

  function onChangeCreateDate() {
    const filterDate = form.getFieldValue('filterDate');
    filterDate && form.setFieldsValue({
      lowFilterDate: moment(filterDate[0]).format('YYYY-MM-DD'),
      highFilterDate: moment(filterDate[1]).format('YYYY-MM-DD')
    })
  }


  function loadTenant(query) {
    setLoadingTenant(true);
    const obs = adminPickerRestService.getTenant({}, { keyword: query });
    handleRequest({
      obs,
      onDone: (res: any) => {
        setTenantList(res.data);
        setLoadingTenant(false);
      }
    })
  }

  function onSelectTenantId(key, option) {
    form.setFieldsValue({
      tenantId: option.key
    })
  }

  return <>
    <div className="row">
      <div className="col-lg-6" style={{ width: '300px' }}>
        <div className="form-group">
          <label>Tipe Filter Tanggal</label>
          <Form.Item name="filterDateType">
            <Select allowClear placeholder="Pilih Tipe Tanggal">
              {invoiceListDateTypes.map(data => (
                <OptionSelect key={data.value} value={data.value}>
                  {data.label}
                </OptionSelect>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>
      <div className="col-lg-6" style={{ width: '300px' }}>
        <div className="form-group">
          <label htmlFor="">Tanggal</label>
          <Form.Item name="filterDate">
            <RangePicker onChange={onChangeCreateDate} className="w-100" />
          </Form.Item>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-6" style={{ width: '300px' }}>
        <div className="form-group">
          <label htmlFor="">Status Pembayaran</label>
          <Form.Item name="paymentStatus">
            <Select mode="multiple">
              {
                availablePaymentStatus && availablePaymentStatus.map(status => (
                  <OptionSelect value={status.value}>
                    {status.label}
                  </OptionSelect>
                ))
              }
            </Select>
          </Form.Item>
        </div>
      </div>
      <div className="col-lg-6" style={{ width: '300px' }}>
        <div className="form-group">
          <label htmlFor="">Seller</label>
          <Spinner spinning={loadingTenant}>
            <Form.Item name="tenant">
              <AutoComplete
                onDropdownVisibleChange={(open) => open ? loadTenant('') : setTenantList(null)}
                onSearch={loadTenant}
                onSelect={onSelectTenantId}
                onClear={() => form.setFieldsValue({ 'tenantId': '' })}
              >
                {
                  tenantList?.map((tenant) => (
                    <Option key={tenant.id} value={tenant.displayName}>
                      {tenant.displayName}
                    </Option>
                  ))
                }
              </AutoComplete>
            </Form.Item>
          </Spinner>
        </div>
      </div>
    </div>

  </>
}


import { Radio as BaseRadio } from 'antd'
import React from 'react'
import { SelectButtonProps, SelectGroupProps } from './select-button'

const SelectGroup = (props: SelectGroupProps) => (
  <BaseRadio.Group
    {...props}
    className={"select-group-deo " + (props.type || 'select') + ' ' + (props.className || '')}
  />
);
const SelectButton = (props: SelectButtonProps) => (
  <BaseRadio.Button
    {...props}
    className={"select-button-deo " + (props.type || 'select') + ' ' + (props.className || '')}
  />
);

export {
  SelectGroup,
  SelectButton
}

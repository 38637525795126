import React, { useState } from 'react';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { IModalValue } from '@clodeo/clodeo-ui/components/feedback/modal/modal';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { TrackingNumberDetailComponent } from '@clodeo/clodeo-ui/components/base/tracking-number-detail/tracking-number-detail.component';
import { AdminMonitoringPickupRestService } from '@clodeo/libs/core/rest/admin/monitoring-pickup/admin-monitoring-pickup-rest.service';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import moment from 'moment-timezone';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { AclService } from 'apps/admin-web/src/app/core/auth/acl.service';
import { AdminTenantSalesInvoiceRestService } from '@clodeo/libs/core/rest/admin/tenant-sales-invoice/admin-tenant-sales-invoice-rest.service';
import { LogdetailComponent } from 'apps/admin-web/src/app/components/user-log-detail/log-detail.component';

export const SendRequestPickupTemplateListComponent = (props: {
  recordData: any;
  pay: any;
  resend: any;
}) => {
  const aclService: AclService = new AclService;
  const { recordData, pay, resend } = props;
  const [modal, setModal] = useState<IModalValue>();
  const [loading, setLoading] = useState(false);
  const [userLogDetailLoading, setUserLogDetailLoading] = useState(false);
  const authenticationService: AuthenticationService = new AuthenticationService();
  const { handleRequest } = new HandleService;
  const adminMonitoringPickupRestService = new AdminMonitoringPickupRestService(
    environment.ENDPOINTS,
    authenticationService.axiosInterceptors
  );

  const adminTenantSalesInvoiceRestService = new AdminTenantSalesInvoiceRestService(
    environment.ENDPOINTS,
    authenticationService.axiosInterceptors
  );

  function showAwbBill(waybillResponse) {
    setModal({
      visible: true,
      title: 'Rincian Pengiriman',
      contentTemplate: (
        <TrackingNumberDetailComponent
          waybillResponse={waybillResponse}
          loading={!waybillResponse}
        />
      ),
    });
  }
  function loadAWBDetail(data: { courierCode: string; waybill: string }) {
    setLoading(true);
    const obs = adminMonitoringPickupRestService.getWaybillDetail(data);
    handleRequest({
      obs,
      hideError: true,
      onError: (err) => {
        setLoading(false);
        showAwbBill(err);
      },
      onDone: (res) => {
        setLoading(false);
        showAwbBill(res);
      }
    })
  }

  function prepareUserLogDetailModal(tenantId: string, invoiceId: string) {
    setUserLogDetailLoading(true);
    const obs = adminTenantSalesInvoiceRestService.getAdminHistory(tenantId, invoiceId);
    handleRequest({
      obs,
      hideError: true,
      onError: (err) => {
        setUserLogDetailLoading(false);
      },
      onDone: (res) => {
        setUserLogDetailLoading(false);
        setModal({
          title: 'Log Detail',
          visible: true,
          contentTemplate: <LogdetailComponent record={res} />
        });
      }
    })
  }

  return (
    <>
      <div className="list-wrapper">
        <div className="d-flex flex-row flex-wrap justify-content-between">

          <div className="d-flex flex-column justify-content-between grouping">
            <div className="d-flex flex-row justify-content-between">
              <div className="label">Tenant ID</div>
              <div className="value">{recordData.tenantId || '-'}</div>
            </div>
            <div className="d-flex flex-row justify-content-between">
              <div className="label">Nama OS</div>
              <div className="value normal">
                {recordData.companyName || '-'}
              </div>
            </div>
          </div>

          <div className="d-flex flex-column justify-content-between grouping">
            <div className="d-flex flex-row justify-content-between">
              <div className="label col-6">Nomor Invoice</div>
              <div className="value">{recordData.transactionNumber || 0}</div>
            </div>
            <div className="d-flex flex-row justify-content-between">
              <div className="label col-6">Tanggal Pickup</div>
              <div className="value normal">
                {moment(recordData.createDateUtc).format(
                  'DD/MM/YYYY'
                )}
              </div>
            </div>
          </div>

          <div className="d-flex flex-column justify-content-between grouping">
            <div className="d-flex flex-row justify-content-between">
              <div className="label">Kurir</div>
              <div className="value text-center">
                <img
                  src={
                    'https://nx-design-system-web-development.clodeo.com/img/logo/courier/code/' +
                    recordData.courierChannelId +
                    '.svg'
                  }
                  alt=""
                  srcSet=""
                  className="img-fluid"
                />
                <b><p className="pt-1">{recordData.shippingCourierProduct}</p></b>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between">
              <div className="label">Resi</div>
              <div
                className="value resi text-center clickable"
                onClick={() => {
                  loadAWBDetail({
                    courierCode: recordData.courierChannelId,
                    waybill: recordData.airwaybillNumber,
                  });
                }}
              >
                <Spinner spinning={loading}>
                  {recordData.airwaybillNumber || '-'}
                </Spinner>
              </div>
            </div>
          </div>

          <div className="d-flex flex-column justify-content-between grouping">
            <div className="d-flex flex-row justify-content-between">
              <div className="label">Status</div>
              <div className="value" style={{ color: '#239CAA' }}>{recordData.shippingCOD === true ? 'COD' : 'NON COD'}</div>
            </div>
            <div className="d-flex flex-row justify-content-between">
              <div className="label">Nilai Total</div>
              <div className="value normal">
                {'Rp ' + (recordData.totalAmount || 0).toLocaleString()}
              </div>
            </div>
          </div>

          <div className="d-flex flex-column justify-content-between grouping">
            <div className="d-flex flex-row justify-content-between">
              <div className="label">User Log Detail</div>
              <div className="value">
                <div className="value resi text-center clickable"
                  onClick={() => { prepareUserLogDetailModal(recordData.tenantId, recordData.id) }}
                >
                  <Spinner spinning={userLogDetailLoading}>
                    Open
                  </Spinner>
                </div>
              </div>
            </div>
            <div className="d-flex flex-row justify-content-between">
              <div className="label">Update</div>
              <div className="value normal">
                {recordData.userAdminInfo?.length ? recordData.userAdminInfo[0]?.lastEditDateUtc : '-'}
              </div>
            </div>
          </div>

          {
            aclService.can(['tenant.request_pickup.edit']) && (
              <div className="d-flex flex-column justify-content-between grouping">
                <Button
                  type="tertiary"
                  label="Resend"
                  className="mb-2"
                  onClick={() => resend(recordData.id)}
                />
                <Button type="tertiary" label="Pay" onClick={() => pay(recordData.id)} />
              </div>
            )}
        </div>
        {modal && (
          <Modal
            {...modal}
            footer={null}
            className="admin"
            onCancel={() => setModal(null)}
          >
            {modal.contentTemplate}
          </Modal>
        )}
      </div>
    </>
  );
};

import { Switch } from '@clodeo/clodeo-ui/components/data-entry/switch/switch.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { AdminRestService } from '@clodeo/libs/core/rest/admin/admin/admin-rest.service';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import React, { useEffect, useState } from 'react';
import { TenantServices } from '../../../tenants.service';
import './warehouse-channels.component.scss';
const authenticationService: AuthenticationService = new AuthenticationService;
const tenantsRestService = new AdminRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const tenantService = new TenantServices;

export const WareHouseChannelsSettingsComponent = (props: {
  record: IWarehouseChannels[];
  tenantId: string;
}) => {
  const { record, tenantId } = props;
  const [loading, setLoading] = useState(false);
  const [warehouseChannels, setDoc] = useState<IWarehouseChannels[]>();


  useEffect(() => {
    setDoc(record);
  }, [record]);

  function loadData() {
    setLoading(true);
    tenantsRestService.loadDataById(tenantId).subscribe((res: any) => { setDoc(res.warehouseChannels); setLoading(false) });
  }

  function onUpdateStatus(warehouseId: string, isAlreadyMember: boolean) {
    setLoading(true);
    const obs = tenantsRestService.warehouseChannels(tenantId, warehouseId, isAlreadyMember);
    tenantService.requestService({
      errorMessage: '',
      successMessage: 'Warehouse channel berhasil diubah ',
      obs,
      onDone: () => {
        loadData()
      }
    })
  }

  return <Spinner spinning={loading}>
    <div className="wrapper-warehouse-channels container-lg container-fluid">
      <div className="row">
        {
          warehouseChannels && warehouseChannels.map((data, i: number) => (
            <div className="col-lg-auto col-12 p-2" key={i}>
              <div className="card-warehouse-channels h-100">
                <div id={'form-' + data.channelWarehouseId}
                  className="p-3 row  justify-content-between">
                  <div className="col-12">
                    <div className="row justify-content-center mb-2">
                      <label className="col-md-6 field-label align-self-center">
                        Nama Warehouse Channels
                      </label>
                      <div className="col-md-6 field-value align-self-center">
                        {data.channelWarehouseName || '-'}
                      </div>
                    </div>
                    <div className="row justify-content-center mb-2">
                      <label className="col-md-6 field-label align-self-center">
                        Nama Warehouse
                      </label>
                      <div className="col-md-6 field-value">
                        {data.warehouseName || '-'}
                      </div>
                    </div>
                    <div className="row justify-content-center mb-2">
                      <label className="col-md-6 field-label align-self-center">
                        Status Member
                      </label>
                      <div className="col-md-6 field-value align-self-center">
                        <Switch checked={data.isAlreadyMember} onChange={(e) => onUpdateStatus(data.warehouseId, e)} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  </Spinner >
}
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component'
import { AdminBillingTransactionRestService } from '@clodeo/libs/core/rest/admin/billing-transaction/admin-billing-transaction-rest.service';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import React from 'react';
import { useState } from 'react'
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { UploadTemplateList } from '@clodeo/clodeo-ui/components/data-entry/upload/upload.component';
import { AclService } from 'apps/admin-web/src/app/core/auth/acl.service';
import { Form } from 'antd';
import { DatePicker } from '@clodeo/clodeo-ui/components/data-entry/datepicker/datepicker.component';
import moment from 'moment';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';

const authenticationService: AuthenticationService = new AuthenticationService;
const adminBillingTransactionRestService = new AdminBillingTransactionRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const { handleRequest } = new HandleService;
const notif = new Notifications();

export const UploadDataPaymentComponent = (
  props: {
    data: IBillingPayment;
    onCancel: () => void;
    onFinish: () => void;
  }
) => {
  const aclService: AclService = new AclService;
  const [loading, setLoading] = useState(false);
  const { data } = props;
  const [form] = Form.useForm();
  const configUploadList = !aclService.can(['wallet.withdrawal_history.edit']) && {
    showRemoveIcon: false
  } || {};

  function onChangeUpload(item) {
    let { fileList } = item;
    fileList = fileList.map((file) => {
      if (file.status == 'done') {
        return file.response;
      }
    });
    form.setFieldsValue({
      attachments: fileList
    })
  }

  function onSelectChange(value: any) {
    form.setFieldsValue({
      paymentDate: moment(value).format('YYYY-MM-DD')
    })
  }

  function onApprove() {
    setLoading(true);
    const formValue = form.getFieldsValue(true);
    if (!formValue.paymentDate || !formValue.attachments) {
      notif.show({
        type: "error",
        title: "Error",
        description: "Upload bukti bayar dan tanggal pembayaran wajib diisi.",
      });
      setLoading(false);
      return;
    }
    
    const payload = {
      tenantId: data.tenantId,
      note: '',
      paymentDate: formValue.paymentDate,
      billingStatus: 'approved',
      attachments: formValue.attachments,
      paymentMethod: 'BCA'
    }

    const obs = adminBillingTransactionRestService.updateBilling(data.id, payload)
    handleRequest({
      obs,
      successMessage: 'Upload bukti pembayaran berhasil',
      onDone: () => {
        setLoading(false);
        props.onFinish()
      },
      onError: () => {
        props.onCancel();
      }
    })
  }

  return <Spinner spinning={loading} >
    <div className="container pt-3">
      <div className="row mb-3 chilbro">
        <div className="col-lg-12 title">
          Silakan pilih file untuk mengunggah bukti pembayaran
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-lg-12">
          <label className="field-label mb-2">Tanggal Pembayaran</label>
          <DatePicker onChange={onSelectChange} className="w-100" />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-lg-12">
          <UploadTemplateList
            buttonProps={{
              className: 'pl-2 pr-3'
            }}
            showUploadList={configUploadList}
            onChange={onChangeUpload}
            baseUrl={`${environment.ENDPOINTS.API_FILE}/upload/admin`}
            headers={{ 'Authorization': `Bearer ${authenticationService.user.access_token}` }}
          />
        </div>
      </div>
      <div className="row p-2">
        <div className="col-lg-12 text-center">
          <Button type="ghosted" label="Batal" onClick={props.onCancel} className="mr-2" />
          <Button type="primary" label="Ok" onClick={onApprove} />
        </div>
      </div>
    </div>
  </Spinner>
}
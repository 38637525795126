import { Tag as BaseTag } from 'antd';
import { TagProps } from './tag.d';
import React from 'react';

const Tag = (props: TagProps) => (
  <BaseTag
    {...props}
    className={'tag-deo ' + (props.colorType) + ' ' + (props.size || 'md') + ' ' + (props.className || '')}
  />
)

export { Tag }
export const environment = {
  environmentName: 'development',
  production: false,
  ENDPOINTS: {
    API_ACCOUNT: 'https://clodeo-dev-api-account.azurewebsites.net',
    API_ADMIN: 'https://clodeo-dev-api-admin.azurewebsites.net',
    API_MAIN: 'https://clodeo-dev-api-main.azurewebsites.net',
    API_MAIN_DEV: 'https://clodeo-dev-api-main.azurewebsites.net',
    API_FILE: 'https://clodeo-dev-api-file.azurewebsites.net',
    API_REPORT: 'https://clodeo-dev-api-report.azurewebsites.net',
    API_ADDON: 'https://clodeo-dev-api-addon.azurewebsites.net',
    API_EXTSRV_SHIPPING: 'https://clodeo-test-extsrv-shipping.azurewebsites.net',
    API_EXTSRV_CHATBOT: 'https://clodeo-dev-extsrv-chatbot.azurewebsites.net',
    API_EXTSRV_SALESCHANNEL: 'https://clodeo-dev-extsrv-saleschannel.azurewebsites.net',
    API_EXTSRV_REPORT: 'https://extsrv-report-api-development.clodeo.id',
    API_CORS_ANYWHERE: 'https://vm1.clodeo.com:5553',
    API_AUTO_PRINT: 'https://localhost:2501',
  },
};

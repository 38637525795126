import React, { useRef, useState } from 'react';

import { environment } from 'apps/admin-web/src/environments/environment';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { DatePicker } from '@clodeo/clodeo-ui/components/data-entry/datepicker/datepicker.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { ExcelService } from '@clodeo/libs/core/excel/excel.service';
import { Confirms } from '@clodeo/clodeo-ui/components/feedback/confirm/confirm.component';
import { UploadTemplateList } from '@clodeo/clodeo-ui/components/data-entry/upload/upload.component';
import { Form } from 'antd';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { RequestWithdrawFundPreviewComponent } from './preview/request-withdraw-fund-preview.component';
import { DebitCODRestService } from '@clodeo/libs/core/rest/admin/debit-cod/debit-cod-rest.service';
import { RequestWithDrawFundUploadService } from '../request-withdraw-fund-upload.service';
import { FormatService } from '@clodeo/libs/core/format/format.service';
import { pick } from 'lodash';


const authenticationService: AuthenticationService = new AuthenticationService();

const debitCODRestService = new DebitCODRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const confirm = new Confirms();
const formatService = new FormatService();
const notif = new Notifications();
const { handleRequest } = new HandleService;
const requestWithDrawFundUploadService: RequestWithDrawFundUploadService = new RequestWithDrawFundUploadService();

export const RequestWithdrawFundUploadComponent = (props: {
  onCancel?();
  onFinish?();
}) => {
  const baseListRef = useRef<any>();
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formStep, setFormStep] = useState<number>(0);
  const [rowsUploadWallet, setRowsUploadWallet] = useState<any>([]);

  const excelService: ExcelService = new ExcelService();

  excelService.data.headers = requestWithDrawFundUploadService.headers;

  const onSubmit = () => {
    const payload = normalizeSimpleBatchPayload();
    setIsLoading(true);
    const obs = debitCODRestService.simpleBatch(
      payload
    );
    handleRequest({
      obs,
      onError: (error) => {
        setIsLoading(false);
        notif.show({
          type: 'error',
          title: 'Error',
          description: error,
        });
        baseListRef.current.showLoading(false);
      },
      onDone: () => {
        setIsLoading(false);
        props.onFinish();
        notif.show({
          type: 'success',
          title: 'Sukses',
          description:
            'Kami sedang mengerjakan data anda. Status dan hasil upload Tenant Wallet akan kami kirimkan melalui email.',
        });
      }
    });
  };

  function onCancel() {
    confirm.show({
      title: 'Informasi',
      content: 'Data akan direset kembali, lanjutkan?',
      onOk() {
        resetForm();
        setIsLoading(false);
      },
    });
  }

  function requestExcelData() {
    setIsLoading(true);
    excelService.readFile({
      file: form.getFieldValue('file')[0].originFileObj,
      onDone: {
        handle: (data) => {
          form.setFieldsValue({rows: data});
          const payload = normalizePayloads()
          const obs = debitCODRestService.requestExcelData(payload);
          handleRequest({
            obs,
            onError: (error) => {
              notif.show({
                type: 'error',
                title: 'Error',
                description: error,
              });
            },
            onDone: (res) => {
              setRowsUploadWallet(res);
              setFormStep(1);
            }
          });
          setIsLoading(false);
        },
      },
    });
  }

  function normalizeSimpleBatchPayload() {
    let payload = [];
    rowsUploadWallet.rows.forEach(element => {
      const payloads = {
          tenantId: element.tenantId,
          companyName: element.companyName,
          bankAccountNumber: element.bankAccountNumber,
          amount: element.amount,
          notes: element.notes,
          tenantBankId: element.tenantBankId,
      }
      payload.push(payloads);
    });
    const payloads = {
      rows: payload,
      transactionDate: rowsUploadWallet.transactionDate
    }
    return payloads;
  }

  function normalizePayloads() {
    const payloads = { ...form.getFieldsValue(true) };
    payloads.transactionDate = formatService.date(
      form.getFieldsValue(true).transactionDate,
      'YYYY-MM-DD'
    );
    const payload = pick(payloads, ['rows', 'transactionDate'])
    return payload;
  }

  function resetForm() {
    setIsLoading(true);
    form.resetFields();
    setFormStep(0);
  }

  function onChangeFile(value) {
    form.setFieldsValue({
      file: value.fileList,
    });
  }

  return (
    <Spinner spinning={isLoading}>
      <div className="container-fluid">
        {formStep == 0 && (
          <Form
            form={form}
            initialValues={
              requestWithDrawFundUploadService.form.initialValues
            }
            onFinish={requestExcelData}
          >
            <Form.Item shouldUpdate>
              {() => {
                return (
                  <>
                    <div className="row">
                      <div className="">
                        <div className="col mb-2">Tanggal Pencairan</div>
                        <div className="col">
                          <Form.Item
                            name="transactionDate"
                            rules={[
                              requestWithDrawFundUploadService.form.rules[
                                'transactionDate'
                              ],
                            ]}
                          >
                            <DatePicker />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col">
                        <Form.Item
                          name="file"
                          rules={[
                            requestWithDrawFundUploadService.form.rules[
                              'file'
                            ],
                          ]}
                        >
                          <UploadTemplateList
                            name="fileExcel"
                            withoutAction={true}
                            onChange={onChangeFile}
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            buttonProps={{
                              type: 'ghosted',
                              iconName: 'deo-upload',
                              className: (form.getFieldValue('file') || [])
                                .length
                                ? 'd-none'
                                : '',
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end mt-3">
                      <Button submit type="primary" label="Next" />
                    </div>
                  </>
                );
              }}
            </Form.Item>
          </Form>
        )}
        {formStep == 1 && (
          <RequestWithdrawFundPreviewComponent
            oriRowsUploadWallet={rowsUploadWallet}
            onCancel={onCancel}
            onSubmit={onSubmit}
          />
        )}
      </div>
    </Spinner>
  );
};

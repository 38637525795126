import { UpdateNoteComponent } from '@clodeo/clodeo-ui/components/base/update-note/update-note.component';
import { Tag } from '@clodeo/clodeo-ui/components/data-display/tag/tag.component';
import { Select } from '@clodeo/clodeo-ui/components/data-entry/select/select.component';
import { IModalValue } from '@clodeo/clodeo-ui/components/feedback/modal/modal';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { DebitCODRestService } from '@clodeo/libs/core/rest/admin/debit-cod/debit-cod-rest.service';
import * as accountingJS from 'accounting-js';
import { Form } from 'antd';
import {
  IColumn,
  IIncludes
} from 'apps/admin-web/src/app/components/base-component/list/base-list';
import { BaseListComponent } from 'apps/admin-web/src/app/components/base-component/list/base-list.component';
import { AclService } from 'apps/admin-web/src/app/core/auth/acl.service';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { capitalize } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { DebitCodDraftUploadComponent } from '../draft-upload/debit-cod-draft-upload.component';
import { DebitCodFilter } from '../more-filter/debit-cod-more-filter.component';
import { DebitCodUploadComponent } from '../upload/debit-cod-upload.component';

const aclService: AclService = new AclService;

export const DebitCodListComponent: React.FC = () => {
  const authenticationService: AuthenticationService = new AuthenticationService();
  const debitCodRestService = new DebitCODRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
  const baseListRef = useRef<any>();
  const [modalVisible, setModalVisible] = useState<IModalValue>();
  const [data, setData] = useState([]);
  const [isTenant, setIsTenant] = useState<boolean>(true);
  const { handleRequest } = new HandleService;
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      ...data
    })
  })

  function loadDataObservable(params) {
    if (params.statuses) {
      params.statuses = (params.statuses).toString();
    }
    params.tenantId = isTenant ? params.keyword : null;
    params.keyword = !isTenant ? params.keyword : null;
    if (!(params.lowDate && params.highDate)) {
      params.lowDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
      params.highDate = moment(new Date).format('YYYY-MM-DD');
    }
    const obs = debitCodRestService.findHistory({ params });
    return obs;
  }

  /**
   * @param data from row value
   * @param note from popover input
   */
  function onUpdateNote(data, note) {
    baseListRef.current.showLoading();
    const { id } = data;
    const payload = {
      ...data,
      note
    }
    const obs = debitCodRestService.updateDebitCod(id, payload)
    handleRequest({
      obs,
      successMessage: 'Note berhasil diubah',
      onDone: () => {
        baseListRef.current.callLoadData();
      }
    });
  }

  const includes: IIncludes = {
    refresh: true,
    refreshAction: () => {
      baseListRef.current.resetFilters();

      setTimeout(() => {
        baseListRef.current.callLoadData();
      }, 10);
    },
    keyword: true,
    toogleOptions: true,
    exports: [{
      label: 'Export berdasarkan filter',
      basedFilter: true,
      fileName: 'Histori Pencairan By Filter'
    }, {
      label: 'Export semua data',
      fileName: 'Histori Pencairan'
    }],
  };

  const columns: IColumn[] = [
    {
      key: '1',
      title: 'SELLER',
      dataIndex: 'sellerName',
      width: '140px'
    },
    {
      key: '2',
      title: 'JUMLAH SALDO DITARIK',
      dataIndex: 'amount',
      width: '140px',
      render: (value) => accountingJS.formatMoney(value || 0, { symbol: 'Rp ', format: '%s %v' }),
      formatExprt: (val) => accountingJS.formatMoney(val || 0, { symbol: 'Rp ', format: "%s %v", decimal: ',', thousand: '.' })

    },
    {
      key: '3',
      title: 'DITARIK KE REKENING',
      dataIndex: 'bankName',
      width: '140px',
      render: (value) => {
        if (value == null) {
          return (
            <p>-</p>
          );
        } else {
          return value;
        }
      }
    },
    {
      key: '4',
      title: 'CABANG BANK',
      dataIndex: 'bankDistrict',
      width: '140px',
      render: (value) => {
        if (value == null) {
          return (
            <p>-</p>
          );
        } else {
          return value;
        }
      }
    },
    {
      key: '5',
      title: 'NOTE',
      dataIndex: 'note',
      width: '130px',
      render: (value, data) => <UpdateNoteComponent hideEdit={(data.status === 'draft') || !aclService.can(['wallet.withdrawal_history.edit'])} note={value} onSubmit={(val) => onUpdateNote(data, val)} />
    },
    {
      key: '6',
      title: 'TOTAL BAYAR',
      dataIndex: 'amount',
      width: '140px',
      render: (value) => accountingJS.formatMoney(value || 0, { symbol: 'Rp ', format: '%s %v' }),
      formatExprt: (val) => accountingJS.formatMoney(val || 0, { symbol: 'Rp ', format: "%s %v", decimal: ',', thousand: '.' })
    },
    {
      key: '7',
      title: 'STATUS',
      width: '70px',
      dataIndex: 'status',
      render: (value) => {
        return (
          <Tag colorType={value.toLowerCase()}>{capitalize(value)}</Tag>
        );
      }
    },
    {
      key: '8',
      title: 'USER',
      width: '70px',
      dataIndex: 'userAdminName'
    },
    {
      key: '9',
      align: 'center',
      width: '70px',
      render: (value, data) => {
        const record = {
          ...data
        }
        return (
          <Button
            type="ghosted"
            iconName={(data.status === 'draft') ? 'deo-upload' : 'i-Paperclip'}
            className="p-2"
            onClick={() => {
              setModalVisible({
                visible: true,
                title: (data.status === 'draft') ? 'Upload Attachments' : 'Attachments',
                contentTemplate:
                  (data.status === 'draft') ?
                    <DebitCodDraftUploadComponent
                      onCancel={() => setModalVisible(null)}
                      onFinish={() => {
                        baseListRef.current.callLoadData();
                        setModalVisible(null)
                      }}
                      record={record}
                    /> :
                    <DebitCodUploadComponent
                      onCancel={() => setModalVisible(null)}
                      onFinish={() => {
                        baseListRef.current.callLoadData();
                        setModalVisible(null)
                      }}
                      record={record}
                    />

              })
            }}
          />
        );
      }
    },
  ];

  function selectIsTenant() {
    const options: any[] = [
      { label: 'Tenant ID', value: true },
      { label: 'Keyword', value: false },
    ];
    return (
        <Select size='large' options={options} value={isTenant} onChange={setIsTenant} />
      )
  }

  return (
    <>
      <BaseListComponent
        listId="debit-cod"
        ref={baseListRef}
        columns={columns}
        loadDataObservable={loadDataObservable}
        includes={{
          ...includes,
          placeholderKeyword: isTenant && 'Cari Tenant ID',
          keywordType: isTenant && 'number',
          suffixComponent: selectIsTenant(),
          moreFilter: {
            template: (
              <DebitCodFilter />
            )
          }
        }}
      />
      {modalVisible && <Modal
        {...modalVisible}
        className="admin"
        footer={null}
        onCancel={() => setModalVisible(null)}
      >
        {modalVisible.contentTemplate}
      </Modal>}
    </>
  );
}

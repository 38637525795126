import {
  InputPassword,
} from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { Form } from 'antd';
import { environment } from 'apps/admin-web/src/environments/environment';
import React, { useState } from 'react';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../../core/auth/authentication.service';
import { AccountAdminRestServices } from '@clodeo/libs/core/rest/account/admin/user/account-admin-user.rest.service';
const { Item } = Form;
const authenticationService = new AuthenticationService();
const resetPasswordRestService = new AccountAdminRestServices(
  `${environment.ENDPOINTS.API_ACCOUNT}`,
  authenticationService.axiosInterceptors
);
export const ResetPasswordComponent = (props: {
  onCancel?: () => void;
  onSuccess?: () => void;
}) => {
  const notif: Notifications = new Notifications();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  function onFinish(val) {
    setLoading(true);
    resetPasswordRestService
      .changePasswordMe(val)
      .pipe(
        catchError((err) => {
          notif.show({
            type: 'error',
            title: 'Error',
            description: err,
            useService: true,
          });
          setLoading(false);
          return throwError(err);
        })
      )
      .subscribe(() => {
        notif.show({
          type: 'success',
          title: 'Sukses',
          description: 'Reset Password Berhasil',
        });
        setLoading(false);
        form.resetFields();
        props.onSuccess && props.onSuccess();
      });
  }

  return (
    <>
      <Spinner spinning={loading}>
        <div className="container-lg container-fluid p 3">
          <Form form={form} initialValues={{oldPassword: null, newPassword: null}} onFinish={onFinish}>
            <div className="row justify-content-between my-1 col-17">
              <label htmlFor="" className="col-5 field-required">
                Password Lama
              </label>
              <div className="col-7">
                <Form.Item
                  name="oldPassword"
                  className="mb-2"
                  rules={[
                    {
                      required: true,
                      message: 'Kolom ini wajib diisi',
                    },
                  ]}
                >
                  <InputPassword />
                </Form.Item>
              </div>
            </div>
            <div className="row justify-content-between my-1 mb-3">
              <label htmlFor="" className="col-5 field-required">
                Password Baru
              </label>
              <div className="col-7">
                <Form.Item
                  name="newPassword"
                  rules={[
                    {
                      required: true,
                      message: 'Kolom ini wajib diisi',
                    },
                  ]}
                >
                  <InputPassword />
                </Form.Item>
              </div>
            </div>
            <div className="d-flex w-100 justify-content-end py-2">
              <Button
                type="primary"
                label="Simpan"
                submit
                className="mr-2"
              />
              <Button
                type="ghosted"
                label="Batal"
                onClick={() => {form.resetFields(); props.onCancel && props.onCancel()}}
              />
            </div>
          </Form>
        </div>
      </Spinner>
    </>
  );
};


import _ from 'lodash';
import moment from 'moment';
export class CouponFormService {

  get form() {
    const required = [{ required: true, message: 'Kolom ini wajib diisi' }];
    const chooseAtleastOne = [{ required: true, message: 'Mohon pilih minimal 1' }];
    return {
      initialValues: {
        code: '',
        name: '',
        couponCategory: ['subscription'],
        couponCategoryType: 'subscription_plan',
        description: '',
        discountType: 'percentage',
        discountValue: '',
        minSpend: 0,
        maxDiscount: 0,
        quota: 0,
        quotaUsage: 0,
        validStartDate: null,
        validEndDate: null,
        couponValidType: 'only',
        accountValidType: [],
        tenantValidType: 'none',
        inactive: false,
        subscriptionCycles: [],
        subscriptionPlans: [],
        tenants: [],
        tenantSettingModal: false,
      },
      rules: {
        code: required,
        name: required,
        discountType: required,
        discountValue: required, // tambah validasi value tidak boleh 0 number
        validStartEndDate: required,
        accountValidType: chooseAtleastOne, // wajib memilih saccountValidType
        subscriptionCycles: chooseAtleastOne, // wajib memilih minimal 1 checkbox
        subscriptionPlans: chooseAtleastOne, // wajib memilih 1 checkbox
      }
    };
  }

    // normalizePayload(data, originalProduct) {
    //   const lines = this.normalizeLine(data.lines, data, originalProduct);
    //   const billingAddress = this.normalizeBillingAdress(data);

    //   const payload = {
    //     transactionDate: moment(new Date()).format('YYYY-MM-DD'),
    //     customerName: data.customerName,
    //     customerPhone: data.customerPhone,
    //     customerMobile: null,
    //     customerEmail: data.customerEmail,
    //     isDropship: data.isDropship,
    //     isPreOrder: data.isPreOrder,
    //     dropshipReceiverName: data.dropshipReceiverName,
    //     dropshipReceiverPhoneNumber: data.dropshipReceiverPhoneNumber,
    //     shippingMethodId: data.shippingMethodId,
    //     recipient: null,
    //     shippingCourier: data.shippingMethod.courierChannelId,
    //     shippingCourierProduct: data.shippingCourierProduct.name,
    //     shippingCharge: data.shippingCourierProduct.price,
    //     insuranceAmount: data.insurance ? data.insuranceAmount : 0,
    //     shippingInsurance: data.insurance,
    //     adjustmentAmount: data.adjustmentAmount || 0,
    //     discountPercent: null,
    //     discountAmount: data.discount,
    //     shippingCOD: data.shippingCOD,
    //     shippingNote: data.shippingNote,
    //     note: data.note,
    //     couponCode: data.coupon,
    //     couponId: data.couponId,
    //     total: data.total || 0,
    //     // totalWeight: data.totalWeight || 0,
    //     billingAddress: {
    //       ...billingAddress,
    //     },
    //     lines: [
    //       ...lines
    //     ],
    //   };

    //   return payload;
    // }
}

import { RangePicker } from '@clodeo/clodeo-ui/components/data-entry/datepicker/datepicker.component';
import { Option, Select } from '@clodeo/clodeo-ui/components/data-entry/select/select.component';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { Form } from 'antd';
import { FormInstance } from 'antd/lib/form';
import moment from 'moment';
import React, { } from 'react';
const { handleRequest } = new HandleService;
export const RequestCancelMoreFilterComponent = (porps: { form?: FormInstance<any> }) => {
  const { form } = porps;
  const dateFormat = 'YYYY-MM-DD';
  const lowDate = moment().subtract(3, 'months');
  const highDate = moment(new Date);
  const options = [
    { value: 'dateFilterType', label: 'Tanggal Order' },
    { value: 'pickupDate', label: 'Tanggal Pickup' },
    { value: 'requestDate', label: 'Tanggal Request' },
  ]

  function onChangeDate() {
    const date = form.getFieldValue('date');
    date && form.setFieldsValue({
      lowDate: moment(date[0]).format('YYYY-MM-DD'),
      highDate: moment(date[1]).format('YYYY-MM-DD')
    })
  }

  return <div className="d-flex" id="tenant-more-filter">
    <div className="d-flex flex-column mr-2">
      <label className="mb-2">Tipe Tanggal</label>
      <Form.Item name="dateFilterType">
        <Select style={{ width: 170 }} >
          {options && options.map(opt => (
            <Option value={opt.value}>
              {opt.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </div>
    <div className="d-flex flex-column">
      <label className="mb-2">Tanggal</label>
      <Form.Item name="date">
        <RangePicker defaultValue={[moment(lowDate, dateFormat), moment(highDate, dateFormat)]} onChange={onChangeDate} />
        {/* <RangePicker onChange={onChangeDate} className="w-100" /> */}
      </Form.Item>
    </div>
  </div>
}


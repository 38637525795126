import React, { useState, useEffect } from 'react';


import { Form } from 'antd';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { environment } from '../../../../../../environments/environment';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { CustomTrainingSchedulesFormComponent } from '../form/custom-training-schedule-form.component';
import { ShippingIntegrationRestService } from '@clodeo/libs/core/rest/admin/shipping-integration/shipping-Integration-rest.service';

// Extra configs
const notif = new Notifications;

export const CustomTrainingScheduleCreateComponent = (props: ICustomTrainingSchedulesCreateProps) => {

  const { onClose, onFinish } = props;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const { handleRequest } = new HandleService;
  const authenticationService = new AuthenticationService;
  const shippingIntegrationRestService = new ShippingIntegrationRestService(`${environment.ENDPOINTS.API_ADMIN}`, authenticationService.axiosInterceptors);

  function onCancel() {
    form.resetFields();
    onClose();
  }

  function onSubmit() {
    const payload = {
      customShippingIntegrationTrainingDates: form.getFieldValue('customShippingIntegrationTrainingDates')
    };
    formValid() && createCustomTrainingSchedule(payload);
  }

  function createCustomTrainingSchedule(payload) {
    const obs = shippingIntegrationRestService.createTrainingDate(payload);
    setLoading(true);
    handleRequest({
      obs,
      successMessage: 'Jadwal training berhasil disimpan.',
      onError: () => setLoading(false),
      onDone: () => {
        setLoading(false);
        onFinish();
        form.resetFields();
      }
    })
  }

  function formValid() {
    if (form.getFieldValue('customShippingIntegrationTrainingDates').length) {
      return true;
    } else {
      notif.show({
        type: 'warning',
        title: 'Peringatan',
        description: 'Mohon pilih minimal 1 jadwal training',
        useService: true
      })
      return false;
    }
  }

  useEffect(() => {
    form.setFieldsValue({
      customShippingIntegrationTrainingDates: [],
      quota: 20,
      trainingDate: null,
      inactive: false,
    });
  }, [])

  return (
    <Spinner spinning={loading}>
      <Form form={form} initialValues={{
        customShippingIntegrationTrainingDates: []
      }}>
        <Form.Item shouldUpdate>
          {() => {
            return <>
              <CustomTrainingSchedulesFormComponent
                form={form}
              />
              <div className="w-100 text-right">
                <Button type="ghosted" label="Batal" className="mr-3" onClick={() => onCancel()} />
                <Button submit type="primary" label="Simpan" onClick={() => onSubmit()} />
              </div>
            </>
          }}
        </Form.Item>
      </Form>
    </Spinner>
  )
}
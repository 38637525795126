import React, { useEffect, useState } from 'react';
import * as accountingJS from 'accounting-js';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { DebitCODRestService } from '@clodeo/libs/core/rest/admin/debit-cod/debit-cod-rest.service';

import { map } from 'lodash';

import { UploadTemplateList } from '@clodeo/clodeo-ui/components/data-entry/upload/upload.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { Form } from 'antd';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { AclService } from 'apps/admin-web/src/app/core/auth/acl.service';
import { AdminTenantBankRestService } from '@clodeo/libs/core/rest/admin/tenant-bank/admin-tenant-bank-rest.service';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { LoadingComponent } from '@clodeo/clodeo-ui/components/general/loading/loading.component';
import { AutoComplete, Option } from '@clodeo/clodeo-ui/components/data-entry/autocomplete/autocomplete.component';
import { InputNumber } from '@clodeo/clodeo-ui/components/data-entry/inputnumber/inputnumber.component';
import { InputTextArea } from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';
import './debit-cod-draft-upload.component.scss';
import { Confirms } from '@clodeo/clodeo-ui/components/feedback/confirm/confirm.component';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
const confirm = new Confirms();
const notif = new Notifications();

export const DebitCodDraftUploadComponent = (props: IDebitCodUpload) => {
  const aclService: AclService = new AclService;
  const authenticationService: AuthenticationService = new AuthenticationService;
  const debitCodRestService = new DebitCODRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
  const adminTenantBankRestService = new AdminTenantBankRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
  const [loading, setLoading] = useState(false);
  const [bankOptions, setBankOptions] = useState<any[]>();
  const [selectedTenantBank, setSelectedTenantBank] = useState<any>();
  const [defaultBank, setDefaultBank] = useState({
    tenantBankId: null,
    bankName: null
  })
  const [form] = Form.useForm();
  const { handleRequest } = new HandleService();

  const { record, onFinish, onCancel } = props;


  function loadTenantBank() {
    setLoading(true);
    const obs = adminTenantBankRestService.findByTenantId(record.tenantId);
    handleRequest({
      obs,
      onDone: (res) => {
        const data = [];
        let newDefaultBank: any = {};
        for (let index = 0; index < res.data.length; index++) {
          const newData = res.data[index];
          newData.displayName = `${newData.accountName} - ${newData.bank.bankName} - ${newData.accountNumber}`;
          data.push(newData);
          if (record.accountName === newData.accountName) {
            newDefaultBank = {
              tenantBankId: newData.id,
              bankName: newData.displayName
            }
          }
        }
        setBankOptions(data);
        setDefaultBank(newDefaultBank);
        setLoading(false);
      },
      onError: (res) => {
        setLoading(false);
      },
    })
  }

  function defaultFileList() {
    let defaults = [];
    defaults = map(record.attachments, function (att) {
      return att && {
        uid: att.id,
        name: att.fileName,
        status: 'done',
        response: att,
        url: att.fileUrl
      }
    });
    return defaults;
  }

  function onChangeUpload(item) {
    let { fileList } = item;
    fileList = fileList.map((file) => {
      if (file.status == 'done') {
        return file.response;
      }
    });
    form.setFieldsValue({
      attachments: fileList
    })
  }

  function handleChangeAccount(val, opt) {
    form.setFieldsValue({
      tenantBankId: opt ? opt.key : null,
      bankName: opt ? opt.children : null
    });
    onSelectedChange();
  }

  function ContentConfirm(props: {
    val: any;
  }) {
    const { val } = props;
    return <table className="table table-borderless" style={{ fontSize: '10px' }}>
      <tbody>
        <tr>
          <td>
            <strong>Rekening</strong>
          </td>
          <td>
            :
          </td>
          <td>
            {val?.selectedTenantBank?.accountNumber || '-'}
          </td>
        </tr>
        <tr>
          <td>
            <strong>Nama Akun</strong>
          </td>
          <td>
            :
          </td>
          <td>
            {val?.selectedTenantBank?.accountName || '-'}
          </td>
        </tr>
        <tr>
          <td>
            <strong>Nama Bank</strong>
          </td>
          <td>
            :
          </td>
          <td>
            {val?.selectedTenantBank?.bank.bankName || '-'}
          </td>
        </tr>
        <tr>
          <td>
            <strong>Balance Amount</strong>
          </td>
          <td>
            :
          </td>
          <td>
            {accountingJS.formatMoney(val?.ballanceAmount || 0, { symbol: 'Rp ', format: '%s %v' })}
          </td>
        </tr>
        <tr>
          <td>
            <strong>Total Bayar</strong>
          </td>
          <td>
            :
          </td>
          <td>
            {accountingJS.formatMoney(val?.amount || 0, { symbol: 'Rp ', format: '%s %v' })}
          </td>
        </tr>
      </tbody>
    </table>
  }

  function onSubmit(val?: any) {
    const id = form.getFieldValue('id');
    const obs = debitCodRestService.updateDebitCod(id, form.getFieldsValue(true));

    if (form.getFieldValue('attachments').length < 1) {
      notif.show({
        type: "error",
        title: "Error",
        description: "Pastikan semua data telah terisi.",
      });
      return;
    }
    confirm.show({
      title: 'Konfirmasi Transfer',
      content: <ContentConfirm val={
        {
          ...val,
          selectedTenantBank,
          ballanceAmount: record?.amount || 0
        }
      } />,
      onOk() {
        setLoading(true);
        handleRequest({
          obs,
          successMessage: 'Berhasil',
          onError: () => {
            setLoading(false);
            onCancel()
          },
          onDone: () => {
            setLoading(false);
            onFinish();
          }
        })
      }
    });
  }

  const configUploadList = !aclService.can(['wallet.withdrawal_history.edit']) && {
    showRemoveIcon: false
  } || {};

  function onSelectedChange() {
    const tenantBankId = form.getFieldValue('tenantBankId');
    let tenantBank: any = null;
    if (bankOptions && tenantBankId) {
      for (let index = 0; index < bankOptions.length; index++) {
        const id = bankOptions[index].id;
        if (id == tenantBankId) {
          tenantBank = bankOptions[index];
          break
        } else {
          tenantBank = null
        }
      }
    }
    setSelectedTenantBank(tenantBank);
  }

  useEffect(() => {
    if (record.tenantId) {
      loadTenantBank()
    }
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      ...record,
      ...defaultBank
    });
    onSelectedChange();
  }, [record, defaultBank])

  return (
    <Spinner spinning={loading} indicator={<LoadingComponent />}>
      <div className="col-12 mt-3 d-flex justify-content-end">
        <Form
          form={form}
          onFinish={onSubmit}
        >
          <Form.Item shouldUpdate>
            {() => (
              <div className="form-attach-wrapper">
                <div className="row my-3 form-group-x">
                  <label htmlFor="" className="col-lg-4">Company Name</label>
                  <div className="col-lg-8 value">
                    {record?.sellerName || '-'}
                  </div>
                </div>
                <div className="row my-3 form-group-x">
                  <label htmlFor="" className="col-lg-4">Akun Bank</label>
                  <div className="col-lg-8 value">
                    <Form.Item name="bankName" rules={[
                      {
                        required: true,
                        message: 'Kolom ini wajib diisi!'
                      }
                    ]}>
                      <AutoComplete
                        onChange={handleChangeAccount}
                      >
                        {
                          (bankOptions && (bankOptions.length > 0)) ? bankOptions.map(tenantBank => (
                            <Option key={tenantBank.id} value={tenantBank.displayName}>{tenantBank.displayName}</Option>
                          ))
                            :
                            <Option value={null}>Tidak Ada Data</Option>
                        }
                      </AutoComplete>
                    </Form.Item>
                  </div>
                </div>

                <div className="row my-3 form-group-x">
                  <label htmlFor="" className="col-lg-4">Total</label>
                  <div className="col-lg-8 value">
                    {accountingJS.formatMoney(record?.amount || 0, { symbol: 'Rp ', format: '%s %v' })}
                  </div>
                </div>

                <div className="row my-3 form-group-x">
                  <label htmlFor="" className="col-lg-4">Total Bayar</label>
                  <div className="col-lg-8 value">
                    <Form.Item name="amount" rules={[
                      {
                        required: true,
                        message: 'Kolom ini wajib diisi!'
                      }
                    ]}>
                      <InputNumber className="w-100" />
                    </Form.Item>
                  </div>
                </div>

                <div className="row my-3 form-group-x">
                  <label htmlFor="" className="col-lg-4">Catatan</label>
                  <div className="col-lg-8 value">
                    <Form.Item name="note">
                      <InputTextArea />
                    </Form.Item>
                  </div>
                </div>

                <div className="row">
                  <div className="col-auto">
                    <UploadTemplateList
                      buttonProps={{
                        className: 'pl-2 pr-3'
                      }}
                      showUploadList={configUploadList}
                      onChange={onChangeUpload}
                      baseUrl={`${environment.ENDPOINTS.API_FILE}/upload/admin`}
                      headers={{ 'Authorization': `Bearer ${authenticationService.user.access_token}` }}
                      defaultFileList={defaultFileList()}
                    />
                  </div>
                </div>
                <div className="text-center pt-2">
                  <Button
                    className="mr-2"
                    type="ghosted"
                    label="Cancel"
                    onClick={() => onCancel()}
                  />
                  {
                    aclService.can(["wallet.withdrawal_history.edit"]) && (
                      <Button
                        type="primary"
                        label="Simpan"
                        submit
                      />
                    )
                  }
                </div>
              </div>
            )}
          </Form.Item>
        </Form>
      </div>
    </Spinner>
  );
}

import React from 'react';
import { LoadingProps } from './loading';
import { LoadingOutlined } from '@ant-design/icons';
export const LoadingOutline = () => <LoadingOutlined spin rev='' />;
export const LoadingComponent = (props: LoadingProps) => (
  <div className={`loadingio-spinner-${props.type || 'ellipsis'}-68p11yt7y8v ${props.className || ''}`}>
    <div className="ldio-ml7p8i9p8dk">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
)
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { LoadingComponent } from '@clodeo/clodeo-ui/components/general/loading/loading.component';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { CouponRestService } from '@clodeo/libs/core/rest/addon/general/coupons/coupon-rest.service';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CouponService } from '../coupon.service';
import { CouponFormComponent } from '../form/coupon-form.component';

export const CouponUpdateComponent = () => {
  const router = useHistory();
  const authenticationService: AuthenticationService = new AuthenticationService;
  const couponRestService = new CouponRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
  const couponService: CouponService = new CouponService();
  const { handleRequest } = new HandleService;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [doc, setDoc] = useState<any>(null);
  const { id } = useParams<{ id: string }>();

  const onSubmit = (form) => {
    setIsLoading(true);
    const payload = couponService.normalizedSetterPayload(form);
    const obs = couponRestService.update(id, payload);
    handleRequest({
      obs,
      successMessage: 'Berhasil memperbarui coupon.',
      onError: () => setIsLoading(false),
      onDone: () => {
        router.push('/subscription/coupon');
        setIsLoading(false);
      }
    });
  }

  const loadData = () => {
    setIsLoading(true);
    const obs = couponRestService.getDetail(id).pipe(switchMap(result => of(couponService.normalizedGetterPayload(result))));
    handleRequest({
      obs,
      onError:() => {
        setDoc(doc);
        setIsLoading(false);
      },
      onDone: (res) => {
        setDoc(res);
        setIsLoading(false);
      }
    })
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Spinner spinning={isLoading} indicator={<LoadingComponent />}>
      <CouponFormComponent doc={doc} parentCallback={onSubmit} formType='update' />
    </Spinner>
  )
}

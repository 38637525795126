import * as accountingJS from 'accounting-js';
import * as _ from 'lodash';
import moment from 'moment';


export class FormatService {

  empty(data: any) {
    return data || '-';
  }

  percentage(data: number) {
    let newData = (data || 0);

    if(_.isString(newData)) {
      newData = parseFloat(newData);

    } else if(_.isNumber(newData) && newData < 1 && newData > -1) {
      newData *= 100;
    } else if(_.isNumber(newData)) {
      newData *= 100;
    }

    return newData;
  }

  operationBoolean(data: any) {
    return data ? 'Ya' : 'Tidak';
  }

  startCase(data: any) {
    return _.startCase(data) || '-';
  }

  date(data, format: string = 'DD/MM/YYYY') {
    return data ? moment(data).format(format) : '';
  }

  dateTimeFormatterFromUtc(data: any) {
    if (data) {
      const dateUtc = new Date(data);
      const offset = (new Date().getTimezoneOffset() * -1);
      dateUtc.setMinutes(dateUtc.getMinutes() + offset);
      return moment(dateUtc).format('DD/MM/YYYY HH:mm:ss');
    }

    return '-';
  }

  currency(data: number) {
    const newData = data || 0;
    return accountingJS.formatMoney(newData, { symbol: "Rp.", precision: 0, format: "%s %v", decimal: ',', thousand: '.' });
  }
}

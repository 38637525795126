import _ from 'lodash';
import moment from 'moment-timezone';
export class CouponService {

  wscols = [
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 }, // hide
    { wch: 20 }, // o wner
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 }
  ];

  normalizedGetterPayload(data) {
    const result = { ...data };
    if (result.validStartDate && result.validEndDate) {
      result.validStartEndDate = [moment(new Date(result.validStartDate)), moment(new Date(result.validEndDate))]
    }
    result.couponCategory = _.castArray(result.couponCategory);
    result.subscriptionCycles = result.subscriptionCycles.map(cycle => (cycle.subscriptionCycle));
    result.subscriptionPlans = result.subscriptionPlans.map(plan => (plan.id));
    result.tenants = result.tenants.map(tenant => ({ id: tenant.tenantId, name: `(${tenant.tenantId}) ${tenant.companyName}` }));
    return result;
  }

  normalizedSetterPayload(data) {
    const validStartDate = _.get(data, 'validStartEndDate[0]');
    const validEndDate = _.get(data, 'validStartEndDate[1]');

    const result = { ...data };
    result.couponCategory = result.couponCategory[0];
    result.quota = `${result.quota}`;
    result.validStartDate = validStartDate ? moment(validStartDate).format('YYYY-MM-DD') : validStartDate;
    result.validEndDate = validEndDate ? moment(validEndDate).format('YYYY-MM-DD') : validEndDate;
    result.subscriptionCycles = result.subscriptionCycles
      .map(cycle => ({
        subscriptionCycle: cycle,
        description: '',
      }));
    result.subscriptionPlans = result.subscriptionPlans
      .map(plan => ({
        id: plan,
        description: '',
      }));
    result.tenants = result.tenantValidType === 'none' ? [] : result.tenants.map(tenant => ({
      tenantId: `${tenant.id}`,
      tenantValidType: result.tenantValidType,
    }));

    return _.omit(result, ['tenantSettingModal', 'validStartEndDate']);
  }

  nomalizeKeyExport(datas) {
    moment.locale('id')
    const sourceData = [...datas];
    const targetData = [];
    for (const data of sourceData) {
      const object = {};
      for (const key in data) {
        const renameKey = _.startCase(key);
        if (key === 'validEndDate' || key === 'validStartDate') {
          data[key] = this.normalizeDateExport(data[key]);
        }
        object[renameKey] = data[key]
      }
      targetData.push(object);
    }
    return targetData;
  }

  normalizeDateExport(date) {
    if (!date) {
      return;
    }
    const utcDate = moment.utc(date);
    const result = utcDate.tz(moment.tz.guess(true)).format('dddd, DD MMMM YYYY HH:mm:ss');
    return result;
  }
}

import React, { useEffect, useState } from 'react';
import { WidgetChartBasic } from '@clodeo/clodeo-ui/components/chart/chart.component';
export const TenantsSummary = (
  props: {
    summary: {
      totalRealTenant: number
      totalTenant: number
      totalRealTenantRegisteredCOD: number
      totalTenantRegisteredCOD: number
    };
    onChangeFilter(val: any): void;
  }
) => {
  const [isCod, setIsCod] = useState<any>(null);
  const { summary } = props;

  useEffect(() => {
    props.onChangeFilter && props.onChangeFilter(isCod);
  }, [isCod])
  return <div className="row w-100 p-2 m-auto">
    <div className="col-12 col-lg-4 p-2">
      <WidgetChartBasic
        active={isCod === undefined}
        onClick={() => { isCod === undefined ? setIsCod(null) : setIsCod(undefined); }}
        label="Total Registered Tenants"
        value={summary.totalRealTenant}
        icon={<IconRealTenant />}
      />
    </div>
    <div className="col-12 col-lg-4 p-2">
      <WidgetChartBasic
        active={isCod}
        onClick={() => { !isCod ? setIsCod(true) : setIsCod(null); }}
        label="Total Registered COD Tenants"
        value={summary.totalRealTenantRegisteredCOD}
        icon={<IconTenantCOD />}
      />
    </div>
  </div>
}

const IconRealTenant = () => (
  <img src="https://nx-design-system-web-development.clodeo.com/img/admin-clodeo/tenant/registered.svg" alt="" srcSet="" />
)
const IconTenantCOD = () => (
  <img src="https://nx-design-system-web-development.clodeo.com/img/admin-clodeo/tenant/registered-cod.svg" alt="" srcSet="" />
)

import { Checkbox, CheckboxGroup } from '@clodeo/clodeo-ui/components/data-entry/checkbox/checkbox.component';
import { Input } from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';
import { Tag } from '@clodeo/clodeo-ui/components/data-display/tag/tag.component';
import { Popover } from '@clodeo/clodeo-ui/components/data-display/popover/popover.component';
import { isBoolean, startCase, findIndex, flattenDeep, map, includes } from 'lodash';
import React, { useEffect, useState } from 'react';
import './user-roles-form.component.scss';
import { AutoComplete, Option } from '@clodeo/clodeo-ui/components/data-entry/autocomplete/autocomplete.component';

export const UserRolesFormComponent = (props: {
  accessPermissions: INormalizeAccessPermission[];
  onFormChange: (payload: IPayloadAccessRoles) => void
  data?: any;
  roleGroups: IRoleGroups[];
}) => {
  const { data, roleGroups, accessPermissions, onFormChange } = props;
  const [form, setForm] = useState<IPayloadAccessRoles>({
    name: "",
    description: "",
    roleGroupId: "",
    permissions: []
  });
  // const [accessPermissions, setAccessPermissions] = useState<any[]>(permissions);
  useEffect(() => {
    onFormChange && onFormChange(form);
  }, [form]);

  useEffect(() => {
    data && patchValue()
  }, [data]);

  function onSelectRoleGroup(key, option) {
    setForm({
      ...form,
      roleGroupId: option.key
    });
  }

  function onClearRoleGroup(value) {
    if (!value) {

      setForm({
        ...form,
        roleGroupId: null
      });
    }
  }

  function patchValue() {
    const permissionData = {};
    data && data.permissionRoles.forEach(roles => {
      checkingPermission(roles.permission.permissionSubGroup, roles.permission);

      if (permissionData[roles.permission.permissionSubGroup]) {
        permissionData[roles.permission.permissionSubGroup].push(roles.permission);
      } else {
        permissionData[roles.permission.permissionSubGroup] = [roles.permission];
      }
    });
    setForm({
      ...data,
      fakePermissions: permissionData,
    });
  }

  function onSelectPermission(group: string, perm: IPermission[]) {
    checkingPermission(group, perm);
    let newPermissions = { ...(form.fakePermissions || {}) };
    newPermissions[group] = perm;
    setForm({ ...form, fakePermissions: newPermissions });
  }

  function checkingPermission(groupName: string, permissions: IPermission[]) {
    let indexGroup = findIndex(accessPermissions, ['group', groupName]);
    if (!permissions.length) {
      accessPermissions[indexGroup] = { ...accessPermissions[indexGroup], isValid: null };
    } else {
      for (let index = 0; index < permissions.length; index++) {
        accessPermissions[indexGroup] = { ...accessPermissions[indexGroup], isValid: false };

        if (permissions[index].id.includes('view')) {
          accessPermissions[indexGroup] = { ...accessPermissions[indexGroup], isValid: true };
          break;
        }
      }
    }
  }

  return <div id="form-access-roles" className="container">
    <div className="row mb-4">
      <div className="col-lg-6 col-sm-12 p-0">
        <label htmlFor="" className="col-lg-12 field-label">Nama</label>
        <div className="col-lg-12">
          <Input value={form && form.name} onChange={(e) => setForm({ ...form, name: e.target.value })} />
        </div>
      </div>
      <div className="col-lg-6 col-sm-12 p-0">
        <label htmlFor="" className="col-lg-12 field-label">Deskripsi</label>
        <div className="col-lg-12">
          <Input value={form && form.description} onChange={(e) => setForm({ ...form, description: e.target.value })} />
        </div>
      </div>
    </div>
    <div className="row mb-4">
      <div className="col-lg-6 col-sm-12 p-0">
        <label htmlFor="" className="col-lg-12 field-label">Departement</label>
        <div className="col-lg-12">
          <AutoComplete
            className={'w-100'}
            onSearch={() => { setForm({ ...form, roleGroupId: null }) }}
            onSelect={onSelectRoleGroup}
            onChange={onClearRoleGroup}
            filterOption={(inputValue, inputOptions) => inputOptions.value.toLowerCase().includes(inputValue.toLowerCase())}
            value={form ? form.roleGroupId : ''}
          >
            {
              roleGroups && roleGroups.map((roleGroup) => (
                <Option key={roleGroup.id} value={roleGroup.name}>
                  {roleGroup.name}
                </Option>
              ))
            }
          </AutoComplete>
        </div>
      </div>
    </div>
    <div className="row mb-2">
      <div className="col-lg-12">
        <div className="scroll-wrapper">
          <div className="row permission-wrapper">
            {accessPermissions.map((perm, i: number) => (
              <div className="col-lg-12" key={'colcol' + i}>
                <PermissionComponent permissions={perm} formFakemPermission={form && form.fakePermissions} onSelectPermission={onSelectPermission} />
              </div>
            ))
            }
          </div>
        </div>
      </div>
    </div>
  </div >
}

const PermissionComponent = (props: {
  permissions: INormalizeAccessPermission;
  formFakemPermission?: { [key: string]: IPermission[] };
  onSelectPermission: (group: string, permission: IPermission[]) => void;
}) => {
  const { permissions, formFakemPermission, onSelectPermission } = props;

  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [isPatch, setIsPatch] = useState<boolean>(false);

  const permissionName = permissions.permissions.map(key => key.name);

  useEffect(() => {
    if (formFakemPermission && !isPatch) {
      const listData: any = map(flattenDeep(Object.values(formFakemPermission)), "name");
      setCheckedList(listData);
      const prepareData = checkSelectedPermission(permissions, listData);
      if (prepareData.data.length) {
        setIndeterminate(!prepareData.isCheckedAll);
        setCheckAll(prepareData.isCheckedAll);
      }

      onSelectPermission(permissions.group, prepareData.data);
      setIsPatch(true);
    }
    return (() => false);
  }, [formFakemPermission]);

  function checkSelectedPermission({ permissions }, checkedPermissions) {
    const data = [];
    for (let index = 0; index < permissions.length; index++) {
      const existData = includes(checkedPermissions, permissions[index].name);
      if (existData) {
        data.push(permissions[index]);
      }
    }

    return {
      data,
      isCheckedAll: data.length === permissions.length
    }

  }

  function onChange(list) {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < permissionName.length);
    setCheckAll(list.length === permissionName.length);
    const prepareData = checkSelectedPermission(permissions, list);
    onSelectPermission(permissions.group, prepareData.data);

  };

  function onCheckAllChange(e) {
    const isTrue = e.target.checked;
    setIndeterminate(false);
    setCheckAll(isTrue);

    if (isTrue) {
      setCheckedList(permissionName);
      onSelectPermission(permissions.group, permissions.permissions);
    } else {
      setCheckedList([]);
      onSelectPermission(permissions.group, []);
    }
  };

  return permissions && <div className="border-bottom py-2 border-bottom h-100">
    <div className="row">
      <div className="col-lg-6 col-12 m-auto">
        <Checkbox
          id={permissions.group}
          indeterminate={indeterminate}
          checked={checkAll}
          className="d-flex align-items-start parent-checkbox"
          onChange={onCheckAllChange}
        >
          <span className="d-flex flex-row">
            <span className="parent d-flex flex-column">
              <span className="parent-label">Menu {startCase(permissions.group)}</span>
              <span>{startCase(permissions.group)}</span>
            </span>
            <span>
              {isBoolean(permissions.isValid) && (
                <Popover placement="top" title={false} trigger="hover" content={<p className="note-content m-0">{permissions.isValid ? 'Pilihan permissions sudah memenuhi syarat' : `Wajib memilih "View ${startCase(permissions.group)}" terlebih dahulu`}</p>}>
                  <Tag colorType={permissions.isValid ? 'deo-green' : 'deo-red'} className="ml-3">{permissions.isValid ? 'Valid' : 'Invalid'}</Tag>
                </Popover>
              )}
            </span>

          </span>
        </Checkbox>
      </div>
      <div className="col-lg-6 col-12">
        <div className="row mb-2">
          <div className="col-lg-12">
            <CheckboxGroup
              className="field-value"
              options={permissionName}
              value={checkedList}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
}

import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component'
import { TenantSalesOrderRestService } from '@clodeo/libs/core/rest/admin/tenant-sales-order/tenant-sales-order-rest.service';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { pick } from 'lodash';
import React from 'react';
import { useState } from 'react'
import { RequestCancelFormComponent } from '../form/request-cancel-form.component';
const authenticationService: AuthenticationService = new AuthenticationService;
const tenantSalesOrderRestService = new TenantSalesOrderRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const { handleRequest } = new HandleService;

export const RequestCancelUpdateComponent = (
  props: {
    data: IPayloadRequestCancel;
    onCancel: () => void;
    onFinish: () => void;
  }
) => {
  const [loading, setLoading] = useState(false);
  function onApprove(data) {
    setLoading(true);
    const payload = pick(data, ['id', 'isApproved', 'reason', 'tenantId']);
    const obs = tenantSalesOrderRestService.updateStatusRequestCancel(payload.id, payload)
    handleRequest({
      obs,
      onDone: () => {
        setLoading(false);
        props.onFinish()
      },
      onError: () => {
        props.onCancel();
      }
    })
  }
  return <Spinner spinning={loading} >
    <RequestCancelFormComponent data={props.data} onCancel={props.onCancel} onSubmit={onApprove} />
  </Spinner>
}
import { RequestService } from '../../request.service';

export class DashboardRestService {
  requestService = new RequestService();
  request: RequestService;
  constructor(
    baseUrl,
    useInterceptor: any = true,
    withoutInterceptor: boolean = false
  ) {
    this.request = this.requestService.new(
      `${baseUrl['API_ADMIN']}/dashboard`,
      useInterceptor,
      withoutInterceptor
    );
  }

  /**
   * Get cod credit dashboard
   * @param qParams
   */
  getSummary(qParams) {
    return this.request.get('/cod-credit', {
      ...qParams,
      data: { 'Content-Type': 'application/json' },
    });
  }

  /**
   * Get order COD count dashboard
   * @param qParams 
   */
  getSalesCODCount(qParams) {
    return this.request.get('/sales-cod-count', {
      ...qParams,
      data: { 'Content-Type': 'application/json' },
    });
  }

  /**
   * Get order COD total amount dashboard
   * @param qParams 
   */
  getSalesCODTotalAmount(qParams) {
    return this.request.get('/sales-cod-total-amount', {
      ...qParams,
      data: { 'Content-Type': 'application/json' },
    });
  }

   /**
   * Get Billing Total Amount dashboard
   * @param qParams
   */
    getBillingTotalAmount(qParams) {
      return this.request.get('/billing-total-amount', {
        ...qParams,
        data: { 'Content-Type': 'application/json' },
      });
    }
}

import React, { useRef, useState } from 'react';
import moment from 'moment';
import AccountingJS from 'accounting-js';
import { get, compact } from 'lodash';

import { ITenantWalletDetailProps } from './tenant-wallet-detail.d';
import { AdminWalletTransactionRestService } from '@clodeo/libs/core/rest/admin/wallet-transaction/admin-wallet-transaction-rest.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { BaseListComponent } from 'apps/admin-web/src/app/components/base-component/list/base-list.component';
import {
  IColumn,
  IIncludes,
} from 'apps/admin-web/src/app/components/base-component/list/base-list';
import { TenantWalletUploadComponent } from '../upload/tenant-wallet-upload.component';

import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { Card } from '@clodeo/clodeo-ui/components/data-display/card/card.component';
import { InputTextArea } from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';
import { IModalValue } from '@clodeo/clodeo-ui/components/feedback/modal/modal';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { AclService } from 'apps/admin-web/src/app/core/auth/acl.service';
import { UpdateNoteComponent } from '@clodeo/clodeo-ui/components/base/update-note/update-note.component';
import { LogdetailComponent } from 'apps/admin-web/src/app/components/user-log-detail/log-detail.component';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { Action } from '@clodeo/clodeo-ui/components/general/action/action.component';
import { Form } from 'antd';

const aclService: AclService = new AclService();
const authenticationService = new AuthenticationService();
const adminWalletTransactionRestService = new AdminWalletTransactionRestService(
  environment.ENDPOINTS,
  authenticationService.axiosInterceptors
);
const { handleRequest } = new HandleService();
const notif: Notifications = new Notifications();

export const TenantWalletDetailComponent = (
  props: ITenantWalletDetailProps
) => {
  const [modalValue, setModalValue] = useState<IModalValue>();

  const { detailTenant, idTenant } = props;
  const baseListRef = useRef<any>();
  const [form] = Form.useForm();
  const includes: IIncludes = {
    toogleOptions: true,
    refresh: true,
    onlyExportAllData: true,
    fileNameExportAllData: 'Detail Transaksi - Tenant Wallet',
  }
  const columns: IColumn[] = compact([
    {
      key: '1',
      title: 'TANGGAL TRANSAKSI',
      dataIndex: 'transactionDate',
      width: '150px',
      render: (value) => moment(value).format('DD-MM-YYYY'),
    },
    {
      key: '2',
      title: 'TYPE TRANSAKSI',
      dataIndex: 'transactionType',
      width: '150px',
    },
    {
      key: '3',
      title: 'NO REKENING',
      dataIndex: 'accountNumber',
      width: '150px',
      render: (value) => value || '-',
    },
    {
      key: '4',
      title: 'NAMA REKENING',
      dataIndex: 'accountName',
      width: '150px',
      render: (value) => value || '-',
    },
    {
      key: '5',
      title: 'NAMA BANK',
      dataIndex: 'bankName',
      width: '170px',
      render: (value) => value || '-',
    },
    {
      key: '6',
      title: 'CABANG BANK',
      dataIndex: 'bankDistrict',
      width: '170px',
      render: (value) => value || '-',
    },
    {
      key: '7',
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '150px',
      render: (value) =>
        AccountingJS.formatMoney(value, { symbol: 'Rp ', format: '%s %v' }),
      formatExprt: (value) =>
        AccountingJS.formatMoney(value, { symbol: 'Rp ', format: '%s %v' }),
    },
    {
      key: '8',
      title: 'FEE TRANSFER',
      dataIndex: 'feeTransfer',
      width: '150px',
      render: (value) =>
        AccountingJS.formatMoney(+(value || 0), {
          symbol: 'Rp ',
          format: '%s %v',
        }),
      formatExprt: (value) =>
        AccountingJS.formatMoney(+(value || 0), {
          symbol: 'Rp ',
          format: '%s %v',
        }),
    },
    {
      key: '9',
      title: 'NOTE',
      dataIndex: 'note',
      width: '150px',
      render: (value, data) => (
        <UpdateNoteComponent
          hideEdit={
            !aclService.can(['wallet.tenant_wallet.edit']) ? true : false
          }
          note={value}
          onSubmit={(val) => editNoteProcess(data, val)}
        />
      ),
    },
    {
      key: '10',
      title: 'STATUS',
      dataIndex: 'status',
      width: '150px',
      render: (value) => value || '-',
    },
    {
      key: '11',
      title: 'DESKRIPSI',
      dataIndex: 'transactionSource',
      width: '150px',
      render: (val: any) => {
        switch (val) {
          case 'deposit':
            return 'Deposit';
          case 'adjustment':
            return 'Adjustment';
          case 'wallet_COD':
            return 'COD Wallet';
          case 'credit_COD':
            return 'Credit COD';
          case 'debit_COD':
            return 'Debit COD';
          case 'billing_payment':
            return 'Billing Payment';
          case 'referal_code':
            return 'Referal Code';
          case 'disbursement':
            return 'Pemotongan Invoice';
          default:
            break;
        }
      },
    },
    {
      key: '12',
      title: 'NO REFERENSI',
      dataIndex: 'referenceNumber',
      width: '150px',
      render: (value) => value || '-',
    },
    {
      key: '12',
      dataIndex: 'userAdminName',
      title: 'USER LOG',
      width: '120px',
      ignoreExprt: true,
      render: (val: any, record) => (
        <Button
          label="Open"
          type="ghosted"
          className="p-2"
          onClick={() => prepareUserLogDetailModal(idTenant)}
        />
      ),
    },
    aclService.can(['wallet.tenant_wallet.edit']) && {
      key: '14',
      title: 'ACTION',
      width: '100px',
      render: (record) => {
        return (
          <>
            <div className="row">
              <Button
                type="tertiary"
                className="p-2 mr-2"
                iconName="i-UploadSimple"
                onClick={() =>
                  setModalValue({
                    visible: true,
                    title: 'Upload Attachments',
                    contentTemplate: (
                      <TenantWalletUploadComponent
                        idTransactions={props.idTenant}
                        idWalletTransactions={record.id}
                        onCancel={() => setModalValue(null)}
                        onSuccess={() => {
                          setModalValue(null);
                          baseListRef.current.callLoadData();
                        }}
                        detailTransaction={{ ...record }}
                      />
                    ),
                  })
                }
              />
              <Action
                type="delete"
                onClick={() => deleteTransaction(record.id)}
              />
            </div>
          </>
        );
      },
    },
  ]);

  function getWalletTransaction(params) {
    return adminWalletTransactionRestService.findTenantByIdTransaction(
      props.idTenant,
      params
    );
  }

  function editNoteProcess(data, note) {
    baseListRef.current.showLoading();
    const { id } = data;
    const payload = {
      ...data,
      note,
    };
    const obs = adminWalletTransactionRestService.updateWalletTransaction(
      id,
      payload
    );
    handleRequest({
      obs,
      successMessage: 'Berhasil merubah data',
      errorMessage: 'Gagal merubah data',
      onError: () => {
        baseListRef.current.hideLoading();
      },
      onDone: () => {
        baseListRef.current.callLoadData();
      },
    });
  }

  function prepareUserLogDetailModal(tenantId) {
    baseListRef.current.showLoading(true);
    const obs = adminWalletTransactionRestService.getAdminHistory(tenantId);
    handleRequest({
      obs,
      hideError: true,
      onError: (err) => {
        baseListRef.current.showLoading(false);
      },
      onDone: (res) => {
        baseListRef.current.showLoading(false);
        setModalValue({
          title: 'Log Detail',
          visible: true,
          data: null,
          className: 'admin',
          contentTemplate: <LogdetailComponent record={res} />,
        });
      },
    });
  }

  function deleteTransaction(transactionId) {
    setModalValue({
      title: 'Hapus Transaksi',
      visible: true,
      data: null,
      className: 'admin',
      contentTemplate: (
        <>
          <Form form={form} onFinish={() => requestDelete(transactionId)}>
            <Form.Item shouldUpdate>
              <div className="row">
                <div className="col-lg-12 mb-3">
                  <div className="d-flex flex-column">
                    <label htmlFor="">Alasan</label>
                    <Form.Item name="note">
                      <InputTextArea />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="action justify-content-center d-flex w-100 mt-2">
                    <Button
                      type="ghosted"
                      label="Batal"
                      className="mr-2"
                      onClick={() => setModalValue(null)}
                    />
                    <Button
                      type="primary"
                      label="Simpan"
                      onClick={() => requestDelete(transactionId)}
                    />
                  </div>
                </div>
              </div>
            </Form.Item>
          </Form>
        </>
      ),
    });
  }

  function requestDelete(transactionId) {
    baseListRef.current.showLoading(true);
    if (!form.getFieldValue('note')) {
      baseListRef.current.showLoading(false);
      notif.show({
        type: 'error',
        title: 'Error',
        description: 'Alasan wajib diisi',
      });
      return;
    }
    const payload = { note: form.getFieldValue('note') };
    const obs = adminWalletTransactionRestService.voidTransaction(
      transactionId,
      payload
    );
    handleRequest({
      obs,
      hideError: true,
      onError: (err) => {
        baseListRef.current.showLoading(false);
        notif.show({
          type: 'error',
          title: 'Error',
          description: err,
        });
        return;
      },
      onDone: (res) => {
        baseListRef.current.showLoading(false);
        baseListRef.current.callLoadData();
        setModalValue(null);
        form.resetFields();
        notif.show({
          type: 'success',
          title: 'Success',
          description: 'Transaksi telah dihapus.',
        });
      },
    });
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-5">
          <Card className="mb-1">
            <div className="row">
              <div className="col-5 font-weight-bolder">Nama Perusahaan</div>
              <div className="col-1 font-weight-bolder">:</div>
              <div className="col-auto"> {get(detailTenant, 'companyInfo.companyName')} </div>
            </div>
          </Card>
          <Card className="mb-1">
            <div className="row">
              <div className="col-5 font-weight-bolder">No. Telp</div>
              <div className="col-1 font-weight-bolder">:</div>
              <div className="col-auto"> {get(detailTenant, 'companyInfo.phone')} </div>
            </div>
          </Card>
          <Card className="mb-1">
            <div className="row">
              <div className="col-5 font-weight-bolder">Email</div>
              <div className="col-1 font-weight-bolder">:</div>
              <div className="col-auto"> {get(detailTenant, 'companyInfo.email')} </div>
            </div>
          </Card>
        </div>
        <div className="col">
          <h1 className="d-flex justify-content-end">
            {AccountingJS.formatMoney(get(detailTenant, 'walletBalance.balance') || 0, { symbol: 'Rp ', format: '%s %v' })}
          </h1>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col">
          <BaseListComponent
            listId="wallet-transactions"
            ref={baseListRef}
            columns={columns}
            loadDataObservable={getWalletTransaction}
            includes={includes}
          />
        </div>
      </div>

      {modalValue && (
        <Modal
          {...modalValue}
          footer={null}
          className="admin"
          onCancel={() => setModalValue(null)}
        >
          {modalValue.contentTemplate}
        </Modal>
      )}
    </div>
  );
};

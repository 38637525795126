import React, { useState, useEffect } from 'react';

import { UsersFormComponent } from '../form/users-form.component';
import { UsersService } from '../users.service';

import { Form } from 'antd';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { AccountAdminRestServices } from '@clodeo/libs/core/rest/account/admin/user/account-admin-user.rest.service';
import { environment } from '../../../../../../environments/environment';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';

// Extra configs
const notif = new Notifications;
const usersService = new UsersService;

export const UsersUpdateComponent = (props: IUsersUpdateProps) => {

  const { onClose, onFinish, roles, data, relatedData } = props;
  const [selectedRowKey, setSelectedRowKey] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userDepartement, setUserDepartement] = useState('');
  const [form] = Form.useForm();

  const { handleRequest } = new HandleService;
  const authenticationService = new AuthenticationService;
  const accountAdminRestServices = new AccountAdminRestServices(`${environment.ENDPOINTS.API_ACCOUNT}`, authenticationService.axiosInterceptors);

  function onSelectedRoles(selectedRowKey, selectedRowsData) {
    setSelectedRowKey(selectedRowKey);
    form.setFieldsValue({ accessRolesAdmin: selectedRowsData.map(item => item.id) });
  }

  function onCancel() {
    setSelectedRowKey([]);
    form.resetFields();
    onClose();
  }

  function onSubmit() {
    formValid() && updateser(form.getFieldsValue(true));
  }

  function updateser(payload) {
    delete payload.accessRoleAdmin
    const obs = accountAdminRestServices.updateUserAdmin(payload);
    setLoading(true);
    handleRequest({
      obs,
      successMessage: 'Berhasil update user',
      onError: () => setLoading(false),
      onDone: () => {
        setLoading(false);
        onFinish();
        setSelectedRowKey([]);
        form.resetFields();
      }
    })
  }

  function formValid() {
    if ((data.accessRoleAdmin && data.accessRoleAdmin.length) || (form.getFieldValue('accessRolesAdmin') && form.getFieldValue('accessRolesAdmin').length)) {
      return true;
    } else {
      notif.show({
        type: 'warning',
        title: 'Peringatan',
        description: 'Mohon pilih minimal 1 role',
        useService: true
      })
      return false;
    }
  }

  useEffect(() => {
    const department =  data.accessRoleAdmin.map(accessRoleAdmin => {
      return accessRoleAdmin.roleGroupName;
    });
    
    setUserDepartement(department.join(', '));
    const accessRolesAdmin = data.accessRoleAdmin ? data.accessRoleAdmin.map(item => item.roleId) : [];
    setSelectedRowKey(accessRolesAdmin);
    data && form.setFieldsValue({
      ...data,
      accessRolesAdmin
    });
  }, []);

  return data ? (
    <Spinner spinning={loading}>
      <Form form={form} >
        <UsersFormComponent
          type="update"
          roles={roles}
          selectedRoles={selectedRowKey}
          onSelectedRoles={onSelectedRoles}
          form={form}
          relatedData={relatedData}
          userDepartement={userDepartement}
        />
        <div className="w-100 text-center">
          <Button type="ghosted" label="Batal" className="mr-3" onClick={() => onCancel()} />
          <Button submit type="primary" label="Simpan" onClick={() => onSubmit()} />
        </div>
      </Form>
    </Spinner>
  ) : null
}
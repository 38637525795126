import { RequestService } from '../../request.service';

export class AccessPermissionRestService {
  requestService = new RequestService;
  request: RequestService;

  /**
   * 
   * @param baseUrl only from environment.ENDPOINTS 
   * @param useInterceptor 
   * @param withoutInterceptor 
   */
  constructor(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
    this.request = this.requestService.new(`${baseUrl['API_ADMIN']}/access-permissions`, useInterceptor, withoutInterceptor);
  }

  /**
   * Get list of access permissions
   * @param
   */
  findAll(qParams) {
    return this.request.get('', qParams);
  }

  /**
   * Get detail of access permissions
   * @param accessPermissionId 
   */
  detail(accessPermissionId) {
    return this.request.get(`detail?accessPermissionId=${accessPermissionId}`);
  }

}
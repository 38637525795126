import React from 'react';
import { ActionProps } from './action';

const Action = (props: ActionProps) => {
  const iconName = matchIconName(props);
  return (
    <React.Fragment>
      <button
        type="button"
        onClick={props.onClick}
        className={`p-1 text-center btn deo-action-${props.type || ''} action-${props.size || 'md'} ${props.className || ''}`}
        // className={'text-center btn deo-action-' + props.type + (' action-' + (props.size || 'md')) + ()}
      >
        <i className={'icon-' + iconName}></i>
      </button>
    </React.Fragment>
  )
}

const matchIconName = (props: ActionProps) => {
  const type = props.type;
  switch (type) {
    case 'delete':
      return 'deo-trash';
    case 'edit':
      return 'deo-pencil';
    case 'setting':
      return 'deo-setting-2';
    case 'upload':
      return 'deo-upload';
    case 'copy':
      return 'deo-copy';
    case 'close':
      return 'deo-close';
    case 'password':
      return 'deo-password';
    case 'accept':
      return 'i-Check';
    case 'reject':
      return 'i-X';
    case ('history' || 'attachments' || 'note'):
      return 'i-Note';
    default:
      return '';
  }
}

export { Action };
import React, { useState } from 'react';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { IModalValue } from '@clodeo/clodeo-ui/components/feedback/modal/modal';
import { TrackingNumberDetailComponent } from '@clodeo/clodeo-ui/components/base/tracking-number-detail/tracking-number-detail.component';
import { AdminMonitoringPickupRestService } from '@clodeo/libs/core/rest/admin/monitoring-pickup/admin-monitoring-pickup-rest.service';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { Popover } from '@clodeo/clodeo-ui/components/data-display/popover/popover.component';


export const CodReceiptNumberTemplateListComponent = (props: {
  data: any
}) => {
  const { data } = props;
  const [modalValue, setModalValue] = useState<IModalValue>();
  const authenticationService: AuthenticationService = new AuthenticationService;
  const adminMonitoringPickupRestService = new AdminMonitoringPickupRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
  const { handleRequest } = new HandleService;

  function showAwbBill(waybillResponse) {
    setModalValue({
      visible: true,
      title: 'Rincian Pengiriman',
      contentTemplate: <TrackingNumberDetailComponent waybillResponse={waybillResponse} loading={!waybillResponse} />
    });
  }
  function loadAWBDetail(data: { courierCode: string, waybill: string }) {
    const obs = adminMonitoringPickupRestService.getWaybillDetail(data);
    handleRequest({
      obs,
      hideError: true,
      onError: showAwbBill,
      onDone: showAwbBill
    })
  }

  return <div className="list-wrapper">
    <div className="d-flex flex-row flex-wrap justify-content-between">

      <div className="d-flex flex-column justify-content-between">
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-40px">Seller</div>
          <div className="value">{data.sellerName || '-'}</div>
        </div>
        <div className="d-flex flex-row justify-content-between ">
          <div className="label w-60px">Tenant ID</div>
          <div className="value normal">{data.tenantId || '-'}</div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between">
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-40px">Kurir</div>
          <div className="value text-center">
            <img src={'https://nx-design-system-web-development.clodeo.com/img/logo/courier/code/' + data.codCreditTransaction.courierChannelId + '.svg'} alt="" srcSet="" className="img-fluid" />
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-40px">Resi</div>
          <div className="value resi text-center clickable" onClick={() => { loadAWBDetail({ courierCode: data.codCreditTransaction.courierChannelId, waybill: data.airwaybillNumber }) }}>{data.airwaybillNumber || '-'}</div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between">
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-50px">Destinasi</div>
          <div className="icon clickable">
            <Popover placement="top" title={false} trigger="hover" content={<p className="note-content m-0">{data.destination || '-'}</p>}>
              <a className="btn btn-deo-ghosted btn-deo-md p-1">
                <i className="icon-i-MapPin icon-1px"></i>
              </a>
            </Popover>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-50px">Catatan</div>
          <div className="icon clickable">
            <Popover placement="top" title={false} trigger="hover" content={<p className="note-content m-0">{data.notes || 'Tidak Ada Catatan'}</p>}>
            <a className="btn btn-deo-ghosted btn-deo-md p-1">
              <i className="icon-i-ChatCenteredText icon-1px"></i>
            </a>
          </Popover>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between">
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-60px">Harga</div>
          <div className="value text-right">
            {'Rp ' + (data.subTotalAmount || 0).toLocaleString()}
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-60px">Pengiriman</div>
          <div className="value text-right">{'Rp ' + (data.shippingCharge || 0).toLocaleString() }</div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between">
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-40px">Diskon </div>
          <div className="value text-right normal">
            {'Rp ' + (data.discount || 0).toLocaleString()}
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-40px">Pajak</div>
          <div className="value text-right normal">{'Rp ' + (data.tax || 0).toLocaleString()}</div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between">
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-40px">Adjust</div>
          <div className="value text-right normal">
            {'Rp ' + (data.adjustment || 0).toLocaleString()}
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-40px">Total</div>
          <div className="value text-right">{'Rp ' + (data.total || 0).toLocaleString()}</div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between">
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-50px">COD(%)</div>
          <div className="value text-right w-40px">
            {data.codFeePercentage || 0}
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-50px">Asuransi</div>
          <div className="value text-right normal w-40px">{'Rp ' + (data.insuranceAmount || 0).toLocaleString()}</div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between">
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-50px">COD(Rp)</div>
          <div className="value text-right">
            {'Rp ' + (data.codFeeValue || 0).toLocaleString()}
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-50px">Receive</div>
          <div className="value text-right">{'Rp ' + (data.receiveAmount || 0).toLocaleString()}</div>
        </div>
      </div>

    </div>
    {modalValue && (
        <Modal
          {...modalValue}
          footer={null}
          className="admin"
          onCancel={() => setModalValue(null)}
        >
          {modalValue.contentTemplate}
        </Modal>
      )}
  </div>
}

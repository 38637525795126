import { RequestService } from '../../request.service';
import { IEntryRelatedData, ITenantSubscription } from './admin.d';
// export { ICoupon };

export class AdminRestService {
  requestService = new RequestService;
  request: RequestService;
  requestAccount: RequestService;
  /**
   * 
   * @param baseUrl only from environment.ENDPOINTS
   * @param useInterceptor 
   * @param withoutInterceptor 
   */
  constructor(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
    this.request = this.requestService.new(`${baseUrl['API_ADMIN']}/admin`, useInterceptor, withoutInterceptor);
    this.requestAccount = this.requestService.new(`${baseUrl['API_ACCOUNT']}/admin`, useInterceptor, withoutInterceptor);
  }

  /**
   * Get list of tenant subscription
   * @param qParams 
   */
  getTenantsSubscriptions(qParams) {
    return this.request.get<{ data: any[], total: number, skip: number, take: number }>('/tenant-subscriptions', qParams);
  }

  /**
   * Get tenant subscription by Id
   * @param id 
   * @param qParams 
   */
  getTenantsSubscriptionsById(id, qParams?) {
    return this.request.get<ITenantSubscription>(`/tenant-subscriptions/${id}`, qParams);
  }

  /**
   * Get summary of tenant subscriptions
   */
  getTenantsSubscriptionsSummary() {
    return this.request.get<any>('/tenant-subscriptions-summary');
  }

  /**
   * Get tenant subscription entry related data
   * @param qParams 
   */
  getRelatedData(qParams) {
    return this.request.get<IEntryRelatedData>('/entry-related-data', qParams);
  }

  /**
   * Get list of tenants
   * @param qParams 
   */
  getAll(qParams) {
    return this.request.get('tenants', qParams);
  }


  /**
   * Get summary of tenants
   */
  getSummary() {
    return this.request.get('tenants-summary');
  }


  /**
   * Mark tenant is real
   * @param isReal 
   * @param tenantId 
   */
  markIsReal(isReal: boolean, tenantId: string) {
    return this.request.put(`tenants/${tenantId}/mark-isreal/${isReal}`);
  }

  /**
   * Activate tenant status
   * @param active 
   * @param tenantId 
   * @param payload 
   */
  markIsActive(active: boolean, tenantId: string, payload: { companyStatus: string }) {
    return this.request.put(`tenants/${tenantId}/activate-tenant/${active}`, payload);
  }

  /**
   * Get tenant subscription entry related data
   * @param clientAppId 
   */
  loadRelatedData(clientAppId: string = 'clodeo-admin-web') {
    return this.request.get(`entry-related-data?clientAppId=${clientAppId}`);
  }

  /**
   * Get detail of tenants
   * @param tenantId 
   */
  loadDataById(tenantId: string) {
    return this.request.get(`tenants/${tenantId}`);
  }

  /**
   * Update Shipping Service
   * @param tenantId 
   * @param payload 
   */
  shippingService(tenantId: string, payload: any) {
    return this.request.post(`tenants/${tenantId}/shipping-service`, payload);
  }

  /**
   * Update Warehouse Channel Is Already Member
   * @param tenantId 
   * @param warehouseId 
   * @param isAlreadyMember 
   */
  warehouseChannels(tenantId: string, warehouseId: string, isAlreadyMember: boolean) {
    return this.request.post(`tenants/${tenantId}/wms/${warehouseId}/is-already-member/${isAlreadyMember}`,);
  }

  /**
   * Get list tenant bill
   * @param params 
   */
  getTenantBills(params) {
    return this.request.get('tenant-bills', params)
  }

  upgradeRenew(type, payload, tenantId) {
    return this.request.put(`tenants/${tenantId}/${type}-subscription`, payload)
  }

  /**
   * 
   * @param id sicepat id
   * @param data payload
   * @returns generate member id sicepat
   */
  generateMemberIdSicepat(id, data) {
    return this.request.put(`/tenants/${id}/sicepat-member-id`, data);
  }

  /**
   * Get history of admin
   * @param tenantId
   */
  getAdminHistory(tenantId: string) {
    return this.request.get(`${tenantId}/histories`);
  }

  /**
   *
   * 
   * @param qParams get all tenant users
   */
  getTenantUser(qParams) {
    return this.request.get('/tenant-user-admin', qParams);
  }

  /**
   * 
   * @param userId user id
   * @returns Update Confirm Email Status
   */
  activationAccount(userId) {
    return this.request.put(`/users/${userId}/confirm-email`);
  }

  /**
   * 
   * @param codeId 
   * @param tenantId 
   * @returns 
   */
  setReferralCode(codeId, tenantId) {
    return this.requestAccount.post(`tenants/${tenantId}/set-referral/${codeId}`)
  }

}

import { IModalValue } from '@clodeo/clodeo-ui/components/feedback/modal/modal';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { AdminBillingTransactionRestService } from '@clodeo/libs/core/rest/admin/billing-transaction/admin-billing-transaction-rest.service';
import { pick } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { tap } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { IColumn, IIncludes } from '../../../../../components/base-component/list/base-list';
import { BaseListComponent } from '../../../../../components/base-component/list/base-list.component';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { BillingShippingReturnMoreFilterComponent } from '../more-filter/billing-shipping-return-more-filter.component';
import { BillingShippingReturnSummaryComponent } from '../summary/billing-shipping-return-summary.component';
import './billing-shipping-return-list.component.scss';
import { BillingShippingReturnTemplateListComponent } from './billing-shipping-return-list-template.component';

export const BillingShippingReturnListComponent = () => {
  const filters = ['keyword', 'options.take', 'options.skip', 'options.includeTotalCount', 'tenantId', 'filterDateType', 'lowFilterDate', 'highFilterDate', 'paymentChannel', 'paymentStatus', 'status'];
  const baseListRef = useRef<any>();
  const authenticationService: AuthenticationService = new AuthenticationService;
  const adminBillingTransactionRestService = new AdminBillingTransactionRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);

  const { handleRequest } = new HandleService;
  const [modalValue, setModalValue] = useState<IModalValue>()

  const [billingShippingReturnSummary, setBillingShippingReturnRequestSummary] = useState({
    totalUnpaid: null,
    totalPartiallyPaid: null,
    totalPaid: null,
    total: null
  })
  const [summaryFilter, setSummaryFilter] = useState(null);
  const [firstInit, setFirstInit] = useState<boolean>(true);

  const columns: IColumn[] = [
    {
      key: '1',
      dataIndex: '',
      render: (record: any) => {
        return (<BillingShippingReturnTemplateListComponent data={record} />);
      }
    }
  ];

  const includes: IIncludes = {
    refresh: true,
    keyword: true,
    toogleOptions: true,
  }

  function loadBillingShippingReturnSummary(params) {
    const summaryParams = {
      lowDate: params.lowCreatedDate,
      highDate: params.highCreatedDate
    }
    const obs = adminBillingTransactionRestService.getBillingReturnFeeSummary({ params: summaryParams })
    handleRequest({
      obs,
      onDone: (res) => setBillingShippingReturnRequestSummary(res)
    })
  }

  function loadDataObservable(params) {
    if (params.paymentChannel) {
      params.paymentChannel = (params.paymentChannel).toString();
    }
    if (params.status) {
      params.status = (params.status).toString();
    }
    if (params.paymentStatus) {
      params.paymentStatus = (params.paymentStatus).toString();
    }
    const filterBySummary = summaryFilter;
    const moreFilterAndParams = pick(params, filters)
    const qParams = { ...moreFilterAndParams, ...filterBySummary }
    return adminBillingTransactionRestService.getBillingReturnFeeListMini({ params: qParams }).pipe(
      tap(() => loadBillingShippingReturnSummary(params))
    )
  }

  useEffect(() => {
    if (!firstInit) {
      baseListRef.current.callLoadData();
    }
    setFirstInit(false)
  }, [summaryFilter])


  return (
    <>
      <BillingShippingReturnSummaryComponent billingShippingReturnSummary={billingShippingReturnSummary} onChangeFilter={setSummaryFilter} />
      <BaseListComponent
        listId="billing-shipping-return-list"
        columns={columns}
        ref={baseListRef}
        loadDataObservable={loadDataObservable}
        includes={{
          ...includes,
          moreFilter: {
            templateRf: (form) => <BillingShippingReturnMoreFilterComponent form={form} />
          }
        }}
      />
      {modalValue && (
        <Modal
          {...modalValue}
          footer={null}
          className="admin"
          onCancel={() => setModalValue(null)}
        >
          {modalValue.contentTemplate}
        </Modal>
      )}
    </>
  )
}

import React from 'react';
import { ErrorComponent } from '@clodeo/clodeo-ui/components/page/error-page.component';
import './not-found.component.scss';

export class NotFoundComponent extends React.Component<any, any> {
  render() {
    return (
      <ErrorComponent />
    );
  }
}

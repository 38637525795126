
import _ from 'lodash';
import { of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { ExcelRenderer } from "react-excel-renderer";
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { IHandleRequest, IHandleLoadAllRequest, IDataLoadAllRequest } from './handle.d';

const notif = new Notifications;

export class HandleService {
  dataLoadAll: IDataLoadAllRequest = {
    records: [],
    totalSkip: 0,
    totalRecord: 0
  }

  handleRequest(params: IHandleRequest) {
    const { obs, hideError, successMessage, errorMessage, onDone, onError, handleTap } = params
    return obs.pipe(
      catchError((error) => {
        if (!hideError) {
          notif.show({
            type: 'error',
            title: 'Error',
            description: errorMessage || error,
            useService: true
          });
        }
        const errData = _.get(error, 'response.data');
        onError && onError(errData || '')
        return throwError(error);
      }),
      tap((response) => {
        handleTap && handleTap(response);
      })
    ).subscribe((res) => {
      successMessage && notif.show({
        type: 'success',
        title: 'Sukses',
        description: successMessage,
      });
      onDone && onDone(res);
    })
  };

  handleLoadAllReq(data: IHandleLoadAllRequest) {
    let params = { ...data.qParams };
    const { obs, hideError, successMessage, errorMessage, onDone, onError, onUpdate, handleTap, method, clearOnDone } = data

    return obs(params).pipe(
      catchError((error) => {
        if (!hideError) {
          notif.show({
            type: 'error',
            title: 'Error',
            description: errorMessage || error,
            useService: true
          });
        }
        const errData = _.get(error, 'response.data');
        onError && onError(errData || '')
        return throwError(error);
      }),
      tap((response) => {
        handleTap && handleTap(response);
      })
    ).subscribe((response) => {

      successMessage && notif.show({
        type: 'success',
        title: 'Sukses',
        description: successMessage,
      });
      this.dataLoadAll.records = [...this.dataLoadAll.records, ...response.data];
      this.dataLoadAll.totalSkip += (response.take || 0);
      this.dataLoadAll.totalRecord = (response.total || 0);
      if (this.dataLoadAll.totalSkip < this.dataLoadAll.totalRecord) {
        switch (method) {
          case 'post':
            params.skip = this.dataLoadAll.totalSkip;
            break;
          default:
            params["options.skip"] = this.dataLoadAll.totalSkip;
            break;
        }
        data.qParams = params;
        this.handleLoadAllReq(data);
        onUpdate && onUpdate(this.dataLoadAll);
      } else {
        this.handleLoadAllReq(data).unsubscribe();
        this.dataLoadAll.totalSkip = this.dataLoadAll.totalRecord;
        onDone && onDone(this.dataLoadAll);
        if (clearOnDone) {
          this.dataLoadAll = {
            records: [],
            totalSkip: 0,
            totalRecord: 0
          }
        }
      }
    })
  };
}

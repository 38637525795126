import React, { useEffect, useState } from 'react';
import './verify-rekening.component.scss'
import { Form } from 'antd';
import { IModalValue } from '@clodeo/clodeo-ui/components/feedback/modal/modal';
import { Confirms } from '@clodeo/clodeo-ui/components/feedback/confirm/confirm.component';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { AdminVerificationRequestRestService } from '@clodeo/libs/core/rest/admin/verification-request/admin-verification-request.rest.service';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { LoadingComponent } from '@clodeo/clodeo-ui/components/general/loading/loading.component';
import { Image } from "@clodeo/clodeo-ui/components/data-display/image/image.component";
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { Checkbox, CheckboxGroup } from '@clodeo/clodeo-ui/components/data-entry/checkbox/checkbox.component';
import { Input } from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';
import { map, find, forEach } from 'lodash'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import FileSaver from "file-saver";
import { DownloadFileRestService } from '@clodeo/libs/core/rest/admin/download-file/download-file-rest.service';

export const VerifyComponent = (props) => {
  const authenticationService: AuthenticationService = new AuthenticationService();
  const adminVerificationRequestRestService = new AdminVerificationRequestRestService(
    environment.ENDPOINTS,
    authenticationService.axiosInterceptors
  );
  const downloadFileRestService = new DownloadFileRestService(`${environment.ENDPOINTS.API_FILE}`, authenticationService.axiosInterceptors);
  const { handleRequest } = new HandleService;
  const notif = new Notifications;

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const [form] = Form.useForm();
  const [modalValue, setModalValue] = useState<IModalValue>();
  let [rejectReason, setRejectReason] = useState();

  const confirm: Confirms = new Confirms;
  const rejectOptions: any = [
    { label: 'Nomor KTP tidak sesuai dengan data sebelumnya', value: 'Nomor KTP tidak sesuai dengan data sebelumnya' },
    { label: 'Foto buku tabungan tidak jelas', value: 'Foto buku tabungan tidak jelas' },
    { label: 'Nama pemilik rekening tidak sesuai dengan KTP', value: 'Nama pemilik rekening tidak sesuai dengan KTP' },
    { label: 'Nama di Buku Tabungan tidak sesuai dengan KTP', value: 'Nama di Buku Tabungan tidak sesuai dengan KTP' },
  ];

  const onCancel = () => {
    form.resetFields();
    setModalValue(null);
  }

  function loadTenantBankChange() {
    setLoading(true);
    const obs = adminVerificationRequestRestService.findSingleData(props.tenantId)
    handleRequest({
      obs,
      onDone: (res) => {
        setData(res);
        setLoading(false);
      }
    })
  }

  useEffect(() => {
    loadTenantBankChange();
  }, [props.tenantId])

  function formValid() {
    const formValue = form.getFieldsValue(true);
    for (const property in formValue) {
      if (formValue[property] || rejectReason) {
        return true;
      }
    }
  }

  function showVerifyDialog() {
    confirm.show({
      title: 'KONFIRMASI DATA',
      content: <p>Apakah anda yakin untuk mem-verifikasi perubahaan data rekening</p>,
      onOk() { approveRequest(true)}
    })
  }

  function showRejectDialog() {
    return setModalValue({
      title: 'Alasan Ditolak',
      visible: true,
      data: null,
      contentTemplate: <>
        <div>
          <div className="ml-2">
            <CheckboxGroup options={rejectOptions} onChange={onReasonChange}/>
          </div>
          <div className="form-group px-2 mt-4">
            <Form form={form} initialValues={{
              reason: ''
            }}>
            <label>Lainnya</label>
              <Form.Item name="reason" shouldUpdate>
                <Input id="reason" />
              </Form.Item>
            </Form>
          </div>
          <div className="text-center">
            <Button type="ghosted" label="Cancel" className="mr-3" onClick={() => onCancel()} />
            <Button submit type="primary" label="Simpan" onClick={() => validateForm()} /> 
          </div>
        </div>
      </>
    })
    // return setModalValue({
    //   title: 'Alasan Ditolak',
    //   visible: true,
    //   data: null,
    //   contentTemplate: <>
    //     <Form form={form} initialValues={{
    //         id_card_number_id: false,
    //         id_card_image_selfie_id: false,
    //         name_id: false,
    //         saving_book_id: false,
    //         rejectReason: '',
    //         reason: ''
    //       }}>
    //         <Form.Item shouldUpdate>
    //           {() => {
    //             return <>
    //               <div className="row px-4 f12">
    //                 <div className="col-lg-6 pb-2">
    //                   <div className="row align-items-center">
    //                     <Form.Item name="id_card_number_id" valuePropName="checked" className="pr-1">
    //                       <Checkbox checked onChange={() => {form.setFieldsValue({'rejectReason': fileType[0].reason})}} variant="inline-label">Nomor KTP tidak sesuai dengan data sebelumnya</Checkbox>
    //                     </Form.Item>
    //                   </div>
    //                 </div>
    //                 <div className="col-lg-6 pb-2">
    //                   <div className="row align-items-center">
    //                     <Form.Item name="id_card_image_selfie_id" valuePropName="checked" className="pr-1">
    //                       <Checkbox onChange={() => {form.setFieldsValue({'rejectReason': fileType[1].reason})}} variant="inline-label">Foto buku tabungan tidak jelas</Checkbox>
    //                     </Form.Item>
    //                   </div>
    //                 </div>
    //                 <div className="col-lg-6 pb-2">
    //                   <div className="row align-items-center">
    //                     <Form.Item name="name_id" valuePropName="checked" className="pr-1">
    //                       <Checkbox onChange={() => {form.setFieldsValue({'rejectReason': fileType[2].reason})}} variant="inline-label">Nama pemilik rekening tidak sesuai dengan KTP</Checkbox>
    //                     </Form.Item>
    //                   </div>
    //                 </div>
    //                 <div className="col-lg-6 pb-2">
    //                   <div className="row align-items-center">
    //                     <Form.Item name="saving_book_id" valuePropName="checked" className="pr-1">
    //                       <Checkbox onChange={() => {form.setFieldsValue({'rejectReason': fileType[3].reason})}} variant="inline-label">Nama di Buku Tabungan tidak sesuai dengan KTP</Checkbox>
    //                     </Form.Item>
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className="form-group px-2">
    //                 <label>Lainnya</label>
    //                 <Form.Item name="reason">
    //                   <Input id="reason" />
    //                 </Form.Item>
    //               </div>
    //               <div className="text-center">
    //                 <Button type="ghosted" label="Cancel" className="mr-3" onClick={() => onCancel()} />
    //                 <Button submit type="primary" label="Simpan" onClick={() => validateForm()} />
    //               </div>
    //             </>
    //           }}
    //         </Form.Item>
    //       </Form>
    //   </>
    // })
  }

  function validateForm() {
    if (formValid()) {
      rejectRequest(false);
    } else {
      notif.show({
        type: 'warning',
        title: 'Peringatan',
        description: 'Mohon berikan alasan tenant ditolak !',
        useService: true
      })
    }
  }

  function rejectRequest(isVerifyData: boolean) {
    setLoading(true);
    const tenantId = data.id;
    const formValue = form.getFieldsValue(true);
    const payload = normalizeVerifyPayload(data, formValue, isVerifyData);
    const obs = adminVerificationRequestRestService.rejectVerification(tenantId, payload);
    handleRequest({
      obs,
      successMessage: 'Data berhasil disimpan.',
      onDone: props.onFinish
    })
    setLoading(false)
  }

  function approveRequest(isVerifyData: boolean) {
    setLoading(true);
    const tenantId = data.id;
    const payload = {reason: ''}
    const obs = adminVerificationRequestRestService.approveVerification(tenantId, payload)
    handleRequest({
      obs,
      successMessage: 'Data berhasil disimpan.',
      onDone: props.onFinish
    })
    setLoading(false)
  }

  function onReasonChange(checkedValues) {
    let datas;
    datas = checkedValues && checkedValues.join("\n");

    rejectReason = datas;
  }

  function normalizeVerifyPayload(data: any, formValue: any, isVerifyData: boolean) {
    if(rejectReason && formValue.reason) {
      return ({reason: rejectReason + "\n" + formValue.reason})
    }
    if(rejectReason && !formValue.reason) {
      return ({reason: rejectReason});
    }

    if(formValue && !rejectReason) {
      return (formValue);
    }
  }

  function printDocument() {
    html2canvas(document.querySelector("#file-to-download")).then(canvas => {
      const pdf = new jsPDF('l', 'cm', 'a4');
      const width = window.screen.availWidth;
      const imgData = canvas.toDataURL('image/png');

      width >= 1920 ? pdf.addImage(imgData, 'PNG', 0.5, 1, 22, 8) : pdf.addImage(imgData, 'PNG', 0.5, 1, 22, 11.5);
      pdf.save("document.pdf"); 
    });
  }

  return (
    <Spinner spinning={loading} indicator={<LoadingComponent />}>
      <div className="container-fluid content-wrapper">
        {data &&
          <div>
            <div id="file-to-download">
              <div className="row">
                <div className="col">
                  <div className="col-lg-5">
                    <div className="row chilbro mb-2">
                      <div className="label col-lg-4">Nama</div>
                      <div className="value col-lg-8">
                        {data.requestName}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div className="row chilbro mb-2">
                      <div className="label col-lg-2">Alamat</div>
                      <div className="value col-lg-10">
                        {data.currentAddress.fullAddress}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="row chilbro mb-2">
                      <div className="label col-lg-4">No. KTP</div>
                      <div className="value col-lg-8">
                        {data.identityCardNumber}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="row chilbro mb-2">
                      <div className="label col-lg-4">No. Telp</div>
                      <div className="value col-lg-8">
                        {'0' + data.requestPhone}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="row chilbro mb-2">
                      <div className="label col-lg-4">Tenant ID</div>
                      <div className="value col-lg-8">
                        {data.tenantId}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="row chilbro mb-2">
                      <div className="label col-lg-4">Nama OS</div>
                      <div className="value col-lg-8">
                        {data.companyName}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="row chilbro mb-2">
                      <div className="label col-lg-4">Email</div>
                      <div className="value col-lg-8">
                        {data.requestEmail}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="row chilbro mb-2">
                      <div className="label col-lg-4">Alasan</div>
                      <div className="value col-lg-8">
                        {data.reason}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            
              <div className="row mt-4">
                <div className="col">
                  <div className="row chilbro mb-2">
                    <span className="ml-4">Rekening Lama</span>
                  </div>
                  <div className="col-lg-10">
                    <div className="row chilbro mb-2">
                      <div className="label col">Nama Bank Lama</div>
                      <div className="value col">
                        {data.oldTenantBank.bank.bankName}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div className="row chilbro mb-2">
                      <div className="label col">Nomor Rekening</div>
                      <div className="value col">
                        {data.oldTenantBank.accountNumber}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div className="row chilbro mb-2">
                      <div className="label col">Nama pemilik Rek</div>
                      <div className="value col">
                        {data.oldTenantBank.accountName}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div className="row chilbro mb-2">
                      <div className="label col">Kota</div>
                      <div className="value col">
                        {data.oldTenantBank.district}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col">
                  <div className="row chilbro mb-2">
                    <span className="ml-4">Rekening Baru</span>
                  </div>
                  <div className="col-lg-10">
                    <div className="row chilbro mb-2">
                      <div className="label col">Nama Bank Baru</div>
                      <div className="value col">
                        {data.newTenantBank.bank.bankName}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div className="row chilbro mb-2">
                      <div className="label col">Nomor Rekening</div>
                      <div className="value col">
                        {data.newTenantBank.accountNumber}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div className="row chilbro mb-2">
                      <div className="label col">Nama pemilik Rek</div>
                      <div className="value col">
                        {data.newTenantBank.accountName}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-10">
                    <div className="row chilbro mb-2">
                      <div className="label col">Kota</div>
                      <div className="value col">
                        {data.newTenantBank.district}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row text-center mt-4">
              <div className="col">
                <div className="pt-2">
                  <Image.PreviewGroup>
                    <Image
                      src={data.tenantBankChangeRequestAttachmentRels[0].id === "saving_book_id" ? data.tenantBankChangeRequestAttachmentRels[0].file.fileUrl : data.tenantBankChangeRequestAttachmentRels[1].file.fileUrl}
                      width="200px"
                      height="200px"
                      style={{ borderRadius: "20px", cursor: "pointer" }}
                    ></Image>
                  </Image.PreviewGroup>
                </div>
                <span>Buku Tabungan</span>
              </div>
              <div className="col">
                <div className="pt-2">
                  <Image.PreviewGroup>
                    <Image
                      src={data.tenantBankChangeRequestAttachmentRels[1].id === "signature_image_id" ? data.tenantBankChangeRequestAttachmentRels[1].file.fileUrl : data.tenantBankChangeRequestAttachmentRels[0].file.fileUrl}
                      width="200px"
                      height="200px"
                      style={{ borderRadius: "20px", cursor: "pointer" }}
                    ></Image>
                  </Image.PreviewGroup>
                </div>
                <span>Tanda Tangan</span>
              </div>
            </div>

            <div style={props.detailApproval ? {display: 'none'} : {display: 'flex'}} className="row justify-content-between mt-4">
              <div>
                <Button type="ghosted" label="Cancel" className="ml-3" onClick={() => props.onClose()} />
              </div>
              <div>
                <Button type="warning" label="Tolak" className="mr-3" onClick={() => showRejectDialog()} />
                <Button submit type="primary" label="Terima" className="mr-3" onClick={() => showVerifyDialog()} />
              </div>
            </div>
            <div className="row justify-content-end mt-4" style={props.detailApproval ? {display: 'flex'} : {display: 'none'}}>
              <a href={data.tenantBankChangeRequestAttachmentRels[0].id === "saving_book_id" ? data.tenantBankChangeRequestAttachmentRels[0].file.fileUrl : data.tenantBankChangeRequestAttachmentRels[1].file.fileUrl} target="_blank" download>
                <Button type="primary" label="Buku Tabungan" className="mr-3" iconName="deo-download" iconPosition="right" />
              </a>
              <a href={data.tenantBankChangeRequestAttachmentRels[1].id === "signature_image_id" ? data.tenantBankChangeRequestAttachmentRels[1].file.fileUrl : data.tenantBankChangeRequestAttachmentRels[0].file.fileUrl} target="_blank" download="Tandan-tangan">
                <Button type="primary" label="Tanda Tangan" className="mr-3" iconName="deo-download" iconPosition="right" />
              </a>
              <Button type="primary" label="Dokumen" className="mr-3" iconName="deo-download" iconPosition="right" onClick={() => printDocument()} />
            </div>
          </div>
        }
      </div>
      {
        modalValue && <Modal
          {...modalValue}
          footer={null}
          width="fit-content"
          onCancel={() => setModalValue(null)}
          className="admin reject-modal"
        >
          {modalValue.contentTemplate}
        </Modal>
      }
    </Spinner>
  )
}
import { AutoComplete, Option } from '@clodeo/clodeo-ui/components/data-entry/autocomplete/autocomplete.component';
import { RangePicker } from '@clodeo/clodeo-ui/components/data-entry/datepicker/datepicker.component';
import { Option as OptionSelect, Select } from '@clodeo/clodeo-ui/components/data-entry/select/select.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { AdminPickerRestService } from '@clodeo/libs/core/rest/admin/picker/admin-picker-rest.service';
import { Form } from 'antd';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

const authenticationService: AuthenticationService = new AuthenticationService();
const adminPickerRestService = new AdminPickerRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const { handleRequest } = new HandleService;

export const BillingPaymentMoreFilterComponent = (porps: IBillingMoreFIlterProps) => {
  const { form } = porps;
  const [availablePayments, setAvailablePayments] = useState<any[]>();
  const [tenantList, setTenantList] = useState<any[]>();
  const [lodaingTenant, setLodaingTenant] = useState<boolean>(false);

  const paymentListDateTypes = [
    { label: 'Tanggal Transaksi', value: 'transactionDate' },
    { label: 'Tanggal Dibuat', value: 'createDate' }
  ];

  const statuses = [
    { label: 'Lunas', value: 'approved' },
    { label: 'Pending', value: 'pending' },
    { label: 'Ditolak', value: 'reject' }
  ];

  function onChangeCreateDate() {
    const filterDate = form.getFieldValue('filterDate');
    filterDate && form.setFieldsValue({
      lowFilterDate: moment(filterDate[0]).format('YYYY-MM-DD'),
      highFilterDate: moment(filterDate[1]).format('YYYY-MM-DD')
    })
  }

  function loadAvailablePayment() {
    const obs = adminPickerRestService.getAvailablePayment()
    handleRequest({
      obs,
      onDone: (res) => setAvailablePayments(res.data)
    })
  }

  function loadTenant(query) {
    setLodaingTenant(true)
    const obs = adminPickerRestService.getTenant({}, { keyword: query });
    handleRequest({
      obs,
      onDone: (res: any) => {
        setLodaingTenant(false)
        setTenantList(res.data)
      }
    })
  }

  function onSelectTenant(key, option) {
    form.setFieldsValue({
      tenantId: option.key
    })
  }

  useEffect(() => {
    loadAvailablePayment()
  }, []);

  return <>
    <div className="row">
      <div className="col-lg-6" style={{ width: '300px' }}>
        <div className="form-group">
          <label>Tipe Filter Tanggal</label>
          <Form.Item name="filterDateType">
            <Select allowClear placeholder="Pilih Tipe Tanggal">
              {paymentListDateTypes.map(data => (
                <OptionSelect key={data.value} value={data.value}>
                  {data.label}
                </OptionSelect>
              ))}
            </Select>
          </Form.Item>
        </div>
      </div>
      <div className="col-lg-6" style={{ width: '300px' }}>
        <div className="form-group">
          <label htmlFor="">Tanggal</label>
          <Form.Item name="filterDate">
            <RangePicker onChange={onChangeCreateDate} className="w-100" />
          </Form.Item>
        </div>
      </div>
    </div>
    {<>
      <div className="row">
        <div className="col-lg-6" style={{ width: '300px' }}>
          <div className="form-group">
            <label htmlFor="">Tipe Pembayaran</label>
            <Form.Item name="paymentChannel">
              <Select mode="multiple">
                {
                  availablePayments && availablePayments.map(payment => (
                    <OptionSelect value={payment.code}>
                      {payment.name}
                    </OptionSelect>
                  ))
                }
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className="col-lg-6" style={{ width: '300px' }}>
          <div className="form-group">
            <label htmlFor="">Status</label>
            <Form.Item name="status">
              <Select mode="multiple">
                {
                  statuses && statuses.map(status => (
                    <OptionSelect value={status.value}>
                      {status.label}
                    </OptionSelect>
                  ))
                }
              </Select>
            </Form.Item>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12" style={{ width: '570px' }}>
          <div className="form-group">
            <label htmlFor="" className="col-12 label">Seller</label>
            <Spinner spinning={lodaingTenant}>
              <Form.Item name="tenant">
                <AutoComplete
                  onDropdownVisibleChange={(open) => open ? loadTenant('') : setTenantList(null)}
                  onSearch={loadTenant}
                  onSelect={onSelectTenant}
                  onClear={() => form.setFieldsValue({ 'tenantId': '' })}
                >
                  {
                    tenantList?.map((tenant) => (
                      <Option key={tenant.id} value={tenant.displayName}>
                        {tenant.displayName}
                      </Option>
                    ))
                  }
                </AutoComplete>
              </Form.Item>
            </Spinner>
          </div>
        </div>
      </div>
    </>}
  </>
}

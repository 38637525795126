import { AdminRestService } from '@clodeo/libs/core/rest/admin/admin/admin-rest.service';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { BaseListComponent } from 'apps/admin-web/src/app/components/base-component/list/base-list.component';
import React, { useRef, useState } from 'react';
import { IColumn, IIncludes } from 'apps/admin-web/src/app/components/base-component/list/base-list';
import { startCase, capitalize } from 'lodash';
import { Tag } from '@clodeo/clodeo-ui/components/data-display/tag/tag.component';
import moment from 'moment';
import * as accountingJS from 'accounting-js';
import './billing-list.component.scss';
import { BillingMoreFilterComponent } from '../more-filter/billing-more-filter.component';
import { BillingSummaryComponent } from '../billing-summary/billing-summary.component';
import { AclService } from 'apps/admin-web/src/app/core/auth/acl.service';
import { NavLink } from 'react-router-dom';
const aclService = new AclService;
const authenticationService: AuthenticationService = new AuthenticationService;
const adminRestService = new AdminRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
export const BillingListComponent = () => {
  const baseListRef = useRef<any>();
  const [billingSummaryFilter, setBillingSummaryFilter] = useState<any>(null);

  function loadDataObservable(params) {
    const filterValue = billingSummaryFilter;
    const qParams = { ...params, ...filterValue }
    return adminRestService.getTenantsSubscriptions({ params: qParams })
  }

  const includes: IIncludes = {
    refresh: true,
    exports: [{
      label: 'Export berdasarkan filter',
      basedFilter: true,
      fileName: 'Subscriptions | By Filter'
    }, {
      label: 'Export semua data',
      fileName: 'Subscriptions'
    }],
    refreshAction: () => {
      baseListRef.current.resetFilters();

      setTimeout(() => {
        baseListRef.current.callLoadData();
      }, 10);
    },
    toogleOptions: true,
    keyword: true,

    moreFilter: {
      template: <BillingMoreFilterComponent />
    }
  }
  const columns: IColumn[] = [
    {
      key: 'TENANT ID',
      title: 'TENANT ID',
      dataIndex: 'tenantId',
      ignoreDisply: true,
    },
    {
      key: 'IS REAL',
      title: 'IS REAL',
      dataIndex: 'isReal',
      ignoreDisply: true
    },
    {
      key: 'IS REAL',
      title: 'IS REAL',
      dataIndex: 'isReal',
      ignoreDisply: true
    },
    {
      key: 'SUBSCRIPTION PLAN ID',
      title: 'SUBSCRIPTION PLAN ID',
      dataIndex: 'subscriptionPlanId',
      ignoreDisply: true
    },
    {
      key: 'SUBSCRIPTION PLAN NAME',
      title: 'SUBSCRIPTION PLAN NAME',
      dataIndex: 'subscriptionPlanId',
      ignoreDisply: true
    },
    {
      key: 'FREE TRIAL END',
      title: 'FREE TRIAL END',
      dataIndex: 'freeTrialEndDate',
      ignoreDisply: true
    },
    {
      key: 'FREE TRIAL END',
      title: 'FREE TRIAL END',
      dataIndex: 'freeTrialEndDate',
      ignoreDisply: true,
      formatExprt: (val) => val ? moment(val).format('DD-MM-YYYY') : '-'
    },
    {
      key: 'BILLING PERIOD START DATE',
      title: 'BILLING PERIOD START DATE',
      dataIndex: 'billingPeriodStartDate',
      ignoreDisply: true,
      formatExprt: (val) => val ? moment(val).format('DD-MM-YYYY') : '-'
    },
    {
      key: 'BILLING PERIOD END DATE',
      title: 'BILLING PERIOD END DATE',
      dataIndex: 'billingPeriodStartDate',
      ignoreDisply: true,
      formatExprt: (val) => val ? moment(val).format('DD-MM-YYYY') : '-'
    },
    {
      key: 'BILLING CYCLE',
      title: 'BILLING CYCLE',
      dataIndex: 'billingCycle',
      ignoreDisply: true
    },
    {
      key: 'BILLING AMOUNT',
      title: 'BILLING AMOUNT',
      dataIndex: 'billingAmount',
      ignoreDisply: true
    },
    {
      key: 'TOTAL PRODUCTS',
      title: 'TOTAL PRODUCTS',
      dataIndex: 'totalProducts',
      ignoreDisply: true
    },
    {
      key: 'MAX NUM OF PRODUCTS',
      title: 'MAX NUM OF PRODUCTS',
      dataIndex: 'maxNumOfProducts',
      ignoreDisply: true
    },
    {
      key: 'MAX NUM OF ORDERS',
      title: 'MAX NUM OF ORDERS',
      dataIndex: 'maxNumOfOrders',
      ignoreDisply: true
    },
    {
      key: 'TOTAL USERS',
      title: 'TOTAL USERS',
      dataIndex: 'totalUsers',
      ignoreDisply: true
    },
    {
      key: 'MAX NUM OF USERS',
      title: 'MAX NUM OF USERS',
      dataIndex: 'maxNumOfUsers',
      ignoreDisply: true
    },
    {
      key: 'ADDITIONAL USERS',
      title: 'ADDITIONAL USERS',
      dataIndex: 'additionalUsers',
      ignoreDisply: true
    },
    {
      key: 'CLIENT APP ID',
      title: 'CLIENT APP ID',
      dataIndex: 'clientAppId',
      ignoreDisply: true
    },
    {
      key: 'COMPANY NAME',
      title: 'COMPANY NAME',
      dataIndex: 'companyInfo',
      ignoreDisply: true,
      formatExprt: (val) => val.name
    },
    {
      key: 'COMPANY PHONE',
      title: 'COMPANY PHONE',
      dataIndex: 'companyInfo',
      ignoreDisply: true,
      formatExprt: (val) => val.phone
    },
    {
      key: 'COMPANY EMAIL',
      title: 'COMPANY EMAIL',
      dataIndex: 'companyInfo',
      ignoreDisply: true,
      formatExprt: (val) => val.email
    },
    {
      key: 'OWNER USER',
      title: 'OWNER USER',
      dataIndex: 'ownerUser',
      ignoreDisply: true,
      formatExprt: (val) => val.fullName  
    },
    {
      key: 'OWNER PHONE',
      title: 'OWNER PHONE',
      dataIndex: 'ownerUser',
      ignoreDisply: true,
      formatExprt: (val) => val.phoneNumber  
    },
    {
      key: 'OWNER EMAIL',
      title: 'OWNER EMAIL',
      dataIndex: 'ownerUser',
      ignoreDisply: true,
      formatExprt: (val) => val.email  
    },
    {
      key: '1',
      dataIndex: 'companyInfo',
      title: 'PERUSAHAAN',
      width: '120px',
      ignoreExprt: true,
      render: (value, rec) => <div className="d-flex flex-column company-info">
        {
          aclService.can(['tenant.subscription.edit']) ?
            <NavLink to={`billing/upgrade-renew/${rec.tenantId}`}>
              <div className="company-name">{value?.name}</div>
            </NavLink> :
            <div className="company-name">{value?.name}</div>
        }
        <div className="company-email mt-2">{value?.email}</div>
      </div>
    },
    {
      key: '2',
      title: 'ID TENANT',
      width: '100px',
      align: 'center',
      dataIndex: 'tenantId',
      ignoreExprt: true,
    },
    {
      key: '3',
      title: 'PACKAGE',
      width: '100px',
      ignoreExprt: true,
      dataIndex: 'subscriptionPlanName',
      align: 'center',
      render: (val) => <Tag colorType={val}>{startCase(val)}</Tag>
    },
    {
      key: '4',
      title: 'PRODUCT',
      ignoreExprt: true,
      dataIndex: 'totalProducts',
      align: 'center',
      width: '70px',
      render: (totalProducts, data) => `${totalProducts} / ${data?.maxNumOfProducts || '∞'}`
    },
    {
      key: '5',
      title: 'USER',
      ignoreExprt: true,
      dataIndex: 'totalUsers',
      align: 'center',
      width: '70px',
      render: (totalUsers, data) => `${totalUsers} / ${data?.maxNumOfUsers || '∞'}`
    },
    {
      key: '6',
      title: 'FREE TRIAL EXPIRY',
      width: '100px',
      ignoreExprt: true,
      dataIndex: 'freeTrialEndDate',
      render: (freeTrialEndDate) => moment(freeTrialEndDate).format('DD-MM-YYYY')
    },
    {
      key: '7',
      title: 'TGL. MULAI',
      ignoreExprt: true,
      width: '100px',
      dataIndex: 'billingPeriodStartDate',
      render: (billingPeriodStartDate) => billingPeriodStartDate ? moment(billingPeriodStartDate).format('DD-MM-YYYY') : '-'
    },
    {
      key: '8',
      width: '100px',
      title: 'TGL. PENAGIHAN',
      ignoreExprt: true,
      dataIndex: 'billingPeriodEndDate',
      render: (billingPeriodEndDate) => billingPeriodEndDate ? moment(billingPeriodEndDate).format('DD-MM-YYYY') : '-'
    },
    {
      key: '9',
      title: 'BILLING CYCLE',
      ignoreExprt: true,
      width: '100px',
      dataIndex: 'billingCycle',
      align: 'center',
      render: (billingCycle) => <Tag colorType={billingCycle} style={{ width: '70px' }}>{capitalize(billingCycle)}</Tag>
    },
    {
      key: '10',
      title: 'TOTAL PENAGIHAN',
      ignoreExprt: true,
      width: '170px',
      dataIndex: 'billingAmount',
      align: 'right',
      render: (billingAmount) => accountingJS.formatMoney(billingAmount || 0, { symbol: 'Rp ', precision: 0, format: '%s %v', decimal: ',', thousand: '.' })
    }
  ];

  function onChangeSummaryFilter(filterValues) {
    setBillingSummaryFilter(filterValues);
    loadData();
  }

  function loadData() {
    setTimeout(() => {
      baseListRef.current.callLoadData();
    }, 10);
  }

  return <>
    <BillingSummaryComponent onChangeFilter={onChangeSummaryFilter} />
    <BaseListComponent
      listId="subscription-billing"
      ref={baseListRef}
      columns={columns}
      loadDataObservable={loadDataObservable}
      includes={includes}
    />
  </>

}

import { Modal } from 'antd';
import { ConfirmProps } from './confirm';
import React from 'react';

export class Confirms {
  show(params: ConfirmProps) {
    const { confirm } = Modal;
    confirm({
      closeIcon: params.closeIcon || <CloseIcon />,
      className: `confirm-deo ${params.className || ''}`,
      okButtonProps: {
        className: params.okButtonProps?.className || 'btn btn-deo-primary btn-deo-md'
      },
      cancelButtonProps: {
        className: 'btn btn-deo-ghosted btn-deo-md'},
      ...params
    });
  }
}

function CloseIcon() {
  return <i className="icon-deo-close"></i>
}
export class MenuRfService {
  static get menus(): IMenuRf[] {
    return [
      {
        id: 'dahsboard',
        icon: 'deo-circle-four',
        label: 'Dashboard',
        permissions: ['dashboard.general.view'],
        to: '/dashboard',
      },

      /*
      Cash On Delivery
      1. Transfer Credit COD
      2. Tracking Number COD
      */
      {
        id: 'cod',
        icon: 'deo-package',
        label: 'COD',
        permissions: ['cod.transfer_credit.view', 'cod.cod_list.view'],
        to: '/cod/transfer-credit-cod',
        subs: [
          {
            id: 'transfer-credit-cod',
            label: 'Transfer Kredit COD',
            permissions: ['cod.transfer_credit.view'],
            to: '/cod/transfer-credit-cod',
          },
          {
            id: 'cod-tracking-number',
            label: 'Resi COD',
            permissions: ['cod.transfer_credit.view'],
            to: '/cod/tracking-number',
          },
        ]
      },

      /*
        Pengiriman | Shipment
        1. Monitoring Pickup
        2. Permintaan Pembatalan
        3. Kirim Request Pickup
      */
      {
        id: 'shipment',
        icon: 'deo-delivery',
        label: 'Pengiriman',
        to: '/shipment/request-cancel',
        permissions: ['tenant.pickup_monitoring.view', 'tenant.request_pickup.view', 'tenant.cancel_pickup.view'],
        subs: [
          // {
          //   id: 'shipment-monitoring-pickup',
          //   label: 'Monitoring Pickup',
          //   to: '/shipment/monitoring-pickup',
          //   permissions: ['tenant.pickup_monitoring.view'],
          // },
          {
            id: 'shipment-request-cancel',
            label: 'Permintaan Pembatalan',
            permissions: ['tenant.cancel_pickup.view'],
            to: '/shipment/request-cancel',
          },
          {
            id: 'shipment-request-pickup-send',
            label: 'Kirim Request Pickup',
            to: '/shipment/request-pickup',
            permissions: ['tenant.request_pickup.view'],
          }
        ]
      },

      /*
        E-Wallet
        1. Tenants Bank
        2. Wallet Transactions
      */
      {
        id: 'e-wallet',
        icon: 'deo-purse',
        label: 'E-Wallet',
        to: '/e-wallet/tenants-bank',
        permissions: ['wallet.tenant_bank.view', 'wallet.tenant_wallet.view', 'wallet.withdrawal.view', 'wallet.withdrawal_history.view'],
        subs: [
          {
            id: 'e-wallet-tenants-bank',
            label: 'Tenants Bank',
            permissions: ['wallet.tenant_bank.view'],
            to: '/e-wallet/tenants-bank',
          },
          {
            id: 'e-wallet-verifikasi-rekening',
            label: 'Verifikasi Rekening',
            permissions: ['wallet.tenant_bank.view'],
            to: '/e-wallet/verifikasi-rekening',
          },
          {
            id: 'wallet-transactions',
            label: 'Tenants Wallet',
            permissions: ['wallet.tenant_wallet.view'],
            to: '/e-wallet/wallet-transactions',
          },
          {
            id: 'request-withdraw-funds',
            label: 'Pencairan Dana',
            permissions: ['wallet.withdrawal.view'],
            to: '/e-wallet/request-withdraw-funds',
          }, {
            id: 'withdraw-of-tenant-funds',
            label: 'Permintaan Penarikan Dana',
            permissions: ['wallet.withdrawal_history.view'],
            to: '/e-wallet/withdraw-of-tenant-funds',
          }, {
            id: 'debit-cod',
            label: 'Histori Pencairan',
            permissions: ['wallet.withdrawal_history.view'],
            to: '/e-wallet/debit-cod',
          },
        ]
      },

      /*
        Tenants
        1. Tenants
        2. Subscriptions
        3. List Transaksi
      */
      {
        id: 'tenant',
        icon: 'deo-storefront',
        label: 'Tenant',
        to: '/tenant/list',
        permissions: ['tenant.general.view', 'tenant.shipping_integration.view', 'tenant.tenant_list.view', 'admin.user_admin.view'],
        subs: [
          {
            id: 'tenant',
            label: 'Tenant',
            permissions: ['tenant.tenant_list.view'],
            to: '/tenant/list',
          },
          // {
          //   id: 'list-transactions',
          //   label: 'List Transaksi',
          //   to: '/tenant/list-transactions',
          // },
          {
            id: 'verification-request',
            label: 'Verification Request',
            permissions: ['tenant.shipping_integration.view'],
            to: '/tenant/verification-request',
          },
          // {
          //   id: 'training-schedule',
          //   label: 'Training Schedule',
          //   permissions: ['tenant.shipping_integration.view'],
          //   to: '/tenant/training-schedule',
          // },
          // {
          //   id: 'custom-training-schedule',
          //   label: 'Custom Training Schedule',
          //   permissions: ['tenant.shipping_integration.view'],
          //   to: '/tenant/custom-training-schedule',
          // },
          {
            id: 'activation-account',
            label: 'Activation Account',
            permissions: ['admin.user_admin.view'],
            to: '/tenant/activation-account',
          },
        ]
      },
      {
        id: 'users',
        icon: 'deo-users',
        to: '/users',
        label: 'Users',
        permissions: ['admin.user_admin.view', 'admin.role_admin.view', 'admin.permission_admin.view'],
        // to: '/of-funds',
        subs: [
          {
            id: 'users-users',
            permissions: ['admin.user_admin.view'],
            label: 'Users',
            to: '/users',
          },
          {
            id: 'roles',
            permissions: ['admin.role_admin.view'],
            label: 'Roles',
            to: '/users/access-roles',
          },
          {
            id: 'list-permissions',
            permissions: ['admin.permission_admin.view'],
            label: 'Permissions',
            to: '/users/permissions',
          },
        ]
      }, {
        id: 'subscription',
        icon: 'deo-receipt',
        to: '/subscription/history-transaction',
        label: 'Subscription',
        permissions: ['tenant.subscription.view', 'tenant.general_coupon.view'],
        subs: [
          {
            id: 'subscription-history-transaction',
            permissions: ['tenant.subscription.view'],
            label: 'History Transaction',
            to: '/subscription/history-transaction',
          },
          {
            id: 'coupon',
            permissions: ['tenant.general_coupon.view'],
            label: 'Coupon',
            to: '/subscription/coupon',
          },
          {
            id: 'upgrade-renew',
            permissions: ['tenant.subscription.view'],
            label: 'Upgrade & Renew',
            to: '/subscription/billing',
          },
        ]
      },
      // hide billing menu after using new payment service

      // {
      //   id: 'bill',
      //   icon: 'i-Article',
      //   label: 'Billing',
      //   to: '/bill/billing-payment',
      //   permissions: ['finance.billing.view'],
      //   subs: [
      //     {
      //       id: 'bill',
      //       permissions: ['finance.billing.view'],
      //       label: 'Pembayaran',
      //       to: '/bill/billing-payment',
      //     },
      //     {
      //       id: 'billing-shipping-invoice',
      //       permissions: ['finance.billing.view'],
      //       label: 'Ongkos Kirim',
      //       to: '/bill/billing-shipping-invoice',
      //     },
      //     {
      //       id: 'billing-shipping-return',
      //       permissions: ['finance.billing.view'],
      //       label: 'Retur',
      //       to: '/bill/billing-shipping-return',
      //     },
      //     {
      //       id: 'billing-adjustment',
      //       permissions: ['finance.billing.view'],
      //       label: 'Adjustment',
      //       to: '/bill/billing-adjustment',
      //     },
      //   ]
      // }, 
    ];
  }
}

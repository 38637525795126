import React, { useRef } from 'react';
import { environment } from 'apps/admin-web/src/environments/environment';
import { AccessPermissionRestService } from '@clodeo/libs/core/rest/admin/access-permissions/access-permissions-rest.services';
import { capitalize } from 'lodash';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { IColumn, IIncludes } from 'apps/admin-web/src/app/components/base-component/list/base-list';
import { BaseListComponent } from 'apps/admin-web/src/app/components/base-component/list/base-list.component';

const UserPermissionListComponent: React.FC = () => {
  const authenticationService: AuthenticationService = new AuthenticationService;
  const accessPermissionRestService = new AccessPermissionRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
  const columns: IColumn[] = [
    {
      key: '1',
      dataIndex: 'permissionGroup',
      title: 'GROUP',
      width: '100px',
      render: (val) => capitalize(val)
    },
    {
      key: '2',
      dataIndex: 'permissionSubGroup',
      title: 'SUB GROUP',
      width: '100px',
      render: (val) => capitalize(val)
    },
    {
      key: '3',
      dataIndex: 'name',
      title: 'NAMA',
      width: '100px',
      render: (val) => capitalize(val)
    },
    {
      key: '4',
      dataIndex: 'description',
      title: 'DESKRIPSI',
      width: '100px',
      render: (val) => capitalize(val)
    }
  ]

  const baseListRef = useRef<any>();

  const loadDataObservable = (params) => {
    return accessPermissionRestService.findAll({ params })
  }

  const includes: IIncludes = {
    refresh: true,
    keyword: true,
    toogleOptions: true
  }

  return <BaseListComponent
    listId="permission-list"
    ref={baseListRef}
    columns={columns}
    loadDataObservable={loadDataObservable}
    includes={includes}
  />
}
export { UserPermissionListComponent }

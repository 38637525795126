import React, { useEffect, useState } from 'react';

import { UsersService } from '../users.service';

import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { Input } from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';
import { Form } from 'antd';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { AccountAdminRestServices } from '@clodeo/libs/core/rest/account/admin/user/account-admin-user.rest.service';
import { environment } from '../../../../../../environments/environment';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import '../form/users-form.component.scss'

// Extra configs
const userService = new UsersService;

export const UsersResetPasswordComponent = (props: IUserResetPasswordProps) => {
  const { handleRequest } = new HandleService;
  const authenticationService = new AuthenticationService;
  const accountAdminRestServices = new AccountAdminRestServices(`${environment.ENDPOINTS.API_ACCOUNT}`, authenticationService.axiosInterceptors);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { id, onClose, onFinish } = props;

  function onSubmit() {
    setLoading(true)
    const obs = accountAdminRestServices.resetPassword(id, form.getFieldValue('password'));
    handleRequest({
      obs,
      successMessage: 'Berhasil reset password',
      onDone: () => { setLoading(false); onFinish(); }
    })
  }
  useEffect(() => {
    form.setFieldsValue({
      password: userService.randomstring(15)
    })
  }, [])

  return (
    <Spinner spinning={loading}>
      <Form form={form}>
        <div className="container-fluid py-2 form-wrapper">
          <div className="row">
            <div className="col-12 label py-2">
              Password Baru
            </div>
            <div className="col-8 align-self-center">
              <Form.Item
                name="password"
                rules={userService.formRules.password}
              >
                <Input readOnly />
              </Form.Item>
            </div>
            <div className="col-4 align-self-center text-right py-2">
              <Button
                type="outline"
                label="Generate"
                onClick={() => form.setFieldsValue({ password: userService.randomstring(15) })}
              />
            </div>
          </div>
          <div className="w-100 text-center py-2">
            <Button type="ghosted" label="Batal" className="mr-3" onClick={() => onClose()} />
            <Button submit type="primary" label="Simpan" onClick={() => onSubmit()} />
          </div>
        </div>
      </Form>
    </Spinner>
  )
}
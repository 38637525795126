import { DatePicker } from '@clodeo/clodeo-ui/components/data-entry/datepicker/datepicker.component';
import { InputNumber } from '@clodeo/clodeo-ui/components/data-entry/inputnumber/inputnumber.component';
import { Input } from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';
import { Radio, RadioGroup } from '@clodeo/clodeo-ui/components/data-entry/radiobutton/radiobutton.component';
import { Switch } from '@clodeo/clodeo-ui/components/data-entry/switch/switch.component';
import { IEntryRelatedData, ITenantSubscription } from '@clodeo/libs/core/rest/admin/admin/admin';
import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import * as accountingJs from 'accounting-js';
import { Notifications } from "@clodeo/clodeo-ui/components/feedback/notification/notification.component";

import { Dropdown } from '@clodeo/clodeo-ui/components/navigation/dropdown/dropdown.component';
import './billing-form.component.scss';
import { Menu, Item } from '@clodeo/clodeo-ui/components/navigation/menu/menu.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { find } from 'lodash';
import moment from 'moment';

export const BillingFormComponent = (props: {
  relatedData?: IEntryRelatedData;
  tenant?: ITenantSubscription;
  onSubmit?: (val) => void;
  onCancel?: () => void;
}) => {
  const notif: Notifications = new Notifications;
  const { relatedData, tenant, onSubmit, onCancel } = props;
  const [type, setType] = useState('Amount');
  const [billingForm] = Form.useForm();
  const required = [{ required: true, message: 'Kolom ini wajib diisi' }]

  const billingCycles = [
    {
      value: 'monthlyPrice', label: '1 Month'
    },
    {
      value: 'quaterlyPrice', label: '3 Month'
    },
    {
      value: 'semesterlyPrice', label: '6 Month'
    },
    {
      value: 'yearlyPrice', label: '12 Month'
    },
  ];

  const menu = (
    <Menu>
      <Item onClick={() => setType('Amount')}>Rp</Item>
      <Item onClick={() => setType('Percent')}>%</Item>
    </Menu>
  )

  const suffixType = () => (
    <Dropdown overlay={menu} placement="bottomLeft"
      trigger={['click']}>
      <Button className="btn-deo-type px-3" label={type === 'Amount' ? 'Rp  ▼' : '%  ▼'} />
    </Dropdown>
  )

  function checkDisbaledPackage(spId: string) {
    if ((billingForm.getFieldValue('type') == 'renew') && (tenant.subscriptionPlanId == spId)) {
      return false;
    } else if ((billingForm.getFieldValue('type') == 'upgrade') && (tenant.subscriptionPlanId !== spId)) {
      return false;
    } else {
      return true;
    }
  }

  function onChangeType() {
    if (billingForm.getFieldValue('type') == 'renew') {
      billingForm.setFieldsValue({ 'subscriptionPlanId': tenant.subscriptionPlanId })
    } else {
      if (billingForm.getFieldValue('subscriptionPlanId') == tenant.subscriptionPlanId) {
        billingForm.resetFields(['subscriptionPlanId'])
      }
    }
  }

  function syncSubtotal() {
    if (billingForm.getFieldValue('subscriptionPlanId')) {
      const subscriptionPlan = relatedData.subscriptionPlan;
      const selectedPlan = find(subscriptionPlan, function (sp) { return sp.id == billingForm.getFieldValue('subscriptionPlanId') });
      const billingCycle = billingForm.getFieldValue('billingCycle');
      const prices = selectedPlan[billingCycle];
      const qty = billingForm.getFieldValue('qty');
      billingForm.setFieldsValue({
        'subtotal': prices * qty,
        'unitPrice': prices
      })
    } else {
      billingForm.setFieldsValue({
        'subtotal': 0
      })
    }
  }

  function onValuesChange(value) {
    onChangeType();
    syncSubtotal();
    syncTotal();
  }

  function getDiscount(subtotal) {
    const discount = parseInt(billingForm.getFieldValue(`discount${type}`) || 0)
    if (type == 'Percent') {
      return (discount * subtotal) / 100;
    } else {
      return discount;
    }
  }

  function getTaxAmount(subtotal) {
    const tax = parseInt(billingForm.getFieldValue(`taxRate`) || 0)
    return (tax * subtotal) / 100;
  }

  function syncTotal() {
    const subtotal = parseInt(billingForm.getFieldValue('subtotal') || 0);
    const discAmount = getDiscount(subtotal);
    const taxAmount = getTaxAmount(subtotal - discAmount);
    const adjustmentAmount = parseInt(billingForm.getFieldValue('adjustmentAmount') || 0);
    billingForm.setFieldsValue({
      'amountPaid': subtotal - discAmount + taxAmount + adjustmentAmount
    })
  }

  function onSubmitForm(val) {
    if (val.hasOwnProperty('errorFields') && val.errorFields.length) {
      notif.show({
        type: "error",
        title: "Error",
        description: "Pastikan semua data telah diisi",
      });
      return;
    }
    onSubmit({
      ...val,
      ...billingForm.getFieldsValue(true),
      discountType: type
    });
  }

  useEffect(() => {
    billingForm.setFieldsValue({
      'discountAmount': 0,
      'discountPercent': 0
    });
    syncTotal();
  }, [type])


  return <Form
    form={billingForm}
    onValuesChange={onValuesChange}
    onFinish={onSubmitForm}
    onFinishFailed={onSubmitForm}
    initialValues={{
      // type: 'upgrade',
      qty: 1,
      subtotal: 0,
      amountPaid: 0,
      taxRate: 0,
      adjustmentAmount: 0,
      // billingCycle: 'monthlyPrice',
      isActivateProduct: false,
      isActivateUser: false,
      subscriptionStartDate: moment(new Date()),
    }}
  >
    <Form.Item
      shouldUpdate
    >
      {
        () => (
          <div className="row billing-form border-top mt-3 mb-5">
            <div className="col-lg-8 first-section">
              <div className="d-flex flex-row group">
                <div className="label-control align-self-center">Invoice</div>
                <Form.Item name="invoiceNumber">
                  <Input placeholder="-Auto-" className="shadow-none" size="large" />
                </Form.Item>
              </div>
              <div className="d-flex flex-row group">
                <div className="label-control align-self-center field-required">Type</div>
                <Form.Item name="type" className="" rules={required}>
                  <RadioGroup className="d-flex flex-wrap">
                    <Radio varian="box" className="m-2" value="upgrade">Upgrade</Radio>
                    <Radio varian="box" className="m-2" value="renew">Renew</Radio>
                  </RadioGroup>
                </Form.Item>
                {
                  billingForm.getFieldValue('type') == 'upgrade' &&
                  <div className="d-flex flex-row ml-3 align-self-center">
                    <div className="label-control align-self-center">Start Date</div>
                    <Form.Item name="subscriptionStartDate">
                      <DatePicker className="shadow-none" size="large" />
                    </Form.Item>
                  </div>
                }
              </div>
              <div className="d-flex flex-row group">
                <div className="label-control align-self-center field-required">Payment Method</div>
                <Form.Item name="paymentMethod" className="" rules={required}>
                  <RadioGroup className="d-flex flex-wrap">
                    {
                      relatedData && relatedData.paymentMethodStr.map(pm => <Radio key={pm} varian="box" className="m-2" value={pm}>{pm}</Radio>)
                    }
                  </RadioGroup>
                </Form.Item>
              </div>
              <div className="d-flex flex-row group">
                <div className="label-control align-self-center field-required">Package</div>
                <Form.Item name="subscriptionPlanId" className="" rules={required}>
                  <RadioGroup className="d-flex flex-wrap">
                    {
                      relatedData && relatedData.subscriptionPlan.map(sp => <Radio disabled={checkDisbaledPackage(sp.id)} key={sp.id} varian="box" className="m-2" value={sp.id}>{sp.name}</Radio>)
                    }
                  </RadioGroup>
                </Form.Item>
              </div>
              <div className="d-flex flex-row group">
                <div className="label-control align-self-center field-required">Select Billing Cycle</div>
                <Form.Item name="billingCycle" rules={required}>
                  <RadioGroup className="d-flex flex-wrap">
                    {
                      billingCycles.map(bc => <Radio key={bc.value} varian="box" className="m-2" value={bc.value}>{bc.label}</Radio>)
                    }
                  </RadioGroup>
                </Form.Item>
              </div>
              <div className="d-flex flex-row group">
                <div className="d-flex flex-row">
                  <div className="label-control align-self-center">Quantity</div>
                  <Form.Item name="qty">
                    <InputNumber />
                  </Form.Item>
                </div>
                <div className="d-flex flex-row mx-lg-4">
                  <div className="label-control align-self-center">Aktifkan Produk</div>
                  <Form.Item name="isActivateProduct">
                    <Switch />
                  </Form.Item>
                </div>
                <div className="d-flex flex-row">
                  <div className="label-control align-self-center">Aktifkan User</div>
                  <Form.Item name="isActivateUser">
                    <Switch />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="col-lg-4 second-section my-3">
              <div className="card-form">
                <div className="d-flex group py-3 justify-content-between">
                  <div className="label-control">Subtotal</div>
                  <div className="value-control">
                    {accountingJs.formatMoney((billingForm.getFieldValue('subtotal') || 0), { symbol: 'Rp ', format: '%s %v' })}
                  </div>
                </div>
                <div className="group bore py-4">
                  <div className="d-flex justify-content-between">
                    <div className="label-control">Discount</div>
                    <div style={{ width: '237px' }}>
                      <Form.Item name={`discount${type}`}>
                        <Input type="number" suffix={suffixType()} />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="d-flex my-3 justify-content-between">
                    <div className="label-control">Tax</div>
                    <div style={{ width: '237px' }}>
                      <Form.Item name="taxRate">
                        <Input type="number" suffix={<div className="text-center w-40px">%</div>} />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="label-control">Adjusment</div>
                    <div style={{ width: '237px' }}>
                      <Form.Item name="adjustmentAmount">
                        <Input type="number" suffix={<div className="text-center w-40px">Rp</div>} />
                      </Form.Item>
                    </div>
                  </div>
                </div>
                <div className="d-flex group py-3 justify-content-between">
                  <div className="label-control">Total</div>
                  <div className="value-control">
                    {accountingJs.formatMoney((billingForm.getFieldValue('amountPaid') || 0), { symbol: 'Rp ', format: '%s %v' })}
                  </div>
                </div>
                <div className=" m-auto py-3 text-center">
                  <Button type="ghosted" label="Batal" className="mr-3" onClick={onCancel} />
                  {/* <Button type="outline" label="Reset" className="mx-3" onClick={() => billingForm.resetFields()} /> */}
                  <Button submit type="primary" label="Simpan" />
                </div>
              </div>
            </div>
          </div>
        )
      }
    </Form.Item>
  </Form>
}

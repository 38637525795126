import React from 'react';
import { useHistory } from 'react-router-dom';
import { Result } from '../feedback/result/result.component';
import { Button } from '../ui-elements/button/button.component';
import { ErrorPageProps } from './error-page';
const ErrorComponent = (props: ErrorPageProps) => {
  const router = useHistory();

  return <>
    <Result
      {...props}
      status={props.errorType || props.status || '404'}
      title={props.title || props.errorType || props.status || '404'}
      subTitle={props.subTitle || ''}
    />
    <div className="text-center">
      <Button id="btn-back-to-home" type="primary" label="Kembali"
        onClick={() => router.push('/')} />
    </div>
  </>
};

export {
  ErrorComponent
}

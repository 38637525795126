import React, { useState } from 'react';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { IModalValue } from '@clodeo/clodeo-ui/components/feedback/modal/modal';
import { TrackingNumberDetailComponent } from '@clodeo/clodeo-ui/components/base/tracking-number-detail/tracking-number-detail.component';
import { AdminMonitoringPickupRestService } from '@clodeo/libs/core/rest/admin/monitoring-pickup/admin-monitoring-pickup-rest.service';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import moment from 'moment';
import { Popover } from '@clodeo/clodeo-ui/components/data-display/popover/popover.component';
import { Action } from '@clodeo/clodeo-ui/components/general/action/action.component';
import { RequestCancelUpdateComponent } from '../update/request-cancel-update.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { AclService } from 'apps/admin-web/src/app/core/auth/acl.service';


const authenticationService: AuthenticationService = new AuthenticationService;
const adminMonitoringPickupRestService = new AdminMonitoringPickupRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const { handleRequest } = new HandleService;
const aclService: AclService = new AclService;

export const RequestCancelTemplateListComponent = (props: {
  data: IRequestCancel;
  onSuccessApprove: () => void
}) => {
  const { data, onSuccessApprove } = props;
  const [modalValue, setModalValue] = useState<IModalValue>();
  const [loading, setLoading] = useState(false);

  function showAwbBill(waybillResponse) {
    setModalValue({
      visible: true,
      title: 'Rincian Pengiriman',
      contentTemplate: <TrackingNumberDetailComponent waybillResponse={waybillResponse} loading={!waybillResponse} />
    });
  }

  function loadAWBDetail(data: { courierCode: string, waybill: string }) {
    setLoading(true);
    const obs = adminMonitoringPickupRestService.getWaybillDetail(data);
    handleRequest({
      obs,
      hideError: true,
      onError: (err) => {
        setLoading(false);
        showAwbBill(err);
      },
      onDone: (res) => {
        setLoading(false);
        showAwbBill(res);
      }
    })
  }



  return <div className="list-wrapper">
    <div className="d-flex flex-row flex-wrap justify-content-between">

      <div className="d-flex flex-column justify-content-between grouping">
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-90px">Request Cancel</div>
          <div className="value normal">{data.createDateUtc ? moment(data.createDateUtc).format('DD MMM YYYY') : '-'}</div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="label w-90px">Tgl Pickup</div>
          <div className="value normal">{data.pickupDate ? moment(data.pickupDate).format('DD MMM YYYY') : '-'}</div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between grouping">
        <div className="d-flex flex-row justify-content-between">
          <div className="label">No. Order</div>
          <div className="value">{data.orderNumber || '-'}</div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="label">Tgl Order</div>
          <div className="value normal">{data.orderDate ? moment(data.orderDate).format('DD MMM YYYY') : '-'}</div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between grouping">
        <div className="d-flex flex-row justify-content-between">
          <div className="label">Kurir</div>
          <div className="value text-center w-120px">
            <img src={'https://nx-design-system-web-development.clodeo.com/img/logo/courier/code/' + data.courierChannelId + '.svg'} alt="" srcSet="" className="img-fluid" />
            <b><p className='pt-1'>{data.shippingCourierProduct || '-'}</p></b>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="label">Resi</div>
          <div className="value resi text-center clickable w-120px" onClick={() => { loadAWBDetail({ courierCode: data.courierChannelId, waybill: data.airwaybillNumber }) }}>
            <Spinner spinning={loading}>
              {data.airwaybillNumber || '-'}
            </Spinner>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between grouping">
        <div className="d-flex flex-row justify-content-between">
          <div className="label">Asal</div>
          <div className="value normal">
            <Popover placement="top" title={false} trigger="hover" content={<p className="m-0">{data.origin || '-'}</p>}>
              {data.origin}
            </Popover>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="label">Tujuan</div>
          <div className="value normal">
            <Popover placement="top" title={false} trigger="hover" content={<p className="m-0">{data.destination || '-'}</p>}>
              {data.destination}
            </Popover>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column justify-content-between grouping">
        <div className="d-flex flex-row justify-content-between">
          <div className="label">Toko</div>
          <div className="value">
            {/* {data.shopName} */}
            <Popover
              placement="top"
              title={false}
              trigger="hover"
              content={
                <>
                  <p>{data.shopName}</p>
                  <p>{data.email}</p>
                  <p>{data.telephoneNumber || '-'}</p>
                </>
              }
            >
              {data.shopName}
            </Popover>
          </div>
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div className="label pt-3">Catatan</div>
          <div className="value normal">
            <Popover
              placement="top"
              title={false}
              trigger="hover"
              content={
                <>
                  <p className="note-content m-0"><b>Admin</b> : {data.note?.adminNote || 'Tidak Ada Catatan'}</p>
                  <p className="note-content m-0"><b>Tenant</b> : {data.note?.reason || 'Tidak Ada Catatan'}</p>
                </>
              }
            >
              <a className="btn btn-deo-ghosted btn-deo-md p-2">
                <i className="icon-i-ChatCenteredText icon-14px"></i>
              </a>
            </Popover>
          </div>
        </div>
      </div>
      {
        (
          (aclService.can(['tenant.cancel_pickup.edit', 'tenant.cancel_pickup.create']) && data.status == 0) ? (
            <div className="d-flex flex-column justify-content-between grouping">
              <div className="d-flex flex-column justify-content-start" style={{ width: '160px' }}>
                <div className="label text-center">Status</div>
                <div className="p-2 text-center w-100">
                  <Action type="accept" className="mr-2" onClick={() => setModalValue({
                    visible: true,
                    onCancel: () => setModalValue(null),
                    contentTemplate: <RequestCancelUpdateComponent onCancel={() => setModalValue(null)} onFinish={() => {
                      setModalValue(null);
                      onSuccessApprove()
                    }} data={{
                      ...data,
                      isApproved: true
                    }} />
                  })} />
                  <Action type="reject" onClick={() => setModalValue({
                    visible: true,
                    onCancel: () => setModalValue(null),
                    contentTemplate: <RequestCancelUpdateComponent onCancel={() => setModalValue(null)} onFinish={() => {
                      setModalValue(null);
                      onSuccessApprove()
                    }} data={{
                      ...data,
                      isApproved: false
                    }} />
                  })} />
                </div>
              </div>
            </div>
          ) : <>
            <div className="d-flex flex-column justify-content-between grouping">
              <div className="d-flex flex-row justify-content-between">
                <div className="label">Status</div>
                <div className="value" style={data.status === 0 ? { color: '#9e9e9e' } : data.status === 1 ? { color: '#147EE3' } : { color: '#E1721D' }}>
                  {data.status === 0 ? 'Draf' : data.status == 1 ? 'Diterima' : 'Ditolak'}
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between">
                <div className="label">User</div>
                <div className="value">
                  {data.userAdminName}
                </div>
              </div>
            </div>
          </>

        )
      }

    </div>
    {modalValue && (
      <Modal
        {...modalValue}
        footer={null}
        width="fit-content"
        className="admin"
        onCancel={() => setModalValue(null)}
      >
        {modalValue.contentTemplate}
      </Modal>
    )}
  </div>
}

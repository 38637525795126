import { InputTextArea } from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { Form } from 'antd';
import React, { useEffect } from 'react';

export const RequestCancelFormComponent = (props: {
  data: IPayloadRequestCancel;
  onCancel: () => void;
  onSubmit: (val: IPayloadRequestCancel) => void;
}) => {
  const { data } = props;
  const [form] = Form.useForm()
  function onOk(val?) {
    const value = form.getFieldsValue(true);
    props.onSubmit && props.onSubmit(value)
  }
  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  return <Form form={form} onFinish={onOk}>
    <div className="container pt-3" style={{ maxWidth: 522 }}>
      <div className="row mb-3 chilbro">
        <div className="col-lg-12 title">
          {`Apakah Anda yakin untuk ${data.isApproved ? 'menerima' : 'menolak'} pembatalan resi berikut ?`}
        </div>
      </div>
      <div className="row chilbro mb-2">
        <div className="label col-lg-4">Kurir</div>
        <div className="col-lg-6">
          <div className="imooge">
            <img src={'https://nx-design-system-web-development.clodeo.com/img/logo/courier/code/' + data.courierChannelId + '.svg'} alt="" srcSet="" className="img-fluid" />
          </div>
        </div>
      </div>
      <div className="row chilbro mb-2">
        <div className="label col-lg-4">Resi</div>
        <div className="value col-lg-8">
          {data.airwaybillNumber}
        </div>
      </div>
      <div className="row chilbro mb-2">
        <div className="label col-lg-4">Alasan</div>
        <div className="value col-lg-8">
          {data.note.reason || '-'}
        </div>
      </div>
      <div className="row mb-2">
        <Form.Item className="col-lg-12 p-2" name='reason' rules={[
          {
            required: !data.isApproved ? true : false,
            message: 'Kolom ini wajib diisi'
          }
        ]}>
          <InputTextArea rows={5} placeholder={`${data.isApproved ? 'Catatan (Opsional)' : 'Harap isi alasan ditolak'}`} />
        </Form.Item>
      </div>
      <div className="row p-2">
        <div className="col-lg-12 text-center">
          <Button type="ghosted" label="Batal" onClick={props.onCancel && props.onCancel} className="mr-2" />
          <Button type="primary" label="Ok" submit />
        </div>
      </div>
    </div>
  </Form>

}
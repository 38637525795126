import { RequestService } from '../../request.service';

export class AdminTenantBankRestService {
  requestService = new RequestService();
  request: RequestService;
  /**
   *
   * @param baseUrl only from environment.ENDPOINTS
   * @param useInterceptor
   * @param withoutInterceptor
   */
  constructor(
    baseUrl,
    useInterceptor: any = true,
    withoutInterceptor: boolean = false
  ) {
    this.request = this.requestService.new(
      `${baseUrl['API_ADMIN']}/tenant-bank`,
      useInterceptor,
      withoutInterceptor
    );
  }

  /**
   * Get list of tenant banks Transaction Line
   * @param qParams
   */
  findAll(params) {
    return this.request.get('', { params });
  }

  /**
   * Get list of tenant banks Transaction Line with more detail search params
   * @param qParams
   */
  findAllQ(options, params) {
    return this.request.post('q', options, { params });
  }

  /**
   * Get Bank by Tenant Id
   * @param tenantId
   */
  findByTenantId(tenantId, params?) {
    return this.request.get(`${tenantId}`, { params });
  }

  /**
   * Add new tenant bank
   * @param payload
   */
  addTenantbank(payload: IPayloadTenantBank, params) {
    return this.request.post('', payload, { params });
  }

  /**
   * Update tenant bank
   * @param tenantBankId
   * @param payload
   */
  updateTenantBank(tenantBankId: string, payload: IPayloadTenantBank) {
    return this.request.put(`${tenantBankId}`, payload, {
      params: { tenantId: payload.tenantId },
    });
  }

  /**
   * Get related data for tenant bank create or update
   * @param qParams
   */
  loadRelated(qParams) {
    return this.request.get('entry-related-data', qParams);
  }

  findById(tenantBankId, params) {
    return this.request.get(tenantBankId, { params });
  }

  /**
   * Delete tenant bank
   * @param tenantId
   */
  deleteTenantBank(TenantBankId, payload, tenantId) {
    return this.request.delete(`${TenantBankId}?tenantId=${tenantId}`, payload);
  }

  /**
   * Get history of admin
   * @param tenantId 
   */
  getAdminHistory(tenantId: string) {
    return this.request.get(`${tenantId}/histories`);
  }

  /** 
    * Activate tenant bank status
    * @param active 
    * @param tenantBankId
    * @param tebabtId
  */
  markIsActive(active: boolean, tenantBankId: string, tenantId: number) {
    return this.request.put(`${tenantBankId}/mark-active/${active}?tenantId=${tenantId}`);
  }
}

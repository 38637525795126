import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { throwError } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { useHistory } from "react-router-dom";

import { CouponRestService } from '@clodeo/libs/core/rest/addon/general/coupons/coupon-rest.service';
import { AccountingService } from '@clodeo/libs/core/math/accounting.service';


import { IColumn, IIncludes } from 'apps/admin-web/src/app/components/base-component/list/base-list';
import { BaseListComponent } from 'apps/admin-web/src/app/components/base-component/list/base-list.component';

import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { Notifications } from "@clodeo/clodeo-ui/components/feedback/notification/notification.component";
import { Switch } from '@clodeo/clodeo-ui/components/data-entry/switch/switch.component';

import { Confirms } from '@clodeo/clodeo-ui/components/feedback/confirm/confirm.component';
import { AclService } from 'apps/admin-web/src/app/core/auth/acl.service';
const aclService = new AclService;

import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { SelectButton, SelectGroup } from '@clodeo/clodeo-ui/components/data-entry/select-button/select-button.component';

export const CouponListComponent = () => {
  const router = useHistory();
  const baseListRef = useRef<any>();
  const notif: Notifications = new Notifications;
  const confirm: Confirms = new Confirms;

  const authenticationService: AuthenticationService = new AuthenticationService;
  const couponRestService = new CouponRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
  const [statusActive, setStatusActive] = useState<boolean>(true);

  const includes: IIncludes = {
    refresh: true,
    refreshAction: () => {
      baseListRef.current.resetFilters();

      setTimeout(() => {
        baseListRef.current.callLoadData();
      }, 10);
    },
    keyword: true,
    toogleOptions: true,
    extraButtons: _.compact([
      aclService.can(['tenant.general_coupon.create']) &&
      {
        label: 'COUPON',
        iconName: 'deo-plus',
        iconPosition: 'left',
        type: 'filter',
        action: () => router.push(`/subscription/coupon/create`)
      }
    ]),
    onlyExportAllData: true,
    fileNameExportAllData: 'Coupon',
  };

  function callData() {
    setTimeout(() => {
      baseListRef.current.callLoadData()
    }, 10);
  }

  const statusFilter = () => (
    <SelectGroup type="switch" onChange={(e) => { setStatusActive(e.target.value); callData() }} defaultValue={statusActive}>
      <SelectButton type="switch" value={true}>{'Aktif'}</SelectButton>
      <SelectButton type="switch" value={false}>{'Tidak Aktif'}</SelectButton>
    </SelectGroup>
  );

  const columns: IColumn[] = _.compact([
    { key: '1', dataIndex: 'id', title: 'ID', width: '130px', ignoreDisply: true },
    { key: '2', dataIndex: 'name', title: 'TITLE', width: '130px' },
    { key: '3', dataIndex: 'code', title: 'COUPON CODE', width: '130px' },
    {
      key: '4',
      dataIndex: 'accountValidType',
      title: 'USER',
      width: '80px',
      render: (value) => {
        switch (value) {
          case 'none':
            return 'All User';
          case 'existing':
            return 'Existing';
          case 'new':
            return 'New';
          default:
            return;
        }
      },
    },
    {
      key: '5',
      dataIndex: 'listSubscriptionPlan',
      title: 'PLAN',
      width: '150px',
      render: (value) => {
        return startCase(value, true);
      },
    },
    {
      key: '6',
      dataIndex: 'listSubscriptionCycle',
      title: 'PERIOD',
      width: '130px',
      render: (value) => {
        return startCase(value, true);
      },
    },
    {
      key: '7',
      dataIndex: 'couponCategory',
      title: 'COUPON CATEGORY',
      ignoreDisply: true,
      width: '120px',
      render: (value) => {
        return startCase(value);
      },
    },
    {
      key: '8',
      dataIndex: 'couponCategoryType',
      title: 'TYPE',
      width: '120px',
      render: (value) => {
        return startCase(value);
      },
    },
    {
      key: '9',
      dataIndex: 'validStartDate',
      title: 'START TIME',
      width: '100px',
      formatExprt: (value) => {
        if (!value) {
          return '-';
        }
        moment.locale('id');
        const utcDate = moment.utc(value);
        const result = utcDate.tz(moment.tz.guess(true)).format('dddd, DD MMMM YYYY HH:mm:ss');
        return result;
      },
      render: (value) => {
        return moment(value).format('DD/MM/YYYY');
      },
    },
    {
      key: '10',
      dataIndex: 'validEndDate',
      title: 'END TIME',
      width: '100px',
      formatExprt: (value) => {
        if (!value) {
          return '-';
        }
        moment.locale('id');
        const utcDate = moment.utc(value);
        const result = utcDate.tz(moment.tz.guess(true)).format('dddd, DD MMMM YYYY HH:mm:ss');
        return result;
      },
      render: (value) => {
        return moment(value).format('DD/MM/YYYY');
      },
    },
    {
      key: '11',
      dataIndex: 'quota',
      title: 'LIMIT',
      width: '80px',
      render: (value, record) => {
        return `${record.quotaUsage} / ${record.quota}`;
      },
    },
    {
      key: '12',
      dataIndex: 'quotaUsage',
      title: 'LIMIT DIGUNAKAN',
      ignoreDisply: true
    },
    {
      key: '13',
      dataIndex: 'discountType',
      title: 'TIPE DISKON',
      width: '150px',
      render: (value, record) => {
        switch (value) {
          case 'percentage':
            return (
              <>
                <span className="grey-color">(%)</span>
                <span className="ml-1">{record.discountValue}%</span>
              </>
            );
          case 'amount':
            return (
              <>
                <span className="grey-color">(Rp.)</span>
                <span className="ml-1">
                  {AccountingService.ac.formatMoney(record.discountValue || 0, {
                    symbol: 'Rp ',
                    precision: 2,
                    format: '%s %v',
                    decimal: ',',
                    thousand: '.',
                  })}
                </span>
              </>
            );
          case 'additional_month':
            return (
              <>
                <span className="grey-color">(Period)</span>
                <span className="ml-1">{record.discountValue} Month</span>
              </>
            );
          default:
            return;
        }
      },
    },
    {
      key: '14',
      dataIndex: 'discountValue',
      title: 'NILAI DISKON',
      ignoreDisply: true
    },
    {
      key: '15',
      dataIndex: 'minSpend',
      title: 'MIN SPEND',
      ignoreDisply: true
    },
    {
      key: '16',
      dataIndex: 'maxDiscount',
      title: 'MAX DISCOUNT',
      ignoreDisply: true
    },
    {
      key: '17',
      dataIndex: 'couponValidType',
      title: 'COUPON VALID TYPE',
      ignoreDisply: true
    },
    {
      key: '18',
      dataIndex: 'couponToSubscriptions',
      title: 'COUPON TO SUBSCRIPTIONS',
      formatExprt: (value) => {
        if (!value) {
          return '-';
        }
        return value;
      },
      ignoreDisply: true
    },
    {
      key: '19',
      dataIndex: 'inactive',
      title: 'STATUS',
      width: '100px',
      formatExprt: (value) => {
        return value ? 'Non Aktif' : 'Aktif';
      },
      align: 'center',
      render: (value, record, i) => {
        return (
          <Switch
            checked={!value}
            disabled={!aclService.can(['tenant.general_coupon.edit'])}
            onChange={(statusValue) => {
              confirm.show({
                title: 'Konfirmasi Data',
                content: `${statusValue ? 
                  'Kupon akan diaktifkan dan dipindahkan ke list Aktif. Apakah anda yakin untuk melanjutkan?' : 
                  'Kupon akan dinon-aktifkan dan dipindahkan ke list Tidak Aktif. Apakah anda yakin untuk melanjutkan?'}`,
                onOk() {
                  setCouponStatus(record, statusValue)
                    .pipe(
                      tap(() => baseListRef.current.callLoadData()))
                    .subscribe();
                },
              });
            }}
            className="inputswitch-deo"
          />
        );
      },
    },
    aclService.can(['tenant.general_coupon.edit']) &&
    {
      key: '20',
      dataIndex: 'id',
      title: 'ACTION',
      ignoreExprt: true,
      width: '90px',
      align: 'center',
      render: (value, record, i) => {
        return (
          <Button
            type="warning"
            className="p-2"
            iconName="i-PencilLine"
            onClick={() => {
              router.push(`/subscription/coupon/${value}/update`);
            }}
          />
        );
      },
    },
  ]);

  function setCouponStatus(record, inactive) {
    baseListRef.current.showLoading();
    return couponRestService.toggleStatus(record.id, inactive)
      .pipe(
        catchError(error => {
          notif.show({
            type: "error",
            title: "Error",
            description: "Gagal melakukan hapus coupon."
          });
          return throwError(error);
        }),
        finalize(() => baseListRef.current.callLoadData())
      )
  }

  function startCase(data, isConcatable = false) {
    if (isConcatable) {
      const split = data.split(',');
      const result = [];
      for (var record of split) {
        record = _.startCase(record);
        result.push(record);
      }
      return _.join(result, ', ');
    }
    return _.startCase(data);
  }

  function loadDataObservable(params) {
    const filterValue = { active: statusActive };
    const qParams = { ...params, ...filterValue };
    return couponRestService.getAll({ params: qParams })
  }


  return (
    <BaseListComponent
      listId="coupon"
      ref={baseListRef}
      columns={columns}
      loadDataObservable={loadDataObservable}
      includes={{
        ...includes,
        extraComponent: statusFilter(),
      }}
    />);
}

import React, { useState } from 'react';
import { ITenantWalletUploadProps } from './tenant-wallet-upload';
import { get } from 'lodash';

import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { AdminWalletTransactionRestService } from '@clodeo/libs/core/rest/admin/wallet-transaction/admin-wallet-transaction-rest.service';

import { UploadTemplateList } from '@clodeo/clodeo-ui/components/data-entry/upload/upload.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';

const { handleRequest } = new HandleService;
const authenticationService: AuthenticationService = new AuthenticationService;
const adminWalletTransactionRestService = new AdminWalletTransactionRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);

export const TenantWalletUploadComponent = (props: ITenantWalletUploadProps) => {
  const [detailTransaction, setDetailTransaction] = useState(props.detailTransaction);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function onChangeUpload(item) {
    const newDetailTransaction = { ...detailTransaction }
    let { fileList } = item;
    fileList = fileList.map((file) => {
      if (file.status == 'done') {
        return file.response;
      }
    });

    newDetailTransaction.attachments = fileList;
    setDetailTransaction(newDetailTransaction)
  }

  function saveAttachments() {
    setIsLoading(true);
    const obs = adminWalletTransactionRestService.updateWalletTransaction(detailTransaction.id, detailTransaction);
    handleRequest({
      obs,
      successMessage: 'Berhasil mengubah attachments',
      onError: () => setIsLoading(false),
      onDone: () => {
        props.onSuccess();
        setIsLoading(false);
      }
    })
  }

  function defaultFileList() {
    const fileList = get(props.detailTransaction, 'attachments').map(item => ({
      uid: item.id,
      name: item.fileName,
      status: 'done',
      response: item,
      url: item.fileUrl
    }));

    return fileList;
  }

  return (
    <Spinner spinning={isLoading}>
      <div className="container-fluid" style={{
        width: '35vw'
      }}>
        <div className="row mb-3">
          <div className="col">
            <UploadTemplateList
              buttonProps={{
                className: 'pl-2 pr-3'
              }}
              onChange={onChangeUpload}
              baseUrl={`${environment.ENDPOINTS.API_FILE}/upload/admin`}
              headers={{ 'Authorization': `Bearer ${authenticationService.user.access_token}` }}
              defaultFileList={defaultFileList()}
            />
          </div>
        </div>
        <div className="row">
          <div className="col d-flex justify-content-end">
            <Button type="primary" label="Save" onClick={saveAttachments} className="mr-2" />
            <Button type="tertiary" label="Cancel" onClick={() => props.onCancel()} />
          </div>
        </div>
      </div>
    </Spinner>
  )
}
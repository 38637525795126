import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { LoadingComponent } from '@clodeo/clodeo-ui/components/general/loading/loading.component';
import { MessageParserService } from '@clodeo/libs/core/common/message-parser.service';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { CouponRestService } from '@clodeo/libs/core/rest/addon/general/coupons/coupon-rest.service';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import '../coupon.component.scss';
import { CouponService } from '../coupon.service';
import { CouponFormComponent } from '../form/coupon-form.component';

export const CouponCreateComponent = () => {
  const router = useHistory();
  const notif: Notifications = new Notifications;
  const couponService: CouponService = new CouponService();
  const authenticationService: AuthenticationService = new AuthenticationService;
  const couponRestService = new CouponRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
  const messageParserService = new MessageParserService;
  const { handleRequest } = new HandleService;
  const [isLoading, setIsLoading] = useState<boolean>(false);



  const validate = (form) => {
    if (get(form, 'discountValue', 0) == 0) {
      notif.show({
        type: 'error',
        title: 'Error',
        description: 'Nominal diskon tidak boleh 0.'
      });
      return false;
    }

    if (
      get(form, 'subscriptionCycles.length', 0) === 0 ||
      get(form, 'subscriptionPlans.length', 0) === 0 ||
      get(form, 'accountValidType.length', 0) === 0 ||
      !get(form, 'validStartDate') || !get(form, 'validEndDate') ||
      !get(form, 'name') || !get(form, 'code') ||
      !get(form, 'discountType')
    ) {
      notif.show({
        type: 'error',
        title: 'Error',
        description: 'Pastikan Semua Data Telah Terisi.',
      });
      return false;
    }

    return true;
  }

  const onSubmit = (form) => {
    setIsLoading(true);
    const payload = couponService.normalizedSetterPayload(form);
    const obs = couponRestService.create(payload);
    if (!validate(payload)) {
      setIsLoading(false);
      return;
    }
    handleRequest({
      obs,
      successMessage: 'Berhasil melakukan tambah coupon.',
      onError: () => setIsLoading(false),
      onDone: () => {
        setIsLoading(false);
        router.push('/subscription/coupon');
      }
    })
  }

  return (
    <Spinner spinning={isLoading} indicator={<LoadingComponent />}>
      <CouponFormComponent formType='create' parentCallback={onSubmit} />
    </Spinner>
  )
}

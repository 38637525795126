import React from "react";
import { Select as BaseSelect } from "antd";
import { SelectProps } from "./select";

const { Option, OptGroup } = BaseSelect;
const Select = (props: SelectProps) => (
  <BaseSelect
    {...props}
    className={`select-deo ${props.variant || 'basic'} ${props.className || ''}`}
    dropdownClassName={`select-dropdown-deo ${props.dropdownClassName}`}
    clearIcon={<ClearIcon />}
    suffixIcon={<Arrow />}
  >
  </BaseSelect>
);

const ClearIcon = () => (
  <div className="icon-deo-close">
  </div>
)

const Arrow = () => (
  <i className="icon-deo-arrow-right-1">
  </i>
)

export {
  Option,
  OptGroup,
  Select
}
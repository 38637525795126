import { ExportService } from '@clodeo/libs/core/export/export.service';
import { AdminCODListRestService } from '@clodeo/libs/core/rest/admin/cod-list/admin-cod-list-rest.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import React, { useState, useRef, useEffect } from 'react';
import {
  IColumn,
  IIncludes
} from '../../../../../components/base-component/list/base-list';
import { pick } from 'lodash';
import moment from 'moment';

import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { CodReceiptNumberTemplateListComponent } from './cod-receipt-number-template-list.component';
import './cod-receipt-number-list.component.scss';
import { CodReceiptNumberMoreFilterComponent } from './more-filter/cod-receipt-number-more-filter.component';
import { BaseListComponent } from 'apps/admin-web/src/app/components/base-component/list/base-list.component';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { FormatService } from '@clodeo/libs/core/format/format.service';
import { AdminPickerRestService } from '@clodeo/libs/core/rest/admin/picker/admin-picker-rest.service';

export const CodReceiptNumberListComponent = () => {
  const filters = ['keyword', 'options.take', 'options.skip', 'options.includeTotalCount', 'courierName', 'tenantId', 'lowDate', 'highDate'];
  const { handleRequest } = new HandleService;
  const formatService = new FormatService();
  const authenticationService: AuthenticationService = new AuthenticationService();
  const adminCODListRestService = new AdminCODListRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
  const adminPickerRestService = new AdminPickerRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);

  const exportService: ExportService = new ExportService();
  const [listCourier, setListCourier] = useState<any>();
  const baseListRef = useRef<any>();

  const includes: IIncludes = {
    refresh: true,
    keyword: true,
    exports: [
      {
        label: 'Export berdasarkan filter',
        action: () => {
          exportService.expToXLS({ data: baseListRef.current.selectedRows.records, wscols: '' }, 'Resi COD | By Filter', columns, true);
        },
        fileName: 'Resi COD | By Filter'

      },
      {
        label: 'Export semua data',
        fileName: 'Resi COD'
      }
    ],
    moreFilter: {
      template: <CodReceiptNumberMoreFilterComponent listCourier={listCourier} />
    }
  };

  const columns: IColumn[] = [
    {
      key: '1',
      title: '',
      ignoreExprt: false,
      className: 'pl-0',
      render: (val, recod) => <CodReceiptNumberTemplateListComponent data={recod} />
    },
    {
      key: '2',
      dataIndex: 'id',
      title: 'ID',
      ignoreDisply: true
    },
    {
      key: '3',
      dataIndex: 'lastUpdateDate',
      title: 'Last Update Date',
      ignoreDisply: true,
      formatExprt: (value) => {
        if (!value) {
          return '-';
        }
        return moment(value).format('DD-MM-YY HH:mm')
      },
    },
    {
      key: '3',
      dataIndex: 'sellerName',
      title: 'Seller Name',
      ignoreDisply: true
    },
    {
      key: '4',
      dataIndex: 'deliveryNotes',
      title: 'Delivery Notes',
      ignoreDisply: true
    },
    {
      key: '5',
      dataIndex: 'airwaybillNumber',
      title: 'Airwaybill',
      ignoreDisply: true
    },
    {
      key: '6',
      dataIndex: 'notes',
      title: 'Notes',
      ignoreDisply: true
    },
    {
      key: '7',
      dataIndex: 'destination',
      title: 'Destination',
      ignoreDisply: true
    },
    {
      key: '8',
      dataIndex: 'amount',
      title: 'Amount',
      ignoreDisply: true,
      formatExprt: (value) => formatService.currency(value)
    },
    {
      key: '9',
      dataIndex: 'codValue',
      title: 'COD Value',
      ignoreDisply: true,
      formatExprt: (value) => formatService.currency(value)
    },
    {
      key: '10',
      dataIndex: 'goodValue',
      title: 'Good Value',
      ignoreDisply: true,
      formatExprt: (value) => formatService.currency(value)
    },
    {
      key: '11',
      dataIndex: 'shippingCharge',
      title: 'Shipping Charge',
      ignoreDisply: true,
      formatExprt: (value) => formatService.currency(value)
    },
    {
      key: '12',
      dataIndex: 'shippingChargeExpedition', 
      title: 'Shipping Charge Expedition',
      ignoreDisply: true,
      formatExprt: (value) => formatService.currency(value)
    },
    {
      key: '13',
      dataIndex: 'insuranceAmount',
      title: 'Insurance Amount',
      ignoreDisply: true,
      formatExprt: (value) => formatService.currency(value)
    },
    {
      key: '14',
      dataIndex: 'discountShippingChargePercentage',
      title: 'Discount Shipping Charge Percentage',
      ignoreDisply: true
    },
    {
      key: '15',
      dataIndex: 'discountShippingChargeAmount',
      title: 'Discount Shipping Charge Amount',
      ignoreDisply: true,
      formatExprt: (value) => formatService.currency(value)
    },
    {
      key: '16',
      dataIndex: 'totalShippingCharge',
      title: 'Total Shipping Charge',
      ignoreDisply: true,
      formatExprt: (value) => formatService.currency(value)
    },
    {
      key: '17',
      dataIndex: 'discount',
      title: 'Discount',
      ignoreDisply: true,
      formatExprt: (value) => formatService.currency(value)
    },
    {
      key: '18',
      dataIndex: 'tax',
      title: 'Tax',
      ignoreDisply: true,
      formatExprt: (value) => formatService.currency(value)
    },
    {
      key: '19',
      dataIndex: 'adjustment',
      title: 'Adjustment',
      ignoreDisply: true,
      formatExprt: (value) => formatService.currency(value)
    },
    {
      key: '20',
      dataIndex: 'total',
      title: 'Total',
      ignoreDisply: true,
      formatExprt: (value) => formatService.currency(value)
    },
    {
      key: '21',
      dataIndex: 'subTotalAmount',
      title: 'Sub Total Amount',
      ignoreDisply: true,
      formatExprt: (value) => formatService.currency(value)
    },
    {
      key: '22',
      dataIndex: 'totalAmount',
      title: 'Total Amount',
      ignoreDisply: true,
      formatExprt: (value) => formatService.currency(value)
    },
    {
      key: '23',
      dataIndex: 'codFeePercentage',
      title: 'COD Fee Percentage',
      ignoreDisply: true
    },
    {
      key: '24',
      dataIndex: 'codFeeValue',
      title: 'COD Fee Value',
      ignoreDisply: true,
      formatExprt: (value) => formatService.currency(value)
    },
    {
      key: '25',
      dataIndex: 'receiveAmount',
      title: 'Receive Amount',
      ignoreDisply: true,
      formatExprt: (value) => formatService.currency(value)
    },
    {
      key: '26',
      dataIndex: 'arAmountPaid',
      title: 'AR Amount Paid',
      ignoreDisply: true,
      formatExprt: (value) => formatService.currency(value)
    },
    {
      key: '27',
      dataIndex: 'arBalanceDue',
      title: 'AR Balance Due',
      ignoreDisply: true,
      formatExprt: (value) => formatService.currency(value)
    },
    {
      key: '28',
      dataIndex: 'tenantId',
      title: 'Tenant ID',
      ignoreDisply: true
    },
    {
      key: '29',
      dataIndex: 'courierChannelId',
      title: 'Courier',
      ignoreDisply: true
    },
    {
      key: '30',
      dataIndex: 'documentNumber',
      title: 'Document Number',
      ignoreDisply: true
    },
    {
      key: '31',
      dataIndex: 'uploadDate',
      title: 'Upload Date',
      ignoreDisply: true,
      formatExprt: (value) => {
        if (!value) {
          return '-';
        }
        return moment(value).format('DD-MM-YY HH:mm')
      }
    },
    {
      key: '2',
      dataIndex: 'createDate',
      title: 'Create Date',
      ignoreDisply: true,
      formatExprt: (value) => {
        if (!value) {
          return '-';
        }
        return moment(value).format('DD-MM-YY HH:mm')
      }
    },
    {
      key: '2',
      dataIndex: 'codCreditTransaction',
      title: 'COD Credit Transaction',
      ignoreDisply: true
    },
    {
      key: '2',
      dataIndex: 'salesTransaction',
      title: 'Sales Transaction',
      ignoreDisply: true
    }
  ];

  function loadDataObservable(qParams) {
    let params = pick(qParams, filters);
    if (!(params.lowDate && params.highDate)) {
      params.lowDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
      params.highDate = moment(new Date).format('YYYY-MM-DD');
    }
    const obs = adminCODListRestService.findAll({ params });

    return obs;
  };

  function loadPickerCourier() {
    const obs = adminPickerRestService.getCourierPicker();
    handleRequest({
      obs,
      onDone: setListCourier,
    });
  }

  useEffect(() => {
    loadPickerCourier();
  }, [])

  return (
    <BaseListComponent
      listId="cod-receipt-number" // ID Wajib diisi
      ref={baseListRef}
      columns={columns}
      loadDataObservable={loadDataObservable}
      includes={includes}
      rowKey="id"
      rowSelection={true}
    />
  );
};

import React, { useEffect, useRef, useState } from 'react';
import "./verification-request-list.component.scss"

import { capitalize, pick } from 'lodash';
import { environment } from 'apps/admin-web/src/environments/environment';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { AdminRestService } from '@clodeo/libs/core/rest/admin/admin/admin-rest.service';
import { IColumn, IIncludes } from 'apps/admin-web/src/app/components/base-component/list/base-list';
import moment from 'moment';
import { BaseListComponent } from 'apps/admin-web/src/app/components/base-component/list/base-list.component';
import { ShippingIntegrationRestService } from '@clodeo/libs/core/rest/admin/shipping-integration/shipping-Integration-rest.service';
import { VerificationRequestMoreFilterComponent } from '../more-filter/verification-request-more-filter.component';
import { Tag } from '@clodeo/clodeo-ui/components/data-display/tag/tag.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { IModalValue } from '@clodeo/clodeo-ui/components/feedback/modal/modal';
import { VerifyComponent } from '../verify/verify.component';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { DetailAndApproval } from '../detail-approval/detail-and-approval-request.component.';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { AclService } from 'apps/admin-web/src/app/core/auth/acl.service';
import { LogdetailComponent } from 'apps/admin-web/src/app/components/user-log-detail/log-detail.component';


export const VerificationRequestListComponent = () => {
  const authenticationService: AuthenticationService = new AuthenticationService();
  const shippingIntegrationRestService = new ShippingIntegrationRestService(`${environment.ENDPOINTS.API_ADMIN}`, authenticationService.axiosInterceptors);
  const adminRestService = new AdminRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
  const aclService = new AclService;
  const filters = ['keyword', 'options.take', 'options.skip', 'options.includeTotalCount', 'lowDate', 'highDate', 'statuses'];

  const { handleRequest } = new HandleService;
  const baseListRef = useRef<any>();
  const [relatedData, setRelatedData] = useState(null);
  const [modalValue, setModalValue] = useState<IModalValue>();
  const [loading, setLoading] = useState(false);

  const includes: IIncludes = {
    refresh: true,
    toogleOptions: true,
    keyword: true
  }

  const columns: IColumn[] = [
    {
      key: '1',
      dataIndex: 'registrationDate',
      title: 'Register Date',
      width: '90px',
      render: (val) => <p>{moment(val).format("DD MMM YYYY")}</p>
    },
    {
      key: '2',
      dataIndex: 'companyName',
      title: 'Nama Perusahaan',
      width: '150px'
    },
    {
      key: '3',
      dataIndex: 'idCardName',
      title: 'Nama Owner',
      width: '150px'
    },
    {
      key: '4',
      dataIndex: 'phoneNumber',
      title: 'No Telp',
      width: '100px'
    },
    {
      key: '5',
      dataIndex: 'email',
      title: 'Email',
      width: '150px'
    },
    {
      key: '6',
      dataIndex: 'trainingDate',
      title: 'Jadwal Training',
      width: '90px',
      render: (val) => <p>{val ? moment(val).format("DD MMM YYYY") : '-'}</p>
    },
    {
      key: '7',
      dataIndex: 'statusApproval',
      title: 'Status',
      width: '150px',
      align: 'center',
      render: (statusApproval) => <Tag colorType={statusApproval}>{capitalize(statusApproval)}</Tag>
    },
    {
      key: '8',
      dataIndex: 'userAdminName',
      title: 'User',
      width: '120px',
      ignoreExprt: true,
      render: (val: any, record) => (
        <a
          type="ghosted"
          className="p-2"
          onClick={() => prepareUserLogDetailModal(record.tenantId)}
        > {val} </a>
      ),
    },
    {
      key: '10',
      dataIndex: 'statusApproval',
      title: 'Aksi',
      width: '150px',
      align: 'center',
      render: (val: any, record: any) => modalSetup(val, record)
    }
  ];

  const loadDataObservable = (qParams) => {
    let params = pick(qParams, filters);
    return shippingIntegrationRestService.getListVerificationRequest({ params })
  };

  function loadRelatedData() {
    const params = { clientAppId: 'clodeo-admin-web' };
    const obs = adminRestService.getRelatedData({ params });
    handleRequest({
      obs,
      onDone: (res) => {
        setRelatedData(res)
      }
    });
  }

  function modalSetup(value: string, record: any) {
    return <>
      {value === 'pending' ?
        aclService.can(['tenant.shipping_integration.edit', 'tenant.shipping_integration.create']) && <Button type="cantaloupe" label="Data" onClick={() => setModalValue({
          title: 'Terima Data',
          visible: true,
          data: null,
          className: "admin modal-reponsive",
          contentTemplate:
            <VerifyComponent
              tenantId={record.tenantId}
              onFinish={onFinishSubmit}
              onClose={() => setModalValue(null)}
            />
        })} /> : value === 'verified' ?
          aclService.can(['tenant.shipping_integration.edit', 'tenant.shipping_integration.create']) &&
          <Button type={'banana'} label={'Approval'} onClick={() => functionDetailApproval(record)} /> :
          <Button type={'aqua'} label={'Detail'} onClick={() => functionDetailApproval(record)} />
      }
    </>
  }

  function functionDetailApproval(record) {
    setModalValue({
      title: 'Verification Request',
      visible: true,
      data: null,
      className: "admin modal-reponsive",
      contentTemplate:
        <DetailAndApproval
          relatedData={relatedData}
          tenantId={record.tenantId}
          onFinish={onFinishSubmit}
          onClose={() => setModalValue(null)}
        />
    });
  }

  function onFinishSubmit() {
    setModalValue(null);
    baseListRef.current.callLoadData();
  }

  function prepareUserLogDetailModal(tenantId) {
    baseListRef.current.showLoading(true);
    const obs = shippingIntegrationRestService.getAdminHistory(tenantId);
    handleRequest({
      obs,
      hideError: true,
      onError: (err) => {
        baseListRef.current.showLoading(false);
      },
      onDone: (res) => {
        baseListRef.current.showLoading(false);
        setModalValue({
          title: 'Log Detail',
          visible: true,
          data: null,
          className: "admin",
          contentTemplate: <LogdetailComponent record={res} />
        })
      }
    })
  }

  useEffect(() => {
    loadRelatedData();
  }, []);

  useEffect(() => {
  }, [relatedData])
  return (
    <>
      <BaseListComponent
        listId="verification-request-list"
        ref={baseListRef}
        columns={columns}
        loadDataObservable={loadDataObservable}
        includes={{
          ...includes,
          relatedData: relatedData,
          moreFilter: {
            template:
              <VerificationRequestMoreFilterComponent />
          }
        }}
      />

      {modalValue &&
        <Modal
          {...modalValue}
          footer={null}
          width="fit-content"
          onCancel={() => setModalValue(null)}
        >
          {modalValue.contentTemplate}
        </Modal>
      }
    </>
  )
}

export default VerificationRequestListComponent;
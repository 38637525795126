import React, { useEffect, useState } from 'react';
import {
  Option,
} from '@clodeo/clodeo-ui/components/data-entry/autocomplete/autocomplete.component';
import { environment } from 'apps/admin-web/src/environments/environment';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { FormInstance } from 'antd/lib/form';
import moment from 'moment';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { RangePicker } from '@clodeo/clodeo-ui/components/data-entry/datepicker/datepicker.component';
import { Form } from 'antd';
import { AdminTenantBankRestService } from '@clodeo/libs/core/rest/admin/tenant-bank/admin-tenant-bank-rest.service';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { Select } from '@clodeo/clodeo-ui/components/data-entry/select/select.component';

const WithdrawOfTenantFundsFilter = (props: { form?: FormInstance<any> }) => {
  const { form } = props;
  const notif: Notifications = new Notifications();
  const authenticationService: AuthenticationService = new AuthenticationService();
  const [loading, setLoading] = useState<boolean>(false);
  const adminTenantBankRestService = new AdminTenantBankRestService(
    environment.ENDPOINTS,
    authenticationService.axiosInterceptors
  );
  const dateFormat = 'YYYY-MM-DD';
  const lowDate = moment().subtract(3, 'months');
  const highDate = moment(new Date);

  const [bankList, setBankList] = useState<any>([]);

  function getAllListBank(keyword?) {
    setLoading(true);
    return adminTenantBankRestService
      .loadRelated({ params: { keyword } })
      .pipe(
        tap((res: any) => {
          setBankList(res.bank);
        }),
        catchError((err) => {
          setLoading(false);
          notif.show({
            type: 'error',
            title: 'Error',
            description: err,
            useService: true,
          });
          return throwError(err);
        })
      )
      .subscribe(() => {
        setLoading(false);
      });
  }


  function onChangeDateValues(dateValues) {
    let lowDate: string;
    let highDate: string;

    if (dateValues && dateValues.length) {
      lowDate = dateValues[0]
        ? moment(dateValues[0]).format('YYYY-MM-DD')
        : null;
      highDate = dateValues[1]
        ? moment(dateValues[1]).format('YYYY-MM-DD')
        : null;
    }

    form.setFieldsValue({
      lowDate,
      highDate
    })
  }

  useEffect(() => {
    getAllListBank();
  }, []);

  return (
    <>
      <div className="d-flex" id="withdraw-tenant-funds-more-filter">
        <div className="d-flex flex-column">
          <label htmlFor="" className="mb-2">
            Bank
          </label>
          <Spinner spinning={loading}>
            <Form.Item name="bankNames">
              <Select
                mode="multiple"
                allowClear
                placeholder="Pilih Bank"
                style={{
                  width: '150px'
                }}
              >
                {bankList.map((value) => (
                  <Option key={value.id} value={value.bankName}>
                    {value.bankName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Spinner>
        </div>
        <div className="d-flex ml-2" id="range-picker-withdraw-more-filter">
          <div className="d-flex flex-column">
            <label htmlFor="" className="mb-2">
              Tanggal
            </label>
            <Form.Item name="dateValues">
            <RangePicker defaultValue={[moment(lowDate, dateFormat), moment(highDate, dateFormat)]} onChange={onChangeDateValues} />
              {/* <RangePicker onChange={onChangeDateValues} /> */}
            </Form.Item>
          </div>
        </div>
      </div>
    </>
  );
};

export { WithdrawOfTenantFundsFilter };

import { Rule } from 'antd/lib/form';

export class UsersService {
  formRules = {
    username: [{ required: true, message: 'Username tidak boleh kosong' }],
    email: [
      { type: 'email', message: 'Format email salah' },
      { required: 'true', message: 'Email tidak boleh kosong' }
    ],
    firstname: [{ required: true, message: 'Nama awal tidak boleh kosong' }],
    lastName: [{ required: true, message: 'Nama akhir tidak boleh kosong' }],
    nationalPhoneNumber: [
      { type: 'number', message: 'Nomor tidak valid' },
      { required: true, message: 'Nomor tidak boleh kosong' }
    ],
    password: [{ required: true, message: 'Password tidak boleh kosong' }]
  };

  dataIsValid(oneData) {
    for(const value of Object.values(oneData)) {
      if (!value) {
        return false
      }
    }
    return true
  }

  randomstring(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }
}
import { RangePicker } from '@clodeo/clodeo-ui/components/data-entry/datepicker/datepicker.component';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Select, Option } from '@clodeo/clodeo-ui/components/data-entry/select/select.component';

export const VerificationRequestMoreFilterComponent = (
  props: {
    values?: any,
    form?: any
  }
) => {
  const { form } = props;
  const [dateValues, setDateValues] = useState<any>(null);

  const StatusComponent = () => {
    const statuses = [
      { label: 'Pending', value: 'pending' },
      { label: 'Verified', value: 'verified' },
      { label: 'Approved', value: 'approved' },
      { label: 'Rejected', value: 'rejected' }]
    return statuses.map(st => (
      <Option key={st.value} value={st.value}>{st.label}</Option>
    ))
  }

  function handleChange(value) {
    form.setFieldsValue({ statuses: value.toString() });
  }
  useEffect(() => {
    /**
     * Declare variable
     */
    let lowDate: string;
    let highDate: string;

    /**
     * Assign value to new variable
     */
    if (dateValues && dateValues.length) {
      /**
     * set low and high date from dateValues
     */
      let [low, high] = dateValues;
      lowDate = low ? moment(low).format('YYYY-MM-DD') : null;
      highDate = high ? moment(high).format('YYYY-MM-DD') : null;
      form.setFieldsValue({ lowDate: lowDate, highDate: highDate });
    }

  }, [dateValues]);

  return <>
    <div className="row">
      <div className="col-7" style={{width: "300px"}}>
        <div className="d-flex flex-column mr-2">
          <label htmlFor="" className="mb-2">Tanggal Training</label>
          <RangePicker value={dateValues} onChange={setDateValues} />
        </div>
      </div>
      <div className="col-5" style={{width: "270px"}}>
        <div className="d-flex flex-column">
          <label htmlFor="" className="mb-2">Status</label>
          <Select
            mode="multiple"
            allowClear
            size="middle"
            style={{ width: '100%' }}
            onChange={handleChange}
          >
            {StatusComponent()}
          </Select>
        </div>
      </div>
    </div>
  </>
}

import { WidgetChartBasic } from '@clodeo/clodeo-ui/components/chart/widget/chart-widget.component';
import { AdminRestService } from '@clodeo/libs/core/rest/admin/admin/admin-rest.service';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { SelectGroup, SelectButton } from '@clodeo/clodeo-ui/components/data-entry/select-button/select-button.component';
import { environment } from 'apps/admin-web/src/environments/environment';

import React, { useEffect, useState } from 'react';
import './billing-summary.component.scss';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
const authenticationService: AuthenticationService = new AuthenticationService;
const adminRestService = new AdminRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const { handleRequest } = new HandleService;
export const BillingSummaryComponent = (
  props: {
    onChangeFilter(val: any): void;
  }
) => {
  const [billingSummary, setBillingSummary] = useState<IBillingSummary>();
  const [loading, setLoading] = useState(false);
  const [isLite, setIsLite] = useState(false);
  const [widgetActive, setWidgetActive] = useState<string>('');
  const [isFreeTrial, setIsFreeTrial] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState<string>();
  function loadSummary() {
    setLoading(true)
    const obs = adminRestService.getTenantsSubscriptionsSummary();
    handleRequest({
      obs,
      onDone: (res: IBillingSummary) => { setBillingSummary(res); setLoading(false) }
    })
  }

  function resetFilter() {
    setWidgetActive('');
    props.onChangeFilter({});
  }

  const iconPackage = (pkg: string) => (
    <img src={`https://nx-design-system-web-development.clodeo.com/img/icon/subcription/${pkg}.svg`} alt="" srcSet="" />
  )

  useEffect(() => {
    loadSummary()
  }, [])

  return <div className="wrapper-billing-summary my-3 px-2">
    <>
      <div className="mb-2 w-100 text-right">
        <SelectGroup type="switch" onChange={(e) => setIsLite(e.target.value)} defaultValue={isLite}>
          <SelectButton type="switch" value={false}>{'Clodeo App'}</SelectButton>
          <SelectButton type="switch" value={true}>{'Clodeo Lite'}</SelectButton>
        </SelectGroup>
      </div>
      {!isLite &&
        <div className="row">
          <div className="col-lg-4 p-2">
            <WidgetChartBasic
              active={widgetActive === 'freeTrial'}
              onClick={() => {
                if (widgetActive === 'freeTrial') {
                  resetFilter();
                  return;
                }
                setWidgetActive('freeTrial');
                props.onChangeFilter({
                  freeTrial: true,
                })
              }} loading={loading} type="horizontal" label="Free Trial Tenant (Total)" value={billingSummary?.totalFreeTrial || 0}  icon={iconPackage('freeTrial')} />
          </div>
          <div className="col-lg-4 p-2">
            <WidgetChartBasic
              active={widgetActive === 'freeTrialWeekBeforeExp'}
              onClick={() => {
                if (widgetActive === 'freeTrialWeekBeforeExp') {
                  resetFilter();
                  return;
                }
                setWidgetActive('freeTrialWeekBeforeExp');
                props.onChangeFilter({
                  freeTrialWeekBeforeExp: true,
                })
              }} loading={loading} type="horizontal" label="Free Trial Tenant (7 Hari lagi)" value={billingSummary?.totalFreeTrialNearlyExp || 0}  icon={iconPackage('freeTrialNearlyExp')} />
          </div>
          <div className="col-lg-4 p-2">
            <WidgetChartBasic
              active={widgetActive === 'starter'}
              onClick={() => {
                if (widgetActive === 'starter') {
                  resetFilter();
                  return;
                }
                setWidgetActive('starter');
                props.onChangeFilter({ subscriptionPlan: 'starter' })
              }} loading={loading} type="horizontal" label="Starter Tenant" value={billingSummary?.totalStarter || 0}  icon={iconPackage('starter')} />
          </div>
          <div className="col-lg-4 p-2">
            <WidgetChartBasic
              active={widgetActive === 'growing'}
              onClick={() => {
                if (widgetActive === 'growing') {
                  resetFilter();
                  return;
                }
                setWidgetActive('growing');
                props.onChangeFilter({ subscriptionPlan: 'growing' })
              }} loading={loading} type="horizontal" label="Growing Tenant" value={billingSummary?.totalGrowing || 0}  icon={iconPackage('growing')} />
          </div>
          <div className="col-lg-4 p-2">
            <WidgetChartBasic
              active={widgetActive === 'professional'}
              onClick={() => {
                if (widgetActive === 'professional') {
                  resetFilter();
                  return;
                }
                setWidgetActive('professional');
                props.onChangeFilter({ subscriptionPlan: 'professional' })
              }}
              loading={loading} type="horizontal" label="Professional Tenant" value={billingSummary?.totalProfessional || 0}  icon={iconPackage('professional')} />
          </div>
          <div className="col-lg-4 p-2">
            <WidgetChartBasic
              active={widgetActive === 'enterprise'}
              onClick={() => {
                if (widgetActive === 'enterprise') {
                  resetFilter();
                  return;
                }
                setWidgetActive('enterprise');
                props.onChangeFilter({ subscriptionPlan: 'enterprise' })
              }}
              loading={loading} type="horizontal" label="Enterprise Tenant" value={billingSummary?.totalEnterprise || 0}  icon={iconPackage('enterprise')} />
          </div>
        </div>
      }
      {
        isLite &&
        <div className="row">
          <div className="col-lg-4 p-2">
            <WidgetChartBasic
              active={widgetActive === 'lite_bronze'}
              onClick={() => {
                if (widgetActive === 'lite_bronze') {
                  resetFilter();
                  return;
                }
                setWidgetActive('lite_bronze');
                props.onChangeFilter({ subscriptionPlan: 'lite_bronze' })
              }} loading={loading} type="horizontal" label="Lite Bronze Tenant" value={billingSummary?.totalLiteBronze || 0} icon={iconPackage('lite_bronze')} />
          </div>
          <div className="col-lg-4 p-2">
            <WidgetChartBasic
              active={widgetActive === 'lite_silver'}
              onClick={() => {
                if (widgetActive === 'lite_silver') {
                  resetFilter();
                  return;
                }
                setWidgetActive('lite_silver');
                props.onChangeFilter({ subscriptionPlan: 'lite_silver' })
              }} loading={loading} type="horizontal" label="Lite Silver Tenant" value={billingSummary?.totalLiteSilver || 0} icon={iconPackage('lite_silver')} />
          </div>
          <div className="col-lg-4 p-2">
            <WidgetChartBasic
              active={widgetActive === 'lite_gold'}
              onClick={() => {
                if (widgetActive === 'lite_gold') {
                  resetFilter();
                  return;
                }
                setWidgetActive('lite_gold');
                props.onChangeFilter({ subscriptionPlan: 'lite_gold' })
              }} loading={loading} type="horizontal" label="Lite Gold Tenant" value={billingSummary?.totalLiteGold || 0}  icon={iconPackage('lite_gold')} />
          </div>
        </div>
      }
    </>
  </div>
}

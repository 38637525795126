import React, { useEffect, useState } from 'react';
import { WidgetChart } from '@clodeo/clodeo-ui/components/chart/chart.component';
export const RequestCancelSummaryComponent = (
  props: {
    summary: {
      total: number;
      totalApproved: number;
      totalRejected: number;
    },
    onChangeFilter(val: any): void;
  }
) => {
  const { summary } = props;
  const [requestStatus, setRequestStatus] = useState<string>(null);

  useEffect(() => {
    if (requestStatus) {
      props.onChangeFilter && props.onChangeFilter({requestStatus})
    }
  }, [requestStatus])

  return <div className="row w-100 p-2 m-auto">
    <div className="col-12 col-lg-4 p-2">
      <WidgetChart
        onClick={() => setRequestStatus('all')}
        label="Total Permintaan"
        value={summary.total}
        icon={<IconRequest />}
      />
    </div>
    <div className="col-12 col-lg-4 p-2">
      <WidgetChart
        onClick={() => setRequestStatus('approved')}
        label="Total Permintaan diterima"
        value={summary.totalApproved}
        icon={<IconRequestAccept />}
      />
    </div>
    <div className="col-12 col-lg-4 p-2">
      <WidgetChart
        onClick={() => setRequestStatus('rejected')}
        label="Total Permintaan ditolak"
        value={summary.totalRejected}
        icon={<IconRequestReject />}
      />
    </div>
  </div>
}

const IconRequest = () => (
  <img src="https://nx-design-system-web-development.clodeo.com/img/admin-clodeo/shipment/request-cancel/request.svg" alt="" srcSet="" />
)
const IconRequestAccept = () => (
  <img src="https://nx-design-system-web-development.clodeo.com/img/admin-clodeo/shipment/request-cancel/request-accept.svg" alt="" srcSet="" />
)

const IconRequestReject = () => (
  <img src="https://nx-design-system-web-development.clodeo.com/img/admin-clodeo/shipment/request-cancel/request-reject.svg" alt="" srcSet="" />
)
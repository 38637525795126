import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { find, chain } from 'lodash';
import React, { useEffect, useState } from 'react';
import { UserRolesFormComponent } from '../form/user-roles-form.component';
import { AccessRolesRestService } from '@clodeo/libs/core/rest/admin/acces-roles/access-roles-rest.service';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { environment } from '../../../../../../environments/environment';
import { AuthenticationService } from '../../../../../core/auth/authentication.service';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { RolesServices } from '../roles.service';

export const UserRolesUpdateComponent = (props: {
  accessPermissions: IPermission[],
  roleGroups: IRoleGroups[];
  onFinish: (val?: any) => void;
  onCancel: () => void;
  data: any;
}) => {
  const { accessPermissions, roleGroups, onCancel, onFinish, data } = props;
  const { handleRequest } = new HandleService;
  const [parentForm, setParentForm] = useState<IPayloadAccessRoles>();
  const [loading, setLoading] = useState(false);
  const [normalizeAccessPermissions, setNormalizeAccessPermissions] = useState<INormalizeAccessPermission[]>();
  const authenticationService: AuthenticationService = new AuthenticationService;
  const rolesService: RolesServices = new RolesServices;
  const accessRolesRestService = new AccessRolesRestService(`${environment.ENDPOINTS.API_ADMIN}`, authenticationService.axiosInterceptors);

  function onSubmit(formValue) {
    if (rolesService.checkingPermission({ ...formValue, validPermission: !find(normalizeAccessPermissions, ['isValid', false]) })) {
      setLoading(true);
      const payloads = rolesService.normalizePayload(formValue);
      const obs = accessRolesRestService.updateAccessRoles(data.id, payloads);
      handleRequest({
        errorMessage: '',
        successMessage: 'Access roles berhasil dirubah',
        obs,
        onError: () => setLoading(false),
        onDone: () => {
          setLoading(false);
          onFinish();
        }
      })
    }
  }

  useEffect(() => {
    if (accessPermissions) {
      const normalize = chain(accessPermissions)
        .groupBy('permissionSubGroup')
        .map((value, key) => ({ group: key, permissions: value }))
        .value();

      setNormalizeAccessPermissions(normalize);
    }
  }, [accessPermissions]);

  function onFormChange(payload: IPayloadAccessRoles) {
    setParentForm(payload)
  }

  return <div id="access-roles-create" className="wrapper-create-access-roles">

    <Spinner spinning={loading || false}>
      {normalizeAccessPermissions && <UserRolesFormComponent
        data={data}
        onFormChange={onFormChange}
        accessPermissions={normalizeAccessPermissions}
        roleGroups={roleGroups}
      />}
      <div className="w-100 text-right">
        <Button label="Batal" type="ghosted" className="mr-3" onClick={() => onCancel && onCancel()} />
        <Button label="Simpan" type="primary" onClick={() => onSubmit(parentForm)} />
      </div>
    </Spinner>
  </div>
}

import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { Input } from '@clodeo/clodeo-ui/components/data-entry/inputtext/inputtext.component';
import { AutoComplete, Option } from '@clodeo/clodeo-ui/components/data-entry/autocomplete/autocomplete.component';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import { AuthenticationService } from 'apps/admin-web/src/app/core/auth/authentication.service';
import { AdminTenantBankRestService } from '@clodeo/libs/core/rest/admin/tenant-bank/admin-tenant-bank-rest.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { HandleService } from '@clodeo/libs/core/handle/handle.service';
import { AdminPickerRestService } from '@clodeo/libs/core/rest/admin/picker/admin-picker-rest.service';

const authenticationService: AuthenticationService = new AuthenticationService();
const adminPickerRestService = new AdminPickerRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const adminTenantBankRestService = new AdminTenantBankRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
const { handleRequest } = new HandleService;
export const TenantsBankFormsComponent = (props: TenantFormProps) => {
  const [form] = Form.useForm();
  const [bankList, setBankList] = useState<IBank[]>();
  const [tenantList, setTenantList] = useState<any[]>();
  const { onCancel, onSubmit, record, type } = props;

  const required = [
    {
      required: true,
      message: 'Kolom ini wajib diisi!'
    }
  ];
  const reqNumber = [
    {
      required: true,
      message: 'Kolom ini hanya boleh diisi angka, spasi atau karakter -'
    }
  ];

  function onSubmitForm(val?) {
    const values = {
      ...val,
      ...form.getFieldsValue(true)
    };
    onSubmit && onSubmit(values);
  }

  function onBankSearch(query) {
    const params = {
      keyword: query
    };
    loadRelatedData(params);
  }

  function loadRelatedData(params) {
    const obs = adminTenantBankRestService.loadRelated({ params })
    handleRequest({
      obs,
      onDone: (res: any) => setBankList(res.bank)
    })
  }

  function loadTenant(query) {
    const obs = adminPickerRestService.getTenant({}, { keyword: query });
    handleRequest({
      obs,
      onDone: (res: any) => setTenantList(res.data)
    })
  }

  function onFormValueChanges() {
    const accountNumberValue = form.getFieldValue('accountNumber');
    if (accountNumberValue) {
      form.setFieldsValue({
        accountNumber: accountNumberValue.replace(/(?![0-9]|[\-\s])./gi, '')
      });
    }
  }


  useEffect(() => {
    type == 'update' && form.setFieldsValue({
      ...record
    });
  }, [record])

  return (
    <Form
      form={form}
      onFinish={onSubmitForm}
      onValuesChange={onFormValueChanges}
    >
      <div className="tenant-add-wrapper px-3" style={
        { width: '45vw', }
      }>
        <div className="row">
          <div className="mb-3 col-lg-6">
            <div className="d-flex flex-column">
              <label htmlFor="" className="field field-required">Tenant</label>
              <Form.Item
                name='tenant'
                rules={required}
              >
                <AutoComplete
                  disabled={type == 'update'}
                  onDropdownVisibleChange={(open) => open ? loadTenant('') : setTenantList(null)}
                  onSearch={loadTenant}
                  onSelect={(val, opt) => form.setFieldsValue({ 'tenantId': opt.key })}
                  onClear={() => form.setFieldsValue({ 'tenantId': '' })}
                >
                  {tenantList && tenantList.map(tenant => (
                    <Option key={tenant.id} value={tenant.companyName}>
                      {tenant.displayName}
                    </Option>
                  ))}
                </AutoComplete>
              </Form.Item>
            </div>
          </div>
          <div className="mb-3 col-lg-6">
            <div className="d-flex flex-column">
              <label htmlFor="" className="field field-required">Nama Bank</label>
              <Form.Item
                name='bank'
                rules={required}
              >
                <AutoComplete
                  onDropdownVisibleChange={(open) => open ? onBankSearch('') : setBankList(null)}
                  onSearch={onBankSearch}
                  onSelect={(val, opt) => form.setFieldsValue({ 'bankId': opt.key })}
                  onClear={() => form.setFieldsValue({ 'bankId': '' })}
                >
                  {bankList && bankList.map(bank => (
                    <Option key={bank.id} value={bank.bankName}>
                      {bank.bankName}
                    </Option>
                  ))}
                </AutoComplete>
              </Form.Item>
            </div>
          </div>
          <div className="mb-3 col-lg-6">
            <div className="d-flex flex-column">
              <label htmlFor="" className="field field-required">No. Rekening</label>
              <Form.Item
                name='accountNumber'
                rules={reqNumber}
              >
                <Input type="text" className="w-100" />
              </Form.Item>
            </div>
          </div>
          <div className="mb-3 col-lg-6">
            <div className="d-flex flex-column">
              <label htmlFor="" className="field field-required">Nama Rekening</label>
              <Form.Item
                name='accountName'
                rules={required}
              >
                <Input />
              </Form.Item>
            </div>
          </div>
          <div className="mb-3 col-lg-12">
            <div className="d-flex flex-column">
              <label htmlFor="" className="field">Cabang</label>
              <Form.Item
                name='district'
              >
                <Input />
              </Form.Item>
            </div>
          </div>
          <div className="col-12">
            <div className="action justify-content-center d-flex w-100 mt-2">
              <Button type="ghosted" label="Batal" className="mr-2" onClick={onCancel} />
              <Button submit type="primary" label="Simpan" />
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}

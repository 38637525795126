import React, {
  useState,
} from 'react';
import { sumBy } from 'lodash';
import * as accountingJS from 'accounting-js';
import { IColumn } from 'apps/admin-web/src/app/components/base-component/list/base-list';
import { Summary, Table } from '@clodeo/clodeo-ui/components/data-display/table/table.component';
import { Spinner } from '@clodeo/clodeo-ui/components/feedback/spinner/spinner.component';
import { FormatService } from '@clodeo/libs/core/format/format.service';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';


const formatService = new FormatService();
export const RequestWithdrawFundPreviewComponent = ({
  oriRowsUploadWallet,
  onCancel,
  onSubmit,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataDetailUploadWallet, setDataDetailUploadWallet] = useState<any>(
    null
  );
  const [rowsUploadWallet, setRowsUploadWallet] = useState<any>(
    oriRowsUploadWallet
  );

  const columns: IColumn[] = [
    {
      key: '0',
      title: 'No',
      width: '30px',
      render: (value, record, index) => index + 1,
    },
    {
      key: '1',
      title: 'Id Tenant',
      dataIndex: 'tenantId',
      width: '100px',
    },
    {
      key: '2',
      title: 'Os Name',
      dataIndex: 'companyName',
      width: '150px',
    },
    {
      key: '3',
      title: 'Total Balance Yang Ditransfer',
      dataIndex: 'amount',
      width: '150px',
      render: (value) => formatService.currency(value),
    },
    {
      key: '4',
      title: 'Total Balance OS (Sistem)',
      dataIndex: 'currentBalance',
      width: '150px',
      render: (value) => formatService.currency(value),
    },
    {
      key: '5',
      title: 'No Rekening',
      dataIndex: 'bankAccountNumber',
      width: '150px',
    },
    {
      key: '6',
      title: 'Notes',
      dataIndex: 'notes',
      width: '150px',
    },
  ];

  return (
    <div>
      {!dataDetailUploadWallet && (
        <>
          <div className="row">
            <div className="col">
              <Spinner className="d-flex align-item-center" spinning={loading}>
                <Table
                  columns={columns} dataSource={rowsUploadWallet.rows || []}
                  summary={(data) => <Summary.Row>
                    <Summary.Cell index={0}>
                      <strong>Total</strong>
                    </Summary.Cell>
                    <Summary.Cell index={1}>
                      <strong>{data.length || 0}</strong>
                    </Summary.Cell>
                    <Summary.Cell index={2}>
                    </Summary.Cell>
                    <Summary.Cell index={3}>
                      <strong>{
                        accountingJS.formatMoney(sumBy(oriRowsUploadWallet.rows, 'amount') || 0, { symbol: 'Rp ', format: '%s %v' })
                      }</strong>
                    </Summary.Cell>
                    <Summary.Cell index={4}>
                      <strong>{
                        accountingJS.formatMoney(sumBy(oriRowsUploadWallet.rows, 'currentBalance') || 0, { symbol: 'Rp ', format: '%s %v' })
                      }</strong>
                    </Summary.Cell>
                  </Summary.Row>
                  }
                />
              </Spinner>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-3">
            <Button
              type="ghosted"
              label="Kembali"
              className="mr-2"
              onClick={() => onCancel()}
            />
            <Button
              submit
              type="primary"
              label="Submit"
              onClick={() => onSubmit()}
            />
          </div>
        </>
      )}
    </div>
  );
};

import { RequestService } from '../../request.service';
import { AdminRestService } from '../admin/admin-rest.service';

export class AdminTenantSalesInvoiceRestService {
  requestService = new RequestService;
  request: RequestService;
  /**
   * 
   * @param baseUrl only from environment.ENDPOINTS
   * @param useInterceptor 
   * @param withoutInterceptor 
   */
  constructor(baseUrl, useInterceptor: any = true, withoutInterceptor: boolean = false) {
    this.request = this.requestService.new(`${baseUrl['API_ADMIN']}/tenants/sales-invoice`, useInterceptor, withoutInterceptor);
  }

  /**
   * Get list of sales invoice integration courier
   * @param qParams 
   */
  findAll(qParams) {
    return this.request.get('', qParams)
  }

  /**
   * Get list of sales invoice integration courier with more detail search params
   * @param qParams 
   */
  findAllQ(qParams) {
    return this.request.post('q', qParams)
  }

  /**
   * Get history of Request Pickup Send
   * @param id 
   */
  getHistories(id: string) {
    return this.request.get(`${id}/histories`)
  }

  /**
   * Sent sales invoice to courier
   * @param payload 
   */
  sendPickupRequest(payload) {
    return this.request.put('sent-pickup-request', payload)
  }
  
  /**
   * Create multiple sales down payment
   * @param payload 
   */
  fullyPaid(payload) {
    return this.request.put('fully-paid', payload)
  }

  loadRelated() {
  }

  /**
   * Get history of admin
   * @param tenantId 
   */
   getAdminHistory(tenantId: string, invoiceId: string) {
    return this.request.get(`${tenantId}/${invoiceId}/histories`);
  }

}

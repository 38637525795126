import React, { useEffect, useState } from 'react';
import { Notifications } from '@clodeo/clodeo-ui/components/feedback/notification/notification.component';
import { AuthenticationService } from '../../../../core/auth/authentication.service';
import { environment } from 'apps/admin-web/src/environments/environment';
import { catchError, finalize } from 'rxjs/operators';
import { DashboardSummary } from '../summary/dashboard-summary.component';
import { throwError } from 'rxjs';
import { DashboardMoreFilterComponent } from '../more-filter/dashboard-more-filter.component';
import { DashboardRestService } from '@clodeo/libs/core/rest/admin/dashboard/dashboard.service';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';
import '../dashboard.component.scss';
import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import moment from 'moment';

const DashboardComponent: React.FC = () => {
  const authenticationService: AuthenticationService = new AuthenticationService();
  const dashboardRestService = new DashboardRestService(
    environment.ENDPOINTS,
    authenticationService.axiosInterceptors
  );

  const lowDate = moment().subtract(3, 'months').format('YYYY-MM-DD');
  const highDate = moment(new Date).format('YYYY-MM-DD');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const notif: Notifications = new Notifications();
  const [showMoreFilter, setshowMoreFilter] = useState(false);
  const defaultParams = {
    lowDate: lowDate,
    highDate: highDate,
  }

  const [params, setParams] = useState<any>(defaultParams);

  const [summary, setSummary] = useState({
    creditCOD: null,
    creditRemaining: null,
    creditTotal: null,
    feeCOD: null,
    countCODUnpaid: null
  });

  const [salesCOD, setSalesCOD] = useState(null);
  const [salesCODTotal, setSalesCODTotal] = useState(null);

  function loadSummary() {
    const qparams = { ...params };
    setIsLoading(true);
    return dashboardRestService
      .getSummary({ params: qparams })
      .pipe(
        catchError((err) => {
          notif.show({
            type: 'error',
            title: 'Error',
            description: err,
            useService: true,
          });
          return throwError(err);
        })
      )
      .subscribe((res: any) => {
        setSummary({ ...summary, ...res });
      });
  }

  function getSalesCODCount() {
    const qparams = { ...params };
    setIsLoading(true);
    return dashboardRestService
      .getSalesCODCount({ params: qparams })
      .pipe(
        catchError((err) => {
          notif.show({
            type: 'error',
            title: 'Error',
            description: err,
            useService: true,
          });
          return throwError(err);
        })
      )
      .subscribe((res: any) => {
        setSalesCOD(res.count);
      });
  }

  const filterAllData = () => {
    const filterValue = {
      ...params,
    };
    return dashboardRestService.getSummary({ params: filterValue });
  };

  function getSalesCODTotalAmount() {
    const qparams = { ...params };
    setIsLoading(true);
    return dashboardRestService
      .getSalesCODTotalAmount({ params: qparams })
      .pipe(
        catchError((err) => {
          notif.show({
            type: 'error',
            title: 'Error',
            description: err,
            useService: true,
          });
          return throwError(err);
        }),
        finalize(() => {
          setIsLoading(false);
        })
      )
      .subscribe((res: any) => {
        setSalesCODTotal(res.total);
      });
  }

  function loadAllData() {
    setIsLoading(true);
    loadSummary();
    getSalesCODCount();
    getSalesCODTotalAmount();
  }

  useEffect(() => {
    setIsLoading(false);
  }, [summary, salesCOD, salesCODTotal]);

  useEffect(() => {
    loadSummary();
    getSalesCODCount();
    getSalesCODTotalAmount();
  }, []);

  useEffect(() => {
    filterAllData();
  }, []);

  return (
    <>
      <div className="d-flex">
        <div className="text-lg-left">
          <Button
            icon
            iconPosition="left"
            type="ghosted"
            iconName="deo-filter"
            className="p-2 text-center"
            onClick={() => setshowMoreFilter(!showMoreFilter)}
          />
        </div>
      </div>
      <Modal
        width="fit-content"
        className="admin"
        visible={showMoreFilter}
        title="Tanggal"
        footer={null}
        onCancel={() => setshowMoreFilter(false)}
      >
        {showMoreFilter && (
          <div className="d-flex flex-column w-100 animate__animated animate__fadeIn">
            <div className="p-2">
              <DashboardMoreFilterComponent
                onMoreFilterChanges={setParams}
                values={params}
              />
            </div>
            <div className="p-2 w-100 justify-content-between d-flex justify-content-lg-end">
              <Button
                type="ghosted"
                label="Reset"
                className="mr-2"
                onClick={() => setParams(defaultParams)}
              />
              <Button
                label="Apply"
                type="primary"
                onClick={() => {
                  loadAllData();
                  setshowMoreFilter(false);
                }}
              />
            </div>
          </div>
        )}
      </Modal>
      <DashboardSummary
        summary={summary}
        salesCOD={salesCOD}
        salesCODTotal={salesCODTotal}
        isLoading={isLoading}
      />
    </>
  );
};

export { DashboardComponent };

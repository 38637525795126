import { ExportService } from "@clodeo/libs/core/export/export.service";
import { FormatService } from '@clodeo/libs/core/format/format.service';
import { AdminCreditCodRestService } from '@clodeo/libs/core/rest/admin/credit-cod/admin-credit-cod.service';
import { IRelatedData } from '@clodeo/libs/core/rest/admin/credit-cod/admin-credit-cod';
import { environment } from "apps/admin-web/src/environments/environment";
import moment from "moment";
import { compact, startCase } from "lodash";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { IColumn, IIncludes } from "../../../../../components/base-component/list/base-list";
import { BaseListComponent } from "../../../../../components/base-component/list/base-list.component";
import { AuthenticationService } from "../../../../../core/auth/authentication.service";
import { IModalValue } from '@clodeo/clodeo-ui/components/feedback/modal/modal';
import { Button } from '@clodeo/clodeo-ui/components/ui-elements/button/button.component';

import { AclService } from 'apps/admin-web/src/app/core/auth/acl.service';
import { TransferCreditCodUploadComponent } from '../upload/transfer-credit-cod-upload.component';

import { Modal } from '@clodeo/clodeo-ui/components/feedback/modal/modal.component';
import { HandleService } from "@clodeo/libs/core/handle/handle.service";
import { Notifications } from "@clodeo/clodeo-ui/components/feedback/notification/notification.component";
import { throwError } from "rxjs";
import { TransferCreditCodDetailRFComponent } from "../detail/transfer-credit-cod-detail-rf.component";
import { AdminCodBalanceRestService } from "@clodeo/libs/core/rest/admin/cod-balance/admin-cod-balance-rest.service";
import _ from "lodash";

export const TransferCreditCodListComponent = () => {
  const baseListRef = useRef<any>();
  const authenticationService: AuthenticationService = new AuthenticationService();
  const adminCreditCodRestService = new AdminCreditCodRestService(environment.ENDPOINTS, authenticationService.axiosInterceptors);
  const exportService: ExportService = new ExportService();
  const aclService: AclService = new AclService;
  const formatService = new FormatService();
  const { handleRequest } = new HandleService;
  const [relatedData, setRelatedData] = useState<IRelatedData>(null);
  const [modalValue, setModalValue] = useState<IModalValue>();
  const notif = new Notifications();

  const includes: IIncludes = {
    refresh: true,
    keyword: true,
    toogleOptions: true,
    exports: [{
      label: 'Export berdasarkan filter',
      basedFilter: true,
      fileName: 'Tenants | By Filter'
    }, {
      label: 'Export semua data',
      fileName: 'Tenants'
    }],
    // extraButtons: _.compact([
    //   aclService.can(['cod.transfer_credit.create', 'cod.transfer_credit.edit']) && {
    //     label: 'UPLOAD RESI',
    //     type: 'filter',
    //     action: () => setModalValue({
    //       visible: true,
    //       title: 'Upload Laporan Resi',
    //       contentTemplate: (
    //         <TransferCreditCodUploadComponent
    //           relatedData={relatedData}
    //           onFinish={() => { setModalValue(null) }}
    //           onCancel={() => { setModalValue(null) }}
    //         />
    //       ),
    //       onCancel: () => { setModalValue(null) }
    //     }),
    //     iconName: 'deo-upload'
    //   }
    // ]),
    onlyExportAllData: true,
    fileNameExportAllData: 'Transfer Credit COD'
  }

  const columns: IColumn[] = [
    {
      key: '0',
      dataIndex: 'id',
      title: 'ID',
      ignoreDisply: true
    },
    {
      key: '1',
      dataIndex: 'uploadDate',
      width: '150px',
      title: 'UPLOAD DATE',
      render: (value) => {
        return moment(value).format('DD MM YYYY');
      },
      formatExprt: (value) => {
        if (!value) {
          return '-';
        }
        moment.locale('id');
        const utcDate = moment.utc(value);
        const result = utcDate.tz(moment.tz.guess(true)).format('DD-MM-YYYY HH:mm');
        return result;
      },
    },
    {
      key: '2',
      dataIndex: 'documentNumber',
      width: '150px',
      title: 'ID FILE',
      align: 'center',
    },
    {
      key: '3',
      dataIndex: 'uploadBy',
      width: '150px',
      title: 'UPLOAD BY'
    },
    {
      key: '4',
      dataIndex: 'codCreditTransactionStatus',
      width: '150px',
      title: 'STATUS TRANSFER',
      render: (value) => startCase(value)
    },
    {
      key: '5',
      dataIndex: 'id',
      title: 'ACTION',
      width: '150px',
      ignoreExprt: true,
      align: 'center',
      render: (value, record) => {
        return <Fragment>
          <Button type="tertiary" label="Detail" onClick={() => {
            setModalValue({
              visible: true,
              title: 'Data Resi COD',
              contentTemplate: <TransferCreditCodDetailRFComponent
                id={value}
                onCancel={() => setModalValue(null)}
              />,
              onCancel: () => { setModalValue(null) }
            })
          }} />
        </Fragment>
      }
    },
    {
      key: '6',
      dataIndex: 'courierChannelId',
      title: 'Kurir',
      ignoreDisply: true
    },
  ];

  const loadDataObservable = (params) => {
    return adminCreditCodRestService.findAll({ params })
  }

  function loadRelated() {
    return adminCreditCodRestService.loadRelated().subscribe(setRelatedData);
  }

  useEffect(() => {
    loadRelated();
  }, []);

  function snycCreditCOD(record) {
    baseListRef.current.showLoading(true);
    const obs = adminCreditCodRestService.snycTransferCreditCOD(record.id);
    handleRequest({
      obs,
      hideError: true,
      onError: () => {
        baseListRef.current.callLoadData();
        baseListRef.current.showLoading(false);
      },
      onDone: () => {
        setTimeout(() => {
          baseListRef.current.showLoading(false);
          baseListRef.current.callLoadData();
        }, 2000);
      }
    })
  }

  return <Fragment>

    <BaseListComponent
      listId="transfer-credit-cod" // ID Wajib diisi
      ref={baseListRef}
      columns={columns}
      loadDataObservable={loadDataObservable}
      includes={includes}
    />

    {
      modalValue && <Modal
        {...modalValue}
        footer={null}
        className="admin"
        width="fit-content"
      >
        {modalValue.contentTemplate}
      </Modal>
    }

  </Fragment >
}
